var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseConfirmacion',{ref:"confirmar"}),_vm._v(" "),_c('v-card',{attrs:{"flat":""}},[_c('v-card-subtitle',[_c('v-radio-group',{attrs:{"dense":"","row":""},model:{value:(_vm.estadoReportes),callback:function ($$v) {_vm.estadoReportes=$$v},expression:"estadoReportes"}},[_c('v-radio',{attrs:{"dense":"","color":"black","label":"Todos","value":"0"}}),_vm._v(" "),_c('v-radio',{attrs:{"dense":"","color":"red","label":"Pendientes","value":"1"}}),_vm._v(" "),_c('v-radio',{attrs:{"dense":"","color":"yellow","label":"En proceso","value":"2"}}),_vm._v(" "),_c('v-radio',{attrs:{"dense":"","color":"green","label":"Solucionados","value":"3"}})],1)],1),_vm._v(" "),_c('v-card-subtitle',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-btn',{staticClass:"rounded-pill pa-3 mr-2",attrs:{"dense":"","color":"red","dark":""},on:{"click":function($event){return _vm.nuevoReporte()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-ticket-account")]),_vm._v("\n            Agregar\n          ")],1)],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":"","solo":"","rounded":""},model:{value:(_vm.search_tickets),callback:function ($$v) {_vm.search_tickets=$$v},expression:"search_tickets"}})],1)],1)],1),_vm._v(" "),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers_tickets,"items":_vm.tickets,"search":_vm.search_tickets},scopedSlots:_vm._u([{key:"item.chat",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.abrirChat(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chat")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Abrir Chat")])])]}},{key:"item.cliente",fn:function(ref){
var item = ref.item;
return [(item.cliente.eliminado == 0)?_c('v-chip',{attrs:{"outlined":"","dark":"","small":"","color":"cyan"},on:{"click":function($event){return _vm.abrirCliente(item.cliente)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_vm._v("\n            "+_vm._s(item.cliente.FullName))],1):_c('v-chip',{attrs:{"outlined":"","dark":"","small":"","color":"black"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-emoticon-dead")]),_vm._v("\n            "+_vm._s(item.cliente.FullName))],1)]}},{key:"item.estado_reporte_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.asignarColor(item.estado_reporte_id).color,"small":"","dark":""},on:{"click":function($event){return _vm.cambiarEstado(item)}}},[_vm._v(_vm._s(_vm.asignarColor(item.estado_reporte_id).texto))])]}},{key:"item.opciones",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.eliminarReporte(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Eliminar")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.editarReporte(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Editar")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.detallesReporte(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-card-bulleted")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Detalles reporte")])])]}}],null,true)})],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"300px"},model:{value:(_vm.dialogEstados),callback:function ($$v) {_vm.dialogEstados=$$v},expression:"dialogEstados"}},[_c('v-card',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Cambiar Estado ")]),_vm._v(" "),_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-form',{ref:"formEstados",attrs:{"lazy-validation":""},model:{value:(_vm.validEstados),callback:function ($$v) {_vm.validEstados=$$v},expression:"validEstados"}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-select',{attrs:{"label":"Status","rules":_vm.generalRegla,"item-text":"estado","item-value":"id","items":_vm.estados},model:{value:(_vm.estado_id),callback:function ($$v) {_vm.estado_id=$$v},expression:"estado_id"}})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialogEstados = false}}},[_vm._v("Cancelar")]),_vm._v(" "),_c('v-btn',{staticClass:"rounded-pill pa-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.actualizarEstado()}}},[_vm._v("Cambiar")])],1)],1)],1)],1),_vm._v(" "),_c('editarReporte',{ref:"refReporteEditar"}),_vm._v(" "),_c('exportar',{ref:"refExportar"}),_vm._v(" "),_c('nuevoReporte',{ref:"refReporte"}),_vm._v(" "),_c('chat',{ref:"refChat"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }