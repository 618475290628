e
<template>
  <div>
    <v-dialog v-model="dialogStatus" max-width="400px">
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title> Estado clientes </v-card-title>
          <v-card-subtitle>
            Los clientes seleccionados cambiaran de estado
          </v-card-subtitle>
          <v-form
            dense
            @submit.prevent="actualizarStatus()"
            ref="form"
            v-model="valid"
          >
            <v-card-text>
              <v-form dense ref="formstatus" v-model="valid" lazy-validation>
                <v-select
                  :items="estados"
                  v-model="status_id"
                  label="Status"
                  item-value="id"
                  item-text="status"
                  :rules="generalRegla"
                  prepend-icon="mdi-power-settings"
                ></v-select>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="cancelar()">Cancelar</v-btn>
              <v-btn
                :disabled="!valid"
                type="submit"
                class="rounded-pill pa-3"
                color="primary"
                >Actualizar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNoActualizados" scrollable max-width="400px">
      <v-card>
        <v-container grid-list-md>
          <v-card-title>Clientes no actualizados</v-card-title>
          <v-card-subtitle>
            Los siguientes clientes no se pudieron actualizar. Revise su
            conexion con sus RB.
          </v-card-subtitle>
          <v-responsive
            id="scroll-target"
            class="overflow-y-auto"
            :max-height="450"
          >
            <v-card-text>
              <div v-for="cliente in noActualizados" :key="cliente.id">
                <p>Cliente: {{ cliente.FullName }}</p>
                <p>Error: {{ cliente.error }}</p>
                <v-divider></v-divider>
              </div>
            </v-card-text>
          </v-responsive>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="actualizarStatus()">Intentar de nuevo</v-btn>
            <v-btn class="rounded-pill pa-3" color="primary" @click="ok()"
              >Ok</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Common from "@/js/Common";
export default {
  data() {
    return {
      status_id: "",
      dialogStatus: false,
      resolve: null,
      reject: null,
      estados: [],
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      seleccionados: [],
      estados: [],
      noActualizados: [],
      dialogNoActualizados: false,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    cargarDatos(seleccionados) {
      this.getDatos();
      this.dialogStatus = true;
      this.seleccionados = seleccionados;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `estados/todos`,
          data: {},
        })
        .then((result) => {
          this.estados = result.data.estados;
        });
    },

    actualizarStatus() {
      if (this.$refs.formstatus.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = `cliente/actualizar/status/masivo`;
        if (this.noActualizados.length > 0) {
          this.seleccionados = this.noActualizados;
        }
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: {
              status_id: this.status_id,
              seleccionados: this.seleccionados,
            },
          })
          .then((result) => {
            if ([200, 201].includes(result.status)) {
              var noActualizados = result.data.clientes_no_actualizados;
              if (noActualizados.length > 0) {
                this.noActualizados = noActualizados;
                this.dialogStatus = false;
                this.dialogNoActualizados = true;
              } else {
                this.dialogStatus = false;
                this.noActualizados = [];
                this.resolve(true);
              }
            }
          });
      }
    },
    cancelar() {
      this.dialogNoActualizados = false;
      this.dialogStatus = false;
      this.resolve(false);
      this.noActualizados = [];
    },
    ok() {
      this.dialogNoActualizados = false;
      this.dialogStatus = false;
      this.resolve(true);
      this.noActualizados = [];
    },
  },
};
</script>