class Servicio {
    constructor() {
        this.nombre = "";
        this.costo = "";
        this.subida = 0;
        this.bajada = 0;
        this.detalles = "";
        this.tipo_servicio = "";
        this.burst_limit_bajada = null;
        this.burst_limit_subida = null;
        this.burst_threshold_bajada = null;
        this.burst_threshold_subida = null;
        this.burst_time_bajada = null;
        this.burst_time_subida = null;
    }


}

export default Servicio