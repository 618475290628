let sonido_guardado = new Audio("sounds/save_request.mp3");
let sonido_actualizado = new Audio("sounds/update_request.mp3");
let sonido_error = new Audio("sounds/error_request.mp3");


const state = {
    items: [],
    item: {}
}
const mutations = {
    SET_ITEMS_STATE: (state, value) => {
        state.items = value
    },
    SET_ITEM_STATE: (state, value) => {
        state.item = value
    }
}
const actions = {
    OnGet: ({ commit, dispatch, getters }, payload) => {
        return new Promise((resolve, reject) => {
            dispatch('get', payload, { root: true }).then(response => {
                if ([200, 201].includes(response.status)) {
                    resolve(response)

                } else {
                    reject();
                }
            })
        })
    },
    OnGetSP: ({ commit, dispatch, getters }, payload) => {
        return new Promise((resolve, reject) => {
            dispatch('getSP', payload, { root: true }).then(response => {
                resolve(response)
            })
        })
    },
    OnSave: ({ commit, dispatch, getters }, payload) => {
        return new Promise((resolve, reject) => {
            dispatch('post', payload, { root: true }).then(response => {
                if ([200, 201].includes(response.status)) {
                    dispatch('notificaciones/add', { tipo: "success", mensaje: "Guardado", show: true, dialog: false, tiempo: 5 }, { root: true });
                    resolve(response)

                    sonido_guardado.play();
                } else {
                    reject();
                    sonido_error.play();
                }
            })
        })
    },
    OnSaveSP: ({ commit, dispatch, getters }, payload) => {
        return new Promise((resolve, reject) => {
            dispatch('post', payload, { root: true }).then(response => {
                resolve(response)
            })
        })
    },

    OnUpdate: ({ commit, dispatch, getters }, payload) => {
        return new Promise((resolve, reject) => {
            dispatch('put', payload, { root: true }).then(response => {
                if ([200, 201].includes(response.status)) {
                    dispatch('notificaciones/add', { tipo: "success", mensaje: "Actualizado", show: true, dialog: false, tiempo: 5 }, { root: true });
                    resolve(response)
                    sonido_actualizado.play();
                } else {
                    reject();
                    sonido_error.play();
                }
            })
        })
    },
    OnDelete: ({ commit, dispatch, getters }, payload) => {
        return new Promise((resolve, reject) => {
            dispatch('destroy', payload, { root: true }).then(response => {
                if ([200, 201].includes(response.status)) {
                    dispatch('notificaciones/add', { tipo: "success", mensaje: "Eliminado", show: true, dialog: false, tiempo: 5 }, { root: true });
                    resolve(response)
                    sonido_guardado.play();

                } else {
                    reject();
                    sonido_error.play();

                }
            })
        })
    },
    setItemsState: ({ commit, dispatch, getters }, value) => {//sets items array and returns array items
        //we can dispatch parent action
        //dispatch('someOtherAction', null, { root: true }) // -> 'someOtherAction'
        commit('SET_ITEMS_STATE', value)
        return new Promise((resolve, reject) => {
            resolve(getters.getItemsState)
        })
    },
    setCatalogoState: ({ commit, dispatch, getters }, value) => {//sets single item and returns item
        commit('SET_CATALOGO_STATE', value)
        return new Promise((resolve, reject) => {
            resolve(getters.getItemState)
        })
    },
}
const getters = {
    getItemsState: state => {//returns items array
        return state.items
    },
    getItemState: state => {//returns item
        return state.item
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
