<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout v-if="zona" row wrap>
          <v-flex xs12>
          
            <v-card class="cool">
              <v-card-title class="display-1" primary-title>
                <strong>{{ zona.nombre }}</strong>
                <v-spacer></v-spacer>
                <v-chip color="green" outlined class="my-1 ma-2"> <strong>{{ zona.antenasCantidad }} </strong>  &nbsp; Antenas</v-chip>
                <v-chip color="purple" outlined class="my-1 ma-2"> <strong>{{ zona.routersCantidad }} </strong>  &nbsp; Router</v-chip>
                <v-chip color="blue" outlined class="my-1 ma-2"> <strong>{{ zona.clientesCantidad }} </strong>  &nbsp; Clientes</v-chip>
              </v-card-title>
                
                
              
              <v-card-text>
                 <v-row v-if="router">
                <v-flex xs12 md3>
                <v-card outlined class="cool pa-2 ma-2">

                
                <v-list v-if="router.tipo_conexion != 3 && router.salud.voltaje">
                  <v-list-item>
                    <v-list-item-avatar class="elevation-3">
                      <v-icon class="amber lighten-1">
                        mdi-flash
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ router.salud.voltaje }} V
                      </v-list-item-title>
                      <v-list-item-subtitle>Voltaje</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
                </v-flex>
                <v-flex xs12 md3>
                  <v-card outlined class="cool pa-2 ma-2">
              
                <v-list v-if="router.tipo_conexion != 3 && router.salud.temperatura">
                  <v-list-item>
                    <v-list-item-avatar class="elevation-3">
                      <v-icon class="purple lighten-1">
                        mdi-thermometer
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ router.salud.temperatura }} °C
                      </v-list-item-title>
                      <v-list-item-subtitle>Temperatura</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
                </v-flex>
                <v-flex xs12 md3>
                  <v-card outlined class="cool pa-2 ma-2">
              
                <v-layout v-if="router.tipo_conexion != 3 && router.salud.voltaje">
                  <v-icon>mdi-battery-medium</v-icon><v-list-item-title>Bateria</v-list-item-title> <v-spacer></v-spacer><h5> {{ calcularPorcentaje(router.salud.voltaje) }}</h5>
                </v-layout>
                <v-card-text v-if="router.tipo_conexion != 3 && router.salud.voltaje">
                  <v-progress-linear height="15" :color="obtenerColor(calcularPorcentaje(router.salud.voltaje))" class="rounded-pill"
                    :value="calcularPorcentaje(router.salud.voltaje)">

                  </v-progress-linear>
                </v-card-text>
              </v-card>
                </v-flex>
                <v-flex xs12 md3>

                  <v-card outlined class="cool pa-2 ma-2">
                 
                
                <v-layout v-if="router.tipo_conexion != 3">
                  <v-icon>mdi-chip</v-icon><v-list-item-title>CPU</v-list-item-title> <v-spacer></v-spacer><h5> {{ router.salud.cpu }}%</h5>
                </v-layout>
                <v-card-text v-if="router.tipo_conexion != 3">
                  <v-progress-linear height="15" :color="colorCPU(router.salud.cpu)" class="rounded-pill"
                    :value="router.salud.cpu">
                  </v-progress-linear>
                </v-card-text>


              </v-card>
                </v-flex>
               </v-row>
                <v-row>
                  <v-col cols="12" md="9" flat>
                    <mapaZona v-if="mostrarMapa" ref="mapa"></mapaZona>
                  </v-col>
                  <v-col cols="12" md="3">
                        <v-tabs v-model="tab">
                          <v-tab>Routers</v-tab>
                          <v-tab>Antenas</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                          <v-tab-item>
                            <v-responsive
                              id="scroll-target"
                              class="overflow-y-auto"
                              max-height="480"
                            >
                              <div
                                v-for="router in zona.routers"
                                :key="router.id"
                              >
                                <v-card @click="abrirRouter(router.id)" outlined class="cool pa-2 my-2">
                                  <v-img
                                  class="cool pa-2"
                                    v-if="router.producto.imagen"
                                    :src="router.producto.imagen"
                                    height="100"
                                  ></v-img>
                                  <v-card-title>{{
                                    router.nombre
                                  }}</v-card-title>
                                  <v-card-subtitle>{{
                                    router.usuario
                                  }}</v-card-subtitle>
                                  <v-card-text>
                                    <v-chip color="grey" outlined class="my-1"> <v-icon small color="primary">mdi-ip</v-icon> &nbsp; {{ router.ip }}</v-chip>
                                    <v-chip color="grey" outlined class="my-1"> <v-icon small color="primary">mdi-router-wireless</v-icon> &nbsp; {{ router.producto.producto }}</v-chip>
                                    <v-chip color="grey" outlined class="my-1"> <v-icon small color="primary">mdi-account-group</v-icon> &nbsp; Clientes: {{ router.clientesCantidad }}</v-chip>
                                    <v-chip color="grey" outlined class="my-1"> <v-icon small color="primary">mdi-memory</v-icon> &nbsp; RAM: {{ router.ram }}</v-chip>
                                    <v-chip color="grey" outlined class="my-1"> <v-icon small color="primary">mdi-disc</v-icon> &nbsp; Disco: {{ router.disco_duro }}</v-chip>
                                    <v-chip color="grey" outlined class="my-1"> <v-icon small color="primary" left>mdi-lan-connect</v-icon>Conexión: <p class="ma-1" v-if="router.tipo_conexion == 1">Ip publica</p><p class="ma-1" v-if="router.tipo_conexion == 2">Script</p><p class="ma-1" v-if="router.tipo_conexion == 3">Ninguno</p></v-chip>
                                    <v-chip color="grey" outlined class="my-1"> <v-icon small color="primary">mdi-web</v-icon> &nbsp; {{ router.estado }}</v-chip>
                                  </v-card-text>
                                </v-card>
                              </div>
                            </v-responsive>
                          </v-tab-item>
                          <v-tab-item>
                            <v-responsive
                              id="scroll-target"
                              class="overflow-y-auto"
                              max-height="500"
                            >
                              <div
                                v-for="antena in antenas"
                                :key="antena.id"
                              >
                              <v-flex xs12>
                            <v-card @click="abrirAntena(antena.id)" outlined class="cool pa-2 my-2">
                                <v-list-item three-line>
                                <v-list-item-avatar
                                            color="indigo"
                                            tile 
                                            size="50"
                                            class="cool"
                                            >
                                                <img v-if="antena.cpe.imagen"
                                                    :src="antena.cpe.imagen"
                                                >
                                                <img v-else
                                                    src="/img/noimagen.jpg"
                                                >
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="headline-2">{{antena.cpe.producto}}</v-list-item-title>
                                        <v-list-item-subtitle><p>{{antena.cpe.marca}}</p></v-list-item-subtitle>
                                        <v-list-item-subtitle @click="abrirIp(antena.ip)" class="headline-2">
                                    <v-icon color="primary">mdi-ip</v-icon>{{ antena.ip }}
                                  </v-list-item-subtitle>

                                    </v-list-item-content>
                                    
                                </v-list-item>
                            </v-card>
                        </v-flex>
                                
                              </div>
                            </v-responsive>
                          </v-tab-item>
                        </v-tabs-items>

                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
           
          </v-flex>
        </v-layout>
        <formEditarZona ref="refEditarZona"></formEditarZona>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
import formEditarZona from "@/js/components/red/zonas/editarZona.vue";
import mapaZona from "@/js/components/red/zonas/detalles/mapaZona.vue";
export default {
  components: {
    mapaZona,
    formEditarZona,
  },
  data() {
    return {
      tab: null,
      zona: null,
      mostrarMapa: true,
      antenas: [],
      voltaje:0,
      temperatura:0,
      router:null
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.getDatos(arreUrl.pop());
    
  },
  computed: {},
  methods: {
    async esperarComponente() {
      await this.$nextTick();
      this.$refs.mapa.mostrarMarker(
        this.zona.clientes,
        this.zona.latitud,
        this.zona.longitud,
        "markerTorre.png",
        this.zona.nombre,
        false
      );
    },
    getDatos(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `zona/` + id,
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            
            this.zona = result.data.zona;
            this.antenas = result.data.antenas;
            for (let index = 0; index < this.zona.routers.length; index++) {
              const router = this.zona.routers[index];
              if(router.tipo_conexion != 3 && router.salud.voltaje){
                this.router = router;
              }
              
            }
            if (this.zona.latitud && this.zona.longitud) {
              this.esperarComponente();
            }
            
          }
        });
    },
    abrirIp(ip){
            window.open("https://"+ip, '_blank');            
        },
        abrirAntena(id){
            document.location.href = "/detalles/antena/vista/" + id;        
        },
        abrirRouter(id) {
      document.location.href = "routers/router/" + id;
    },
    calcularPorcentaje(voltaje) {

var sistema12 = true;
if (voltaje >= 15) {

  sistema12 = false;
}
var voltaje100 = sistema12 ? 12.7 : 24.7;
if (voltaje >= voltaje100) {
  return "100%";
}
var voltaje90 = sistema12 ? 12.5 : 24.5;
if (voltaje >= voltaje90 && voltaje < voltaje100) {
  this.iconoBateria = "mdi-battery-90";
  return "90%";
}

var voltaje80 = sistema12 ? 12.42 : 24.42;
if (voltaje >= voltaje80 && voltaje < voltaje90) {
  this.iconoBateria = "mdi-battery-80";
  return "80%";
}

var voltaje70 = sistema12 ? 12.32 : 24.32;
if (voltaje >= voltaje70 && voltaje < voltaje80) {
  this.iconoBateria = "mdi-battery-70";
  return "70%";
}

var voltaje60 = sistema12 ? 12.2 : 24.2;
if (voltaje >= voltaje60 && voltaje < voltaje70) {
  this.iconoBateria = "mdi-battery-60";
  return "60%";
}

var voltaje50 = sistema12 ? 12.06 : 24.06;
if (voltaje >= voltaje50 && voltaje < voltaje70) {
  this.iconoBateria = "mdi-battery-50";
  return "50%";
}
this.colorBateria = "orange";

var voltaje40 = sistema12 ? 11.90 : 23.90;
if (voltaje >= voltaje40 && voltaje < voltaje50) {
  this.iconoBateria = "mdi-battery-40";
  return "40%";
}

var voltaje30 = sistema12 ? 11.75 : 23.75;
if (voltaje >= voltaje30 && voltaje < voltaje40) {
  this.iconoBateria = "mdi-battery-30";
  return "30%";
}
this.colorBateria = "red";

var voltaje20 = sistema12 ? 11.58 : 23.58;
if (voltaje >= voltaje20 && voltaje < voltaje30) {
  this.iconoBateria = "mdi-battery-20";
  return "20%";
}

var voltaje10 = sistema12 ? 11.31 : 23.31;
if (voltaje >= voltaje10 && voltaje < voltaje20) {
  this.iconoBateria = "mdi-battery-10";
  return "10%";
}
this.iconoBateria = "mdi-battery-outline";

return "0%";
},


    obtenerColor(porcentaje) {
      const valor = parseFloat(porcentaje.replace("%", ""));
      const verde = [0, 255, 0]; // Color verde en RGB
      const rojo = [255, 0, 0]; // Color rojo en RGB

      // Interpolación lineal entre verde y rojo
      const color = rojo.map((componenteRojo, index) => {
        const componenteVerde = verde[index];
        const valorColor = Math.round(
          (componenteVerde - componenteRojo) * (valor / 100) + componenteRojo
        );
        return valorColor;
      });

      // Construir el color en formato RGB
      const colorRGB = `rgb(${color.join(", ")})`;

      return colorRGB;
    },
    colorCPU(cpu) {
      if (cpu < 10 && cpu > 50) {
        return "#1565C0";
      }
      if (cpu > 50 && cpu < 80) {
        return "#EF6C00";
      }
      if (cpu >= 80) {
        return "#BF360C";
      }
      return "#1565C0";
    },
  },
};
</script>