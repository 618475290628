<template>
    <div id="overlay">
        <div @click="easterEgg=true" class="loading">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <slot name="message"></slot>
            <v-row v-if="!easterEgg" class="d-flex justify-center align-center">

            <v-progress-linear
      v-model="skill"
      color="blue-grey"
      height="20"
      style="width: 20%; "
      class="cool mt-2"

    >
      <template v-slot:default="{value}">
        <strong>{{ Math.ceil(value) }}%</strong>
      </template>
      
    </v-progress-linear>
</v-row>

        </div>
        <game v-if="easterEgg" ref="game"></game>
    </div>
</template>
<script>
    import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
    import game from '@/js/components/block_game/easterEgg.vue'
    export default {
        name: 'loading',
        components: {
            game
        },
        mounted() {
            
        },
        data: function(){
            return {
                easterEgg:false,
            }
        },
        watch:{
            
        },
        computed: {
            skill() {
            return this.$store.getters.getSkill_State;
            
            },
            
        },
        methods: {
            
        }
    }
</script>
<style scoped>
#overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
}
.loading{
    position: fixed;
    width: 100%;
    top: 50%;
    margin-top: -64px;
    text-align: center;
}
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 48px;
}
.lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #525f7f;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
}
#overlay{ display: block; z-index: 9999; }
</style>