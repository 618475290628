<template>
    <div class="component mt-2">
        <base-component>
            <div slot="component">
                <v-container grid-list-md>
                    
                <v-layout row wrap>
                    
                    <v-flex xs12 md12>
                      <v-card class="cool pa-4">
                        
                      <v-stepper class="elevation-0"  v-model="e1">
                        <v-stepper-header class="elevation-0">
                        <v-stepper-step :complete="e1 == 1" step="1">Datos empresa</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="e1 == 2" step="2">Donación</v-stepper-step>
                        </v-stepper-header>

                        <v-stepper-items>
                        <v-stepper-content step="1">
                            <formEmpresa></formEmpresa>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <donacion></donacion>
                        </v-stepper-content>

                        </v-stepper-items>
                    </v-stepper>
                      </v-card>  
                    </v-flex>
                </v-layout>
                </v-container>
            </div>
        </base-component>
    </div>
</template>
<script>

import formEmpresa from '@/js/components/configuracion_inicial/stepEmpresaComponent.vue';
import donacion from '@/js/components/configuracion_inicial/stepDonacionComponent.vue';

export default {
    components:{
        formEmpresa,
        donacion,
    },
    data() {
        return {
            
        };
    },
    mounted() {

    },
    computed: {
        e1(){
            return this.$store.getters.getE1;
        }
    },
    methods: {
    
    }

};
</script>