<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <v-row justify="center">
          <v-dialog v-model="dialog" max-width="300" persistent>
            <v-card>
              <v-container grid-list-md>
                <v-card-title primary-title> Nuevo </v-card-title>
                <v-form
                  dense
                  ref="formTipo"
                  v-model="validTipo"
                  lazy-validation
                >
                  <v-container grid-list-md>
                    <v-text-field
                      label="Tipo de egreso"
                      v-model="tipo.tipo"
                      prepend-icon="mdi-format-list-bulleted-type"
                      :rules="generalRegla"
                    ></v-text-field>
                  </v-container>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">Cancelar</v-btn>
                    <v-btn
                      :disabled="!validTipo"
                      color="primary"
                      class="rounded-pill pa-3"
                      @click="nuevoTipo()"
                    >
                      Registrar
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-container>
            </v-card>
          </v-dialog>
        </v-row>
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 md12>
              <v-form
                dense
                ref="form"
                v-model="valid"
                enctype="multipart/form-data"
                lazy-validation
              >
                <v-layout row wrap>
                  <v-flex xs12 md6>
                    <v-select
                      v-model="egreso.negocio_id"
                      :items="negocios"
                      :rules="generalRegla"
                      item-text="nombre"
                      item-value="id"
                      no-data-text="No hay elementos registrados"
                      label="Negocio o sucursal"
                    ></v-select>
                  </v-flex>

                  <v-flex xs12 md6>
                    <v-select
                      :items="tipos"
                      v-model="egreso.tipo_id"
                      label="Tipo de egreso"
                      :rules="generalRegla"
                      item-text="tipo"
                      item-value="id"
                      no-data-text="No hay elementos registrados"
                    >
                      <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item @click="dialog = true">
                          <v-list-item-avatar color="grey lighten-3">
                            <v-icon>mdi-plus</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>Agregar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-flex>
                  <v-textarea
                    v-model="egreso.observaciones"
                    rows="3"
                    label="Observaciones"
                    filled
                  ></v-textarea>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </base-component>
  </div>
</template>
<script>
export default {
  data() {
    return {
      valid: true,
      validTipo: true,
      tipos: [],
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      egreso: {
        observaciones: "",
        tipo_id: "",
        negocio_id: "",
      },
      tipo: {
        tipo: "",
      },
      dialog: false,
      negocios: [],
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "form/egreso/negocio",
          data: {},
        })
        .then((result) => {
          this.tipos = result.data.tipos;
          this.negocios = result.data.negocios;
        });
    },
    nuevoTipo() {
      if (this.$refs.formTipo.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "tipo_egreso_negocio";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.tipo,
          })
          .then((result) => {
            this.tipos.unshift(result.data.tipo);
            this.egreso.tipo_id = result.data.tipo.id;
            this.dialog = false;
            this.$refs.formTipo.reset();
          });
      }
    },
    datos() {
      if (this.$refs.form.validate()) {
        return this.egreso;
      } else {
        return null;
      }
    },
    limpiar() {
      this.$refs.form.reset();
    },
  },
};
</script>