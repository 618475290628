<template>
    <div class="component">
        <base-component>
            <div slot="component">
                <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
                <v-layout row wrap>
                    <v-flex xs12 md12>
                        <homeEmpresa></homeEmpresa>
                    </v-flex>
                   
                </v-layout>
            </div>
        </base-component>
    </div>
</template>
<script>
import Common from '../../Common';
import homeEmpresa from '@/js/components/home/homeEmpresaComponent.vue';
export default {
    components:{
        homeEmpresa
    },
    data() {
        return {
            
        };
    },
    mounted() {
        this.usuarioRegistrado();
    },
    computed: {
       registrado(){
           return this.$store.getters.getUsuarioRegistrado;
       } 
    },
    methods: {
        usuarioRegistrado(){
            let dispatch = 'UsersModule/OnGet'
            let endpoint = `usuario/registrado`
            this.$store.dispatch(dispatch, {
                    url: endpoint,
                    data: {}
                }).then(result => {
                    let registrado = result.data.registrado
                    
                    
                    this.$store.dispatch('asignaregistrado',registrado);         
                               
                })
        }
    }

};
</script>