<template>
  
      <div>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-container grid-list-md>
              <v-card-title primary-title>Compartir</v-card-title>
              <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  dense
                  @submit.prevent="enviar"
                >
                <v-card-text>
                  
                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-select
                                v-model="persona"
                                :items="personal"
                                outlined
                                :rules="generalRegla"
                                item-text="nombre"
                                label="Personal"
                                prepend-icon="mdi-worker"
                                return-object
                              >
                              
                                <template max-width="60" v-slot:item="{ item }">
                                  <v-list-item-avatar
                                    color="indigo"
                                    class="
                                      headline
                                      font-weight-light
                                      white--text
                                    "
                                  >
                                    {{ item.nombre.charAt(0) }}
                                  </v-list-item-avatar>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      >{{ item.FullName }}</v-list-item-title
                                    >
                                    <v-list-item-subtitle
                                      >Celular:
                                      {{ item.celular }}</v-list-item-subtitle
                                    >
                                  </v-list-item-content>
                                </template>
                              
                              </v-select>
                            
                    </v-flex>
                  </v-layout>

                </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">Cancelar</v-btn>
                    <v-btn
                      type="submit"
                      class="rounded-pill pa-3"
                      :disabled="!valid"
                      color="primary"
                      >Enviar</v-btn
                    >
                  </v-card-actions>
                </v-form>
            </v-container>
          </v-card>
        </v-dialog>
      </div>
  
</template>
<script>
import Common from "@/js/Common";
import LocalStorage from "@/js/LocalStorage.js";

export default {
  data() {
    return {
      valid: true,
      dialog: false,
      mensaje: "",
      generalRegla: [v => !!v || "Se necesita el campo"],
      personal:[],
      persona:null

    };
  },
  mounted() {
  },
  computed: {},
  methods: {
    cargarDatos(mensaje) {
      this.getDatos()
      this.mensaje = mensaje;
      this.dialog = true;
    },
    getDatos(){
            this.$store.dispatch('UsersModule/OnGet', {
                url: 'datos/form/actividad',
                data: {}
            }).then(result => {
              
                this.personal = result.data.personal;
                
            })
        },
    enviar() {
      if (this.$refs.form.validate()) {
      
      let url = 'https://api.whatsapp.com/send?phone='
      +this.persona.celular
      +'&text='
      +this.mensaje;
      window.open(url, "_blank");
      this.dialog = false;
      }
    },
  },
};
</script>