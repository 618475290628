<template>
    <v-container grid-list-md>
      <cpu ref="cpu"></cpu>
      <ram ref="ram"></ram>
      <temperatura ref="temperatura"></temperatura>
      <voltaje ref="voltaje"></voltaje>
</v-container>
  </template>
  <script>
  
  import cpu from "@/js/components/red/routers/detalles/graficas_router/cpu.vue";
  import ram from "@/js/components/red/routers/detalles/graficas_router/ram.vue";
  import temperatura from "@/js/components/red/routers/detalles/graficas_router/temperatura.vue";
  import voltaje from "@/js/components/red/routers/detalles/graficas_router/voltaje.vue";
  
  export default {
    components: {
      cpu,
      ram,
      temperatura,
      voltaje,
      
    },
    data() {
          return {
          
          };
      },
      mounted() {
        var arreUrl = window.location.href.split('/');
        var id =arreUrl.pop()
        this.getDatos(id);
      },
      computed: {
          
      },
      methods: {
        getDatos(id){
            this.$store.dispatch('UsersModule/OnGet', {
                url: `graficar/router/`+id,
                data: {}

            }).then(result => {
                if([200, 201].includes(result.status)){
                  
                let datos = result.data.datos;
                
                if(datos.length > 0){
                    this.$refs.cpu.graficar(datos);
                    this.$refs.ram.graficar(datos);
                    this.$refs.temperatura.graficar(datos);
                    this.$refs.voltaje.graficar(datos);
                }

                }
            })

        },
      }
  
  };
  </script>