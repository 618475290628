<!-- esqueleto para cualquier componente
<template>
    <div class="component">
        <base-component>
            <div slot="component">
                <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
                <v-layout row wrap>
                    <v-flex xs12 md12>
                      
                    </v-flex>
                </v-layout>
            </div>
        </base-component>
    </div>
</template>
<script>
import Common from '@/js/Common'
export default {

    data() {
        return {
        
        };
    },
    mounted() {

    },
    computed: {
        
    },
    methods: {
    
    }

};
</script>
-->

<template>

    <div class="component">
        <base-component>
            <div slot="component">
                <!-- ES necesario para el dialog de confirmacion -->
                <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
                <!-- fin confirmacion -->
                <v-layout row wrap>
                    <v-flex xs12 md12>
                        <!-- ejemplo de componente -->
                        <h3>variable ejemplo:{{variable_ejemplo}}</h3>
                        <v-btn @click="cambia('adios')" color="success">llama vuex</v-btn>
                        <v-btn @click="aviso()" color="yellow">Aviso</v-btn>
                        <v-btn @click="confirmacion()" color="purple">confirmacion</v-btn>
                        <v-btn @click="notificacion()" color="primary">notificación</v-btn>
                        <v-btn @click="carga()" color="secondary">spinner de carga</v-btn>
    
                    </v-flex>
                </v-layout>
                <v-container>
                   <v-layout>
                       
                    <v-flex class="d-flex justify-center" xs6 lg6 md6>
                        <v-select
                        align-center
                        :items="items"
                        label="Standard"
                        ></v-select>
                    </v-flex>
                   </v-layout>
                  
                </v-container> 
            </div>
        </base-component>
    </div>

</template>

<script>
//este escript se importa ya estaba tiene cosas comunes que se pueden presentar en un proyecto

import Common from '../../Common'
import LocalStorage from '@/js/LocalStorage';
export default {
    components:{
    },
    data() {
        return {
            items: [],
        };
    },
    mounted() {

        //ejemplos del js common
        var prueba = Common.localDateTime(new Date);
        console.log('prueba: ', prueba);

        var esnumero = Common.validateNumber(3);
        console.log('esnumero: ', esnumero);
        var anio = Common.normalizeYear(2010);
        console.log('anio: ', anio);

        var caduco = Common.checkExpirationDate("06/20");
        console.log('caduco: ', caduco);

        var formatodinero = Common.formatMoney("88",2);
        console.log('formatodinero: ', formatodinero);


        /////////////////////////

        LocalStorage.set('algo', {
                id: 1,
                nombre: 'Eduardo'
            });
        console.log(
            LocalStorage.get('algo')
        )
        LocalStorage.remove('algo');
        this.$store.dispatch("mostrarCarga", true);


    },
    computed: {
        //lo que hace es ecuchar el cambio en el archivo de vuex modulo_ejemplo
        variable_ejemplo() {
            return this.$store.getters.get_variable_ejemplo;
        }
    },
    methods: {

        cambia(variable) {
            //ejecuta el metodo en vuex para cambiar el atributo
            this.$store.dispatch('llamada_ejemplo', variable);
        },
        confirmacion() {
            //para utilizar este componenegte se tiene que agregar el componenten en cada vista que se vaya a usar
            //<BaseConfirmacion ref="confirmar"></BaseConfirmacion>

            //el primer parametro es del titulo y el segundo es el mensaje a mostrar y el color de la cabecera
            //la promesa hace que se pare la ejecucion hasta que que reciba una respuesta del usuario o presione la tecla ESC o presione en otro lugar de la pantalla
            this.$refs.confirmar.open('Confirmación', 'Esta seguro de hacer esto?', { color: 'orange darken-2' }).then((confirm) => {
                if (confirm) {
                    console.log('presiono el boton si');
                } else {
                    console.log('Presiono cancelar');
                }
            });
        },
        notificacion() {
            //se puede llamar desde cualquier parte sin importar nada
            // como parametro recibe el tipo  que puede ser:
            // success = verde
            // warning = amarillo
            // ...

            this.$store.dispatch("notificaciones/add", {
                tipo: "success",
                mensaje: "Agregado :D",
                tiempo: 1000
            });
            this.$store.dispatch("notificaciones/add", {
                tipo: "warning",
                mensaje: "alerta  :/",
                tiempo: 2000

            });
            this.$store.dispatch("notificaciones/add", {
                tipo: "error",
                mensaje: "error  :(",
                tiempo: 3000

            });

            //esta notificacion es solo para errores se muestra en color rojo y se pone 
            // en el catch despues de hacer una peticion con axios
            //this.$store.dispatch("Errores/evaluar", error);
        },
        carga() {
            console.log("entro");

            // se puede llamar desde cualquier parte del proyecto
            //true para mostrar

            this.$store.dispatch("mostrarCarga", true);

        

            /*
            
            ignorar es solo para que pare el tiempo
            const sleep = (milliseconds) => {
                return new Promise(resolve => setTimeout(resolve, milliseconds))
            }
            sleep(100000).then(() => {

                //false para ocultar
                  })
          */
           //this.$store.dispatch("mostrarCarga", false);


          

        },

        aviso() {

            // se puede llamar en cualquier parte es solo para dar avisos
            this.$store.dispatch("aviso", {
                mostrar: true,
                color: "primary",
                titulo: "Aviso",
                mensaje: "Ejemplo de mensaje"
            });

            
        }

    }

};
</script>