<template>
  <div>
    <v-dialog v-model="dialog" max-width="610">
      <v-card>
        <v-card-title class="display-1" primary-title>
          <strong>Editar</strong>
        </v-card-title>
        <v-container grid-list-md>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12 md12>
                <v-form ref="form" v-model="valid" lazy-validation dense>
                  <v-layout row wrap>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="Nombre del rol"
                        v-model="nombre"
                        :rules="generalRegla"
                        prepend-icon="mdi-account-plus"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="Slug"
                        v-model="rol.slug"
                        :rules="generalRegla"
                        prepend-icon="mdi-format-color-text"
                        readonly
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs12 md12>
                      <v-textarea
                        v-model="rol.description"
                        rows="2"
                        label="Descripción"
                        filled
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                  <p><strong>Permisos</strong></p>
                  <v-responsive class="overflow-y-auto" max-height="250">
                    <v-layout row wrap>
                      <v-expansion-panels popout>
                        <v-expansion-panel
                          v-for="(modelo, j) in modelos"
                          :key="j"
                        >
                          <v-expansion-panel-header>{{
                            modelo.model
                          }}</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-layout row wrap>
                              <div v-for="(permiso, i) in permisos" :key="i">
                                <v-flex
                                  v-if="permiso.modelo == modelo.model"
                                  md3
                                >
                                  <v-checkbox
                                    v-model="permiso.seleccionado"
                                    :label="permiso.name"
                                  ></v-checkbox>
                                </v-flex>
                              </div>
                            </v-layout>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-layout>
                  </v-responsive>
                </v-form>
              </v-flex>
            </v-layout>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="cancelar()" text>Cancelar</v-btn>
            <v-btn
              @click="actualizar()"
              class="rounded-pill pa-3"
              color="primary"
              >Registrar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rol: {
        slug: "",
        name: "",
        description: "",
      },
      nombre: "",
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      permissions: [],
      modelos: [],
      resolve: null,
      reject: null,
      dialog: false,
      permisosActuales: [],
    };
  },
  mounted() {},
  watch: {
    nombre(val) {
      if (val != null) {
        this.rol.name = val;
        let slug = val;
        slug = slug.replace(/\W+(?!$)/g, "-").toLowerCase();
        this.rol.slug = slug;
      }
    },
  },
  computed: {
    permisos() {
      return this.permissions.map((entry) => {
        const id = entry.id;
        const name = entry.name;
        let seleccionado = false;
        for (let index = 0; index < this.permisosActuales.length; index++) {
          let actual = this.permisosActuales[index].slug;
          if (entry.slug === actual) {
            seleccionado = true;
            break;
          }
        }

        const modelo = entry.model;
        return Object.assign({}, entry, { id, name, seleccionado, modelo });
      });
    },
  },
  methods: {
    esperarDatos(elemento) {
      if (elemento) {
        let id = elemento.id;
        this.getDatos(id);
        this.dialog = true;
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      }
    },
    getDatos(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "roles/" + id,
          data: {},
        })
        .then((result) => {
          this.rol = result.data.rol;
          this.nombre = result.data.rol.name;
          this.modelos = result.data.modelos;
          this.permissions = result.data.todosPermisos;
          this.permisosActuales = result.data.permisos;
        });
    },

    actualizar() {
      if (this.$refs.form.validate()) {
        let dispatch = "UsersModule/OnUpdate";
        let endpoint = "roles/" + this.rol.id;
        let datos = {
          permisos: this.sacarElegidos(this.permisos),
          rol: this.rol,
        };
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: datos,
          })
          .then((result) => {
            this.dialog = false;
            this.limpiar();
            this.resolve(result.data.rol);
          });
      }
    },
    limpiar() {
      this.$refs.form.reset();
    },
    sacarElegidos(permisos) {
      var elegidos = [];
      for (let index = 0; index < permisos.length; index++) {
        const permiso = permisos[index];
        if (permiso.seleccionado == true) {
          elegidos.push(permiso);
        }
      }
      return elegidos;
    },
    cancelar() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>