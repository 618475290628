<template>
  <div>
    <v-card v-if="mostrar" class="cool" :color="colorGrafica">
      <!--
          <v-container grid-list-md>
            <v-layout row wrap>
              <h5>Estabilidad de la red de hoy</h5>
              <v-spacer></v-spacer>
              
              <v-btn class="mr-1" small text>Hora</v-btn>
              <v-btn class="mr-1" small text>Dia</v-btn>
              <v-btn class="mr-1" small text>Mes</v-btn>
            </v-layout>
          </v-container>
              -->

      <apexchart
        type="area"
        height="230"
        :options="chartOptionsEstabilidad"
        :series="seriesEstabilidad"
      ></apexchart>
    </v-card>
  </div>
</template>
<script>
import apexchart from "vue-apexcharts";
import Common from "@/js/Common";
export default {
  components: {
    apexchart,
  },
  data() {
    return {
      mostrar: false,
      colorGrafica: "#0D47A1",
      seriesEstabilidad: [
        {
          name: "Porcentaje",
          data: [],
        },
      ],
      chartOptionsEstabilidad: {
        chart: {
          type: "area",
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },

        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: function (y) {
              return y + "%";
            },
          },
        },
        colors: ["#F5F5F5"],

        title: {
          text: "0%",
          offsetX: 10,
          offsetY: 10,
          style: {
            fontSize: "24px",
            color: "white",
          },
        },
        subtitle: {
          text: "Estabilidad de la red hoy",
          offsetX: 10,
          offsetY: 40,
          style: {
            fontSize: "14px",
            color: "white",
          },
        },
        tooltip: {
          x: {
            format: "HH:mm",
          },
        },
      },
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `home/grafica/estabilidad`,
          data: {},
        })
        .then((result) => {
          this.formatoEstabilidadRed(result.data.recolectado);
        });
    },
    formatoEstabilidadRed(datos) {
      let porcentajes = [];
      let tiempo = [];

      for (let index = 0; index < datos.length; index++) {
        let estabilidad = datos[index].estabilidad;
        porcentajes.push(estabilidad);
        let hora = datos[index].hora_completa;
        tiempo.push(hora.toString().substr(0, 5));
      }

      this.seriesEstabilidad[0] = {
        name: "Red al",
        data: porcentajes,
      };
      if (porcentajes.length > 0) {
        var porcentaje = porcentajes[porcentajes.length - 1];
        this.chartOptionsEstabilidad.title.text = porcentaje + "%";
        if (porcentaje >= 0) {
          //rojo
          this.colorGrafica = "red darken-3";
        }
        if (porcentaje >= 50) {
          //azul
          this.colorGrafica = "light-blue darken-3";
        }
        if (porcentaje >= 100) {
          //verde
          this.colorGrafica = "green";
        }
      }
      this.chartOptionsEstabilidad.xaxis.categories = tiempo;
      this.mostrar = true;
    },
  },
};
</script>