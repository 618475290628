<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <v-row v-if="pago">
          <v-col cols="12" md="6">
            <v-row>
              <!-------------------DATOS CLIENTE-------------------->
              <v-col cols="12">
                <v-card class="cool pa-3">
                  <v-card-title primary-title> Datos cliente </v-card-title>
                  <v-card-text>
                    <v-list two-line>
                      <v-list-item>
                        <v-list-item-avatar size="56">
                          <img
                            height="175"
                            v-if="pago.cliente.imagen"
                            :src="pago.cliente.imagen"
                          />
                          <img
                            v-else
                            height="175"
                            src="/img/user_default.png"
                          />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            pago.cliente.FullName
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Nombre</v-list-item-subtitle>
                        </v-list-item-content>
                        <!--

                                                    <v-list-item-action>
                                                        <v-btn @click="abrirCliente()" icon>
                                                            <v-icon>mdi-eye</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action>
                                                    -->
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-map-marker </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title
                            >{{ pago.cliente.direccion }}
                            {{ pago.cliente.colonia }}
                            {{ pago.cliente.municipio }}</v-list-item-title
                          >
                          <v-list-item-subtitle>Dirección</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-phone </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title v-if="pago.cliente.telefono">{{
                            pago.cliente.telefono
                          }}</v-list-item-title>
                          <v-list-item-title v-else
                            >No registrado</v-list-item-title
                          >
                          <v-list-item-subtitle>Telefono</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-cellphone </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title v-if="pago.cliente.celular">{{
                            pago.cliente.celular
                          }}</v-list-item-title>
                          <v-list-item-title v-else
                            >No registrado</v-list-item-title
                          >
                          <v-list-item-subtitle>Celular</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-email </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title v-if="pago.cliente.correo">{{
                            pago.cliente.correo
                          }}</v-list-item-title>
                          <v-list-item-title v-else
                            >No registrado</v-list-item-title
                          >
                          <v-list-item-subtitle>Correo</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-calendar </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ pago.cliente.dia_pago }} de cada
                            mes</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >Dia de pago</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-------------------CAJERO-------------------->
              <v-col cols="12" md="12" v-if="pago.persona">
                <v-card class="cool pa-3">
                  <v-card-text>
                    <v-list subheader two-line>
                      <v-list-item>
                        <v-list-item-avatar size="56">
                          <img
                            height="175"
                            v-if="pago.cliente.imagen"
                            :src="pago.persona.imagen"
                          />
                          <img
                            v-else
                            height="175"
                            src="/img/user_default.png"
                          />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            pago.persona.FullName
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Cajero</v-list-item-subtitle>
                        </v-list-item-content>
                        <!--
                                                    <v-list-item-action>
                                                        <v-btn @click="abrirCajero()" icon>
                                                            <v-icon>mdi-eye</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action>
                                                    -->
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <!-------------------ESTADO DE PAGO-------------------->
              <v-col cols="12" md="12">
                <v-card
                  :color="calcularColor(pago.status_pago_id)"
                  class="cool pa-3"
                >
                  <v-card-title primary-title> Estado de pago </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-list
                          :color="calcularColor(pago.status_pago_id)"
                          two-line
                        >
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>mdi-cash-register</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title>{{
                                pago.estado.status
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                >Status</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>mdi-calendar-month</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title
                                >{{ pago.mes.mes }}/{{
                                  pago.anio
                                }}</v-list-item-title
                              >
                              <v-list-item-subtitle
                                >Mes y año del pago</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="pago.status_pago_id == 1">
                            <v-list-item-icon>
                              <v-icon>mdi-calendar-check</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title>{{
                                pago.fecha + "-" + pago.hora
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                >Fecha de liquidación</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="pago.status_pago_id == 1">
                            <v-list-item-icon>
                              <v-icon>mdi-calendar-check</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title>{{
                                pago.tipo.tipo
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                >Tipo de liquidación</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-list
                          :color="calcularColor(pago.status_pago_id)"
                          two-line
                        >
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>mdi-account-cash</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title
                                >${{ pago.monto }}</v-list-item-title
                              >
                              <v-list-item-subtitle>Monto</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>mdi-cash-plus</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title
                                >${{ pago.extra }}</v-list-item-title
                              >
                              <v-list-item-subtitle>Extra</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>mdi-cash-minus</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title
                                >${{ pago.descuento }}</v-list-item-title
                              >
                              <v-list-item-subtitle
                                >Descuento</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      <v-col cols="12" v-if="pago.detalles">
                        <v-divider></v-divider>
                        <p class="font-weight-bold">Detalles de pago</p>
                        <p>{{ pago.detalles }}</p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-list :color="calcularColor(pago.status_pago_id)">
                      <v-list-item>
                        <v-list-item-avatar color="grey lighten-5">
                          <span class="text-h4 font-weight-bold">{{
                            pago.retraso
                          }}</span>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Dias de retraso</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-actions>
                </v-card>
              </v-col>
              <!-------------------DATOS SERVICIO-------------------->
              <v-col cols="12">
                <v-card color="blue" class="cool pa-3">
                  <v-card-title primary-title> Servicio </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="5">
                        <v-list color="blue" two-line>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>mdi-cash-usd</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title
                                >${{ pago.servicio.costo }}</v-list-item-title
                              >
                              <v-list-item-subtitle>Costo</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>mdi-package-variant-closed</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title>{{
                                pago.servicio.nombre
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                >Paquete</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      <v-col cols="12" md="7">
                        <v-list color="blue" two-line>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>mdi-format-list-bulleted-type</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title>{{
                                pago.servicio.tipo.tipo
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                >Tipo de servicio</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>mdi-speedometer</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title
                                ><v-icon color="pink">mdi-arrow-up-bold</v-icon>
                                {{ pago.servicio.subida }}Mbps/{{
                                  pago.servicio.bajada
                                }}Mbps
                                <v-icon color="green accent-2"
                                  >mdi-arrow-down-bold</v-icon
                                ></v-list-item-title
                              >
                              <v-list-item-subtitle
                                >Velocidad</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      <v-col cols="12" v-if="pago.servicio.detalles">
                        <v-divider></v-divider>
                        <p class="font-weight-bold">Detalles de servicio</p>
                        <p>{{ pago.servicio.detalles }}</p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-------------------BOTONES-------------------->

              <v-speed-dial
                v-model="fab"
                :top="top"
                :bottom="bottom"
                :right="right"
                :left="left"
                :direction="direction"
                :open-on-hover="hover"
                :transition="transition"
                style="position: absolute"
              >
                <template v-slot:activator>
                  <v-btn v-model="fab" color="yellow darken-2" dark fab>
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-btn
                  v-if="pago.status_pago_id == 1"
                  fab
                  dark
                  small
                  color="blue"
                  @click="ticketCalor()"
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
                <v-btn fab dark small color="cyan" @click="recibo()">
                  <v-icon>mdi-file-document</v-icon>
                </v-btn>
                <!--
                <v-btn fab dark small color="green" @click="editar()">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn fab dark small color="red" @click="eliminar()">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                -->
              </v-speed-dial>
            </v-row>
          </v-col>
        </v-row>
        <!--
                <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
                <formEditarPago ref="refEditarPago"></formEditarPago>
                    -->
      </div>
    </base-component>
  </div>
</template>
<script>
//import formEditarPago from "@/js/components/pagos/todos/editarPago.vue";
import Comun from "@/js/Common.js";
import jsPDF from "jspdf";
import LocalStorage from "@/js/LocalStorage.js";

export default {
  components: {
    //formEditarPago
  },
  data() {
    return {
      hoy: new Date().toISOString().substr(0, 10),

      pago: null,
      direction: "top",
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.show(arreUrl.pop());
  },
  computed: {},
  methods: {
    show(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `pago/` + id,
          data: {},
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.pago = result.data.pago;
          }
        });
    },
    calcularColor(id) {
      if (id == 1) {
        return "green accent-3";
      }
      if (id == 2) {
        return "deep-orange lighten-1";
      }
      if (id == 3) {
        return "deep-purple accent-2";
      }
    },
    /*
        eliminar() {
            this.$refs.confirmar
                .open("Confirmación", "Esta seguro de eliminar este elemento?", {
                color: "warninig",
                })
                .then((confirm) => {
                if (confirm) {
                    let dispatch = "UsersModule/OnDelete";
                    let endpoint = `pago/` + this.pago.id;

                    this.$store
                    .dispatch(dispatch, {
                        url: endpoint,
                        data: this.pago,
                    })
                    .then((result) => {
                       
                        document.location.href = "pagos/vista";
                        
                    });
                } else {
                }
                });
        },
        editar() {
            this.PagoActualizar = this.pago;
            const pago = Object.assign({}, this.pago);
            this.$refs.refEditarPago.esperarDatos(pago).then((confirm) => {
                if (confirm) {
                this.elementos.splice(
                    this.elementos.indexOf(this.PagoActualizar),
                    1,
                    confirm
                );
                }
            });
        },
        */
    recibo() {
      const item = this.pago;
      let pdfName = "Recibo-" + Comun.formatoFecha(this.hoy);
      var doc = new jsPDF();

      var nombre =
        item.cliente.nombre +
        " " +
        item.cliente.apellido_paterno +
        " " +
        item.cliente.apellido_materno;
      var direccion = item.cliente.direccion;
      var colonia = item.cliente.colonia;
      var municipio = item.cliente.municipio;
      var telefono = item.cliente.telefono;
      var celular = item.cliente.celular ? item.cliente.celular : "";
      var correo = item.cliente.correo;
      var estado = item.estado.status;
      var status = item.status_pago_id;
      var servicio = item.servicio.nombre;
      var tipoServicio = item.servicio.tipo.tipo;
      var detalles = item.servicio.detalles;
      var bajada = item.servicio.bajada;
      var subida = item.servicio.subida;
      var descuento = item.descuento;
      var extra = item.extra;
      var tipo = item.tipo ? item.tipo.tipo : "";
      var fecha = item.fecha ? item.fecha : "";
      var hora = item.hora ? item.fecha : "";
      var detallePago = item.detalles;
      var mes = item.mes.mes;
      var monto = item.monto;
      var creado = item.created_at;
      var logo = item.empresa.logo ? item.empresa.logo : "";

      doc.setFont("arial");
      doc.setFontSize(12);
      var lMargin = 15; //left margin in mm
      var rMargin = 15; //right margin in mm
      var pdfInMM = 210; // width of A4 in mm
      var pageCenter = pdfInMM / 2;

      var img1 = new Image();
      img1.src = "img/recursos/tune.png";
      doc.addImage(img1, "png", 0, -2, 290, 20);
      if (logo) {
        var img2 = new Image();
        img2.src = logo;
        doc.addImage(img2, "png", 15, 25, 20, 20);
      }

      doc.setFont("helvetica");
      doc.setFontSize(15);

      var cabecera = "RECIBO DE PAGO";
      var linea = doc.splitTextToSize(cabecera, pdfInMM - lMargin - rMargin);
      doc.text(linea, pageCenter, 40, "center");

      doc.setFontSize(14);
      doc.setFont("arial");
      doc.text(15, 58, "Cliente: ");

      doc.setFontSize(12);

      doc.text(15, 65, nombre);
      doc.text(15, 70, direccion + ", " + colonia);
      doc.text(15, 75, municipio);
      if (telefono) {
        doc.text(15, 80, "Telefono: " + telefono);
      }
      doc.text(15, 85, "Celular: " + celular);
      if (correo) {
        doc.text(15, 90, "Correo: " + correo);
      }

      doc.text(135, 65, "Estado: " + estado);

      doc.setLineWidth(1);
      doc.setDrawColor(0);
      if (status == 1) {
        doc.setFillColor(0, 255, 0);
      }
      if (status == 2) {
        doc.setFillColor(255, 0, 0);
      }
      if (status == 3) {
        doc.setFillColor(139, 0, 255);
      }
      doc.circle(150, 80, 10, "FD");

      doc.setLineWidth(1);
      doc.line(195, 100, 15, 100);
      doc.setLineWidth(0.5);
      doc.line(195, 102, 15, 102);

      doc.setFontSize(14);
      doc.text(15, 110, "Detalles del servicio: ");

      doc.text(15, 115, "Nombre: " + servicio);
      doc.text(15, 120, "Tipo: " + tipoServicio);
      doc.text(15, 125, "Detalles: " + detalles);
      if (tipo == "Internet") {
        doc.text(
          15,
          130,
          "Velocidad: " + "Bajada: " + bajada + "MB / Subida: " + subida + "MB"
        );
      }

      var head = [
        ["Descuento", "Extra", "Pago", "Fecha", "Mes", "detalles", "Monto"],
      ];
      var body = [
        [descuento, extra, tipo, fecha + "-" + hora, mes, detallePago, monto],
      ];
      doc.autoTable({
        head: head,
        body: body,
        startY: 145,
        bodyStyles: {
          valign: "top",
        },
        styles: {
          rowPageBreak: "auto",
          overflow: "linebreak",
        },
        columnStyles: {
          text: {
            cellWidth: "wrap",
          },
        },
        margin: { left: 15, right: 15 },
      });
      doc.setFontSize(14);
      let finalY = doc.autoTable.previous.finalY;
      doc.text(160, finalY + 6, "Total: $" + monto);
      doc.setFontSize(8);
      doc.text(15, 240, "Generado el: " + creado);

      //var img = new Image();
      //img.src = "img/recursos/ola1.jpg";
      //doc.addImage(img, "jpg", 0, 247, 290, 55);

      doc.save(pdfName + ".pdf");
    },
    ticketCalor() {
      let url = "ticket/calor/pago/" + this.pago.id;
      window.open(url, "_blank");
    },
    abrirCliente() {
      LocalStorage.set("TAB_ACTUAL_CLIENTE", 0);
      document.location.href = "detalles/cliente/" + this.pago.cliente.id;
    },
    abrirCajero() {
      LocalStorage.set("TAB_ACTUAL_PERSONAL", 0);
      document.location.href = "vista/persona/detalles/" + this.pago.persona.id;
    },
  },
};
</script>
