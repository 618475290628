<template>
  <div>
    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <v-row justify="center">
      <v-dialog v-model="dialogTipo" max-width="300" persistent>
        <v-card>
          <v-card-title class="headline" primary-title>
            <strong>Nuevo</strong>
          </v-card-title>
          <v-form dense ref="formTipo" v-model="validTipo" lazy-validation>
            <v-container grid-list-md>
              <v-text-field
                label="Prodencia"
                v-model="tipo.tipo"
                prepend-icon="mdi-format-list-bulleted-type"
                :rules="generalRegla"
              ></v-text-field>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialogTipo = false">Cancelar</v-btn>
              <v-btn
                :disabled="!validTipo"
                color="primary"
                class="rounded-pill pa-3"
                @click="nuevoTipo()"
              >
                Registrar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog v-model="dialogEditarEgreso" max-width="600" persistent>
      <v-card>
        <v-card-title primary-title class="display-1">
          <strong>Editar</strong>
        </v-card-title>
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 md12>
              <v-container grid-list-md>
                <v-form
                  dense
                  ref="form"
                  v-model="valid"
                  enctype="multipart/form-data"
                  lazy-validation
                >
                  <v-layout row wrap>
                    <v-layout row wrap>
                      <v-flex xs12 md4>
                        <v-text-field
                          label="Monto"
                          v-model="egreso.monto"
                          :rules="generalRegla"
                          prepend-icon="mdi-cash-usd"
                          type="number"
                          prefix="$"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md8>
                        <v-file-input
                          :rules="rulesFile"
                          placeholder="Maximo 2MB"
                          show-size
                          v-model="archivo"
                          hint="Puede ser documento o imagen"
                          prepend-icon="mdi-file-document"
                          small-chips
                          multiple
                          label="Archivo Comprobante"
                        ></v-file-input>
                      </v-flex>
                      <v-flex xs12 md12>
                        <v-textarea
                          v-model="egreso.detalles"
                          label="Detalles"
                          rows="2"
                        ></v-textarea>
                      </v-flex>
                      <v-flex xs12 md12>
                        <v-select
                          v-model="egreso.tipo_id"
                          :items="tipos"
                          item-text="tipo"
                          item-value="id"
                          label="Tipo egreso"
                          :rules="generalRegla"
                          prepend-icon="mdi-format-list-bulleted-type"
                          no-data-text="No hay elementos registrados"
                        >
                          <template v-slot:append-item>
                            <v-divider class="mb-2"></v-divider>
                            <v-list-item @click="dialogTipo = true">
                              <v-list-item-avatar color="grey lighten-3">
                                <v-icon>mdi-plus</v-icon>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>Agregar</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-select>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex xs12 md6>
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="computedDateFormatted"
                              label="Dia de revisión"
                              prepend-icon="mdi-calendar"
                              readonly
                              :rules="generalRegla"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            locale="es-mx"
                            v-model="egreso.fecha"
                            no-title
                            @input="menu2 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-menu
                          ref="menu"
                          v-model="menu_reloj"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="egreso.hora"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="egreso.hora"
                              label="Hora"
                              prepend-icon="mdi-av-timer"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="menu_reloj"
                            v-model="egreso.hora"
                            full-width
                            @click:minute="$refs.menu.save(egreso.hora)"
                          ></v-time-picker>
                        </v-menu>
                      </v-flex>
                    </v-layout>
                    <v-flex xs12 md12>
                      <v-select
                        v-model="egreso.proveedor_id"
                        :items="proveedores"
                        :rules="generalRegla"
                        item-text="nombre"
                        item-value="id"
                        label="Proveedor"
                      ></v-select>
                    </v-flex>

                    <v-flex xs12 md12>
                      <v-textarea
                        v-model="egreso.observaciones"
                        label="Observaciones"
                        rows="2"
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="cancelar()">Cancelar</v-btn>
                  <v-btn
                    :disabled="!valid"
                    color="primary"
                    class="rounded-pill pa-3"
                    @click="actualizarEgreso()"
                  >
                    Actualizar
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      egreso: {
        monto: "",
        detalles: "",
        archivo: null,
        tipo_id: "",
        observaciones: "",
        proveedor_id: "",
        hora: null,
        fecha: null,
      },
      tipo: {
        tipo: "",
      },
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      rulesFile: [
        (value) =>
          !value ||
          value.length == 0 ||
          value[0].size < 2000000 ||
          "Debe ser menor a 2 MB!",
      ],
      tipos: [],
      archivo: null,
      validTipo: true,
      dialogTipo: false,
      dialogEditarEgreso: false,
      proveedores: [],
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: new Date().toISOString().substr(0, 10),
      menu2: false,
      menu_reloj: false,
    };
  },
  mounted() {},
  watch: {
    archivo(val) {
      if (val != null) {
        this.egreso.archivo = val[0];
      }
    },
    date(val) {
      this.dateFormatted = this.formatDate(val);
    },
  },
  computed: {
    computedDateFormatted() {
      if (this.egreso.fecha) {
        return this.formatDate(this.egreso.fecha);
      } else {
        return "";
      }
    },
  },
  methods: {
    esperarDatos(elemento) {
      this.egreso.id = elemento.id;
      this.egreso.empresa_id = elemento.empresa_id;
      this.egreso.monto = elemento.egreso.monto;
      this.egreso.detalles = elemento.egreso.detalles;
      this.egreso.tipo_id = elemento.tipo_id;
      this.egreso.fecha = elemento.egreso.fecha;
      this.egreso.hora = elemento.egreso.hora;
      this.egreso.proveedor_id = elemento.proveedor_id;
      this.egreso.observaciones = elemento.observaciones;
      this.getDatosInicio();

      this.dialogEditarEgreso = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    actualizarEgreso() {
      if (this.$refs.form.validate()) {
        this.resolve(this.egreso);
        this.dialogEditarEgreso = false;
        return this.egreso;
      } else {
        return null;
      }
    },
    limpiar() {
      this.$refs.form.reset();
      this.archivo = null;
    },
    cancelar() {
      this.resolve(false);
      this.dialogEditarEgreso = false;
    },
    getDatosInicio() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "form/egreso/compras",
          data: {},
        })
        .then((result) => {
          this.tipos = result.data.tipos;
          this.proveedores = result.data.proveedores;
        });
    },
    nuevoTipo() {
      if (this.$refs.formTipo.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "tipo_compra";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.tipo,
          })
          .then((result) => {
            this.tipos.unshift(result.data.tipo);
            this.egreso.tipo_id = result.data.tipo.id;
            this.dialogTipo = false;
            this.$refs.formTipo.reset();
          });
      }
    },
  },
};
</script>