<template>
  <div>
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card class="cool" flat>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-btn
                    v-if="!colaTotal"
                    @click="editarColaTotal()"
                    color="red"
                    class="rounded-pill pa-3"
                    dark
                  >
                    <v-icon left>mdi-plus</v-icon>
                    CREAR COLA TOTAL
                  </v-btn>
                  <v-btn
                    @click="addQueues()"
                    color="red"
                    class="rounded-pill pa-3"
                    dark
                  >
                    <v-icon small left>mdi-server-network</v-icon>
                    Reescribir todas las queues
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    solo
                    v-model="search"
                    @keyup.enter="getDatos"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    class="rounded-pill"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-layout row wrap>
              <v-flex xs12>
                <v-card
                  v-if="colaTotal"
                  :color="colorQueue(colaTotal)"
                  class="cool"
                  dark
                >
                  <v-card-title primary-title>
                    Cola Total - Subida :
                    {{ convertiraMegas(colaTotal["max-limit"].split("/")[0]) }}
                    Bajada :
                    {{ convertiraMegas(colaTotal["max-limit"].split("/")[1]) }}
                    <br />

                    <v-spacer></v-spacer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn small @click="editarColaTotal()" icon v-on="on">
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar cola total</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn @click="eliminarColaTotal()" icon v-on="on">
                          <v-icon small> mdi-delete </v-icon>
                        </v-btn>
                      </template>
                      <span>Eliminar cola total del RB</span>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-subtitle>
                    Redes: {{ colaTotal.target }}
                  </v-card-subtitle>
                  <v-card-text> </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
            <v-data-table
              v-model="selected"
              :search="search"
              :headers="headers"
              :items="elementos"
              :options.sync="options"
              :server-items-length="totalElementos"
              :loading="loading"
              :expanded.sync="expanded"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-format-horizontal-align-left',
                lastIcon: 'mdi-format-horizontal-align-right',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                itemsPerPageAllText: 'Todos',
                itemsPerPageText: 'Registros por página:',
                itemsPerPageOptions: [10, 100, 500, 1000, -1],
              }"
            >
              <template v-slot:top>
                <v-container grid-list-md>
                  <v-layout v-if="selected.length > 0" row wrap>
                    <v-flex xs12>
                      <v-btn
                        @click="moverDeRouter()"
                        small
                        dark
                        color="success"
                        outlined
                        ><v-icon left>mdi-router-wireless</v-icon> Mover de
                        RB</v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-container>
              </template>

              <template v-slot:item.administracion="{ item }">
                <v-chip
                  v-if="item.antena.administracion == 1"
                  dark
                  small
                  outlined
                  color="green"
                >
                  <v-icon left>mdi-window-minimize</v-icon>
                  Queue
                </v-chip>
                <v-chip
                  v-if="item.antena.administracion == 2"
                  dark
                  small
                  outlined
                  color="indigo"
                >
                  <v-icon left>mdi-account-network</v-icon>
                  PPPoe
                </v-chip>
                <v-chip
                  v-if="item.antena.administracion == 3"
                  dark
                  small
                  outlined
                  color="blue"
                >
                  <v-icon left>mdi-account-outline</v-icon>
                  Ningúno
                </v-chip>
              </template>
              <template v-slot:item.detalles="{ item }">
                <div
                  v-if="item.antena.administracion == 2 && item.antena.perfil"
                  class="ma-1"
                >
                  <v-layout row wrap>
                    <strong class="mr-2"> Perfil: </strong>
                    {{ item.antena.perfil.name }}
                  </v-layout>
                  <v-layout row wrap>
                    <strong class="mr-2"> Secret: </strong>
                    {{ item.antena.secret }}
                  </v-layout>
                  <v-layout row wrap>
                    <strong class="mr-2"> Password: </strong>
                    {{ item.antena.password_secret }}
                  </v-layout>
                </div>
              </template>
              <template v-slot:item.servicio="{ item }">
                <div>
                  <v-icon>mdi-arrow-up-bold-circle-outline</v-icon>
                  <strong>
                    {{ item.servicios[0].subida }}
                  </strong>
                  Mb/
                  <strong>{{ item.servicios[0].bajada }} </strong>
                  Mb
                  <v-icon>mdi-arrow-down-bold-circle-outline</v-icon>
                </div>
              </template>
              <template v-slot:item.cliente="{ item }">
                <v-chip
                  small
                  color="primary"
                  outlined
                  @click="abrirCliente(item)"
                >
                  <v-icon left>mdi-account</v-icon>
                  {{ item.FullName }}</v-chip
                >
              </template>
              <template v-slot:item.ip="{ item }">
                <v-btn text color="blue" @click="abrirIp(item.antena.ip)">{{
                  item.antena.ip
                }}</v-btn>
              </template>

              <template v-slot:item.status="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      fab
                      class="elevation-0"
                      @click="editarEstado(item)"
                      v-bind="attrs"
                      v-on="on"
                      :color="getColorStatus(item.status.id)"
                      dark
                    >
                      <v-icon x-small v-if="item.status_cliente_id == 1"
                        >mdi-worker</v-icon
                      >
                      <v-icon x-small v-if="item.status_cliente_id == 2"
                        >mdi-wifi</v-icon
                      >
                      <v-icon x-small v-if="item.status_cliente_id == 3"
                        >mdi-wifi-off</v-icon
                      >
                      <v-icon x-small v-if="item.status_cliente_id == 4"
                        >mdi-timer</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>{{ item.status.status }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.opciones="{ item }">
                <v-tooltip v-if="item.antena.administracion == 1" top>
                  <template v-slot:activator="{ on }">
                    <v-btn small @click="registrarCola(item)" icon v-on="on">
                      <v-icon small> mdi-router-wireless </v-icon>
                    </v-btn>
                  </template>
                  <span>Reescribir cola en el RB</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn small @click="verAntena(item)" icon v-on="on">
                      <v-icon small>mdi-access-point-network</v-icon>
                    </v-btn>
                  </template>
                  <span>Ver antena</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="eliminarColaCliente(item.antena)"
                      icon
                      v-on="on"
                    >
                      <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar cola total del RB</span>
                </v-tooltip>
              </template>
              <template v-slot:no-results>
                <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                  >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                >
              </template>
              <template v-slot:no-data>
                <v-alert :value="true" color="red" dark icon="mdi-alert-circle">
                  <v-row align="center">
                    <v-col> Ningún dato que mostrar :( </v-col>
                    <v-col class="shrink">
                      <v-btn @click="getDatos()">
                        <v-icon pl-4>mdi-cached</v-icon> Refrescar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <editarEstadoCliente ref="refEstadoCliente"></editarEstadoCliente>
    <cambiarEstadoClienteEnrouter
      ref="refCambiarEstadoCliente"
    ></cambiarEstadoClienteEnrouter>
    <moverDeRouter ref="moverRouter"></moverDeRouter>
    <editarColaTotal ref="refEditarColaTotal"></editarColaTotal>
  </div>
</template>
  
  <script>
import moverDeRouter from "@/js/components/acciones_masivas/moverDeRouter.vue";
import LocalStorage from "@/js/LocalStorage.js";
import editarEstadoCliente from "@/js/components/clientes/listar_clientes/cambiarEstadoCliente.vue";
import cambiarEstadoClienteEnrouter from "@/js/components/clientes/listar_clientes/cambiarEstadoClienteRouter.vue";
import editarColaTotal from "@/js/components/red/routers/detalles/simple_queue/editarColaTotal.vue";

export default {
  components: {
    moverDeRouter,
    editarEstadoCliente,
    cambiarEstadoClienteEnrouter,
    editarColaTotal,
  },
  data() {
    return {
      search: "",
      expanded: [],
      singleSelect: false,
      selected: [],
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      headers: [
        {
          text: "Estado",
          value: "status",
          sortable: false,
        },
        { text: "cliente", value: "cliente", sortable: false },

        { text: "Ip", value: "ip", sortable: false },
        { text: "Servicio", value: "servicio", sortable: false },
        { text: "Administracion", value: "administracion", sortable: false },

        { text: "Detalles", value: "detalles", sortable: false },

        { text: "Opciones", value: "opciones", sortable: false },
      ],
      dialog: false,
      AntenaActualizar: {},
      id: null,
      status_id: "",
      dialogStatus: false,
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      estados: [],
      colaTotal: null,
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.id = arreUrl.pop();
    this.getEstados();
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},

  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `router/clientes/` + this.id,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.loading = false;
            this.elementos = result.data.clientes.data;
            this.totalElementos = result.data.clientes.total;
            this.colaTotal = result.data.colaTotal;
          }
        });
    },
    editarEstado(elemento) {
      const cliente = Object.assign({}, elemento);
      this.$refs.refEstadoCliente.cargarDatos(cliente).then((resultado) => {
        if (resultado) {
          this.clientes = [];
          this.getDatos();
        }
      });
    },
    cambiarEstadoEnRouter(elemento) {
      const cliente = Object.assign({}, elemento);
      this.$refs.refCambiarEstadoCliente
        .cargarDatos(cliente)
        .then((resultado) => {
          if (resultado) {
            this.clientes = [];
            this.getDatos();
          }
        });
    },
    getEstados() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `estados/todos`,
          data: {},
        })
        .then((result) => {
          this.estados = result.data.estados;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },

    status(item) {
      this.itemActualizar = item;
      const antena = Object.assign({}, item);
      this.status_id = antena.cliente.status_cliente_id;

      this.dialogStatus = true;
    },
    actualizarStatus() {
      if (this.$refs.formstatus.validate()) {
        let dispatch = "UsersModule/OnUpdate";
        let endpoint =
          `cambiar/estado/cliente/` + this.itemActualizar.cliente.id;

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: { status_id: this.status_id },
          })
          .then((result) => {
            this.dialogStatus = false;
            this.itemActualizar.cliente.status_cliente_id =
              result.data.cliente.status_cliente_id;
            this.itemActualizar.cliente.status = result.data.cliente.status;
          });
      }
    },

    moverDeRouter() {
      this.$refs.moverRouter.cargarDatos(this.selected).then((resultado) => {
        if (resultado) {
          this.getDatos();
          this.selected = [];
        }
      });
    },
    eliminarColaCliente(antena) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar la Simple Queue?", {
          color: "orange darken-2",
        })
        .then((confirm) => {
          if (confirm) {
            this.$store
              .dispatch("UsersModule/OnSave", {
                url: "cola/eliminar/router/antena/" + this.id,
                data: antena,
              })
              .then((result) => {
                this.getDatos();
              });
          }
        });
    },
    editarCola(cliente) {
      this.$refs.refEditarCola.esperarDatos(cliente).then((resultado) => {
        if (resultado) {
          this.getDatos();
        }
      });
    },
    getColorStatus(item) {
      if (item == 1) return "orange";
      else if (item == 2) return "green";
      else if (item == 3) return "red";
      else if (item == 4) return "purple";
      else return "back";
    },
    registrarCola(item) {
      this.$store
        .dispatch("UsersModule/OnSave", {
          url: `registrar/cola/cliente/` + this.id,
          data: item,
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.getDatos();
          }
        });
    },
    abrirIp(ip) {
      window.open("https://" + ip, "_blank");
    },
    abrirCliente(cliente) {
      LocalStorage.set("TAB_ACTUAL_CLIENTE", 0);
      document.location.href = "detalles/cliente/" + cliente.id;
    },
    verAntena(item) {
      LocalStorage.set("TAB_ACTUAL_CLIENTE", 0);
      document.location.href = "/detalles/antena/vista/" + item.antena.id;
    },
    convertiraMegas(velocidad) {
      return velocidad / 1000000 + "MB";
    },
    colorPing(item) {
      var latenciaAlta = 200;
      var latenciaMedia = 100;
      if (item.ping != null) {
        var latenciaMS = item.ping.split("ms")[0];
        var latencia = latenciaMS.split("us");

        if (latencia.length == 2) {
          item.ping = 0;
        }
        if (latencia >= latenciaAlta) {
          return "red";
        } else if (latencia >= latenciaMedia) {
          return "orange";
        } else {
          return "green";
        }
      } else {
        return "black";
      }
    },
    detallesQueue(cola) {
      if (!cola) {
        return {
          icono: "mdi-emoticon-dead",
          detalles: "Simple Queue no encontrada",
          texto: "N/E",
        };
      }
      if (cola.parent == "TOTAL") {
        return {
          icono: "mdi-checkbox-marked-circle-outline",
          detalles: "Queue registrada correctamente",
          texto: "Ok",
        };
      }
      if (cola.parent == "none") {
        return {
          icono: "mdi-alert",
          detalles: "Esta fuera de la Queue TOTAL",
          texto: "S/P",
        };
      }
    },
    colorQueue(cola) {
      if (!cola) {
        return "black";
      }
      var bytesUsedUp = cola.rate.split("/")[0];
      var bytesCapacityUp = cola["max-limit"].split("/")[0];
      var bytesUsedDown = cola.rate.split("/")[1];
      var bytesCapacityDown = cola["max-limit"].split("/")[1];
      var percentUsedUp = (bytesUsedUp / bytesCapacityUp) * 100;
      var percentUsedDown = (bytesUsedDown / bytesCapacityDown) * 100;

      if (percentUsedUp >= 90 || percentUsedDown >= 90) {
        return "red"; // si el uso es superior al 90% se devuelve el color rojo
      } else if (percentUsedUp >= 50 || percentUsedDown >= 50) {
        return "orange"; // si el uso está entre el 50% y el 89% se devuelve el color naranja
      } else {
        return "green"; // si el uso está por debajo del 50% se devuelve el color verde
      }
    },
    addQueues() {
      let dispatch = "UsersModule/OnSave";
      let endpoint = "add/queues/router/" + this.id;

      this.$store
        .dispatch(dispatch, {
          url: endpoint,
        })
        .then((result) => {
          this.getDatos();
        });
    },
    editarColaTotal() {
      this.$refs.refEditarColaTotal.esperarDatos(this.id).then((resultado) => {
        if (resultado) {
          this.getDatos();
        }
      });
    },
    eliminarColaTotal() {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar la cola total?", {
          color: "orange darken-2",
        })
        .then((confirm) => {
          if (confirm) {
            this.$store
              .dispatch("UsersModule/OnSave", {
                url: `cola/total/eliminar/router/` + this.id,
                data: {},
              })
              .then((result) => {
                this.getDatos();
              });
          }
        });
    },
  },
};
</script>
  