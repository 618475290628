<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12>
              <v-card-title primary-title> Permisos asignados </v-card-title>
            </v-flex>
            <v-flex xs12 md12>
              <v-container grid-list-md>
                <v-form dense ref="form" v-model="valid" lazy-validation>
                  <v-layout row wrap>
                    <v-flex xs12 md4>
                      <v-select
                        :items="roles"
                        v-model="rol_id"
                        label="Roles"
                        item-text="name"
                        item-value="id"
                        :rules="generalRegla"
                        prepend-icon="mdi-calendar-multiple"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 md8>
                      <v-textarea
                        v-model="rol.description"
                        rows="2"
                        label="Descripción"
                        filled
                        readonly
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                  <v-divider></v-divider>

                  <p><strong>Permisos</strong></p>
                  <v-layout class="mb-4" row wrap>
                    <v-expansion-panels popout>
                      <v-expansion-panel
                        v-for="(modelo, j) in modelos"
                        :key="j"
                      >
                        <v-expansion-panel-header>{{
                          modelo.model
                        }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-layout row wrap>
                            <div v-for="(permiso, i) in permisos" :key="i">
                              <v-flex v-if="permiso.modelo == modelo.model" md3>
                                <v-checkbox
                                  v-model="permiso.seleccionado"
                                  :label="permiso.name"
                                ></v-checkbox>
                              </v-flex>
                            </div>
                          </v-layout>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-layout>
                  <v-layout row wrap>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="guardar()"
                      :disabled="!valid"
                      class="rounded-pill pa-3"
                      color="primary"
                      >Actualizar</v-btn
                    >
                  </v-layout>
                </v-form>
              </v-container>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
export default {
  data() {
    return {
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      valid: true,
      rol_id: 0,
      roles: [],
      permissions: [],
      permisosActivos: [],
      permisosActuales: [],
      rolActual: null,
      modelos: [],
      rol: {
        id: "",
        name: "",
        description: "",
      },
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.id = arreUrl.pop();
    this.getDatos();
  },
  watch: {
    rol_id(val) {
      this.getPermisos(val);
    },
  },
  computed: {
    permisos() {
      return this.permissions.map((entry) => {
        const id = entry.id;
        const name = entry.name;
        var seleccionado = false;
        for (var i = 0; i < this.permisosActivos.length; i++) {
          if (this.permisosActivos[i].id == id) {
            seleccionado = true;
          }
        }
        const modelo = entry.model;
        return Object.assign({}, entry, { id, name, seleccionado, modelo });
      });
    },
  },
  methods: {
    getPermisos(id) {
      let dispatch = "UsersModule/OnGet";
      let enpoint = "roles/" + id;
      this.$store
        .dispatch(dispatch, {
          url: enpoint,
          data: {},
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.rol = result.data.rol;
            if (this.rol.id == this.rolActual.id) {
              this.permisosActivos = this.permisosActuales;
            } else {
              this.permisosActivos = result.data.permisos;
            }
          }
        });
    },
    guardar() {
      if (this.$refs.form.validate()) {
        var elegidos = this.sacarElegidos(this.permisos);

        var datos = {
          rol_id: this.rol_id,
          permisos: elegidos,
        };
        let dispatch = "UsersModule/OnSave";
        let enpoint = "actualizar/permisos/personal/" + this.id;

        this.$store
          .dispatch(dispatch, {
            url: enpoint,
            data: datos,
          })
          .then((result) => {
            this.permisosActivos = result.data.permisos;
          });
      }
    },
    getDatos() {
      let dispatch = "UsersModule/OnGet";
      let enpoint = "datos/permisos/personal/" + this.id;

      this.$store
        .dispatch(dispatch, {
          url: enpoint,
          data: {},
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.roles = result.data.roles;
            this.rol_id = result.data.rolAsignado.id;
            this.rolActual = result.data.rolAsignado;
            this.modelos = result.data.modelos;
            this.permisosActuales = result.data.permisosAsignados;
            this.permissions = result.data.permisosTodos;
          }
        });
    },
    sacarElegidos(permisos) {
      var elegidos = [];
      for (let index = 0; index < permisos.length; index++) {
        var permiso = permisos[index];
        if (permiso.seleccionado == true) {
          elegidos.push(permiso);
        }
      }
      return elegidos;
    },
  },
};
</script>