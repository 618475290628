<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <v-card class="cool pa-4">
          <v-layout row wrap>
            <v-flex xs12 md10>
              <v-layout row wrap>
                <v-flex xs7 md7>
                  <v-card flat dense class="cool ma-4">
                    <v-tabs center-active dense show-arrows>
                      <v-tab @click="seleccionarCategoria({ id: 0 })">
                        Todos
                      </v-tab>
                      <v-tab
                        v-for="categoria in categorias"
                        :key="categoria.id"
                        @click="seleccionarCategoria(categoria)"
                      >
                        {{ categoria.categoria }}
                      </v-tab>
                    </v-tabs>
                  </v-card>
                </v-flex>
                <v-divider vertical></v-divider>
                <v-flex ml-4 mt-4 xs4 md4>
                  <v-text-field
                    dense
                    solo
                    class="rounded-pill"
                    v-model="search"
                    @keyup.enter="getDatos"
                    prepend-icon="mdi-magnify"
                    label="Buscador"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-card :height="scrollProductos" dense flat>
                <v-responsive
                  id="scroll-target"
                  class="overflow-y-auto"
                  :max-height="scrollProductos"
                >
                  <v-row justify="center">
                    <div
                      v-for="(producto, index) in productos"
                      :key="producto.id"
                    >
                      <v-card
                        max-width="220"
                        dense
                        class="ma-3"
                        :disabled="producto.cantidad <= 0"
                      >
                        <v-img
                          v-if="producto.imagen"
                          :src="producto.imagen"
                          height="100px"
                          color="purple"
                        ></v-img>
                        <v-img
                          v-else
                          src="/img/noimagen.jpg"
                          height="100px"
                          color="purple"
                        ></v-img>
                        <v-container grid-list-md>
                          <h6>
                            {{ producto.producto }}
                          </h6>
                          <p class="color-text-red">
                            {{ producto.marca }}
                          </p>
                          <v-layout row wrap>
                            <v-btn
                              color="deep-purple darken-1"
                              dark
                              @click="agregarCarrito(producto)"
                            >
                              <v-icon>mdi-cart-plus</v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>

                            <h5 class="ma-2">
                              ${{
                                parseFloat(producto.precio_venta).toFixed(2)
                              }}
                            </h5>
                            <v-btn icon @click="verDetalles(producto)">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </v-layout>
                        </v-container>
                      </v-card>
                    </div>
                  </v-row>
                </v-responsive>
              </v-card>
              <v-card flat>
                <div class="text-center">
                  <v-pagination
                    v-model="page"
                    :length="paginas"
                    :value="page"
                  ></v-pagination>
                </div>
              </v-card>
            </v-flex>
            <v-flex xs12 md2>
              <v-card height="500" dense>
                <v-responsive
                  id="scroll-target"
                  class="overflow-y-auto"
                  :max-height="510"
                >
                  <div v-for="(producto, index) in carrito" :key="producto.id">
                    <v-card elevation-2 dense class="ma-2">
                      <v-container grid-list-md>
                        <v-layout row wrap>
                          <h6>{{ producto.producto }}</h6>
                          <v-spacer></v-spacer>
                          <v-icon small @click="quitarProducto(producto, index)"
                            >mdi-close</v-icon
                          >
                        </v-layout>
                        <p>{{ producto.marca }}</p>
                        <v-layout row wrap>
                          <h6>
                            ${{
                              parseFloat(
                                producto.precio_venta * producto.piezas
                              ).toFixed(2)
                            }}
                          </h6>
                          <v-spacer></v-spacer>
                          <h6>Cant: {{ producto.piezas }}</h6>
                        </v-layout>
                        <v-layout row wrap>
                          <v-flex xs6>
                            <v-btn
                              block
                              dark
                              small
                              @click="menosPiezas(producto)"
                              color="deep-purple darken-1"
                            >
                              <v-icon>mdi-minus-circle</v-icon>
                            </v-btn>
                          </v-flex>
                          <v-flex xs6>
                            <v-btn
                              block
                              dark
                              small
                              @click="masPiezas(producto)"
                              color="deep-purple darken-1"
                            >
                              <v-icon>mdi-plus-circle</v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card>
                  </div>
                </v-responsive>
              </v-card>
              <v-card color="lime lighten-2" dense>
                <v-container grid-list-md>
                  <v-form ref="form" v-model="valid" lazy-validation dense>
                    <v-layout row wrap>
                      <v-text-field
                        v-model="descuento"
                        type="number"
                        label="Descuento"
                        placeholder="0.00"
                        :rules="reglaDescuento"
                        prefix="$"
                      ></v-text-field>
                    </v-layout>
                  </v-form>
                  <v-layout row wrap>
                    <h5>Total:</h5>
                    <v-spacer></v-spacer>
                    <h5>{{ calcularTotal() }}</h5>
                  </v-layout>

                  <v-divider></v-divider>
                  <v-row class="ma-1" justify="center">
                    <v-btn
                      @click="vender()"
                      block
                      class="rounded-pill pa-3"
                      color="success"
                      >Vender
                      <v-icon>mdi-lock</v-icon>
                    </v-btn>
                  </v-row>
                </v-container>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </div>
    </base-component>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      paginas: 0,
      search: "",
      categorias: [],
      categoria: 0,
      items: [],
      carrito: [],
      descuento: "0",
      valid: true,
      scrollProductos: "603",
      reglaDescuento: [(v) => !!v || "si no hay descuento dejar en 0"],
      isMobile: false,
    };
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });

    this.getDatos();
    this.getCategorias();
  },
  computed: {
    productos() {
      return this.items.map((entry) => {
        let ver = false;
        let piezas = 1;
        return Object.assign({}, entry, { ver, piezas });
      });
    },
  },
  watch: {
    page(val) {
      this.getDatos();
    },
    isMobile(val) {
      if (val) {
        this.scrollProductos = "350";
      } else {
        this.scrollProductos = "603";
      }
    },
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    getDatos() {
      this.loading = true;
      let datos = {
        page: this.page,
        per_page: 12,
        search: this.search,
        sort: "id|desc",
        categoria: this.categoria,
      };

      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `productos/tienda`,
          data: datos,
        })
        .then((result) => {
          this.loading = false;
          this.items = result.data.productos.data;
          this.paginas = result.data.productos.last_page;
        });
    },
    getCategorias() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "categorias",
          data: {},
        })
        .then((result) => {
          this.categorias = result.data.categorias;
        });
    },
    seleccionarCategoria(item) {
      this.page = 1;
      this.categoria = item.id;
      this.getDatos();
    },
    verDetalles(producto) {
      this.$store.dispatch("aviso", {
        mostrar: true,
        color: "primary",
        titulo: "Detalles",
        mensaje:
          "CANTIDAD EN EXISTENCIA: " +
          producto.cantidad +
          "       PROVEEDOR: " +
          producto.proveedor.nombre +
          "      CATEGORIA: " +
          producto.categoria.categoria +
          "      Detalles: " +
          producto.detalles,
      });
    },
    agregarCarrito(item) {
      var esta = false;
      for (var i = 0; i < this.carrito.length; i++) {
        if (item.id == this.carrito[i].id) {
          if (item.piezas >= item.cantidad) {
            this.$store.dispatch("aviso", {
              mostrar: true,
              color: "primary",
              titulo: "No hay mas",
              mensaje:
                "Ya no existe mas producto en stock revisa el inventario",
            });
          } else {
            item.piezas = item.piezas + 1;
          }
          esta = true;
        }
      }

      if (!esta) {
        this.carrito.push(item);
      }
    },
    quitarProducto(producto, index) {
      producto.piezas = 1;
      this.carrito.splice(index, 1);
    },
    menosPiezas(producto) {
      if (producto.piezas > 1) {
        producto.piezas = producto.piezas - 1;
      }
    },
    masPiezas(producto) {
      if (producto.piezas >= producto.cantidad) {
        this.$store.dispatch("aviso", {
          mostrar: true,
          color: "primary",
          titulo: "No hay mas",
          mensaje: "Ya no existe mas producto en stock revisa el inventario",
        });
      } else {
        producto.piezas = producto.piezas + 1;
      }
    },
    calcularTotal() {
      let total = 0.0;
      for (let index = 0; index < this.carrito.length; index++) {
        let costo =
          this.carrito[index].precio_venta * this.carrito[index].piezas;

        total = total + costo;
      }

      return parseFloat(total - this.descuento).toFixed(2);
    },
    vender() {
      if (this.carrito.length > 0 && this.$refs.form.validate()) {
        this.$refs.confirmar
          .open(
            "Confirmación",
            "¿Esta seguro de realizar esta venta de un total de: " +
              this.calcularTotal(),
            { color: "blue lighten-2" }
          )
          .then((confirm) => {
            if (confirm) {
              let dispatch = "UsersModule/OnSave";
              let endpoint = "registrar/venta";
              let datos = {
                carrito: this.carrito,
                total: this.calcularTotal(),
                descuento: this.descuento,
              };
              this.$store.dispatch("mostrarCarga", true);

              this.$store
                .dispatch(dispatch, {
                  url: endpoint,
                  data: datos,
                })
                .then((result) => {
                  this.carrito = [];
                  this.categoria = 0;
                  this.search = "";
                  this.page = 1;
                  this.getDatos();
                });
            } else {
            }
          });
      }
    },
  },
};
</script>
