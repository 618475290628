<template>
    <div class="component">
        <base-component>
            <div slot="component">
                <v-container grid-list-md>
                    
                <v-card-title primary-title class="headline">
                    <strong>Rol</strong>
                </v-card-title>
                <v-layout row wrap>
                    <v-flex xs12 md12>
                        <v-container grid-list-md>
                            <v-form dense ref="form" v-model="valid" lazy-validation>
                                  <v-layout row wrap>
                                      <v-flex xs12 md4>
                                          <v-select
                                              :items="roles"
                                              v-model="rol_id"
                                              label="Roles"
                                              item-text="name"
                                              item-value="id"
                                              :rules="generalRegla"
                                              prepend-icon="mdi-calendar-multiple"
                                          ></v-select>
                                      </v-flex>
                                    <v-flex xs12 md8>
                                        <v-textarea
                                            v-model="rol.description"
                                            rows="1"
                                            label="Descripción"
                                            filled
                                            readonly
                                        ></v-textarea>                                        
                                      </v-flex>
                                     </v-layout>
                                <p><strong>Permisos</strong></p>

                                <v-responsive class="overflow-y-auto mb-4" max-height="300">
                                     <v-layout row wrap>
                                     <v-expansion-panels popout>
                                       <v-expansion-panel v-for="(modelo,j) in modelos" :key="j">
                                            <v-expansion-panel-header>{{modelo.model}}</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                               <v-layout row wrap>
                                                   <div v-for="(permiso,i) in permisos" :key="i">
                                                <v-flex v-if="permiso.modelo == modelo.model" md3>
                                                    <v-checkbox 
                                                        v-model="permiso.seleccionado"
                                                        :label="permiso.name"
                                                    ></v-checkbox>
                                                    
                                                </v-flex>
                                                </div>
                                               </v-layout>
                                               
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        </v-expansion-panels>
                                     </v-layout>
                                     </v-responsive>
                                     <v-layout row wrap>
                                        <v-spacer></v-spacer>
                                         <v-btn text @click="regresar()" >Regresar</v-btn>
                                        <v-btn @click="validate()" class="rounded-pill pa-3" :disabled="!valid" color="primary">Continuar</v-btn>
                                
                                     </v-layout>
                                                   
                            </v-form>
                        </v-container>
                    </v-flex>
                </v-layout>
                </v-container>
                
            </div>
        </base-component>
    </div>
</template>
<script>
export default {

    data() {
        return {
            valid: true,
            rol_id:0,
            roles:[],
            generalRegla:[
                v =>
                !!v ||
                "Se necesita el sueldo"],
            permissions:[],
            modelos:[],
            rol:{
                id:"",
                name:"",
                description:"",
            }
            

        };
    },
    mounted() {
        this.getDatos();
        
    },
    watch: {
        rol_id(val){
            this.getPermisos(val);
        }
    },
    computed: {
        permisos(){
          return this.permissions.map(entry => {
          const id = entry.id;
          const name = entry.name;
          const seleccionado = true;
          const modelo = entry.model;
          return Object.assign({}, entry, {id,name,seleccionado,modelo })
          })
          
         },
    },
    methods: {
        getPermisos(id){
            let dispatch = 'UsersModule/OnGet';
            let enpoint = 'roles/'+id;

            this.$store.dispatch(dispatch,{
                url:enpoint,
                data: {}
            }).then(result => {
                
                if([200,201].includes(result.status)){
                    this.rol = result.data.rol;
                    this.permissions = result.data.permisos;
                    this.modelos = result.data.modelos;
                }
            })
        },
        validate(){
            if(this.$refs.form.validate()){
                var elegidos = this.sacarElegidos(this.permisos);
                
                
                var datos={
                    rol_id:this.rol_id,
                    seleccionados:elegidos,
                }
                this.$store.dispatch('registroPersonalPaso3',{paso:4,datos:datos})
            }
        },
        regresar(){
            this.$store.dispatch('pasoPersonal',2);
        },
        getDatos(){
            let dispatch = 'UsersModule/OnGet';
            let enpoint = 'todos/roles';

            this.$store.dispatch(dispatch,{
                url:enpoint,
                data: {}
            }).then(result => {
                
                if([200,201].includes(result.status)){
                    this.roles = result.data.roles;
                }
            })
        },
        sacarElegidos(permisos){
            var elegidos =[]
            for (let index = 0; index < permisos.length; index++) {
                const permiso = permisos[index];
                if(permiso.seleccionado == true){
                    elegidos.push(permiso);
                }


            }
            return elegidos;
        },
       
    }

};
</script>