<template>
    <div class="text-center">
        <v-dialog dense persistent v-model="mostrar" width="400">
            <v-card>
              <v-container grid-list-md>
                <v-card-title primary-title><strong>{{ titulo }}</strong></v-card-title>
                <v-card-text>{{ mensaje }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="rounded-pill pa-3" @click="cerrar()">Okay</v-btn>
                </v-card-actions>
              </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>
 
</template>
<script>
export default {
  props: {
    titulo: {
      type: String,
      required: false
    },
    mensaje: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      vista:true,
    };
  },
  computed:{
    mostrar(){
      return this.$store.getters.get_dialog_datos.mostrar;
    },
    color(){
      return this.$store.getters.get_dialog_datos.color;
    }
  },
  mounted(){
    
  },
  methods:{
    cerrar(){
      this.$store.dispatch('cerrar_aviso');
    }
  }
};
</script>