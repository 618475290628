<template>
  <v-dialog v-model="dialog" max-width="400" persistent>
    <v-card>
      <v-container grid-list-md>
        <v-card-title primary-title>
          <strong>Editar</strong>
        </v-card-title>
        <v-form
          dense
          ref="form"
          @submit.prevent="enviarDatos()"
          v-model="valid"
          lazy-validation
        >
          <v-card-text>
            <v-text-field
              label="Tipo"
              v-model="tipo.captacion"
              prepend-icon="mdi-cube-send"
              :rules="generalRegla"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelar()">Cancelar</v-btn>
            <v-btn
              color="primary"
              class="rounded-pill pa-3"
              type="submit"
              :disabled="!valid"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      dialog: false,
      tipo: {
        id: 0,
        tipo: "",
      },
      resolve: null,
      reject: null,
    };
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    mostrar(item) {
      this.tipo = item;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    enviarDatos() {
      if (this.$refs.form.validate()) {
        let dispatch = "UsersModule/OnUpdate";
        let endpoint = `captacion/` + this.tipo.id;

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.tipo,
          })
          .then((result) => {
            this.dialog = false;
            this.limpiar();
            this.resolve(result.data.captacion);
          });
      }
    },
    limpiar() {
      this.$refs.form.reset();
    },
    cancelar() {
      this.dialog = false;
      this.resolve(false);
    },
  },
};
</script>