<template>
  <div>
    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <v-layout row wrap>
      <v-flex xs12 md12>
        <v-card class="cool-xs pa-4" outlined>
          <div v-if="showChart">
            <apexchart
              type="line"
              height="350"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import apexchart from "vue-apexcharts";

export default {
  components: {
    apexchart,
  },
  data() {
    return {
      showChart: false,
      series: [
        {
          name: "Ping (MS)",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Ping",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          type: "datetime",
          categories: [],
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      },
    };
  },
  mounted() {
   
  },
  computed: {},
  methods: {
    
    graficar(datos) {
      
      let pings = [];
      for (let index = 0; index < datos.length; index++) {
        let dato = datos[index];
        
        pings.push(dato.ping?parseInt(dato.ping):dato.ping);
        var date = new Date(dato.created_at);
        var fecha = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        ).toISOString();

        this.chartOptions.xaxis.categories.push(fecha);
      }

      this.series = [
        {
          name: "Ping (MS)",
          data: pings,
        },
      ];
      

      this.showChart = true;

    },
  },
};
</script>