<template>
      <v-card>
          <v-card-title primary-title>
            <v-layout justify-center align-center>
              <v-flex xs12>
                <v-card color="green" class="elevation-6">
                  <v-card-title>
                    <h5 class="headline mb-0" style="color: white;">{{title}}</h5>
                  </v-card-title>
                </v-card>
              </v-flex>
            </v-layout>
          </v-card-title>
        <v-card-text>
            <slot></slot>
        </v-card-text>
      </v-card>
</template>

<script>
export default {
  props: {
      title:{
          type:String,
          required:true
      }
  }
};
</script>

<style lang="scss" scoped>
.v-card__title--primary {
    padding-top: 16px;
    padding-bottom: 0px;
}
.v-card__text {
    padding-top: 0px;
}
</style>