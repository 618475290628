
class Persona {
    constructor(){
             this.id = 0
             this.user_id = ""
             this.nombre = ""
             this.apellido_paterno= ""
             this.apellido_materno= ""
             this.direccion = ""
             this.municipio= ""
             this.estado= ""
             this.municipio= ""
             this.cp =""
             this.colonia= ""
             this.longitud= ""
             this.latitud= ""
             this.sexo = ""
             this.lunes = ""
             this.martes = ""
             this.miercoles= ""
             this.jueves= ""
             this.viernes= ""
             this.sabado= ""
             this.domingo= ""
             this.lunes_entrada= ""
             this.lunes_salida= ""
             this.martes_entrada= ""
             this.martes_salida= ""
             this.miercoles_entrada= ""
             this.miercoles_salida= ""
             this.jueves_entrada= ""
             this.jueves_salida= ""
             this.viernes_entrada= ""
             this.viernes_salida= ""
             this.sabado_entrada= ""
             this.sabado_salida= ""
             this.domingo_entrada= ""
             this.domingo_salida= ""
             this.imagen = null
             this.eliminado= ""
             this.celular =""
             this.tipo_id= "";
             this.periodo_pago_id="";
             this.sueldo="";
             this.fecha_nacimiento=null;
             
    }
}

export default Persona
