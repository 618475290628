<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <v-layout row wrap>
          <v-flex xs12 md12>
            <v-dialog v-model="dialog" max-width="800px" persistent>
              <v-card>
                <v-container grid-list-md>
                  <v-card-title primary-title> Actualizar </v-card-title>

                  <v-container grid-list-md>
                    <v-form
                      dense
                      ref="form"
                      v-model="valid"
                      enctype="multipart/form-data"
                      lazy-validation
                    >
                      <v-layout row wrap>
                        <v-flex xs12 md12>
                          <v-autocomplete
                            ref="autoCliente"
                            v-model="reporte.cliente_id"
                            :items="items"
                            item-text="todo"
                            item-value="id"
                            max-width="30"
                            :search-input.sync="searchCliente"
                            hide-no-data
                            label="Cliente"
                            placeholder="Empieza a escribir para búscar"
                            prepend-icon="mdi-account"
                          >
                            <template max-width="60" v-slot:item="{ item }">
                              <v-list-item-avatar
                                color="indigo"
                                class="headline font-weight-light white--text"
                              >
                                {{ item.nombre.charAt(0) }}
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >{{ item.nombre }}
                                  {{ item.apellido_paterno }}
                                  {{ item.apellido_materno }}</v-list-item-title
                                >
                                <v-list-item-subtitle>{{
                                  item.direccion
                                }}</v-list-item-subtitle>
                                <v-list-item-subtitle
                                  >Celular:
                                  {{ item.celular }}</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap>
                        <v-flex xs12 md8>
                          <v-text-field
                            label="Asunto"
                            v-model="reporte.asunto"
                            :rules="generalRegla"
                            prepend-icon="mdi-pen"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 md4>
                          <v-text-field
                            label="Costo"
                            v-model="reporte.costo"
                            :rules="generalRegla"
                            type="number"
                            prepend-icon="mdi-cash-usd"
                            prefix="$"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>

                      <v-layout row wrap>
                        <v-flex xs12 md6>
                          <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="computedDateFormatted"
                                label="Dia de revisión"
                                prepend-icon="mdi-calendar"
                                readonly
                                :rules="generalRegla"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              locale="es-mx"
                              v-model="reporte.fecha"
                              no-title
                              @input="menu2 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-flex>

                        <v-flex xs12 md6>
                          <v-menu
                            ref="menu"
                            v-model="menu_reloj"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="reporte.hora"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="reporte.hora"
                                label="Hora"
                                prepend-icon="mdi-av-timer"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="menu_reloj"
                              v-model="reporte.hora"
                              full-width
                              @click:minute="$refs.menu.save(reporte.hora)"
                            ></v-time-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-form>
                  </v-container>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="cancelar()">Cancelar</v-btn>
                    <v-btn
                      @click="actualizar()"
                      class="rounded-pill pa-3"
                      color="primary"
                      >Guardar</v-btn
                    >
                  </v-card-actions>
                </v-container>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
export default {
  data() {
    return {
      reporte: {
        costo: "",
        asunto: "",
        fecha: null,
        hora: null,
        personal: "",
        cliente_id: "",
      },
      clientes: [],
      personal: [],
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: new Date().toISOString().substr(0, 10),
      menu2: false,
      menu_reloj: false,
      searchPersonal: "",
      searchCliente: "",
      resolve: null,
      reject: null,
      dialog: false,
    };
  },
  mounted() {
    this.getDatos();
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  computed: {
    computedDateFormatted() {
      if (this.reporte.fecha) {
        return this.formatDate(this.date);
      } else {
        return "";
      }
    },
    items() {
      return this.clientes.map((entry) => {
        const todo =
          entry.nombre +
          " " +
          entry.apellido_paterno +
          " " +
          entry.apellido_materno +
          " ";
        return Object.assign({}, entry, { todo });
      });
    },
    itemsPersonal() {
      return this.personal.map((entry) => {
        const todo =
          entry.nombre +
          " " +
          entry.apellido_paterno +
          " " +
          entry.apellido_materno +
          " ";
        return Object.assign({}, entry, { todo });
      });
    },
  },
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "datos/form/reporte",
          data: {},
        })
        .then((result) => {
          this.clientes = result.data.clientes;
        });
    },
    esperarDatos(item) {
      this.reporte = item;
      this.reporte.personal = item.personal.id;
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    actualizar() {
      if (this.$refs.form.validate()) {
        let dispatch = "UsersModule/OnUpdate";
        let endpoint = `reporte/` + this.reporte.id;

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.reporte,
          })
          .then((result) => {
            this.limpiar();
            this.dialog = false;
            this.resolve(result.data.reporte);
          });
      }
    },
    limpiar() {
      this.reporte.costo = "";
      this.reporte.asunto = "";
      this.reporte.fecha = null;
      this.reporte.hora = null;
      this.reporte.personal = "";
      this.reporte.cliente_id = "";
      this.searchCliente = "";
      this.searchPersonal = "";
    },
    cancelar() {
      this.dialog = false;
      this.resolve(false);
    },
  },
};
</script>