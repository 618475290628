export const namespaced = true

export const state = {
    notifications: []
}

let nextId = 1

export const mutations = {
    PUSH(state, notification) {

        for (let index = 0; index < state.notifications.length; index++) {
            if (notification.id == state.notifications[index].id) {
                return;
            }
        }
        state.notifications.push({
            ...notification,
            id: nextId++
        })

    },
    DELETE(state, notificationToRemove) {

        state.notifications = state.notifications.filter(
            notification => notification.id !== notificationToRemove.id
        )


    },
    MOSTRAR_DETALLES(state, notification) {
        for (let index = 0; index < state.notifications.length; index++) {
            if (notification.id == state.notifications[index].id) {
                state.notifications[index].dialog = true;
                return;
            }
        }
    },
    OCULTAR_NOTIFICACION(state, notification) {
        for (let index = 0; index < state.notifications.length; index++) {
            if (notification.id == state.notifications[index].id) {
                state.notifications[index].show = false;
                return;
            }
        }
    }

}
export const actions = {
    add({ commit }, notification) {
        var nuevaNotification = {
            tipo: notification.tipo,
            mensaje: notification.mensaje,
            show: notification.show,
            dialog: notification.dialog,
            tiempo: notification.tiempo ? notification.tiempo : 3
        };
        commit('PUSH', nuevaNotification)
    },
    remove({ commit }, notificationToRemove) {
        commit('DELETE', notificationToRemove)
    },
    detallesNotificacion({ commit }, notification) {
        commit('MOSTRAR_DETALLES', notification)
    },

    cerrarNotificacion({ commit }, notification) {
        commit('OCULTAR_NOTIFICACION', notification)
    },
}