<template>
  <div>
    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <v-dialog v-model="dialog" max-width="450px">
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title> Nuevo </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation dense>
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12 md12>
                    <v-text-field
                      label="Nombre del tipo de porcentaje"
                      v-model="tipo.tipo"
                      :rules="generalRegla"
                      prepend-icon="mdi-lead-pencil"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 md6>
                    <v-text-field
                      label="Porcentaje empresa"
                      v-model="porcentajeEmpresa"
                      prepend-icon="mdi-factory"
                      suffix="%"
                      type="number"
                      :rules="porcentajeRegla2"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field
                      label="Porcentaje negocio"
                      v-model="tipo.porcentaje_negocio"
                      prepend-icon="mdi-store"
                      suffix="%"
                      type="number"
                      :rules="porcentajeRegla"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="cancelar()" text>Cancelar</v-btn>
            <v-btn @click="nuevo()" class="rounded-pill" color="primary"
              >Registrar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tipo: {
        tipo: "",
        porcentaje_empresa: "",
        porcentaje_negocio: "",
      },
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      porcentajeRegla: [
        (v) => !!v || "Se necesita el campo",
        (v) => (v >= 0 && v <= 100) || "No puede ser mayor a 100",
        (v) =>
          parseInt(v) + parseInt(this.tipo.porcentaje_empresa) == 100 ||
          "la suma de los porcentajes debe ser igual a 100",
      ],
      porcentajeRegla2: [
        (v) => !!v || "Se necesita el campo",
        (v) => (v >= 0 && v <= 100) || "No puede ser mayor a 100",
      ],
      porcentajeEmpresa: "",
      dialog: false,
    };
  },
  mounted() {},
  watch: {
    porcentajeEmpresa(val) {
      this.tipo.porcentaje_empresa = val;
      this.tipo.porcentaje_negocio = "";
    },
  },
  computed: {},
  methods: {
    mostrar() {
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    nuevo() {
      if (this.$refs.form.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "tipo_ingreso_negocio";
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.tipo,
          })
          .then((result) => {
            this.dialog = false;
            this.limpiar();
            this.resolve(result.data.tipo);
          });
      }
    },
    limpiar() {
      this.$refs.form.reset();
    },
    cancelar() {
      (this.dialog = false), this.resolve(false);
    },
  },
};
</script>