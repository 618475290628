<template>
  <div>
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12 md12>
          <v-form
            dense
            ref="form"
            v-model="valid"
            enctype="multipart/form-data"
            lazy-validation
          >
            <v-layout row wrap>
              <v-layout row wrap>
                <v-flex xs12 md4>
                  <v-text-field
                    v-model="cliente.nombre"
                    :rules="nombreReglas"
                    label="Nombre"
                    required
                    filled
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    v-model="cliente.apellido_paterno"
                    :rules="generalRegla"
                    label="Apellido paterno"
                    required
                    filled
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    v-model="cliente.apellido_materno"
                    :rules="generalRegla"
                    label="Apellido materno"
                    required
                    filled
                  ></v-text-field>
                </v-flex>

                <!-- <v-flex xs12 md3>
									<v-file-input :rules="rulesFile" accept="image/png, image/jpeg, image/bmp" placeholder="Maximo 2MB" show-size v-model="imagen" prepend-icon="mdi-camera" small-chips multiple label="Foto del cliente"></v-file-input>
								</v-flex> -->

                <v-flex xs12 md4>
                  <v-text-field
                    label="Celular"
                    v-model="cliente.celular"
                    type="number"
                    :rules="telefonoReglas"
                    append-icon="mdi-cellphone-android"
                    filled
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    label="Teléfono"
                    v-model="cliente.telefono"
                    type="number"
                    append-icon="mdi-phone"
                    filled
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 md4>
                  <v-text-field
                    label="Correo cliente"
                    v-model="cliente.correo"
                    append-icon="mdi-mail"
                    :rules="emailRules"
                    filled
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-flex xs12 md12>
                <p class="tittle">Dirección</p>
                <v-layout row wrap>
                  <v-flex xs12 md2>
                    <v-text-field
                      v-model="cp"
                      label="Código postal"
                      :rules="generalRegla"
                      type="number"
                      required
                      filled
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md5>
                    <v-text-field
                      v-model="cliente.estado"
                      label="Estado"
                      :rules="generalRegla"
                      required
                      filled
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md5>
                    <v-text-field
                      v-model="cliente.municipio"
                      label="Municipio"
                      :rules="generalRegla"
                      required
                      filled
                    ></v-text-field>
                  </v-flex>
                  <v-flex v-if="colonias.length != 0" xs12 md4>
                    <v-select
                      v-model="cliente.colonia"
                      :items="colonias"
                      item-text="nombre"
                      item-value="nombre"
                      :rules="generalRegla"
                      label="Colonias"
                      filled
                      :menu-props="{ top: true, offsetY: true }"
                      hide-details
                      single-line
                    ></v-select>
                  </v-flex>
                  <v-flex v-if="colonias.length == 0" xs12 md4>
                    <v-text-field
                      v-model="cliente.colonia"
                      :rules="generalRegla"
                      label="Colonia"
                      required
                      filled
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      v-model="cliente.direccion"
                      label="Dirección"
                      :rules="generalRegla"
                      required
                      filled
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-switch
                      v-model="datosFiscales"
                      label="Datos Fiscales"
                    ></v-switch>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex v-if="datosFiscales" xs12 md12>
                <v-card outlined flat class="pa-4 mb-4">
                  <v-layout row wrap>
                    <v-flex xs12 md6>
                      <v-text-field
                        v-model="cliente.razon_social"
                        label="Razón social"
                        append-icon="mdi-office-building"
                        required
                        filled
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="Tipo de identificación"
                        v-model="cliente.tipo_identificacion"
                        append-icon="mdi-ballot"
                        filled
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="RFC-RUC-NIT"
                        v-model="cliente.RFC_RUC_NIT"
                        append-icon="mdi-briefcase-account"
                        filled
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="Retenciones"
                        v-model="cliente.retenciones"
                        filled
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout row wrap>
              <v-flex class="mb-2" xs12>
                <mapa ref="ubicacion"></mapa>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!valid"
                color="primary"
                @click="validate"
                class="rounded-pill pa-3"
                >Continuar</v-btn
              >
            </v-layout>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import mapa from "@/js/components/mapa/mapaComponent.vue";
import Cliente from "@/js/components/clientes/Cliente.js";
export default {
  components: {
    mapa,
  },
  data() {
    return {
      cliente: new Cliente(),
      rulesFile: [
        (value) =>
          !value ||
          value.length == 0 ||
          value[0].size < 2000000 ||
          "Debe ser menor a 2 MB!",
      ],
      //imagen:null,
      valid: true,
      datosFiscales: false,
      nombreReglas: [(v) => !!v || "Se necesita el nombre"],
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      telefonoReglas: [(v) => v > 0 || "Se necesita un número de contacto"],
      emailRules: [
        (v) => /.+@.+/.test(v) || v.length == 0 || "Introduce un correo valido",
      ],
      dialog_mapa: false,
      colonias: [],
      modal2: false,
      modal: false,
      cp: "",
    };
  },
  mounted() {
    this.esperarComponente();
  },
  watch: {
    cp(val) {
      //this.getDatosApi(val);
      this.cliente.codigo_postal = val;
    },
    /*
		imagen(val) {
			if (val != null) {
				this.cliente.imagen = val[0];
			}
		},
		*/
  },
  computed: {},
  methods: {
    async esperarComponente() {
      await this.$nextTick();
      this.$refs.ubicacion.mostrarMarker(
        null,
        null,
        "markerCasa4.png",
        "Zona",
        true
      );
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.cliente.latitud = this.$refs.ubicacion.getLatitud();
        this.cliente.longitud = this.$refs.ubicacion.getLongitud();
        this.$store.dispatch("PasoRegistroCliente", {
          paso: 2,
          cliente: this.cliente,
        });
      }
    },
    getDatosApi(cp) {
      if (cp.length == 5) {
        let dispatch = "UsersModule/OnGet";
        let endpoint = "datos/codigo/postal/" + cp;
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: {},
          })
          .then((result) => {
            if ([200, 201].includes(result.status)) {
              let datos = result.data.data;

              this.cliente.estado = datos.estado;
              this.cliente.municipio = datos.municipio;
              this.colonias = datos.colonias;
            }
          });
      }
    },
  },
};
</script>
