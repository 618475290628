<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title> Mover de zona </v-card-title>
          <v-card-subtitle>
            ¿Desea mover todos los elementos seleccionados a la siguiente zona?
          </v-card-subtitle>
          <v-form dense @submit.prevent="enviar()" ref="form" v-model="valid">
            <v-card-text>
              <v-select
                v-model="zona_id"
                :items="items"
                item-text="nombre"
                item-value="id"
                label="zona"
                prepend-icon="mdi-city"
                :rules="generalRegla"
                no-data-text="No hay elementos registrados"
              >
                <template v-slot:append-item>
                  <v-divider class="mb-2"></v-divider>
                  <v-list-item @click="nuevaZona()">
                    <v-list-item-avatar color="grey lighten-3">
                      <v-icon>mdi-plus</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>Agregar</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="cancelar()" text>Cancelar</v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                color="success"
                class="rounded-pill pa-3"
                >Mover</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
    <nuevaZona ref="nuevaZona"></nuevaZona>
  </div>
</template>
<script>
import Common from "@/js/Common";
import nuevaZona from "@/js/components/red/zonas/nuevaZona.vue";

export default {
  components: {
    nuevaZona,
  },
  data() {
    return {
      dialog: false,
      zona_id: "",
      items: [],
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      seleccionados: [],
      resolve: null,
      reject: null,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `zonas/all`,
          data: {},
        })
        .then((result) => {
          this.items = result.data.zonas;
        });
    },
    cargarDatos(elementos) {
      this.getDatos();
      this.seleccionados = elementos;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    nuevaZona() {
      this.$refs.nuevaZona.mostrar().then((resultado) => {
        if (resultado) {
          this.items.push(resultado);
          this.zona_id = resultado.id;
        }
      });
    },
    enviar() {
      if (this.$refs.form.validate()) {
        let datos = {
          zona: this.zona_id,
          selecionados: this.seleccionados,
        };

        this.$store
          .dispatch("UsersModule/OnSave", {
            url: `clientes/mover/elementos/seleccionados`,
            data: datos,
          })
          .then((result) => {
            if ([200, 201].includes(result.status)) {
              this.dialog = false;
              this.resolve(result.data.actualizados);
            }
          });
      }
    },
    cancelar() {
      this.dialog = false;
      this.resolve(false);
    },
  },
};
</script>