var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":"","wrap":""}},[(_vm.showChart)?_c('v-flex',{staticClass:"justify-center",attrs:{"xs12":"","md6":""}},[_c('apexchart',{attrs:{"type":"pie","width":"530","options":_vm.chartOptions,"series":_vm.series}})],1):_vm._e(),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-card',{staticClass:"cool"},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.elementos,"search":_vm.search},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.elementos
                    .map(function (x) {
                      return x.id;
                    })
                    .indexOf(item.id) + 1)+"\n              ")]}},{key:"item.icono",fn:function(ref){
                    var item = ref.item;
return [_c('v-img',{staticClass:"cool-xs ma-1",attrs:{"src":'http://icon.horse/icon/'+item['ip_destino'],"height":"30","width":"30","ma-2":""}})]}},{key:"item.consumo",fn:function(ref){
                    var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(_vm.bytesToMegabytes(item.bytes,3))+" Mb")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }