<template>
  <v-container grid-list-md>
    <perfilesTabla></perfilesTabla>
    <secretsTabla></secretsTabla>
  </v-container>
</template>
<script>
import Common from "@/js/Common";
import perfilesTabla from "@/js/components/red/routers/detalles/apartado_pppoe/perfiles/tablaPerfiles.vue";
import secretsTabla from "@/js/components/red/routers/detalles/apartado_pppoe/secrets/tablaSecrets.vue";
export default {
  components: {
    secretsTabla,
    perfilesTabla,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>