<template>
    <div class="component mt-4">
        <base-component>
            <div slot="component">
                <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
                
                <v-layout v-if="autorizado" row wrap>
                    <v-flex xs12 md12>
                        <v-card class="cool">
                            <v-card-title primary-title>
                            <strong>Detalles personal</strong>
                            </v-card-title>
                            <v-tabs
                            class="cool"
                            v-model="tab"
                            color="red"
                            grow
                            show-arrows
                            >
                            <v-tab>
                                <v-icon class="mr-2">mdi-face</v-icon>
                                Persona</v-tab>
                            <v-tab>
                                <v-icon class="mr-2">mdi-av-timer</v-icon>
                                Horario
                            </v-tab>
                            <v-tab>
                                <v-icon class="mr-2">mdi-cash-usd</v-icon>
                                Pagos</v-tab>
                            <v-tab>
                                <v-icon class="mr-2">mdi-home</v-icon>
                                Instalaciones</v-tab>
                            <v-tab>
                                <v-icon class="mr-2">mdi-library-books</v-icon>
                                Tikets</v-tab>
                            <v-tab>
                                <v-icon class="mr-2">mdi-coin</v-icon>
                                Facturas cobradas</v-tab>
                            <v-tab>
                                <v-icon class="mr-2">mdi-worker</v-icon>
                                Trabajos</v-tab>
                            <v-tab>
                                <v-icon class="mr-2">mdi-account-card-details</v-icon>
                                Permisos
                            </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <persona></persona>
                            </v-tab-item>
                            <v-tab-item>
                                <horario ref="horario"></horario>
                            </v-tab-item>
                            <v-tab-item>
                                <pagos></pagos>
                            </v-tab-item>
                            <v-tab-item>
                                <instalaciones></instalaciones>
                            </v-tab-item>
                            <v-tab-item>
                                <tickets></tickets>
                            </v-tab-item>
                            <v-tab-item>
                                <facturas></facturas>
                            </v-tab-item>
                            <v-tab-item>
                               <actividades></actividades>
                            </v-tab-item>
                            <v-tab-item>
                               <permisos></permisos>
                            </v-tab-item>
                            </v-tabs-items>
                        </v-card>
                    </v-flex>
                </v-layout>
            </div>
        </base-component>
    </div>
</template>
<script>
import Common from '@/js/Common'
import persona from '@/js/components/personal/detalles/persona/datosPersona.vue';
import horario from '@/js/components/personal/detalles/horario/horarioPersona.vue';
import pagos from '@/js/components/personal/detalles/pagos/pagosPersona.vue';
import tickets from '@/js/components/personal/detalles/tickets/tablaTickets.vue';
import actividades from '@/js/components/personal/detalles/actividades/tablaActividades.vue';
import facturas from '@/js/components/personal/detalles/facturas/tablaFacturasPersonal.vue';
import instalaciones from '@/js/components/personal/detalles/instalaciones/tablaInstalaciones.vue';
import permisos from '@/js/components/personal/detalles/permisos/vista.vue';
import LocalStorage from "@/js/LocalStorage.js";

export default {
    components:{
        persona,
        horario,
        pagos,
        tickets,
        actividades,
        permisos,
        instalaciones,
        facturas
    },
    data() {
        return {
            tab: 0,
            autorizado:false,
        };
    },
    watch:{
        tab(val){
                 LocalStorage.set("TAB_ACTUAL_PERSONAL", val);
 
        }
    },
    mounted() {
        var arreUrl = window.location.href.split('/');
        this.show(arreUrl.pop());
        var actualTab = LocalStorage.get("TAB_ACTUAL_PERSONAL");
    if (actualTab) {
      this.tab = actualTab;
    }
    },
    computed: {
        
    },
    methods: {
        show(id){
            this.$store.dispatch('UsersModule/OnGet', {
                url: `personal/`+id,
                data: {}

            }).then(result => {
                if([200,201].includes(result.status)){
                this.autorizado = true;
                
                var persona = result.data.persona;
                this.$store.dispatch('showPersona',persona);
                //this.$refs.horario.cargarDatos(result.data.persona);
                this.$store.dispatch('datosHorario',result.data.persona);
                }
            
            })

        },
        
    }

};
</script>