<template>
  <div>
    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card>
        <v-card-title primary-title> Cambiar perfil </v-card-title>
        <v-container grid-list-md>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-flex xs12 md12>
              <v-select
                v-model="perfil_nuevo_id"
                :items="perfiles"
                label="Perfiles de servicios"
                item-value="id"
                :rules="generalRegla"
                item-text="servicio.nombre"
                no-data-text="No hay elementos registrados"
                prepend-icon="mdi-server-plus"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.servicio.nombre }}
                    </v-list-item-title>
                    <v-list-item-title>
                      ${{ item.servicio.costo }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{
                      "Subida: " +
                      item.servicio.bajada +
                      "MB / Bajada:" +
                      item.servicio.subida +
                      "MB"
                    }}</v-list-item-subtitle>
                    <v-list-item-title
                      >Perfil PPPoe: {{ item.name }}</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >Local address:
                      {{ item.local_address }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      >Remote address:
                      {{ item.remote_address }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template>
                <template v-slot:append-item>
                  <v-divider class="mb-2"></v-divider>
                  <v-list-item @click="nuevo()">
                    <v-list-item-avatar color="grey lighten-3">
                      <v-icon>mdi-server-plus</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>Registrar nuevo</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-flex>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelar()">Cancelar</v-btn>
          <v-btn
            class="rounded-pill"
            color="primary"
            @click="cambiar_servicio()"
          >
            Registrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <nuevoPerfil ref="refNuevoPerfil"></nuevoPerfil>
  </div>
</template>
<script>
import nuevoPerfil from "@/js/components/red/routers/detalles/apartado_pppoe/perfiles/nuevoPerfil.vue";

export default {
  components: {
    nuevoPerfil,
  },
  data() {
    return {
      dialog: false,
      perfil_nuevo_id: null,
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      perfiles: [],
      resolve: null,
      reject: null,
      id: "",
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.id = arreUrl.pop();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "todos/perfiles/disponibles/cliente/" + this.id,
          data: {},
        })
        .then((result) => {
          this.perfiles = result.data.perfiles;
        });
    },

    esperarDatos() {
      this.getDatos();
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    //me quede aqui tenfo que enviar el post para actualizar el perfil
    cambiar_servicio() {
      let dispatch = "UsersModule/OnSave";
      let endpoint =
        "remplar/perfil/pppoe/cliente/" + this.perfil_nuevo_id + "/" + this.id;
      this.$store
        .dispatch(dispatch, {
          url: endpoint,
        })
        .then((result) => {
          this.limpiar();
          this.dialog = false;
          this.resolve(result.data.servicio);
        });
    },
    limpiar() {
      this.$refs.form.reset();
    },
    cancelar() {
      this.resolve(false);
      this.dialog = false;
    },
    nuevo() {
      var mikrotik = this.perfiles[0].router_id;
      this.$refs.refNuevoPerfil.mostrar(mikrotik).then((resultado) => {
        if (resultado) {
          this.perfiles.push(resultado);
          this.perfil_nuevo_id = resultado.id;
        }
      });
    },
  },
};
</script>