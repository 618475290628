var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component"},[_c('base-component',[_c('div',{attrs:{"slot":"component"},slot:"component"},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-card-title',{staticClass:"headline",attrs:{"primary-title":""}},[_c('strong',[_vm._v("Horario")])]),_vm._v(" "),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md2":""}},[_c('v-switch',{attrs:{"inset":"","label":"Lunes"},model:{value:(_vm.persona.lunes),callback:function ($$v) {_vm.$set(_vm.persona, "lunes", $$v)},expression:"persona.lunes"}})],1),_vm._v(" "),_c('v-flex',{attrs:{"vertical":"","xs12":"","sm12":"","md2":""}},[(_vm.persona.lunes)?_c('v-menu',{ref:"menu_lunes_e",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.persona.lunes_entrada,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.persona, "lunes_entrada", $event)},"update:return-value":function($event){return _vm.$set(_vm.persona, "lunes_entrada", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Entrada","rules":_vm.generalRegla,"prepend-icon":"mdi-av-timer","readonly":""},model:{value:(_vm.persona.lunes_entrada),callback:function ($$v) {_vm.$set(_vm.persona, "lunes_entrada", $$v)},expression:"persona.lunes_entrada"}},'v-text-field',attrs,false),on))]}}],null,false,206736905),model:{value:(_vm.menu_lunes_e),callback:function ($$v) {_vm.menu_lunes_e=$$v},expression:"menu_lunes_e"}},[_vm._v(" "),(_vm.menu_lunes_e)?_c('v-time-picker',{on:{"click:minute":function($event){return _vm.$refs.menu_lunes_e.save(_vm.persona.lunes_entrada)}},model:{value:(_vm.persona.lunes_entrada),callback:function ($$v) {_vm.$set(_vm.persona, "lunes_entrada", $$v)},expression:"persona.lunes_entrada"}}):_vm._e()],1):_vm._e()],1),_vm._v(" "),_c('v-flex',{attrs:{"vertical":"","xs6":"","sm12":"","md2":""}},[(_vm.persona.lunes)?_c('v-menu',{ref:"menu_lunes_s",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.persona.lunes_salida,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.persona, "lunes_salida", $event)},"update:return-value":function($event){return _vm.$set(_vm.persona, "lunes_salida", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"salida","rules":_vm.generalRegla,"prepend-icon":"mdi-av-timer","readonly":""},model:{value:(_vm.persona.lunes_salida),callback:function ($$v) {_vm.$set(_vm.persona, "lunes_salida", $$v)},expression:"persona.lunes_salida"}},'v-text-field',attrs,false),on))]}}],null,false,204779721),model:{value:(_vm.menu_lunes_s),callback:function ($$v) {_vm.menu_lunes_s=$$v},expression:"menu_lunes_s"}},[_vm._v(" "),(_vm.menu_lunes_s)?_c('v-time-picker',{on:{"click:minute":function($event){return _vm.$refs.menu_lunes_s.save(_vm.persona.lunes_salida)}},model:{value:(_vm.persona.lunes_salida),callback:function ($$v) {_vm.$set(_vm.persona, "lunes_salida", $$v)},expression:"persona.lunes_salida"}}):_vm._e()],1):_vm._e()],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md2":""}},[_c('v-switch',{attrs:{"inset":"","label":"Martes"},model:{value:(_vm.persona.martes),callback:function ($$v) {_vm.$set(_vm.persona, "martes", $$v)},expression:"persona.martes"}})],1),_vm._v(" "),_c('v-flex',{attrs:{"vertical":"","xs12":"","sm12":"","md2":""}},[(_vm.persona.martes)?_c('v-menu',{ref:"menu_martes_e",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.persona.martes_entrada,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.persona, "martes_entrada", $event)},"update:return-value":function($event){return _vm.$set(_vm.persona, "martes_entrada", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Entrada","rules":_vm.generalRegla,"prepend-icon":"mdi-av-timer","readonly":""},model:{value:(_vm.persona.martes_entrada),callback:function ($$v) {_vm.$set(_vm.persona, "martes_entrada", $$v)},expression:"persona.martes_entrada"}},'v-text-field',attrs,false),on))]}}],null,false,4252581620),model:{value:(_vm.menu_martes_e),callback:function ($$v) {_vm.menu_martes_e=$$v},expression:"menu_martes_e"}},[_vm._v(" "),(_vm.menu_martes_e)?_c('v-time-picker',{on:{"click:minute":function($event){return _vm.$refs.menu_martes_e.save(_vm.persona.martes_entrada)}},model:{value:(_vm.persona.martes_entrada),callback:function ($$v) {_vm.$set(_vm.persona, "martes_entrada", $$v)},expression:"persona.martes_entrada"}}):_vm._e()],1):_vm._e()],1),_vm._v(" "),_c('v-flex',{attrs:{"vertical":"","xs6":"","sm12":"","md2":""}},[(_vm.persona.martes)?_c('v-menu',{ref:"menu_martes_s",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.persona.martes_salida,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.persona, "martes_salida", $event)},"update:return-value":function($event){return _vm.$set(_vm.persona, "martes_salida", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"salida","rules":_vm.generalRegla,"prepend-icon":"mdi-av-timer","readonly":""},model:{value:(_vm.persona.martes_salida),callback:function ($$v) {_vm.$set(_vm.persona, "martes_salida", $$v)},expression:"persona.martes_salida"}},'v-text-field',attrs,false),on))]}}],null,false,3138384180),model:{value:(_vm.menu_martes_s),callback:function ($$v) {_vm.menu_martes_s=$$v},expression:"menu_martes_s"}},[_vm._v(" "),(_vm.menu_martes_s)?_c('v-time-picker',{on:{"click:minute":function($event){return _vm.$refs.menu_martes_s.save(_vm.persona.martes_salida)}},model:{value:(_vm.persona.martes_salida),callback:function ($$v) {_vm.$set(_vm.persona, "martes_salida", $$v)},expression:"persona.martes_salida"}}):_vm._e()],1):_vm._e()],1)],1),_vm._v(" "),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md2":""}},[_c('v-switch',{attrs:{"inset":"","label":"Miercoles"},model:{value:(_vm.persona.miercoles),callback:function ($$v) {_vm.$set(_vm.persona, "miercoles", $$v)},expression:"persona.miercoles"}})],1),_vm._v(" "),_c('v-flex',{attrs:{"vertical":"","xs12":"","sm12":"","md2":""}},[(_vm.persona.miercoles)?_c('v-menu',{ref:"menu_miercoles_e",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.persona.miercoles_entrada,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.persona, "miercoles_entrada", $event)},"update:return-value":function($event){return _vm.$set(_vm.persona, "miercoles_entrada", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Entrada","rules":_vm.generalRegla,"prepend-icon":"mdi-av-timer","readonly":""},model:{value:(_vm.persona.miercoles_entrada),callback:function ($$v) {_vm.$set(_vm.persona, "miercoles_entrada", $$v)},expression:"persona.miercoles_entrada"}},'v-text-field',attrs,false),on))]}}],null,false,360281453),model:{value:(_vm.menu_miercoles_e),callback:function ($$v) {_vm.menu_miercoles_e=$$v},expression:"menu_miercoles_e"}},[_vm._v(" "),(_vm.menu_miercoles_e)?_c('v-time-picker',{on:{"click:minute":function($event){return _vm.$refs.menu_miercoles_e.save(_vm.persona.miercoles_entrada)}},model:{value:(_vm.persona.miercoles_entrada),callback:function ($$v) {_vm.$set(_vm.persona, "miercoles_entrada", $$v)},expression:"persona.miercoles_entrada"}}):_vm._e()],1):_vm._e()],1),_vm._v(" "),_c('v-flex',{attrs:{"vertical":"","xs6":"","sm12":"","md2":""}},[(_vm.persona.miercoles)?_c('v-menu',{ref:"menu_miercoles_s",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.persona.miercoles_salida,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.persona, "miercoles_salida", $event)},"update:return-value":function($event){return _vm.$set(_vm.persona, "miercoles_salida", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"salida","rules":_vm.generalRegla,"prepend-icon":"mdi-av-timer","readonly":""},model:{value:(_vm.persona.miercoles_salida),callback:function ($$v) {_vm.$set(_vm.persona, "miercoles_salida", $$v)},expression:"persona.miercoles_salida"}},'v-text-field',attrs,false),on))]}}],null,false,3053334381),model:{value:(_vm.menu_miercoles_s),callback:function ($$v) {_vm.menu_miercoles_s=$$v},expression:"menu_miercoles_s"}},[_vm._v(" "),(_vm.menu_miercoles_s)?_c('v-time-picker',{on:{"click:minute":function($event){return _vm.$refs.menu_miercoles_s.save(_vm.persona.miercoles_salida)}},model:{value:(_vm.persona.miercoles_salida),callback:function ($$v) {_vm.$set(_vm.persona, "miercoles_salida", $$v)},expression:"persona.miercoles_salida"}}):_vm._e()],1):_vm._e()],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md2":""}},[_c('v-switch',{attrs:{"inset":"","label":"Jueves"},model:{value:(_vm.persona.jueves),callback:function ($$v) {_vm.$set(_vm.persona, "jueves", $$v)},expression:"persona.jueves"}})],1),_vm._v(" "),_c('v-flex',{attrs:{"vertical":"","xs12":"","sm12":"","md2":""}},[(_vm.persona.jueves)?_c('v-menu',{ref:"menu_jueves_e",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.persona.jueves_entrada,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.persona, "jueves_entrada", $event)},"update:return-value":function($event){return _vm.$set(_vm.persona, "jueves_entrada", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Entrada","rules":_vm.generalRegla,"prepend-icon":"mdi-av-timer","readonly":""},model:{value:(_vm.persona.jueves_entrada),callback:function ($$v) {_vm.$set(_vm.persona, "jueves_entrada", $$v)},expression:"persona.jueves_entrada"}},'v-text-field',attrs,false),on))]}}],null,false,3686515890),model:{value:(_vm.menu_jueves_e),callback:function ($$v) {_vm.menu_jueves_e=$$v},expression:"menu_jueves_e"}},[_vm._v(" "),(_vm.menu_jueves_e)?_c('v-time-picker',{on:{"click:minute":function($event){return _vm.$refs.menu_jueves_e.save(_vm.persona.jueves_entrada)}},model:{value:(_vm.persona.jueves_entrada),callback:function ($$v) {_vm.$set(_vm.persona, "jueves_entrada", $$v)},expression:"persona.jueves_entrada"}}):_vm._e()],1):_vm._e()],1),_vm._v(" "),_c('v-flex',{attrs:{"vertical":"","xs6":"","sm12":"","md2":""}},[(_vm.persona.jueves)?_c('v-menu',{ref:"menu_jueves_s",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.persona.jueves_salida,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.persona, "jueves_salida", $event)},"update:return-value":function($event){return _vm.$set(_vm.persona, "jueves_salida", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"salida","rules":_vm.generalRegla,"prepend-icon":"mdi-av-timer","readonly":""},model:{value:(_vm.persona.jueves_salida),callback:function ($$v) {_vm.$set(_vm.persona, "jueves_salida", $$v)},expression:"persona.jueves_salida"}},'v-text-field',attrs,false),on))]}}],null,false,661911410),model:{value:(_vm.menu_jueves_s),callback:function ($$v) {_vm.menu_jueves_s=$$v},expression:"menu_jueves_s"}},[_vm._v(" "),(_vm.menu_jueves_s)?_c('v-time-picker',{on:{"click:minute":function($event){return _vm.$refs.menu_jueves_s.save(_vm.persona.jueves_salida)}},model:{value:(_vm.persona.jueves_salida),callback:function ($$v) {_vm.$set(_vm.persona, "jueves_salida", $$v)},expression:"persona.jueves_salida"}}):_vm._e()],1):_vm._e()],1)],1),_vm._v(" "),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md2":""}},[_c('v-switch',{attrs:{"inset":"","label":"Viernes"},model:{value:(_vm.persona.viernes),callback:function ($$v) {_vm.$set(_vm.persona, "viernes", $$v)},expression:"persona.viernes"}})],1),_vm._v(" "),_c('v-flex',{attrs:{"vertical":"","xs12":"","sm12":"","md2":""}},[(_vm.persona.viernes)?_c('v-menu',{ref:"menu_viernes_e",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.persona.viernes_entrada,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.persona, "viernes_entrada", $event)},"update:return-value":function($event){return _vm.$set(_vm.persona, "viernes_entrada", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Entrada","rules":_vm.generalRegla,"prepend-icon":"mdi-av-timer","readonly":""},model:{value:(_vm.persona.viernes_entrada),callback:function ($$v) {_vm.$set(_vm.persona, "viernes_entrada", $$v)},expression:"persona.viernes_entrada"}},'v-text-field',attrs,false),on))]}}],null,false,1727855960),model:{value:(_vm.menu_viernes_e),callback:function ($$v) {_vm.menu_viernes_e=$$v},expression:"menu_viernes_e"}},[_vm._v(" "),(_vm.menu_viernes_e)?_c('v-time-picker',{on:{"click:minute":function($event){return _vm.$refs.menu_viernes_e.save(_vm.persona.viernes_entrada)}},model:{value:(_vm.persona.viernes_entrada),callback:function ($$v) {_vm.$set(_vm.persona, "viernes_entrada", $$v)},expression:"persona.viernes_entrada"}}):_vm._e()],1):_vm._e()],1),_vm._v(" "),_c('v-flex',{attrs:{"vertical":"","xs6":"","sm12":"","md2":""}},[(_vm.persona.viernes)?_c('v-menu',{ref:"menu_viernes_s",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.persona.viernes_salida,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.persona, "viernes_salida", $event)},"update:return-value":function($event){return _vm.$set(_vm.persona, "viernes_salida", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"salida","rules":_vm.generalRegla,"prepend-icon":"mdi-av-timer","readonly":""},model:{value:(_vm.persona.viernes_salida),callback:function ($$v) {_vm.$set(_vm.persona, "viernes_salida", $$v)},expression:"persona.viernes_salida"}},'v-text-field',attrs,false),on))]}}],null,false,640473304),model:{value:(_vm.menu_viernes_s),callback:function ($$v) {_vm.menu_viernes_s=$$v},expression:"menu_viernes_s"}},[_vm._v(" "),(_vm.menu_viernes_s)?_c('v-time-picker',{on:{"click:minute":function($event){return _vm.$refs.menu_viernes_s.save(_vm.persona.viernes_salida)}},model:{value:(_vm.persona.viernes_salida),callback:function ($$v) {_vm.$set(_vm.persona, "viernes_salida", $$v)},expression:"persona.viernes_salida"}}):_vm._e()],1):_vm._e()],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md2":""}},[_c('v-switch',{attrs:{"inset":"","label":"Sabado"},model:{value:(_vm.persona.sabado),callback:function ($$v) {_vm.$set(_vm.persona, "sabado", $$v)},expression:"persona.sabado"}})],1),_vm._v(" "),_c('v-flex',{attrs:{"vertical":"","xs12":"","sm12":"","md2":""}},[(_vm.persona.sabado)?_c('v-menu',{ref:"menu_sabado_e",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.persona.sabado_entrada,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.persona, "sabado_entrada", $event)},"update:return-value":function($event){return _vm.$set(_vm.persona, "sabado_entrada", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Entrada","rules":_vm.generalRegla,"prepend-icon":"mdi-av-timer","readonly":""},model:{value:(_vm.persona.sabado_entrada),callback:function ($$v) {_vm.$set(_vm.persona, "sabado_entrada", $$v)},expression:"persona.sabado_entrada"}},'v-text-field',attrs,false),on))]}}],null,false,2098624658),model:{value:(_vm.menu_sabado_e),callback:function ($$v) {_vm.menu_sabado_e=$$v},expression:"menu_sabado_e"}},[_vm._v(" "),(_vm.menu_sabado_e)?_c('v-time-picker',{on:{"click:minute":function($event){return _vm.$refs.menu_sabado_e.save(_vm.persona.sabado_entrada)}},model:{value:(_vm.persona.sabado_entrada),callback:function ($$v) {_vm.$set(_vm.persona, "sabado_entrada", $$v)},expression:"persona.sabado_entrada"}}):_vm._e()],1):_vm._e()],1),_vm._v(" "),_c('v-flex',{attrs:{"vertical":"","xs6":"","sm12":"","md2":""}},[(_vm.persona.sabado)?_c('v-menu',{ref:"menu_sabado_s",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.persona.sabado_salida,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.persona, "sabado_salida", $event)},"update:return-value":function($event){return _vm.$set(_vm.persona, "sabado_salida", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"salida","rules":_vm.generalRegla,"prepend-icon":"mdi-av-timer","readonly":""},model:{value:(_vm.persona.sabado_salida),callback:function ($$v) {_vm.$set(_vm.persona, "sabado_salida", $$v)},expression:"persona.sabado_salida"}},'v-text-field',attrs,false),on))]}}],null,false,4139672722),model:{value:(_vm.menu_sabado_s),callback:function ($$v) {_vm.menu_sabado_s=$$v},expression:"menu_sabado_s"}},[_vm._v(" "),(_vm.menu_sabado_s)?_c('v-time-picker',{on:{"click:minute":function($event){return _vm.$refs.menu_sabado_s.save(_vm.persona.sabado_salida)}},model:{value:(_vm.persona.sabado_salida),callback:function ($$v) {_vm.$set(_vm.persona, "sabado_salida", $$v)},expression:"persona.sabado_salida"}}):_vm._e()],1):_vm._e()],1)],1),_vm._v(" "),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md2":""}},[_c('v-switch',{attrs:{"inset":"","label":"Domingo"},model:{value:(_vm.persona.domingo),callback:function ($$v) {_vm.$set(_vm.persona, "domingo", $$v)},expression:"persona.domingo"}})],1),_vm._v(" "),_c('v-flex',{attrs:{"vertical":"","xs12":"","sm12":"","md2":""}},[(_vm.persona.domingo)?_c('v-menu',{ref:"menu_domingo_e",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.persona.domingo_entrada,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.persona, "domingo_entrada", $event)},"update:return-value":function($event){return _vm.$set(_vm.persona, "domingo_entrada", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Entrada","rules":_vm.generalRegla,"prepend-icon":"mdi-av-timer","readonly":""},model:{value:(_vm.persona.domingo_entrada),callback:function ($$v) {_vm.$set(_vm.persona, "domingo_entrada", $$v)},expression:"persona.domingo_entrada"}},'v-text-field',attrs,false),on))]}}],null,false,3868827937),model:{value:(_vm.menu_domingo_e),callback:function ($$v) {_vm.menu_domingo_e=$$v},expression:"menu_domingo_e"}},[_vm._v(" "),(_vm.menu_domingo_e)?_c('v-time-picker',{on:{"click:minute":function($event){return _vm.$refs.menu_domingo_e.save(_vm.persona.domingo_entrada)}},model:{value:(_vm.persona.domingo_entrada),callback:function ($$v) {_vm.$set(_vm.persona, "domingo_entrada", $$v)},expression:"persona.domingo_entrada"}}):_vm._e()],1):_vm._e()],1),_vm._v(" "),_c('v-flex',{attrs:{"vertical":"","xs6":"","sm12":"","md2":""}},[(_vm.persona.domingo)?_c('v-menu',{ref:"menu_domingo_s",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.persona.domingo_salida,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.persona, "domingo_salida", $event)},"update:return-value":function($event){return _vm.$set(_vm.persona, "domingo_salida", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"salida","rules":_vm.generalRegla,"prepend-icon":"mdi-av-timer","readonly":""},model:{value:(_vm.persona.domingo_salida),callback:function ($$v) {_vm.$set(_vm.persona, "domingo_salida", $$v)},expression:"persona.domingo_salida"}},'v-text-field',attrs,false),on))]}}],null,false,1307881761),model:{value:(_vm.menu_domingo_s),callback:function ($$v) {_vm.menu_domingo_s=$$v},expression:"menu_domingo_s"}},[_vm._v(" "),(_vm.menu_domingo_s)?_c('v-time-picker',{on:{"click:minute":function($event){return _vm.$refs.menu_domingo_s.save(_vm.persona.domingo_salida)}},model:{value:(_vm.persona.domingo_salida),callback:function ($$v) {_vm.$set(_vm.persona, "domingo_salida", $$v)},expression:"persona.domingo_salida"}}):_vm._e()],1):_vm._e()],1)],1),_vm._v(" "),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"rounded-pill pa-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.validate()}}},[_vm._v("Actualizar")])],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }