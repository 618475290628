<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title>
            Mover de Router administrador
          </v-card-title>
          <v-card-subtitle>
            ¿Desea mover todos los elementos seleccionados al siguiente router?
          </v-card-subtitle>
          <v-form dense @submit.prevent="enviar()" ref="form" v-model="valid">
            <v-card-text>
              <v-select
                v-model="router_id"
                :items="items"
                label="Administrar en RB"
                item-value="id"
                :rules="generalRegla"
                item-text="nombre"
                no-data-text="No hay elementos registrados"
                prepend-icon="mdi-server-minus"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-avatar
                    color="indigo"
                    class="headline font-weight-light white--text"
                  >
                    {{ item.nombre.charAt(0) }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                    <v-list-item-subtitle
                      >Ip: {{ item.ip }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template>
              </v-select>
              <v-layout row wrap>
                <v-switch
                  label="¿Desea mover tambien las Queues?"
                  inset
                  v-model="moverColas"
                ></v-switch>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="cancelar()" text>Cancelar</v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                color="success"
                class="rounded-pill pa-3"
                >Mover</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Common from "@/js/Common";

export default {
  components: {},
  data() {
    return {
      dialog: false,
      router_id: "",
      items: [],
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      seleccionados: [],
      resolve: null,
      reject: null,
      moverColas: true,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `routers/all`,
          data: {},
        })
        .then((result) => {
          this.items = result.data.routers;
        });
    },
    cargarDatos(elementos) {
      this.getDatos();

      this.seleccionados = elementos;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    enviar() {
      if (this.$refs.form.validate()) {
        let datos = {
          router: this.router_id,
          selecionados: this.seleccionados,
          moverColas: this.moverColas,
        };

        this.$store
          .dispatch("UsersModule/OnSave", {
            url: `router/mover/elementos/seleccionados`,
            data: datos,
          })
          .then((result) => {
            if ([200, 201].includes(result.status)) {
              this.dialog = false;
              this.resolve(result.data.actualizados);
            }
          });
      }
    },
    cancelar() {
      this.dialog = false;
      this.resolve(false);
    },
  },
};
</script>