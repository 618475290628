var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component mt-4"},[_c('base-component',[_c('div',{attrs:{"slot":"component"},slot:"component"},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"cool pa-4"},[_c('v-card-title',{staticClass:"display-1"},[_c('strong',[_vm._v("Antenas")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-flex',{staticClass:"d-flex justify-center",attrs:{"xs12":"","md5":""}},[_c('v-radio-group',{attrs:{"dense":"","row":""},model:{value:(_vm.filtroEstado),callback:function ($$v) {_vm.filtroEstado=$$v},expression:"filtroEstado"}},[_c('v-radio',{attrs:{"dense":"","color":"blue","label":"Todos","value":"2"}}),_vm._v(" "),_c('v-radio',{attrs:{"dense":"","color":"green","label":"Conectados","value":"1"}}),_vm._v(" "),_c('v-radio',{attrs:{"dense":"","color":"orange","label":"Desconectados","value":"0"}})],1)],1)],1),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"rounded-pill",attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.nuevo()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("\n                    Agregar\n                  ")],1),_vm._v(" "),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text rounded-pill ma-1",attrs:{"color":"red"}},'v-btn',attrs,false),on),[_vm._v("\n                        "+_vm._s(_vm.nombreTipo)+"\n                        "),_c('v-icon',{attrs:{"rigth":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_vm._v(" "),_c('v-list',[_c('v-responsive',{staticClass:"overflow-y-auto",attrs:{"id":"scroll-target","max-height":450}},_vm._l((_vm.tipos),function(z){return _c('v-list-item',{key:z.id,attrs:{"link":""},on:{"click":function($event){return _vm.filtrarTipo(z)}}},[_c('v-list-item-title',[_vm._v(_vm._s(z.nombre))])],1)}),1)],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"rounded-pill",attrs:{"solo":"","append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDatos($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_vm._v(" "),_c('v-data-table',{attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.elementos,"options":_vm.options,"server-items-length":_vm.totalElementos,"loading":_vm.loading,"footer-props":{
                showFirstLastPage: true,
                firstIcon: 'mdi-format-horizontal-align-left',
                lastIcon: 'mdi-format-horizontal-align-right',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                itemsPerPageAllText: 'Todos',
                itemsPerPageText: 'Registros por página:',
                itemsPerPageOptions: [10, 100, 500, 1000, -1],
              }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"grid-list-md":""}},[(_vm.selected.length > 0)?_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-btn',{attrs:{"small":"","outlined":"","dark":"","color":"purple"},on:{"click":function($event){return _vm.moverDeZona()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-tower-fire")]),_vm._v(" Mover de\n                        zona")],1)],1)],1):_vm._e()],1)]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"lime lighten-1","icon":"mdi-alert"}},[_vm._v("Tu búsqueda \""+_vm._s(_vm.search)+"\" no se encuentra.")])]},proxy:true},{key:"item.imagen",fn:function(ref){
              var item = ref.item;
return [(item.cpe.imagen)?_c('v-img',{staticClass:"cool-xs ma-1",attrs:{"src":item.cpe.imagen,"height":"60","width":"60","ma-2":""}}):_c('v-img',{staticClass:"cool-xs ma-1",attrs:{"src":"/img/noimagen.jpg","height":"60","width":"60"}})]}},{key:"item.nombre",fn:function(ref){
              var item = ref.item;
return [(item.nombreCliente)?_c('strong',[_c('a',{on:{"click":function($event){return _vm.abrirCliente(item.clienteId)}}},[_vm._v("\n                    "+_vm._s(item.nombreCliente)+"\n                  ")])]):_c('strong',[_vm._v("\n                  "+_vm._s(item.nombre)+"\n                ")])]}},{key:"item.antena",fn:function(ref){
              var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.cpe.producto)+"\n                "),_c('br'),_vm._v("\n                "+_vm._s(item.cpe.marca)+"\n              ")]}},{key:"item.ip",fn:function(ref){
              var item = ref.item;
return [(item.ip)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.abrirIp(item.ip)}}},[_vm._v("\n                  "+_vm._s(item.ip)+"\n                ")]):_c('v-btn',{attrs:{"text":"","color":"indigo"},on:{"click":function($event){return _vm.abrirIp(item.ip)}}},[_vm._v("\n                  Pendiente\n                ")])]}},{key:"item.ping",fn:function(ref){
              var item = ref.item;
return [(item.ping)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"ma-1",attrs:{"outlined":"","color":_vm.colorPing(item.ping),"dark":"","small":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-heart-pulse")]),_vm._v("\n                      "+_vm._s(item.ping.ping ? item.ping.ping + "ms" : "--")+"\n                    ")],1)]}}],null,true)},[_vm._v(" "),(item.ping)?_c('span',[_vm._v("Actualización: "),_c('br'),_vm._v("\n                    Hora: "+_vm._s(item.ping.created_at.split(" ")[1])+"\n                    "),_c('br'),_vm._v("\n                    Fecha: "+_vm._s(item.ping.created_at.split(" ")[0]))]):_vm._e()]):_vm._e()]}},{key:"item.zona",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":"purple","dark":"","outlined":""},on:{"click":function($event){return _vm.abrirZona(item.zona_id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-radio-tower")]),_vm._v("\n                  "+_vm._s(item.nombreZona))],1)]}},{key:"item.administracion",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.calcularColor(item.administracion),"outlined":""}},[(item.administracion == 1)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-window-minimize")]):_vm._e(),_vm._v(" "),(item.administracion == 2)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-network")]):_vm._e(),_vm._v(" "),(item.administracion == 3)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-outline")]):_vm._e(),_vm._v(" "),(item.administracion == 1)?_c('div',[_vm._v("Simple Queue")]):_vm._e(),_vm._v(" "),(item.administracion == 2)?_c('div',[_vm._v("PPPoe")]):_vm._e(),_vm._v(" "),(item.administracion == 3)?_c('div',[_vm._v("Ningúna")]):_vm._e()],1)]}},{key:"item.opciones",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.detalles(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Detalles")])]),_vm._v(" "),(item.eliminado == false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.editarAntena(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Editar")])]):_vm._e(),_vm._v(" "),(item.eliminado == false && item.clienteId == null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.eliminar(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Desactivar")])]):_vm._e(),_vm._v(" "),(item.eliminado)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dense":"","color":item.eliminado == true ? 'red' : 'green',"fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.restaurar(item)}}},on),[_c('v-icon',[_vm._v("mdi-backup-restore")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Restaurar")])]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"red","dark":"","icon":"mdi-alert-circle"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_vm._v(" Ningún dato que mostrar :( ")]),_vm._v(" "),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{on:{"click":function($event){return _vm.getDatos()}}},[_c('v-icon',{attrs:{"pl-4":""}},[_vm._v("mdi-cached")]),_vm._v(" Refrescar\n                      ")],1)],1)],1)],1)]},proxy:true}])})],1)],1)],1),_vm._v(" "),_c('formAntena',{ref:"refAntena"}),_vm._v(" "),_c('editarAntena',{ref:"refEditarAntena"}),_vm._v(" "),_c('BaseConfirmacion',{ref:"confirmar"}),_vm._v(" "),_c('moverDeZona',{ref:"moverZona"}),_vm._v(" "),_c('moverDeRouter',{ref:"moverRouter"})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }