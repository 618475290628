<template>
  <div class="component mt-4">
    <base-component>
      <div v-if="mostrar" slot="component">
        <v-layout row wrap>
          <v-flex xs12 md6>
            <apexchart
              type="radialBar"
              height="320"
              :options="tiposClientesChartOptions"
              :series="tiposClientesSeries"
            ></apexchart>
          </v-flex>
          <v-flex xs12 md6>
            <apexchart
              type="radialBar"
              height="320"
              :options="captacionesChartOptions"
              :series="captacionesSeries"
            ></apexchart>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <apexchart
              type="bar"
              height="350"
              :options="zonasChartOptions"
              :series="zonasSeries"
            ></apexchart>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <apexchart
              type="bar"
              height="350"
              :options="paquetesChartOptions"
              :series="paquetesSeries"
            ></apexchart>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import apexchart from "vue-apexcharts";
import Common from "@/js/Common";
export default {
  components: {
    apexchart,
  },
  data() {
    return {
      mostrar: false,
      tiposClientesSeries: [],
      tiposClientesChartOptions: {
        chart: {
          type: "radialBar",
        },
        title: {
          text: "Cantidad de clientes por tipo",
          position: "center",
          offsetX: 0,
          style: {
            fontSize: "12px",
          },
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: "30%",
              background: "transparent",
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        colors: ["#1ab7ea", "#0084ff", "#39539E", "#0077B5"],
        labels: [],
        legend: {
          show: true,
          floating: true,
          fontSize: "12px",
          position: "left",
          offsetX: 50,
          offsetY: 15,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          formatter: function (seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
          },
          itemMargin: {
            vertical: 3,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      },
      //captaciones grafica
      captacionesSeries: [76, 67, 61, 90],
      captacionesChartOptions: {
        chart: {
          type: "radialBar",
        },
        title: {
          text: "Cantidad de clientes por captacion",
          position: "center",
          offsetX: 0,
          style: {
            fontSize: "12px",
          },
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: "30%",
              background: "transparent",
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        colors: ["#1ab7ea", "#0084ff", "#39539E", "#0077B5"],
        labels: ["Vimeo", "Messenger", "Facebook", "LinkedIn"],
        legend: {
          show: true,
          floating: true,
          fontSize: "12px",
          position: "left",
          offsetX: 50,
          offsetY: 15,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          formatter: function (seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
          },
          itemMargin: {
            vertical: 3,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      },

      //datos clietes por zona
      zonasSeries: [
        {
          name: "Clientes",
          data: [],
        },
      ],
      zonasChartOptions: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {},
          },
        },
        title: {
          text: "Clientes por zona",
          position: "center",
          offsetX: 0,
          style: {
            fontSize: "12px",
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [],
        },
      },
      //paquetes grafica
      paquetesSeries: [
        {
          name: "Clientes",
          data: [],
        },
      ],
      paquetesChartOptions: {
        chart: {
          height: 350,
          type: "bar",
        },
        title: {
          text: "Cantidad de clientes por paquete de servicio",
          position: "center",
          offsetX: 0,
          style: {
            fontSize: "12px",
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "50%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
        },

        grid: {
          row: {
            colors: ["#fff", "#f2f2f2"],
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
          },
          categories: [],
          tickPlacement: "on",
        },
        yaxis: {
          title: {
            text: "Clientes",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100],
          },
        },
      },
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `graficas/datos/clientes`,
          data: {},
        })
        .then((result) => {
          this.tiposClientesSeries = result.data.serie_tipo;
          this.tiposClientesChartOptions.labels = result.data.tipo_cliente;
          this.captacionesSeries = result.data.serie_captaciones;
          this.captacionesChartOptions.labels = result.data.captaciones;

          this.zonasSeries = [
            {
              name: "Clientes",
              data: result.data.serie_zona,
            },
          ];
          this.zonasChartOptions.xaxis.categories = result.data.zonas;
          let seriePaquetes = [];
          let paquetesNombres = [];
          for (let i = 0; i < result.data.planes.length; i++) {
            seriePaquetes.push(result.data.planes[i].clientesCantidad);
            paquetesNombres.push(result.data.planes[i].nombre);
          }
          this.paquetesSeries = [
            {
              name: "Clientes",
              data: seriePaquetes,
            },
          ];
          this.paquetesChartOptions.xaxis.categories = paquetesNombres;

          this.mostrar = true;
        });
    },
  },
};
</script>