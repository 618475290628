

import Axios from "axios"

export default {
    state: {
        dialog_mostrar:false,
        dialog_mensaje: '',
        dialog_titulo: '',
        dialog_color: '',
    },
    mutations: {
        mutacion_aviso(state,dato){
            state.dialog_mostrar = dato.mostrar;
            state.dialog_mensaje = dato.mensaje;
            state.dialog_color = dato.color;
            state.dialog_titulo = dato.titulo;
        },
        mutacion_cerrar_aviso(state){
           state.dialog_mostrar = false
        },
        
    },
    actions: {
    aviso({commit},payload){
        commit('mutacion_aviso',payload);
    },
    cerrar_aviso({commit}){
        commit('mutacion_cerrar_aviso');
    },
    },
    getters: {
      get_dialog_mostrar(state){
          return state.mostrar;
      },
      get_dialog_mensaje(state){
        return state.dialog_mensaje;
      },
      get_dialog_titulo(state){
        return state.dialog_titulo;
      },
      get_dialog_color(state){
        return state.dialog_color;
      },
      get_dialog_datos(state){
        var datos ={
            mostrar: state.dialog_mostrar,
            color: state.dialog_color,
            mensaje: state.dialog_mensaje,
            titulo: state.dialog_titulo,
            color: state.dialog_color
        };
        
        return datos;
      
    },
    },
modules:{
  
}
};
