<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
         
        <v-stepper v-model="e1" class="cool">
          <v-stepper-header class="elevation-0">
            <v-stepper-step :complete="e1 > 1" step="1">Datos del cliente</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2">Instalación</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3">Red</v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1" class="pt-0">
              <formCliente></formCliente>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-0">
              <formInstalacion></formInstalacion>
            </v-stepper-content>
            <v-stepper-content step="3" class="pt-0">
              <formRedAntena></formRedAntena>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
          
      </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import formCliente from "@/js/components/clientes/steps_registro_instalacion/stepClienteComponent.vue";
import formInstalacion from "@/js/components/clientes/steps_registro_instalacion/stepInstalacionComponent.vue";
import formRedAntena from "@/js/components/clientes/steps_registro_instalacion/stepAntenaComponent.vue";

export default {
  components: {
    formCliente,
    formInstalacion,
    formRedAntena,
  },
  data() {
    return {
      
    };
  },
  mounted() {
    
  },
  computed: {
    e1() {
      return this.$store.getters.getPasoRegistroCliente;
    },
  },
  methods: {
    
  },
};
</script>