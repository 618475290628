<template>
    <div class="component mt-4">
        <base-component>
            <div slot="component">
                <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
                <v-layout row wrap>
                    <v-flex xs12 md12>
                        <v-card class="cool pa-2">
                            <v-tabs
                             class="cool pa-4"
                            v-model="tab"
                            background-color="red"
                            dark
                            show-arrows
                            >
                            <v-tab>
                                Tipos de pago
                            </v-tab>
                           
                            </v-tabs>
                            <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <tipos></tipos>
                            </v-tab-item>
                            </v-tabs-items>
                        </v-card>
                    </v-flex>
                </v-layout>
            </div>
        </base-component>
    </div>
</template>
<script>
import Common from '@/js/Common'
import tipos from '@/js/components/pagos/recursos/tablas/tipos_pagos/tipos.vue'
export default {
    components:{
        tipos,
    },
    data() {
        return {
            tab:1,
        };
    },
    mounted() {

    },
    computed: {
        
    },
    methods: {
    
    }

};
</script>