<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <v-container grid-list-md>
          <v-card-title class="headline" primary-title>
            <strong>Horario</strong>
          </v-card-title>

          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12 md2>
                  <v-switch
                    inset
                    label="Lunes"
                    v-model="persona.lunes"
                  ></v-switch>
                </v-flex>
                <v-flex vertical xs12 sm12 md2>
                  <v-menu
                    v-if="persona.lunes"
                    ref="menu_lunes_e"
                    v-model="menu_lunes_e"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="persona.lunes_entrada"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="persona.lunes_entrada"
                        label="Entrada"
                        :rules="generalRegla"
                        prepend-icon="mdi-av-timer"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_lunes_e"
                      v-model="persona.lunes_entrada"
                      @click:minute="
                        $refs.menu_lunes_e.save(persona.lunes_entrada)
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex vertical xs6 sm12 md2>
                  <v-menu
                    v-if="persona.lunes"
                    ref="menu_lunes_s"
                    v-model="menu_lunes_s"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="persona.lunes_salida"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="persona.lunes_salida"
                        label="salida"
                        :rules="generalRegla"
                        prepend-icon="mdi-av-timer"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_lunes_s"
                      v-model="persona.lunes_salida"
                      @click:minute="
                        $refs.menu_lunes_s.save(persona.lunes_salida)
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 md2>
                  <v-switch
                    inset
                    label="Martes"
                    v-model="persona.martes"
                  ></v-switch>
                </v-flex>
                <v-flex vertical xs12 sm12 md2>
                  <v-menu
                    v-if="persona.martes"
                    ref="menu_martes_e"
                    v-model="menu_martes_e"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="persona.martes_entrada"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="persona.martes_entrada"
                        label="Entrada"
                        :rules="generalRegla"
                        prepend-icon="mdi-av-timer"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_martes_e"
                      v-model="persona.martes_entrada"
                      @click:minute="
                        $refs.menu_martes_e.save(persona.martes_entrada)
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex vertical xs6 sm12 md2>
                  <v-menu
                    v-if="persona.martes"
                    ref="menu_martes_s"
                    v-model="menu_martes_s"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="persona.martes_salida"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="persona.martes_salida"
                        label="salida"
                        :rules="generalRegla"
                        prepend-icon="mdi-av-timer"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_martes_s"
                      v-model="persona.martes_salida"
                      @click:minute="
                        $refs.menu_martes_s.save(persona.martes_salida)
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 md2>
                  <v-switch
                    inset
                    label="Miercoles"
                    v-model="persona.miercoles"
                  ></v-switch>
                </v-flex>
                <v-flex vertical xs12 sm12 md2>
                  <v-menu
                    v-if="persona.miercoles"
                    ref="menu_miercoles_e"
                    v-model="menu_miercoles_e"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="persona.miercoles_entrada"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="persona.miercoles_entrada"
                        label="Entrada"
                        :rules="generalRegla"
                        prepend-icon="mdi-av-timer"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_miercoles_e"
                      v-model="persona.miercoles_entrada"
                      @click:minute="
                        $refs.menu_miercoles_e.save(persona.miercoles_entrada)
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex vertical xs6 sm12 md2>
                  <v-menu
                    v-if="persona.miercoles"
                    ref="menu_miercoles_s"
                    v-model="menu_miercoles_s"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="persona.miercoles_salida"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="persona.miercoles_salida"
                        label="salida"
                        :rules="generalRegla"
                        prepend-icon="mdi-av-timer"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_miercoles_s"
                      v-model="persona.miercoles_salida"
                      @click:minute="
                        $refs.menu_miercoles_s.save(persona.miercoles_salida)
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 md2>
                  <v-switch
                    inset
                    label="Jueves"
                    v-model="persona.jueves"
                  ></v-switch>
                </v-flex>
                <v-flex vertical xs12 sm12 md2>
                  <v-menu
                    v-if="persona.jueves"
                    ref="menu_jueves_e"
                    v-model="menu_jueves_e"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="persona.jueves_entrada"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="persona.jueves_entrada"
                        label="Entrada"
                        :rules="generalRegla"
                        prepend-icon="mdi-av-timer"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_jueves_e"
                      v-model="persona.jueves_entrada"
                      @click:minute="
                        $refs.menu_jueves_e.save(persona.jueves_entrada)
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex vertical xs6 sm12 md2>
                  <v-menu
                    v-if="persona.jueves"
                    ref="menu_jueves_s"
                    v-model="menu_jueves_s"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="persona.jueves_salida"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="persona.jueves_salida"
                        label="salida"
                        :rules="generalRegla"
                        prepend-icon="mdi-av-timer"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_jueves_s"
                      v-model="persona.jueves_salida"
                      @click:minute="
                        $refs.menu_jueves_s.save(persona.jueves_salida)
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 md2>
                  <v-switch
                    inset
                    label="Viernes"
                    v-model="persona.viernes"
                  ></v-switch>
                </v-flex>
                <v-flex vertical xs12 sm12 md2>
                  <v-menu
                    v-if="persona.viernes"
                    ref="menu_viernes_e"
                    v-model="menu_viernes_e"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="persona.viernes_entrada"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="persona.viernes_entrada"
                        label="Entrada"
                        :rules="generalRegla"
                        prepend-icon="mdi-av-timer"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_viernes_e"
                      v-model="persona.viernes_entrada"
                      @click:minute="
                        $refs.menu_viernes_e.save(persona.viernes_entrada)
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex vertical xs6 sm12 md2>
                  <v-menu
                    v-if="persona.viernes"
                    ref="menu_viernes_s"
                    v-model="menu_viernes_s"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="persona.viernes_salida"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="persona.viernes_salida"
                        label="salida"
                        :rules="generalRegla"
                        prepend-icon="mdi-av-timer"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_viernes_s"
                      v-model="persona.viernes_salida"
                      @click:minute="
                        $refs.menu_viernes_s.save(persona.viernes_salida)
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 md2>
                  <v-switch
                    inset
                    label="Sabado"
                    v-model="persona.sabado"
                  ></v-switch>
                </v-flex>
                <v-flex vertical xs12 sm12 md2>
                  <v-menu
                    v-if="persona.sabado"
                    ref="menu_sabado_e"
                    v-model="menu_sabado_e"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="persona.sabado_entrada"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="persona.sabado_entrada"
                        label="Entrada"
                        :rules="generalRegla"
                        prepend-icon="mdi-av-timer"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_sabado_e"
                      v-model="persona.sabado_entrada"
                      @click:minute="
                        $refs.menu_sabado_e.save(persona.sabado_entrada)
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex vertical xs6 sm12 md2>
                  <v-menu
                    v-if="persona.sabado"
                    ref="menu_sabado_s"
                    v-model="menu_sabado_s"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="persona.sabado_salida"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="persona.sabado_salida"
                        label="salida"
                        :rules="generalRegla"
                        prepend-icon="mdi-av-timer"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_sabado_s"
                      v-model="persona.sabado_salida"
                      @click:minute="
                        $refs.menu_sabado_s.save(persona.sabado_salida)
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 md2>
                  <v-switch
                    inset
                    label="Domingo"
                    v-model="persona.domingo"
                  ></v-switch>
                </v-flex>
                <v-flex vertical xs12 sm12 md2>
                  <v-menu
                    v-if="persona.domingo"
                    ref="menu_domingo_e"
                    v-model="menu_domingo_e"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="persona.domingo_entrada"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="persona.domingo_entrada"
                        label="Entrada"
                        :rules="generalRegla"
                        prepend-icon="mdi-av-timer"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_domingo_e"
                      v-model="persona.domingo_entrada"
                      @click:minute="
                        $refs.menu_domingo_e.save(persona.domingo_entrada)
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex vertical xs6 sm12 md2>
                  <v-menu
                    v-if="persona.domingo"
                    ref="menu_domingo_s"
                    v-model="menu_domingo_s"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="persona.domingo_salida"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="persona.domingo_salida"
                        label="salida"
                        :rules="generalRegla"
                        prepend-icon="mdi-av-timer"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_domingo_s"
                      v-model="persona.domingo_salida"
                      @click:minute="
                        $refs.menu_domingo_s.save(persona.domingo_salida)
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-spacer></v-spacer>
                <v-btn
                  @click="validate()"
                  class="rounded-pill pa-3"
                  color="primary"
                  >Actualizar</v-btn
                >
              </v-layout>
            </v-container>
          </v-form>
        </v-container>
      </div>
    </base-component>
  </div>
</template>
<script>
export default {
  data() {
    return {
      persona: {
        lunes: false,
        martes: false,
        miercoles: false,
        jueves: false,
        viernes: false,
        sabado: false,
        domingo: false,
        lunes_entrada: null,
        lunes_salida: null,
        martes_entrada: null,
        martes_salida: null,
        miercoles_entrada: null,
        miercoles_salida: null,
        jueves_entrada: null,
        jueves_salida: null,
        viernes_entrada: null,
        viernes_salida: null,
        sabado_entrada: null,
        sabado_salida: null,
        domingo_entrada: null,
        domingo_salida: null,
      },
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      //timepikers
      menu_lunes_e: false,
      menu_lunes_s: false,

      menu_martes_e: false,
      menu_martes_s: false,

      menu_miercoles_e: false,
      menu_miercoles_s: false,

      menu_jueves_e: false,
      menu_jueves_s: false,

      menu_viernes_e: false,
      menu_viernes_s: false,

      menu_sabado_e: false,
      menu_sabado_s: false,

      menu_domingo_e: false,
      menu_domingo_s: false,
    };
  },
  mounted() {
    this.persona = Object.assign({}, this.$store.getters.getDatosHorario);
  },
  computed: {},
  methods: {
    cargarDatos(persona) {
      // this.persona = persona;
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (!this.persona.lunes) {
          this.persona.lunes_entrada = null;
          this.persona.lunes_salida = null;
        }
        if (!this.persona.martes) {
          this.persona.martes_entrada = null;
          this.persona.martes_salida = null;
        }
        if (!this.persona.miercoles) {
          this.persona.miercoles_entrada = null;
          this.persona.miercoles_salida = null;
        }
        if (!this.persona.jueves) {
          this.persona.jueves_entrada = null;
          this.persona.jueves_salida = null;
        }
        if (!this.persona.viernes) {
          this.persona.viernes_entrada = null;
          this.persona.viernes_salida = null;
        }
        if (!this.persona.sabado) {
          this.persona.sabado_entrada = null;
          this.persona.sabado_salida = null;
        }
        if (!this.persona.domingo) {
          this.persona.domingo_entrada = null;
          this.persona.domingo_salida = null;
        }

        this.actualizar();
      }
    },
    actualizar() {
      let dispatch = "UsersModule/OnSave";
      let endpoint = "actualizar/horario";

      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: this.persona,
        })
        .then((result) => {});
    },
  },
};
</script>