window.sessionStorage.clear();
let Static = {
        set(k, value){
            localStorage.setItem(k, JSON.stringify(value));
        },
        get(k){
            var data = localStorage[k];

            if(data === undefined) return null;

            return JSON.parse(data);
        },
        remove(k){
            localStorage.removeItem(k);
        },
        clear(){
            //localStorage.clear();
            window.localStorage.clear()
        }
    }
export default Static

