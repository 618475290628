

import Axios from "axios"

export default {
    state: {
        e1:1,
        empresa:{},
        persona: {},
        zona: {},
        router: {},
        bot:{},
        coordenadas:{},
        registrado: true,
    },
    mutations: {
        mutacionE1(state,dato){
            state.e1 = dato;
        },
        datosEmpresa(state,datos){
            state.empresa=datos;
        },
        datosPersona(state,datos){
            state.persona=datos;
        },
        datosZona(state,datos){
            state.zona=datos;
        },
        datosRouter(state,datos){
            state.router=datos;
        },
        mutacionCoordenadas(state,datos){
            state.coordenadas = datos;
        },
        mutacionRegistrado(state,datos){
            state.registrado = datos;
        }
    },
    actions: {
    datosPaso1({commit},payload){
        commit('mutacionE1',payload.step);
        commit('datosEmpresa',payload.empresa);
        commit('datosPersona',payload.persona);
    },
    datosPaso2({commit},payload){
        commit('mutacionE1',payload.step);
        commit('datosZona',payload.zona);
        commit('datosRouter',payload.router);
    },
    asignaE1({commit},payload){
        commit('mutacionE1',payload);
    },
    asignaCoordenadas({commit},payload){
        commit('mutacionCoordenadas',payload);
    },
    asignaregistrado({commit},payload){
        commit('mutacionRegistrado',payload);
    }
    },
    getters: {
      getE1(state){
          return state.e1;
      },
      getDatosRegistro(state){
        return {negocio:state.negocio, bot: state.bot};
      },
      getCoordenadas(state){
          return state.coordenadas;
      },
      getPersona(state){
          return state.persona;
      },
      getEmpresa(state){
        return state.empresa;
        },
      getZona(state){
        return state.zona;
      },
      getRouter(state){
        return state.router;
      },
      getUsuarioRegistrado(state){
          return state.registrado;
      }
    },
modules:{
  
}
};
