<template>
    
            <div>
                  <v-dialog v-model="dialog" max-width="400" persistent>
                    <v-card>
                        <v-card-title class="display-1" primary-title>
                          <stroing>  Editar</stroing>
                        </v-card-title>
                        <v-container grid-list-md>
                               <v-form ref="form" v-model="valid" lazy-validation dense>
                                      <v-container grid-list-md>
                                          <v-layout row wrap>
                                      <v-flex xs12 md12>
                                        <v-text-field
                                            label="Banco"
                                            v-model="cuenta.banco"
                                            :rules="generalRegla"
                                            prepend-icon="mdi-bank"
                                        ></v-text-field>                                          
                                      </v-flex>
                                      <v-flex xs12 md12>
                                        <v-text-field
                                            label="Titular"
                                            v-model="cuenta.titular"
                                            :rules="generalRegla"
                                            prepend-icon="mdi-account"
                                        ></v-text-field>                                          
                                      </v-flex>
                                      <v-flex xs12 md12>
                                        <v-text-field
                                            label="Número de cuenta"
                                            v-model="cuenta.numero_cuenta"
                                            :rules="generalRegla"
                                            prepend-icon="mdi-account-card-details"
                                            type="number"
                                        ></v-text-field>                                          
                                      </v-flex>
                                      <v-flex xs12 md12>
                                        <v-text-field
                                            label="Transferencia bancaria"
                                            v-model="cuenta.transferencia"
                                            prepend-icon="mdi-transfer"
                                        ></v-text-field>                                          
                                      </v-flex>
                                      
                                     </v-layout>
                                      </v-container>
                                    </v-form>
                        
                               <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="cancelar()">Cancelar</v-btn>
                            <v-btn
                            class="rounded-pill"
                            :disabled="!valid"
                                color="primary"
                                @click="actualizarServicio()"
                            >
                                Actualizar
                            </v-btn>
                            </v-card-actions>
                            </v-container>
                        </v-card>
                        </v-dialog>
            </div>
        
</template>
<script>
export default {
    data() {
        return {
            cuenta:{
              banco:"",
              numero_cuenta:"",
              transferencia:"",
              titular:"",
            },
            valid: true,
            generalRegla: [v => !!v || "Se necesita el campo",],
            dialog: false,
            resolve:null,
            reject:null,
        };
    },
    mounted() {
        
    },
    watch:{
        
    },
    computed: {
       
    },
    methods: {
        actualizarServicio() {
            if (this.$refs.form.validate()) {
                
                this.resolve(this.cuenta);
                this.dialog = false;
                return this.cuenta;
            } else {
                return null;
            }
        },
        limpiar(){
            this.$refs.form.reset();
        },
        esperarDatos(elemento) {
            this.cuenta = elemento;
            this.dialog = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        cancelar(){
            this.resolve(false);
            this.dialog = false;
        }
        

        
        
        
    
    }

};
</script>