<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <v-card>
          <v-card-text>
            <v-form dense ref="form" v-model="valid" lazy-validation>
              <p class="text-subtitle-2">
                <strong>Monto de donación mensual sugerida en dolores</strong>
              </p>
              <v-divider></v-divider>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <strong> Tipo de conexion con el sistema </strong>
                  <v-radio-group dense v-model="tipoDeConexion">
                    <v-radio
                      color="secondary"
                      label="Ninguno (Sin conexion al RB)- $0.1 x cliente"
                      value="0.1"
                    ></v-radio>
                    <v-radio
                      color="primary"
                      label="Ip publica - $0.2 x cliente"
                      value="0.2"
                    ></v-radio>
                    <v-radio
                      color="success"
                      label="Script (No necesita ip publica) - $0.3 x cliente"
                      value="0.3"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>

                <v-flex xs12 md6>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <strong> Necesitas soporte? </strong>
                    </v-flex>
                    <v-flex xs12>
                      <v-radio-group dense v-model="necesitaSoporte" row>
                        <v-radio
                          dense
                          color="blue"
                          label="Si - $0.1 x cliente"
                          value="0.1"
                        ></v-radio>
                        <v-radio
                          dense
                          color="orange"
                          label="No - $0 x cliente"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <strong>
                        Necesitas subir archivos de comprobantes de gastos de tu
                        empresa?
                      </strong>
                    </v-flex>
                    <v-flex xs12>
                      <v-radio-group dense v-model="necesitaArchivos" row>
                        <v-radio
                          dense
                          color="green"
                          label="Si - $0.1 x cliente"
                          value="0.1"
                        ></v-radio>
                        <v-radio
                          dense
                          color="orange"
                          label="No - $0 x cliente"
                          value="0"
                        ></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12>
                  <h3>Total = ${{ donacionMensual }} Dolares mensuales</h3>
                  por cada cliente registrado en el sistema.
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
            </v-form>
            <v-card flat width="700" class="cool pa-4 ma-auto">
              <v-layout row wrap>
                <v-flex xs12>
                  <p class="text-center">
                    <strong>
                      Gracias por ayudar a que INNOVAISP siga existiendo
                      <br />
                      Le recomendamos la donación de un dolar como pago de
                      inscripción.
                      <br />
                      <strong>IMPORTANTE: </strong>el dinero donado no podra se
                      devuelto.
                    </strong>
                  </p>
                </v-flex>
              </v-layout>
              <v-layout align-center row wrap>
                <v-flex xs12 md12>
                  <v-text-field
                    label="Donación sugerida"
                    outlined
                    prepend-icon="mdi-cash-usd"
                    v-model="donacion"
                    hint="Dolar"
                    type="number"
                    persistent-hint
                    :rules="reglasDonacion"
                  ></v-text-field>
                </v-flex>
                <v-flex align-self-center xs12>
                  <div
                    v-show="donacion != null && donacion > 0"
                    ref="paypal"
                  ></div>
                </v-flex>
              </v-layout>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="regresar()">Regresar</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </base-component>
  </div>
</template>
<script>
export default {
  data() {
    return {
      valid: false,
      mostrar: false,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      tipoDeConexion: "0.1",
      necesitaSoporte: "0.1",
      necesitaArchivos: "0.1",
      //paypal:
      user: {
        name: "",
        lastName: "",
        email: "",
      },
      donacion: 1,
      reglasDonacion: [
        (v) => v != null || "Se necesita el campo",
        (v) => v > 0 || "Debe ser mayo a 0",
      ],
    };
  },
  mounted() {
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=Aba_XrglcjT4vz6ZZ_dBJxkux-bbxBht1VetW1QUTnV6ZW8m9-KzQ2DLNbTCAhCh-sXr-K81ERudTeDf";
    // test script.src ="https://www.paypal.com/sdk/js?client-id=AUFs1UViZ-VlGv39zOykmuj_f4hnt0xrfLQgDoOP-MvoRLRxbxOcJ2thTk6mtc21HVx2d9RR7yFCBiMj";
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
    this.getDatos();
  },
  watch: {},
  computed: {
    donacionMensual() {
      var resultado =
        parseFloat(this.tipoDeConexion) +
        parseFloat(this.necesitaSoporte) +
        parseFloat(this.necesitaArchivos);
      return parseFloat(resultado).toFixed(1);
    },
  },
  methods: {
    regresar() {
      this.$store.dispatch("asignaE1", 1);
    },
    finalizar() {
      var empresa = this.$store.getters.getEmpresa;
      var persona = this.$store.getters.getPersona;
      let datos = new FormData();

      //datos empresa
      datos.append("nombre", empresa.nombre);
      datos.append("razon_social", empresa.razon_social);
      datos.append("tipo_identificacion", empresa.tipo_identificacion);
      datos.append("RFC_RUC_NIT", empresa.RFC_RUC_NIT);
      datos.append("direccion", empresa.direccion);
      datos.append("estado", empresa.estado);
      datos.append("municipio", empresa.municipio);
      datos.append("colonia", empresa.colonia);
      datos.append("codigo_postal", empresa.codigo_postal);
      datos.append("longitud", empresa.longitud);
      datos.append("latitud", empresa.latitud);
      datos.append("email", empresa.email);
      datos.append("telefono", empresa.telefono);
      datos.append("retenciones", empresa.retenciones);
      datos.append("pago_por_cliente", this.donacionMensual);
      datos.append("logo", empresa.logo);

      //persona
      datos.append("nombre_persona", persona.nombre);
      datos.append("apellido_paterno", persona.apellido_paterno);
      datos.append("apellido_materno", persona.apellido_materno);
      datos.append("imagen", persona.imagen);

      let dispatch = "UsersModule/OnSave";
      let endpoint = "usuarios";

      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: datos,
        })
        .then((result) => {
          document.location.href = "/home";
        });
    },
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "usuario/logueado",
        })
        .then((result) => {
          this.user = result.data.user;
        });
    },

    setLoaded(resp) {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              application_context: {
                shipping_preference: "NO_SHIPPING",
              },
              payer: {
                email_address: this.user.email,
                name: {
                  given_name: this.user.name,
                },
              },
              purchase_units: [
                {
                  amount: {
                    currency_code: "USD",
                    value: this.donacion,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions, resp) => {
            this.$store
              .dispatch("UsersModule/OnGet", {
                url:
                  "/paypal/process/registration/" +
                  data.orderID +
                  "/" +
                  this.user.id,
              })
              .then((result) => {
                this.valid = true;
                this.finalizar();
              });
          },
          onError: (err) => {},
        })
        .render(this.$refs.paypal);
    },
  },
};
</script>
