<template>
  <v-card class="cool ma-4 pa-3" flat dark color="black">
    <v-card-title primary-title class="display-1 my-4">
      <v-icon x-large>mdi-console-line</v-icon> Logs
    </v-card-title>
    <v-card-subtitle primary-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        solo
        rounded
        single-line
        hide-details
      ></v-text-field>
    </v-card-subtitle>
    <v-card-text>
      <v-data-table
        dark
        class="green--text black"
        :headers="headers"
        :items="logs"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50],
        }"
        :items-per-page="30"
        :search="search"
      ></v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import Common from "@/js/Common";
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "id", align: "start", value: ".id", sortable: true },
        { text: "Hora", value: "time", sortable: true },
        { text: "Tipo", value: "topics" },
        { text: "Mensaje", value: "message", sortable: true },
      ],
      logs: [],
      id: 0,
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.id = arreUrl.pop();
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `logs/router/` + this.id,
          data: {},
        })
        .then((result) => {
          

          this.logs = result.data.logs;
        });
    },
  },
};
</script>
