<template>
  <div>
    <div class="component-container">
      <slot name="component"></slot>
    </div>
    <loading v-if="getIsLoadingState">
      <div slot="message">{{ loading_message }}</div>
    </loading>
    <BaseDialogs :titulo="dialog.titulo" :mensaje="dialog.mensaje" />

    <div class="contenedor">
      <div v-for="notificacion in notifications" :key="notificacion.id">
        <BaseNotificacion :datos="notificacion" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import loading from "./Loading.vue";
export default {
  name: "BaseComponent",
  props: {
    loading_message: {
      type: String,
      default: "Espere un momento",
    },
  },
  components: {
    loading,
  },
  data() {
    return {
      focused: true,
    };
  },
  watch: {},
  mounted() {
    window.addEventListener("blur", () => (this.focused = false));
    window.addEventListener("focus", () => (this.focused = true));
    axios.get("notification").then(({ data: { data, url } }) => {
      let procesarNotificacion = (notificacion, i) => {
        let data = notificacion.notification_data[0].content;
        let content = {
          1: "Se te ha asignado a una instalación del cliente " + data.FullName,
          7: "Se han actualizado los datos del cliente " + data.FullName,
          2:
            "Su pago correspondiente al mes de " +
            this.procesarFecha(data.mes_id + "-01-" + data.anio).slice(5) +
            " ha sido registrado",
          3: `Se te ha asignado una nueva actividad: ${data.trabajo}`,
          4: `Se han actualizado los datos de la actividad: ${data.trabajo}`,
          5: `Se te ha asignado un ticket: ${data.asunto}`,
          6: `Se han actualizado los datos del ticket ${data.asunto}`,
          8: `Se ha iniciado el trabajo: ${data.trabajo}`,
          9: `Se ha terminado el trabajo: ${data.trabajo}`,
          10: `Se ha iniciado el proceso del ticket: ${data.asunto}`,
          11: `Se ha terminado el proceso del ticket: ${data.asunto}`,
          12: `Tienes un pago pendiente correspondiente al mes de ${this.procesarFecha(
            data.mes_id + "-01-" + data.anio
          ).slice(5)}`,
        };
        if (i == -1) {
          this.notificacionNavegador(
            notificacion.id,
            notificacion.notification_type_id,
            notificacion.notification_data[0].id,
            notificacion.title,
            content[notificacion.notification_type_id]
          );
        }
        this.crearTarjeta(
          notificacion.id,
          notificacion.title,
          content[notificacion.notification_type_id],
          this.formatDate(notificacion.created_at),
          notificacion.is_read ? "none" : "inline",
          notificacion.notification_type_id,
          notificacion.notification_data[0].id,
          notificacion.notification_type.icon
        );
      };
      data.forEach(procesarNotificacion);
      Echo.private(url).listen("Notify", (e) =>
        procesarNotificacion(e.data, -1)
      );
    });
    this.registerServiceWorker();
  },
  computed: {
    ...mapGetters(["getIsLoadingState"]),
    dialog() {
      return this.$store.getters.get_dialog_datos;
    },
    ...mapState("notificaciones", ["notifications"]),
  },
  beforeDestroy() {
    window.sessionStorage.clear();
  },
  methods: {
    ...mapActions(["get"]),
    notificacionNavegador(id, tipo, detalles_id, titulo, content) {
      let notification = new Notification(titulo, {
        body: content,
        icon: "img/favicon.ico",
      });

      // Reproducir sonido y vibración
      if ("vibrate" in navigator && navigator.userAgentData.mobile) {
        navigator.vibrate([500, 300, 500]);
      }

      let audio = new Audio("sounds/pago_liquidado.mp3");
      audio.play();

      notification.onclick = () => {
        window.open(this.redireccionar(id, tipo, detalles_id), "_blank");
        notification.close();
      };
    },
    procesarFecha(fecha) {
      return new Date(fecha).toLocaleDateString("es", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
    crearTarjeta(id, title, content, fecha, leido, tipo, detalles_id, icono) {
      const tarjeta = `
			<div class="card notification-card mb-3" style="background-color: #fff; margin: 10px;cursor:pointer;">
				<div class="card-header d-flex justify-content-between align-items-center p-1" style="background-color: #fff;">
					<p class="text-muted ml-2 mb-0">${fecha}</p>
					<div class="justify-content-end">
						<i class="fa fa-circle mr-2" style="color: #007bff; display: ${leido}"></i>
					</div>
					</div>
					<div class="card-body p-3">
						<div class="d-flex align-items-center">
							<i class="fa ${icono} fa-2x me-3 mr-4" style="color: #007bff;"></i>
						<div>
						<h5 class="mb-0">${title}</h5>
						<p class="card-text mb-1">${content}</p>
						<button type="button" class="btn btn-outline-danger btn-block mt-2">
							Eliminar Notificación
						</button>
						<button type="button" class="btn btn-outline-info btn-block mt-2">
							Ver
						</button>
					</div>
				</div>
			</div>
			`;
      $("#notificaciones").append(tarjeta);
      $("#contador-notificaciones").html(notificaciones.children.length);

      let [btn_eliminar, btn_ver] = [...notificaciones.children]
        .at(-1)
        .querySelectorAll("button");
      btn_eliminar.onclick = () => this.eliminarTarjeta(id);
      btn_ver.onclick = () =>
        (location.href = this.redireccionar(id, tipo, detalles_id));
    },
    formatDate(fecha) {
      let opciones = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };
      return new Date(fecha).toLocaleString("es", opciones).replace(",", "");
    },
    redireccionar(id, tipo, detalles_id) {
      let rutas = {
        1: "/vista/detalles/instalacion/" + detalles_id, // Registro de cliente
        2: "/detalles/pago/" + detalles_id, // Pago nuevo
        3: "/vista/detalles/actividad/" + detalles_id, // Actividad nueva
        4: "/vista/detalles/actividad/" + detalles_id, // Actividad actualizada
        5: "/vista/detalles/ticket/" + detalles_id, // Nuevo ticket
        6: "/vista/detalles/ticket/" + detalles_id, // Ticket actualizado
        7: "/vista/detalles/instalacion/" + detalles_id, // Ticket actualizado
        8: "/vista/detalles/actividad/" + detalles_id, // Ticket actualizado
        9: "/vista/detalles/actividad/" + detalles_id, // Ticket actualizado
        10: "/vista/detalles/ticket/" + detalles_id, // Ticket actualizado
        11: "/vista/detalles/ticket/" + detalles_id, // Ticket actualizado
        12: "/detalles/pago/" + detalles_id, // Ticket actualizado
      };
      if (rutas[tipo]) {
        axios.post(`notification/${id}/check`);
        return rutas[tipo];
      }
    },
    eliminarTarjeta(id) {
      axios.delete(`notification/${id}`);
      event.target.closest(".card").remove();
      $("#contador-notificaciones").html(notificaciones.children.length);
    },
    // -->[ServiceWorker]<--
    registerServiceWorker() {
      if (!("serviceWorker" in navigator)) {
        return;
      }
      navigator.serviceWorker
        .register("/sw.js")
        .then(() => this.initialiseServiceWorker());
    },
    initialiseServiceWorker() {
      if (!("showNotification" in ServiceWorkerRegistration.prototype)) {
        return;
      }
      if (Notification.permission === "denied") {
        return;
      }
      if (!("PushManager" in window)) {
        return;
      }
      navigator.serviceWorker.ready.then((registration) => {
        registration.pushManager
          .getSubscription()
          .then((subscription) => {
            if (!subscription) {
              this.subscribe();
              return;
            }
            this.updateSubscription(subscription);
          })
          .catch((e) => {});
      });
    },
    subscribe() {
      navigator.serviceWorker.ready.then((registration) => {
        const options = {
          userVisibleOnly: true,
          applicationServerKey: this.urlBase64ToUint8Array(
            Laravel.vapidPublicKey
          ),
        };
        registration.pushManager
          .subscribe(options)
          .then((subscription) => this.updateSubscription(subscription))
          .catch((e) => {
            if (Notification.permission === "denied") {
            } else {
              console.error(
                "No se pudo suscribir al servicio de notificaciones push.",
                e
              );
            }
          });
      });
    },
    updateSubscription(subscription) {
      this.$store.dispatch("UsersModule/OnSaveSP", {
        url: "/subscriptions",
        data: subscription,
      });
    },
    urlBase64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/");
      const rawData = atob(base64);
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
  },
};
</script>

<style lang="scss">
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
.v-toolbar:not(.v-toolbar--fixed) .v-toolbar__content {
  margin-left: 0;
}
.contenedor {
  position: fixed;
  bottom: 0;
  right: 0;
  padding-right: 40px;
}
</style>
