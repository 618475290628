<template>
    <div class="component">
        <base-component>
            <div slot="component">
                <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
                <v-layout row wrap>
                    <v-flex xs12 md12>
                        <h3>Este seccion exporta un reporte general con todas las actividades, pagos, ingresos, egreso, etc realizadas en algun periodo de tiempo o mes.</h3>
                    </v-flex>
                    <v-container grid-list-md>
                                   <!--
                                    <v-layout row wrap>
                                            
                                        <v-flex class="d-flex justify-center" xs12 md12>
                                            <v-radio-group dense v-model="filtro" row>
                                                <v-radio dense color="yellow" label="Rango" value="1"></v-radio>
                                                <v-radio dense color="pink" label="Mes" value="2"></v-radio>
                                            </v-radio-group>
                                        </v-flex>
                                    </v-layout> 
                                    -->
                                    
                                    <v-layout disabled row wrap>
                                            <v-flex v-if="filtro == 1" xs12 md4>
                                                <v-menu
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                    v-model="computedDateFormatted"
                                                    label="Fecha Inicio"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    :disabled="filtro !='1'"
                                                    :rules="generalRegla"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker locale="es-mx" v-model="fecha_inicio" no-title @input="menu = false"></v-date-picker>
                                                </v-menu>
                                            </v-flex>
                                            <v-flex v-if="filtro == 1" xs12 md4>
                                                <v-menu
                                                v-model="menu2"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                    v-model="computedDateFormatted2"
                                                    label="Fecha Fin"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    :rules="generalRegla"
                                                    v-bind="attrs"
                                                    :disabled="filtro !='1'"

                                                    v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker locale="es-mx" :min="fecha_inicio" v-model="fecha_fin" no-title @input="menu2 = false"></v-date-picker>
                                                </v-menu>
                                            </v-flex>
                                            <v-flex v-if="filtro == 2" xs12 md4>
                                            <v-select
                                                 :items="meses"
                                                 v-model="mes_id"
                                                 item-text="mes"
                                                 item-value="id"
                                                 :rules="generalRegla"
                                                 label="Mes"
                                                 prepend-icon="mdi-calendar-blank"
                                             ></v-select>
                                            </v-flex>
                                            <v-flex v-if="filtro == 2" xs12 md4>
                                                <v-select
                                                 :items="anios"
                                                 v-model="anio"
                                                 :rules="generalRegla"
                                                 label="Año"
                                                 prepend-icon="mdi-calendar-blank"
                                             ></v-select>
                                        </v-flex>

                                    </v-layout>
                                    <!--
                                    <v-layout row wrap>
                                        <v-flex class="d-flex justify-center" xs12 md12>
                                            <v-radio-group dense v-model="formato" row>
                                                <v-radio dense color="green" label="Excel" value="1"></v-radio>
                                                <v-radio dense color="red" label="PDF" value="2"></v-radio>
                                            </v-radio-group>
                                        </v-flex>
                                    </v-layout>
                                    -->
                                    <v-layout row wrap>
                                        <v-btn @click="exportar()" color="success">Generar y exportar</v-btn>
                                    </v-layout> 
                                </v-container>
                </v-layout>
            </div>
        </base-component>
    </div>
</template>
<script>
import Common from '@/js/Common'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
export default {

    data() {
        return {
            dialog:false,
            menu2:false,
            menu:false,
            fecha_inicio:null,
            fecha_fin:null,
            filtro:'2',
            formato:'1',
            valid:true,
            date: new Date().toISOString().substr(0, 10),
            tabla:'',
            generalRegla: [v => !!v || "Se necesita el campo"],
            meses:[],
            mes_id:1,
            anios:[],
            anio:"2021",
        };
    },
    mounted() {
        this.getDatosIniciales();
    },
    watch: {
        fecha_inicio(val){
            this.fecha_fin = null;
        }
    },
    computed: {
        computedDateFormatted () {
            if(this.fecha_inicio){
                if (!this.fecha_inicio) return null
                const [year, month, day] = this.fecha_inicio.split('-')
                return `${day}/${month}/${year}`
            }else{
                return ""
            }
        },
        computedDateFormatted2 () {
            if(this.fecha_fin){
                if (!this.fecha_fin) return null
                const [year, month, day] = this.fecha_fin.split('-')
                return `${day}/${month}/${year}`
            }else{
                return ""
            }
        },
    },
    methods: {
        getDatosIniciales(){
            this.$store.dispatch('UsersModule/OnGet', {
                url: `datos/iniciales/form/reporte`,
                data: {}

            }).then(result => {
                
                this.meses = result.data.meses;
                this.anios = result.data.anios;
                
            })
        },
        exportar(){
            var datos={
                mes: this.mes_id,
                anio: this.anio,
                fecha_inicio: this.fecha_inicio,
                fecha_fin:this.fecha_inicio,
                filtro: this.filtro,
            }
            
            this.$store.dispatch('UsersModule/OnSave', {
                url: `reporte/completo/datos`,
                data: datos

            }).then(result => {
                
                this.generarPDF(result.data);
                
                
            })
        },
        generarPDF(datos){
                let instalaciones = datos.instalacion;
                
                let pdfName = 'Reporte general-'+this.date;
                var doc = new jsPDF();

                var cabecera = 'Reporte general: '+this.tabla;
                doc.setFontSize(12);
                doc.text(10,10,cabecera);
                doc.text(10,15,"Instalaciones realizadas");
                var tablaInstalaciones = this.tablaInstalaciones(instalaciones);
                    doc.autoTable({
                        head: tablaInstalaciones.head,
                        body: tablaInstalaciones.body,
                        startY: 20,
                        margin: { left: 10,right: 10, bottom:10 }
                        });
                        
                let finalY = doc.previousAutoTable.finalY;
                doc.setFontSize(12);
                doc.text("Total:"+Common.formatMoney(tablaInstalaciones.total), 10, finalY + 10);
                
                doc.setFontSize(14);
                doc.text(10,finalY+20,"Estado de Pagos");
                var tablaPagos = this.tablaPagos(datos);
                    doc.autoTable({
                        head: tablaPagos.head,
                        body: tablaPagos.body,
                        startY: finalY+25,
                        margin: { left: 10,right: 10, bottom:10 }
                        });
                
                let finalTablaPagos = doc.previousAutoTable.finalY; 
                doc.setFontSize(14);
                doc.text(10,finalTablaPagos+20,"Actividades realizadas: ");
                var tablaActividades = this.tablaActividades(datos.actividades);
                    doc.autoTable({
                        head: tablaActividades.head,
                        body: tablaActividades.body,
                        startY: finalTablaPagos+25,
                        margin: { left: 10,right: 10, bottom:10 }
                        });
                
                doc.setFontSize(12);
                let finalTablaActividades = doc.previousAutoTable.finalY; 
                doc.text("Total:"+Common.formatMoney(tablaActividades.total,2), 10, finalTablaActividades + 10); 
                
                doc.setFontSize(14);
                doc.text("Reportes realizados", 10, finalTablaActividades + 25);
               
                var tablaReportes = this.tablaReportes(datos.reportes);
                    doc.autoTable({
                        head: tablaReportes.head,
                        body: tablaReportes.body,
                        startY: finalTablaActividades+30,
                        margin: { left: 10,right: 10, bottom:10 }
                        });

                doc.setFontSize(12);
                let finalTablaReportes = doc.previousAutoTable.finalY; //this gives you the value of the end-y-axis-position of the previous autotable.
                doc.text("Total:"+Common.formatMoney(tablaReportes.total,2), 10, finalTablaReportes + 10); //you use the variable and add the number of pixels you want it to move.
                
                doc.text("Negocios ingresos", 10, finalTablaReportes + 20); //you use the variable and add the number of pixels you want it to move.
                
                var tablaIngresosNegocio = this.tablaIngresosNegocio(datos.ingresoNegocio);
                    doc.autoTable({
                        head: tablaIngresosNegocio.head,
                        body: tablaIngresosNegocio.body,
                        startY: finalTablaReportes+25,
                        margin: { left: 10,right: 10, bottom:10 }
                        });
                
                doc.setFontSize(12);
                let finalTablaIngresosNegocios = doc.previousAutoTable.finalY; //this gives you the value of the end-y-axis-position of the previous autotable.
                doc.text("Total:"+Common.formatMoney(tablaIngresosNegocio.total,2), 10, finalTablaIngresosNegocios + 10); //you use the variable and add the number of pixels you want it to move.
                doc.setFontSize(14);
                
                doc.text("Negocios Egresos", 10, finalTablaIngresosNegocios + 20); //you use the variable and add the number of pixels you want it to move.
                var tablaEgresosNegocio = this.tablaEgresosNegocio(datos.egresoNegocio);
                    doc.autoTable({
                        head: tablaEgresosNegocio.head,
                        body: tablaEgresosNegocio.body,
                        startY: finalTablaIngresosNegocios+25,
                        margin: { left: 10,right: 10, bottom:10 }
                        });

                let finalTablaEgresosNegocios = doc.previousAutoTable.finalY; //this gives you the value of the end-y-axis-position of the previous autotable.
                doc.setFontSize(12);
                doc.text("Total:"+Common.formatMoney(tablaEgresosNegocio.total,2), 10, finalTablaEgresosNegocios + 10); //you use the variable and add the number of pixels you want it to move.
               
                doc.setFontSize(14);
                doc.text("Egresos de la empresa Compras", 10, finalTablaEgresosNegocios + 20); //you use the variable and add the number of pixels you want it to move.
                var tablaEgresosCompras = this.tablaEgresosCompras(datos.compras);
                    doc.autoTable({
                        head: tablaEgresosCompras.head,
                        body: tablaEgresosCompras.body,
                        startY: finalTablaEgresosNegocios+25,
                        margin: { left: 10,right: 10, bottom:10 }
                        });
               
                let finalTablaEgresosCompras = doc.previousAutoTable.finalY; //this gives you the value of the end-y-axis-position of the previous autotable.
                doc.setFontSize(12);
                doc.text("Total:"+Common.formatMoney(tablaEgresosCompras.total,2), 10, finalTablaEgresosCompras + 10); //you use the variable and add the number of pixels you want it to move.
                doc.setFontSize(14);
                doc.text("Egresos de la empresa Personal", 10, finalTablaEgresosCompras + 20); //you use the variable and add the number of pixels you want it to move.
                 var tablaEgresosEmpleados = this.tablaEgresosEmpleados(datos.empleados);
                    doc.autoTable({
                        head: tablaEgresosEmpleados.head,
                        body: tablaEgresosEmpleados.body,
                        startY: finalTablaEgresosCompras+25,
                        margin: { left: 10,right: 10, bottom:10 }
                        });
                
                let finalTablaEgresosEmpleados = doc.previousAutoTable.finalY; //this gives you the value of the end-y-axis-position of the previous autotable.
                doc.setFontSize(12);
                doc.text("Total:"+Common.formatMoney(tablaEgresosEmpleados.total,2), 10, finalTablaEgresosEmpleados + 10); //you use the variable and add the number of pixels you want it to move.
                doc.setFontSize(14);
                doc.text("Otros egresos", 10, finalTablaEgresosEmpleados + 20); //you use the variable and add the number of pixels you want it to move.
                var tablaEgresosOtros = this.tablaEgresosOtros(datos.otrosEgresos);
                    doc.autoTable({
                        head: tablaEgresosOtros.head,
                        body: tablaEgresosOtros.body,
                        startY: finalTablaEgresosEmpleados+25,
                        margin: { left: 10,right: 10, bottom:10 }
                        });

                let finalTablaEgresosOtros = doc.previousAutoTable.finalY; //this gives you the value of the end-y-axis-position of the previous autotable.
                doc.setFontSize(12);
                doc.text("Total:"+Common.formatMoney(tablaEgresosOtros.total,2), 10, finalTablaEgresosOtros + 10); //you use the variable and add the number of pixels you want it to move.
                doc.setFontSize(14);
                doc.text("Ingresos de la empresa", 10, finalTablaEgresosOtros + 20); //you use the variable and add the number of pixels you want it to move.
                var tablaIngresos = this.tablaIngresos(datos.ingreso);
                    doc.autoTable({
                        head: tablaIngresos.head,
                        body: tablaIngresos.body,
                        startY: finalTablaEgresosOtros+25,
                        margin: { left: 10,right: 10, bottom:10 }
                        });
                let finalTablaIngreso = doc.previousAutoTable.finalY; //this gives you the value of the end-y-axis-position of the previous autotable.
                doc.setFontSize(12);
                doc.text("Total:"+Common.formatMoney(tablaIngresos.total,2), 10, finalTablaIngreso + 10); //you use the variable and add the number of pixels you want it to move.
                var todosIngresos = tablaInstalaciones.total + tablaActividades.total + tablaReportes.total + tablaIngresosNegocio.total + tablaIngresos.total + tablaPagos.total;
                doc.setFontSize(14);
                doc.text("Resultados Ingresos: ", 10, finalTablaIngreso + 20); //you use the variable and add the number of pixels you want it to move.
                var head = [["Tipo de ingreso","Total"]];
                var body = [];
                body.push(["Instalaciones",Common.formatMoney(tablaInstalaciones.total,2)]);
                body.push(["Actividades",Common.formatMoney(tablaActividades.total,2)]);
                body.push(["Reportes",Common.formatMoney(tablaReportes.total,2)]);
                body.push(["Negocios",Common.formatMoney(tablaIngresosNegocio.total,2)]);
                body.push(["Ingresos externos",Common.formatMoney(tablaIngresos.total,2)]);
                body.push(["Pagos actuales",Common.formatMoney(tablaPagos.total,2)]);
                body.push([]);
                body.push(["Total",Common.formatMoney(todosIngresos,2)]);
                   doc.autoTable({
                        head: head,
                        body: body,
                        startY: finalTablaIngreso+25,
                        margin: { left: 10,right: 10, bottom:10 }
                        });
                
                let finalTablaResultadosIngresos = doc.previousAutoTable.finalY; //this gives you the value of the end-y-axis-position of the previous autotable.
                var todosEgresos = tablaEgresosNegocio.total+tablaEgresosCompras.total+tablaEgresosEmpleados.total+tablaEgresosOtros.total;
                doc.text("Resultados Egresos: ", 10, finalTablaResultadosIngresos + 20); //you use the variable and add the number of pixels you want it to move.
                var headE = [["Tipo de egreso","Total"]];
                var bodyE = [];
                bodyE.push(["Negocios",Common.formatMoney(tablaEgresosNegocio.total,2)]);
                bodyE.push(["Compras",Common.formatMoney(tablaEgresosCompras.total,2)]);
                bodyE.push(["Empleados",Common.formatMoney(tablaEgresosEmpleados.total,2)]);
                bodyE.push(["Otros",Common.formatMoney(tablaEgresosOtros.total,2)]);
                bodyE.push([]);
                bodyE.push(["Total",Common.formatMoney(todosEgresos,2)]);
                   doc.autoTable({
                        head: headE,
                        body: bodyE,
                        startY: finalTablaResultadosIngresos+25,
                        margin: { left: 10,right: 10, bottom:10 }
                        });

                
                let finalTablaResultadosEgresos = doc.previousAutoTable.finalY; //this gives you the value of the end-y-axis-position of the previous autotable.
                
                doc.text("Resultado de ingresos menos los egresos : "+Common.formatMoney(todosIngresos-todosEgresos,2), 10, finalTablaResultadosEgresos + 10); //you use the variable and add the number of pixels you want it to move.
                



                doc.save(pdfName + '.pdf');



                

        },
        tablaIngresos(ingresos){
                var head = [["Fecha","Tipo","Detalles","Monto"]];
                var body = [];
                var total = 0.00;
                for (var i = 0; i < ingresos.length; i++) {
                    var elemento = ingresos[i]; 
                    var row = [elemento.fecha,elemento.procedencia.procedencia,elemento.detalles,elemento.monto];
                    total += parseFloat(elemento.monto);
                    body.push(row);
                }

            return {head:head,body:body,total:total};
        },
        tablaEgresosOtros(egresos){
                var head = [["Fecha","Tipo","Detalles","Monto"]];
                var body = [];
                var total = 0.00;
                for (var i = 0; i < egresos.length; i++) {
                    var elemento = egresos[i]; 
                    var row = [elemento.fecha,elemento.tipo.otro,elemento.observaciones,elemento.monto];
                    total += parseFloat(elemento.monto);
                    body.push(row);
                }

            return {head:head,body:body,total:total};
        },
        tablaEgresosEmpleados(egresos){
                var head = [["Fecha","Tipo","Personal","Monto"]];
                var body = [];
                var total = 0.00;
                for (var i = 0; i < egresos.length; i++) {
                    var elemento = egresos[i]; 
                    var row = [elemento.fecha,elemento.tipo.tipo,elemento.persona.FullName,elemento.monto];
                    total += parseFloat(elemento.monto);
                    body.push(row);
                }

            return {head:head,body:body,total:total};
        },
        tablaEgresosCompras(egresos){
                var head = [["Fecha","Tipo","Detalles","Monto"]];
                var body = [];
                var total = 0.00;
                for (var i = 0; i < egresos.length; i++) {
                    var elemento = egresos[i]; 
                    var row = [elemento.fecha,elemento.tipo.tipo,elemento.observaciones,elemento.monto];
                    total += parseFloat(elemento.monto);
                    body.push(row);
                }

            return {head:head,body:body,total:total};
        },

        tablaEgresosNegocio(egresos){
                var head = [["Negocio","Fecha","Tipo","Monto"]];
                var body = [];
                var total = 0.00;
                for (var i = 0; i < egresos.length; i++) {
                    var elemento = egresos[i]; 
                    var row = [elemento.negocio.nombre,elemento.fecha,elemento.tipo.tipo,elemento.monto];
                    total += parseFloat(elemento.monto);
                    body.push(row);
                }

            return {head:head,body:body,total:total};
        },

        tablaIngresosNegocio(egresos){
                var head = [["Negocio","Fecha","Total","Negocio","Empresa"]];
                var body = [];
                var total = 0.00;
                for (var i = 0; i < egresos.length; i++) {
                    var elemento = egresos[i]; 
                    var row = [elemento.negocio.nombre,elemento.fecha,elemento.total,elemento.parte_negocio,elemento.parte_empresa];
                    total += parseFloat(elemento.parte_empresa);
                    body.push(row);
                }

            return {head:head,body:body,total:total};
        },

        tablaReportes(reportes){
                var head = [["Asunto","Fecha","Cliente","Status","Costo"]];
                var body = [];
                var total = 0.00;
                for (var i = 0; i < reportes.length; i++) {
                    var elemento = reportes[i]; 
                    var row = [elemento.asunto,elemento.fecha,elemento.cliente.FullName,elemento.estado.estado,elemento.costo];
                    total += parseFloat(elemento.costo);
                    
                    body.push(row);
                }
            return {head:head,body:body,total:total};
        },

        tablaActividades(actividades){
                var head = [["Trabajo","Fecha","Personal","Costo"]];
                var body = [];
                var total = 0.00;
                for (var i = 0; i < actividades.length; i++) {
                    var elemento = actividades[i]; 
                    var row = [elemento.trabajo,elemento.fecha,elemento.personal.FullName,elemento.costo];
                    total += parseFloat(elemento.costo);
                    
                    body.push(row);
                }
            return {head:head,body:body,total:total};
        },

        tablaInstalaciones(instalaciones){
                var head = [["Cliente","Zona","Fecha","Costo"]];
                var body = [];
                var total = 0.00;
                for (var i = 0; i < instalaciones.length; i++) {
                    var elemento = instalaciones[i]; 
                    var row = [elemento.FullName,elemento.zona.nombre,elemento.fecha_instalacion,elemento.costo_instalacion];
                    total += parseFloat(elemento.costo_instalacion);
                    
                    body.push(row);
                }
            return {head:head,body:body,total:total};
        },
        tablaPagos(datos){
                var head = [["Concepto pago","Total"]];
                var body = [];
                var totalpendientes = 0.00;
                var totalLiquidados = 0.00;
                var totalPromesas = 0.00;
                var pendientes = datos.pagosPendientes;
                var liquidados = datos.pagosLiquidados;
                var promesas = datos.pagosPromesas;
                for (var i = 0; i < pendientes.length; i++) {
                    var elemento = pendientes[i];
                    var monto = parseFloat(elemento.monto); 
                    var descuento = parseFloat(elemento.descuento); 
                    var extra = parseFloat(elemento.extra); 

                    totalpendientes += parseFloat(monto);
                   
                }

                for (var i = 0; i < liquidados.length; i++) {
                    var elemento = liquidados[i];
                    var monto = parseFloat(elemento.monto); 
                    var descuento = parseFloat(elemento.descuento); 
                    var extra = parseFloat(elemento.extra); 

                    totalLiquidados += parseFloat(monto);
                   
                }

                for (var i = 0; i < promesas.length; i++) {
                    var elemento = promesas[i];
                    var monto = parseFloat(elemento.monto); 
                    var descuento = parseFloat(elemento.descuento); 
                    var extra = parseFloat(elemento.extra); 

                    totalPromesas += parseFloat(monto);
                   
                }
                var row = ["Pagos pendientes",Common.formatMoney(totalpendientes,2)];
                var row2 = ["Pagos Liquidados",Common.formatMoney(totalLiquidados,2)];
                var row3 = ["Pagos Promesas",Common.formatMoney(totalPromesas,2)];
                var row4 = ["total",Common.formatMoney(totalPromesas+totalLiquidados+totalpendientes,2)];

                body.push(row);
                body.push(row2);
                body.push(row3);
                body.push(row4);

            return {head:head,body:body,total:totalLiquidados};
        }
    }

};
</script>