<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12 md12>
            <v-dialog v-model="dialog" max-width="350px" persistent>
              <v-card>
                <v-container grid-list-md>
                  <v-card-title primary-title> Confirma tú email </v-card-title>
                  <v-card-subtitle primary-subtitle>
                    Se envio un código de confirmación a tu correo electronico
                    revisa en tu bandeja de entrada o en la parte de spam
                  </v-card-subtitle>
                  <v-form
                    dense
                    ref="form"
                    lazy-validation
                    @submit.prevent="confirmar()"
                    v-model="valid"
                    enctype="multipart/form-data"
                  >
                    <v-card-text>
                      <v-layout row wrap> </v-layout>
                      <v-layout row wrap>
                        <v-text-field
                          v-model="codigo"
                          label="Introduce el código"
                          type="number"
                          outlined
                          :rules="generalRegla"
                        ></v-text-field>
                      </v-layout>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="reenviar()" text>Reenviar correo</v-btn>
                      <v-btn
                        :disabled="!valid"
                        type="submit"
                        color="success"
                        class="rounded-pill pa-3"
                        >Validar</v-btn
                      >
                    </v-card-actions>
                  </v-form>
                </v-container>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
export default {
  data() {
    return {
      dialog: true,
      codigo: "",
      valid: true,
      codigoEnviado: 0,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
    };
  },
  mounted() {
    this.codigoEnviado = Math.floor(Math.random() * 99999);
    this.reenviar();
  },
  computed: {},
  methods: {
    confirmar() {
      if (this.$refs.form.validate()) {
        if (this.codigo == this.codigoEnviado) {
          let dispatch = "UsersModule/OnUpdate";
          let endpoint = "usuario/validar/correo";
          this.$store
            .dispatch(dispatch, {
              url: endpoint,
            })
            .then((result) => {});
        } else {
          this.codigo = "";
        }
      }
    },
    reenviar() {
      this.$store
        .dispatch("UsersModule/OnSave", {
          url: "enviar/correo/verificacion",
          data: {
            codigo_enviar: this.codigoEnviado,
          },
        })
        .then((result) => {});
    },
  },
};
</script>