<template>
  
      <div>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-container grid-list-md>
              <v-card-title primary-title> Mensaje WhatsApp </v-card-title>
              <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  dense
                  @submit.prevent="enviar"
                >
                <v-card-text>
                  
                  <v-layout row wrap>
                    <v-flex xs3>
                      <v-text-field
                        label="Lada"
                        v-model="lada"
                        :rules="generalRegla"
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs9>
                      <v-text-field
                        label="Numero celular"
                        v-model="celular"
                        :rules="generalRegla"
                        outlined
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-textarea
                    label="Mensaje"
                    v-model="mensaje"
                    :rules="generalRegla"
                    rows="4"
                    auto-grow
                    outlined
                  ></v-textarea>
                </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">Cancelar</v-btn>
                    <v-btn
                      type="submit"
                      class="rounded-pill pa-3"
                      :disabled="!valid"
                      color="primary"
                      >Procesar</v-btn
                    >
                  </v-card-actions>
                </v-form>
            </v-container>
          </v-card>
        </v-dialog>
      </div>
   
</template>
<script>
import Common from "@/js/Common";
import LocalStorage from "@/js/LocalStorage.js";

export default {
  data() {
    return {
      valid: true,
      dialog: false,
      mensaje: "",
      celular: null,
      lada: "+52",
      generalRegla: [(v) => !!v || "Se necesita el campo"],
    };
  },
  mounted() {
    var lada = LocalStorage.get("LADA");
    if (lada) {
      this.lada = lada;
    }
  },
  computed: {},
  methods: {
    cargarDatos(item) {
      this.celular = item;
      this.dialog = true;
    },
    enviar() {
      let url =
        "https://api.whatsapp.com/send?phone=" +
        this.lada +
        this.celular +
        "&text=" +
        this.mensaje;
      window.open(url, "_blank");
      LocalStorage.set("LADA", this.lada);
      this.dialog = false;
    },
  },
};
</script>