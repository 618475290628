<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <v-flex xs12 md12>
          <v-dialog v-model="dialog" max-width="800" persistent>
            <v-card>
              <v-container grid-list-md>
                <v-card-title class="display-1" primary-title>
                  <strong>{{ data.id ? "Editar" : "Agregar" }}</strong>
                </v-card-title>
                <v-card-text>
                  <v-form ref="form" v-model="valid" lazy-validation dense>
                    <v-container grid-list-md>
                      <v-layout row wrap>
                        <v-flex xs12>
                          <v-text-field
                            v-model="data.title"
                            :rules="generalRegla"
                            label="Titulo"
                            required
                            prepend-icon="mdi-pen"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            v-model="data.content"
                            :rules="generalRegla"
                            label="Contenido"
                            required
                            prepend-icon="mdi-file-document"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-select
                            v-model="data.roles"
                            :items="roles"
                            label="Rol del personal"
                            item-value="id"
                            item-text="name"
                            :rules="generalRegla"
                            no-data-text="No hay elementos registrados"
                            prepend-icon="mdi-account-multiple"
                            multiple
                          >
                          </v-select>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="cancelar()">Cancelar</v-btn>
                  <v-btn
                    :disabled="!valid"
                    class="rounded-pill pa-3"
                    color="primary"
                    @click="actualizar()"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-card>
          </v-dialog>
        </v-flex>
      </div>
    </base-component>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {},
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      dialog: false,
      roles: [],
    };
  },
  mounted() {
    this.getDatos();
  },
  methods: {
    getDatos() {
      let dispatch = "UsersModule/OnGet";
      let enpoint = "notificacion/get/roles";
      this.$store
        .dispatch(dispatch, {
          url: enpoint,
        })
        .then((result) => (this.roles = result.data));
    },
    actualizar() {
      if (this.$refs.form.validate()) {
        let dispatch = `UsersModule/${this.data.id ? "OnUpdate" : "OnSave"}`;
        let endpoint = `notification-type/${this.data.id || ""}`;
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.data,
          })
          .then((result) => {
            this.dialog = false;
            this.resolve(result.data);
          });
      }
    },
    limpiar() {
      this.$refs.form.reset();
    },
    cargarDatos(elemento) {
      this.data = elemento;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    cancelar() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>