<template>
    <div class="component">
        <base-component>
            <div slot="component">
                <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
                <v-container grid-list-md>
                    
                <v-layout row wrap>
                <v-flex xs12 md6>
                <v-card v-if="actividad">
                    <v-list-item three-line>
                                
                                    <v-list-item-content>
                                        <v-list-item-title class="headline mb-4">{{actividad.trabajo}}</v-list-item-title>
                                        <v-list-item-subtitle>{{actividad.direccion}}, {{actividad.colonia}}, {{actividad.municipio}}</v-list-item-subtitle>
                                        <v-list-item-subtitle>{{actividad.codigo_postal}}, {{actividad.estado}}</v-list-item-subtitle>
                                        <v-list-item-subtitle>Personal: {{actividad.personal.nombre}} Celular: {{actividad.personal.apellido_paterno}} {{actividad.personal.apellido_materno}}</v-list-item-subtitle>
                                        <v-list-item-subtitle>Celular personal: {{actividad.personal.celular}}</v-list-item-subtitle>
                                        
                                        <v-list-item-subtitle>Fecha: <v-chip class="ma-2" color="primary">
                                            {{actividad.fecha}}
                                        </v-chip>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                             Hora inicio: <v-chip class="ma-2" color="accent">
                                            {{actividad.hora_inicio}}
                                        </v-chip> 
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                            Hora fin: <v-chip class="ma-2" color="accent">
                                            {{actividad.hora_fin}}
                                        </v-chip>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>Asigando por: 
                                            {{actividad.asignado.nombre+' '+actividad.asignado.apellido_paterno+' '+actividad.asignado.apellido_materno}}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>Creacion: 
                                            {{actividad.created_at}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    
                                </v-list-item>
                       
                                
                </v-card>
                </v-flex>
                <v-flex xs12 md6>
                    <v-card>
                        <mapa v-if="actividad" ref="mapa"></mapa>                      
                    </v-card>
                </v-flex>
                </v-layout>
                <v-layout row wrap>
                    
                <v-flex xs12 md12>
                    <v-card v-if="actividad">
                        <v-card-title primary-title>
                            Detalles
                        </v-card-title>
                        <v-container grid-list-md>
                            
                        <v-card-text>
                            <v-layout row wrap>
                                
                                <v-flex xs12 md6>
                                    <h5 class="headline-5">Detalles:</h5>
                                     {{actividad.detalles}}
                                </v-flex>
                            
                                <v-flex xs12 md6>
                                   <h5 class="headline-5"> Observaciones:</h5>
                                        {{actividad.observaciones}}
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex xs12 md12>
                                     <h5 class="headline-5">
                                         Materiales:</h5>
                                     <p>{{actividad.materiales}}</p>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                        </v-container>

                    </v-card>
                </v-flex>
                </v-layout>
                </v-container>

            </div>
        </base-component>
    </div>
</template>
<script>
import mapa from '@/js/components/mapa/mapaComponent.vue'
export default {
    components:{
        mapa,
        
    },
    data() {
        return {
            actividad:null,
        };
    },
    mounted() {
        var arreUrl = window.location.href.split('/');
        this.show(arreUrl.pop());
    },
    computed: {
        
    },
    methods: {
        show(id){
            
            this.$store.dispatch('UsersModule/OnGet', {
                url: `actividad/`+id,
                data: {}

            }).then(result => {
                
                this.actividad = result.data.actividad;
                this.$store.dispatch('showActividad',result.data.actividad);
                ///this.$refs.mapa.ponerPuntero(result.data.actividad);
                    
            })
        }
    }

};
</script>