<template>
  <div>
    <v-card flat>
      <v-container grid-list-md>
        <v-layout row wrap>
          <v-spacer></v-spacer>
          <v-text-field
            solo
            class="rounded-pill"
            v-model="search"
            @keyup.enter="getDatos"
            append-icon="mdi-magnify"
            label="Buscador"
            single-line
            hide-details
          ></v-text-field>
        </v-layout>
      </v-container>
      <v-divider></v-divider>
      <v-data-table
        dense
        :search="search"
        :headers="headers"
        :items="servicios"
        :footer-props="{
          firstIcon: 'mdi-format-horizontal-align-left',
          lastIcon: 'mdi-format-horizontal-align-right',
          prevIcon: 'mdi-chevron-left',
          nextIcon: 'mdi-chevron-right',
          itemsPerPageAllText: 'Todos',
          itemsPerPageText: 'Registros por página:',
          itemsPerPageOptions: [3, 5, 10, 15, -1],
        }"
      >
        <template v-slot:no-results>
          <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
            >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
          >
        </template>
        <template v-slot:item.costo="{ item }">
          <strong class="orange--text">{{ formatoDinero(item.costo) }}</strong>
        </template>
        <template v-slot:item.subida="{ item }">
          <div v-if="item.subida">
            <v-icon class="ml-2" color="blue"
              >mdi-arrow-up-bold-circle-outline</v-icon
            >
            <strong class="ml-2">{{ item.subida }} </strong> Mb.
          </div>
        </template>
        <template v-slot:item.bajada="{ item }">
          <div v-if="item.bajada">
            <v-icon class="ml-2" color="pink"
              >mdi-arrow-down-bold-circle-outline</v-icon
            >
            <strong class="ml-2">{{ item.bajada }} </strong> Mb.
          </div>
        </template>
        <template v-slot:item.opciones="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn @click="detalles(item)" icon v-on="on">
                <v-icon small>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <span>Detalles</span>
          </v-tooltip>
          <v-tooltip v-if="administracion == 2" top>
            <template v-slot:activator="{ on }">
              <v-btn @click="cambiarPerfil(item)" icon v-on="on">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip v-else top>
            <template v-slot:activator="{ on }">
              <v-btn @click="cambiar(item)" icon v-on="on">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>

        <template v-slot:no-data>
          <v-alert :value="true" color="red" dark icon="mdi-alert-circle">
            <v-row align="center">
              <v-col> Ningún dato que mostrar :( </v-col>
              <v-col class="shrink">
                <v-btn @click="getDatos()"
                  ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                >
              </v-col>
            </v-row>
          </v-alert>
        </template>
      </v-data-table>
    </v-card>
    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <cambiarServicio ref="cambiarServicio"></cambiarServicio>
    <cambiarPerfil ref="cambiarPerfil"></cambiarPerfil>
  </div>
</template>

<script>
import Common from "@/js/Common.js";
import cambiarServicio from "@/js/components/clientes/detalles/servicios/cambiarServicio.vue";
import cambiarPerfil from "@/js/components/clientes/detalles/servicios/cambiarPerfil.vue";
export default {
  components: {
    cambiarServicio,
    cambiarPerfil,
  },
  data() {
    return {
      search: "",
      options: {},
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      headers: [
        { text: "Nombre", value: "nombre", sortable: true },
        { text: "Costo", value: "costo" },
        { text: "Servicio", value: "tipo.tipo", sortable: false },
        { text: "Subida", value: "subida", sortable: true },
        { text: "Bajada", value: "bajada", sortable: true },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      servicios: [],
      id: null,
      administracion: 1,
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.id = arreUrl.pop();
    this.getDatos();
  },
  watch: {},
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "servicios/contratados/cliente/" + this.id,
          data: {},
        })
        .then((result) => {
          this.servicios = result.data.servicios;
          this.administracion = result.data.administracion;
        });
    },
    cambiar(elemento) {
      this.$refs.cambiarServicio.esperarDatos().then((confirm) => {
        if (confirm) {
          this.servicios.splice(this.servicios.indexOf(elemento), 1, confirm);
        }
      });
    },
    cambiarPerfil(elemento) {
      this.$refs.cambiarPerfil.esperarDatos().then((confirm) => {
        if (confirm) {
          this.servicios.splice(this.servicios.indexOf(elemento), 1, confirm);
        }
      });
    },
    formatoDinero(cantidad) {
      return Common.formatMoney(cantidad);
    },
    detalles(item) {
      window.location.href = "vista/detalles/servicio/" + item.id;
    },
  },
};
</script>
