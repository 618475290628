<template>
  <div>
    <v-alert
      :value="datos.show"
      :type="datos.tipo"
      transition="scale-transition"
      elevation="5"
      prominent
      dense
    >
      <v-row align="center">
        <v-col class="grow">
          {{ datos.mensaje }}
        </v-col>
        <v-col v-if="datos.show" class="shrink">
          <v-btn @click="verDetalles()" class="ma-2" text>ver</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-dialog v-model="datos.dialog" persistent max-width="380">
      <v-card dark :color="datos.tipo">
        <v-container grid-list-md>
          <v-card-title primary-title>
            <strong>Detalles</strong>
            <v-spacer></v-spacer>
            <v-icon @click="cerrarDialog()">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            {{ datos.mensaje }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="ayuda()" text>Ayuda</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    datos: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hide: false,
      dialog: false,
    };
  },
  mounted() {
    if (this.datos.tiempo) {
      this.delay(this.datos.tiempo * 1000);
    } else {
      this.delay(3000);
    }
    if (this.datos.dialog) {
      this.verDetalles();
    }
  },
  methods: {
    ...mapActions("notificaciones", [
      "remove",
      "detallesNotificacion",
      "cerrarNotificacion",
    ]),
    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    async delay(ms) {
      await this.sleep(ms);
      if (this.datos.dialog == true) {
        this.cerrarNotificacion(this.datos);
      } else {
        this.remove(this.datos);
      }
    },
    verDetalles() {
      this.cerrarNotificacion(this.datos);
      this.detallesNotificacion(this.datos);
    },

    cerrarDialog() {
      this.remove(this.datos);
    },
    ayuda() {
      let url =
        "https://api.whatsapp.com/send?phone=+529511452239&text=Hola%20Oscar,%20necesito%20ayuda%20con%20esto:%20" +
        this.datos.mensaje;
      window.open(url, "_blank");
    },
  },
};
</script>
<!--
<style lang="scss" scoped>
.contenedor {
  position: fixed;
  bottom: 20;
  right: 10;
  padding-right: 40px;
}
</style>
-->