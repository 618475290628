<template>
  <div>
    <v-dialog v-model="dialog" max-width="400px" persistent>
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title class="headline">
            <strong>Liquidado!</strong>
            <v-spacer></v-spacer>
            <v-icon @click="cerrarAviso()">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-img
              class="ma-auto"
              height="180"
              width="90"
              onload="this.stop()"
              src="img/success.gif"
            ></v-img>
            <v-layout row wrap>
              <strong>Opciones:</strong>
            </v-layout>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="recibo()" dark class="rounded-pill pa-3" color="red"
              ><v-icon mr-2>mdi-file-pdf-box</v-icon>Recibo</v-btn
            >
            <v-btn
              @click="ticketDeCalor()"
              dark
              class="rounded-pill pa-3"
              color="blue"
            >
              <v-icon mr-2>mdi-printer</v-icon>Ticket</v-btn
            >
            <v-btn
              @click="enviarNotificacionLiquidados()"
              dark
              class="rounded-pill pa-3"
              color="success"
            >
              <v-icon mr-2>mdi-whatsapp</v-icon>Notificar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <aviso_whats ref="aviso_whats"></aviso_whats>
  </div>
</template>

<script>
import aviso_whats from "@/js/components/pagos/registrar_pago/aviso_whatsapp.vue";
import jsPDF from "jspdf";
import Comun from "@/js/Common.js";
import "jspdf-autotable";

export default {
  components: {
    aviso_whats,
  },
  data() {
    return {
      dialog: false,
      pagosLiquidados: [],
      cliente: null,
    };
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    mostrar(cliente, pagos) {
      this.pagosLiquidados = pagos;
      this.cliente = cliente;
      this.dialog = true;
    },
    ticketDeCalor() {
      let ids = "";
      for (let i = 0; i < this.pagosLiquidados.length; i++) {
        let id = this.pagosLiquidados[i].id;
        if (i == 0) {
          ids += id;
        } else {
          ids += "," + id;
        }
      }
      let url = "tickets/pagos/liquidados/" + ids;
      window.open(url, "_blank");
    },
    enviarNotificacionLiquidados() {
      this.$refs.aviso_whats.mostrar(this.cliente, this.pagosLiquidados, true);
    },
    cerrarAviso() {
      this.pagosLiquidados = [];
      this.dialog = false;
    },

    recibo() {
      var item = {
        cliente: this.cliente,
      };

      let hoy = new Date().toISOString().substr(0, 10);
      let pdfName = "Recibo-" + Comun.formatoFecha(hoy);
      var doc = new jsPDF();

      var nombre =
        item.cliente.nombre +
        " " +
        item.cliente.apellido_paterno +
        " " +
        item.cliente.apellido_materno;
      var direccion = item.cliente.direccion;
      var colonia = item.cliente.colonia;
      var municipio = item.cliente.municipio;
      var estado = "Liquidado";
      var status = 1;
      var logo = this.pagosLiquidados[0].logo
        ? this.pagosLiquidados[0].logo
        : "";

      doc.setFontSize(12);
      var lMargin = 15; //left margin in mm
      var rMargin = 15; //right margin in mm
      var pdfInMM = 210; // width of A4 in mm
      var pageCenter = pdfInMM / 2;

      var img1 = new Image();
      img1.src = "img/recursos/tune.png";
      doc.addImage(img1, "png", 0, -2, 290, 20);
      if (logo) {
        var img2 = new Image();
        img2.src = logo;
        doc.addImage(img2, "png", 15, 25, 20, 20);
      }

      doc.setFont("helvetica");
      doc.setFontSize(15);

      var cabecera = "RECIBO DE PAGO";
      var linea = doc.splitTextToSize(cabecera, pdfInMM - lMargin - rMargin);
      doc.text(linea, pageCenter, 40, "center");

      doc.setFontSize(14);
      doc.text(15, 58, "Cliente: ");

      doc.setFontSize(12);

      doc.text(15, 65, nombre);
      doc.text(15, 70, direccion + ", " + colonia);
      doc.text(15, 75, municipio);
      doc.text(135, 65, "Estado: " + estado);

      doc.setLineWidth(1);
      doc.setDrawColor(0);
      if (status == 1) {
        doc.setFillColor(0, 255, 0);
      }
      if (status == 2) {
        doc.setFillColor(255, 0, 0);
      }
      if (status == 3) {
        doc.setFillColor(139, 0, 255);
      }
      doc.circle(150, 80, 10, "FD");

      //doc.setFontSize(14);
      doc.text(
        15,
        90,
        "Fecha de Liquidacion: " +
          this.pagosLiquidados[0].fecha +
          " " +
          this.pagosLiquidados[0].hora
      );
      doc.text(15, 95, "Forma de pago: " + this.pagosLiquidados[0].tipo.tipo);
      doc.text(15, 100, "Cajero: " + this.pagosLiquidados[0].persona.FullName);

      var head = [["servicio", "Mes y año", "Descuento", "Extra", "Pago"]];
      var body = [];
      var total = 0;
      for (let index = 0; index < this.pagosLiquidados.length; index++) {
        const pago = this.pagosLiquidados[index];
        total = total + parseFloat(pago.monto);
        var row = [
          pago.servicio.nombre,
          pago.mes.mes + " " + pago.anio,
          Comun.formatMoney(pago.descuento),
          Comun.formatMoney(pago.extra),
          Comun.formatMoney(pago.monto),
        ];
        body.push(row);
      }

      doc.autoTable({
        head: head,
        body: body,
        startY: 120,
        bodyStyles: {
          valign: "top",
        },
        styles: {
          rowPageBreak: "auto",
          overflow: "linebreak",
        },
        columnStyles: {
          text: {
            cellWidth: "wrap",
          },
        },
        margin: { left: 15, right: 15 },
      });
      doc.setFontSize(14);
      let finalY = doc.autoTable.previous.finalY;
      doc.text(160, finalY + 6, "Total: " + Comun.formatMoney(total));
      doc.setFontSize(12);
      var empresa = "¡GRACIAS POR SU PREFERENCIA!";
      var linea_empresa = doc.splitTextToSize(
        empresa,
        pdfInMM - lMargin - rMargin
      );
      doc.text(linea_empresa, pageCenter, finalY + 30, "center");
      doc.setFontSize(10);
      var empresa = "-- " + this.pagosLiquidados[0].empresa.nombre + " --";
      var linea_empresa = doc.splitTextToSize(
        empresa,
        pdfInMM - lMargin - rMargin
      );
      doc.text(linea_empresa, pageCenter, finalY + 45, "center");

      var empresa = this.pagosLiquidados[0].empresa.direccion;
      var linea_empresa = doc.splitTextToSize(
        empresa,
        pdfInMM - lMargin - rMargin
      );
      doc.text(linea_empresa, pageCenter, finalY + 50, "center");

      var empresa = "Cel: " + this.pagosLiquidados[0].empresa.telefono;
      var linea_empresa = doc.splitTextToSize(
        empresa,
        pdfInMM - lMargin - rMargin
      );
      doc.text(linea_empresa, pageCenter, finalY + 55, "center");
      doc.save(pdfName + ".pdf");
    },
  },
};
</script>