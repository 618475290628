var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"rounded-pill ma-1",attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.nuevo()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("\n                  Agregar\n                ")],1)],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"rounded-pill",attrs:{"dense":"","append-icon":"mdi-magnify","label":"Buscador","solo":"","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDatos($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-data-table',{attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.elementos,"options":_vm.options,"server-items-length":_vm.totalElementos,"loading":_vm.loading,"footer-props":{
              showFirstLastPage: true,
              firstIcon: 'mdi-format-horizontal-align-left',
              lastIcon: 'mdi-format-horizontal-align-right',
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right',
              itemsPerPageAllText: 'Todos',
              itemsPerPageText: 'Registros por página:',
              itemsPerPageOptions: [10, 500, 1000, 5000, -1],
            }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"lime lighten-1","icon":"mdi-alert"}},[_vm._v("Tu búsqueda \""+_vm._s(_vm.search)+"\" no se encuentra.")])]},proxy:true},{key:"item.id",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n              "+_vm._s(_vm.elementos
                  .map(function (x) {
                    return x.id;
                  })
                  .indexOf(item.id) + 1)+"\n            ")]}},{key:"item.cliente",fn:function(ref){
                  var item = ref.item;
return [(item.cliente)?_c('v-chip',{attrs:{"outlined":"","dark":"","color":"cyan"},on:{"click":function($event){return _vm.abrirCliente(item.cliente)}}},[_vm._v(_vm._s(item.cliente.FullName))]):_vm._e()]}},{key:"item.monto",fn:function(ref){
                  var item = ref.item;
return [_vm._v("\n              $"+_vm._s(item.monto)+"\n            ")]}},{key:"item.descuento",fn:function(ref){
                  var item = ref.item;
return [_c('p',{staticClass:"success--text"},[_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-arrow-up-bold-circle-outline")]),_vm._v("$"+_vm._s(item.extra)+"\n              ")],1),_vm._v(" "),_c('p',{staticClass:"red--text"},[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v("mdi-arrow-down-bold-circle-outline")]),_vm._v("$"+_vm._s(item.descuento)+"\n              ")],1)]}},{key:"item.correspondencia",fn:function(ref){
                  var item = ref.item;
return [_vm._v("\n              "+_vm._s(item.mes.mes)),_c('br'),_vm._v(_vm._s(item.anio)+"\n            ")]}},{key:"item.estado",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{attrs:{"dense":"","small":"","text-color":"white","color":_vm.calcularColor(item.status_pago_id)}},[_vm._v(_vm._s(item.estado.status))])]}},{key:"item.retraso",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","dark":"","color":parseInt(item.retraso) == 0 ? 'green' : 'red'}},[_vm._v(_vm._s(item.retraso))])]}},{key:"item.fecha",fn:function(ref){
                  var item = ref.item;
return [(item.fecha)?_c('v-chip',{staticClass:"mb-1",attrs:{"small":"","outlined":"","color":"primary"}},[_vm._v(_vm._s(item.fecha))]):_vm._e(),_vm._v(" "),(item.hora)?_c('v-chip',{attrs:{"small":"","outlined":"","color":"secondary"}},[_vm._v(_vm._s(item.hora))]):_vm._e()]}},{key:"item.cajero",fn:function(ref){
                  var item = ref.item;
return [(item.persona)?_c('v-chip',{attrs:{"outlined":"","dark":"","color":"pink"}},[_vm._v(_vm._s(item.persona.nombre))]):_vm._e()]}},{key:"item.opciones",fn:function(ref){
                  var item = ref.item;
return [(item.eliminado == false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.detalles(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Ver detalles")])]):_vm._e(),_vm._v(" "),(item.status_pago_id == 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.ticketCalor(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-printer")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Ticket")])]):_vm._e(),_vm._v(" "),(item.eliminado == false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.recibo(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-document")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Recibo")])]):_vm._e(),_vm._v(" "),(item.eliminado == false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.editar(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Editar")])]):_vm._e(),_vm._v(" "),(item.eliminado == false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.eliminar(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Eliminar")])]):_vm._e(),_vm._v(" "),(item.eliminado)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dense":"","small":"","color":item.eliminado == true ? 'red' : 'green',"fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.restaurar(item)}}},on),[_c('v-icon',[_vm._v("mdi-backup-restore")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Restaurar")])]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"red","dark":"","icon":"mdi-alert-circle"}},[_c('v-row',[_c('v-col',[_vm._v(" Ningún dato que mostrar :( ")]),_vm._v(" "),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{on:{"click":function($event){return _vm.getDatos()}}},[_c('v-icon',{attrs:{"pl-4":""}},[_vm._v("mdi-cached")]),_vm._v(" Refrescar")],1)],1)],1)],1)]},proxy:true}])})],1)],1)],1)],1),_vm._v(" "),_c('formPago',{ref:"refPago"}),_vm._v(" "),_c('BaseConfirmacion',{ref:"confirmar"}),_vm._v(" "),_c('formEditarPago',{ref:"refEditarPago"}),_vm._v(" "),_c('exportar',{ref:"refExportar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }