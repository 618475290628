<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        
        <v-col cols="12" class="pt-0">
          <div style="height: 345px; width: 100%;">

      <l-map v-if="showMap" :zoom="zoom" :center="center" :options="mapOptions" @update:center="centerUpdate" style="border-radius: 20px;" class="shadow"
        @update:zoom="zoomUpdate">
        <l-tile-layer :url="url" :attribution="attribution" />
        <l-marker v-if="marker" :lat-lng.sync="marker" :icon="marker.icon" :draggable="arrastre">
          <l-tooltip :content="label" />
          <l-icon :icon-url="'img/' + icono" :icon-anchor="staticAnchor" :icon-size="[55, 55]" />
        </l-marker>
      </l-map>
    </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
 


</template>

<script>
import { latLng, icon } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LIcon } from "vue2-leaflet";

export default {
  name: "Example",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      marker: latLng(23.0000000, -102.0000000),
      zoom: 13,
      currentZoom: 11.5,
      center: latLng(23.0000000, -102.0000000),
      currentCenter: latLng(23.0000000, -102.0000000),
      mapOptions: {
        zoomSnap: 0.5
      },
      showMap: false,
      staticAnchor: [26, 47],
      latitudInicial: null,
      longitudInicial: null,
      icono: 'marker.png',
      label: 'Ubicación',
      arrastre: true,
    };
  },
  mounted() {

  },
  methods: {
    zoomUpdate(zoom) {

      this.currentZoom = zoom;
    },
    centerUpdate(center) {

      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    },
    mostrarMarker(latitud, longitud, icono, label, arrastre) {
      if (icono) {
        this.icono = icono;
      }
      if (label) {
        this.label = label;
      }
      this.arrastre = arrastre;
      if (latitud != 'null' && longitud != 'null' && latitud != null && longitud != null && latitud != '' && longitud != '') {
        this.marker = latLng(latitud, longitud);
        this.center = latLng(latitud, longitud);
        this.zoom = 16;
        this.currentZoom = 16;
        this.showMap = true;

      } else {
        this.getGps();
      }

    },
    getGps() {
      this.$store.dispatch("mostrarCarga", true);

      const error = (err) => {

        this.marker = latLng(23.0000000, -102.0000000);
        this.center = latLng(23.0000000, -102.0000000);
        this.zoom = 4.5;
        this.showMap = true;
        this.latitudInicial = 23.0000000;
        this.longitudInicial = -102.0000000;

        this.$store.dispatch("mostrarCarga", false);

      };
      const success = (position) => {
        this.marker = latLng(position.coords.latitude, position.coords.longitude);
        this.center = latLng(position.coords.latitude, position.coords.longitude);
        this.zoom = 16.5;
        this.showMap = true;
        this.latitudInicial = position.coords.latitude;
        this.longitudInicial = position.coords.longitude;
        this.$store.dispatch("mostrarCarga", false);

      };
      navigator.geolocation.getCurrentPosition(success, error);


    },
    getUbicacion() {
      if (this.marker.lat + '' == this.latitudInicial + '' && this.marker.lng + '' == this.longitudInicial + '') {
        return null;
      }
      return this.marker;
    },
    getLatitud() {
      if (this.marker.lat + '' == this.latitudInicial + '') {
        return null;
      }
      return this.marker.lat;
    },
    getLongitud() {
      if (this.marker.lng + '' == this.longitudInicial + '') {
        return null;
      }
      return this.marker.lng;
    }
  }
};
</script>