
class Empresa {
    constructor(){
        this.id = 0
        this.nombre = ""
        this.logo = []
             this.razon_social= ""
             this.tipo_identificacion= ""
             this.RFC_RUC_NIT = ""
             this.direccion= ""
             this.estado= ""
             this.municipio= ""
             this.colonia= ""
             this.longitud= ""
             this.latitud= ""
             this.email= ""
             this.retenciones= ""
             this.codigo_postal =""
             
    }
}

export default Empresa
