<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="elevation-0">
              <v-card-text>
                <p class="text-subtitle-2"><strong>Empresa</strong></p>
                <v-form
                  dense
                  ref="form"
                  v-model="valid"
                  enctype="multipart/form-data"
                  lazy-validation
                >
                  <v-layout row wrap>
                    <v-flex xs12 md3>
                      <v-text-field
                        v-model="empresa.nombre"
                        :rules="nombreReglas"
                        label="Nombre de la empresa"
                        required
                        filled
                        prepend-inner-icon="mdi-office-building"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-text-field
                        :rules="telefonoReglas"
                        label="Telefono de la empresa"
                        v-model="empresa.telefono"
                        type="number"
                        filled
                        prepend-inner-icon="mdi-phone"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-text-field
                        label="Correo de la empresa"
                        v-model="empresa.email"
                        filled
                        prepend-inner-icon="mdi-email"
                        :rules="emailRules"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-file-input
                        :rules="rulesFile"
                        accept="image/png, image/jpeg, image/bmp"
                        placeholder="Maximo 2MB"
                        v-model="logo"
                        prepend-icon=""
                        prepend-inner-icon="mdi-camera"
                        small-chips
                        filled
                        multiple
                        label="Logotipo"
                      ></v-file-input>
                    </v-flex>
                  </v-layout>
                  <p class="text-subtitle-2"><strong>Administrador</strong></p>
                  <v-layout row wrap>
                    <v-flex xs12 md3>
                      <v-text-field
                        v-model="persona.nombre"
                        :rules="nombreReglas"
                        label="Nombre"
                        required
                        filled
                        prepend-inner-icon="mdi-account"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-text-field
                        v-model="persona.apellido_paterno"
                        :rules="nombreReglas"
                        label="Apellido paterno"
                        required
                        filled
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-text-field
                        v-model="persona.apellido_materno"
                        label="Apellido materno"
                        :rules="generalRegla"
                        required
                        filled
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-file-input
                        :rules="rulesFile"
                        accept="image/png, image/jpeg, image/bmp"
                        placeholder="Maximo 2MB"
                        show-size
                        v-model="imagen"
                        filled
                        prepend-icon=""
                        prepend-inner-icon="mdi-camera"
                        small-chips
                        multiple
                        label="Imagen de usuario"
                      ></v-file-input>
                    </v-flex>
                  </v-layout>

                  <p class="text-subtitle-2">
                    <strong>Dirección empresa</strong>
                  </p>
                  <v-layout row wrap>
                    <v-flex xs12 md3>
                      <v-text-field
                        v-model="cp"
                        label="Código postal"
                        :rules="generalRegla"
                        filled
                        type="number"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-text-field
                        v-model="empresa.estado"
                        label="Estado"
                        filled
                        :rules="generalRegla"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-text-field
                        v-model="empresa.municipio"
                        label="Municipio"
                        filled
                        :rules="generalRegla"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex v-if="colonias.length != 0" xs12 md3>
                      <v-select
                        v-model="empresa.colonia"
                        :items="colonias"
                        item-text="nombre"
                        item-value="nombre"
                        label="Colonias"
                        filled
                        :menu-props="{ maxHeight: '160' }"
                        single-line
                      ></v-select>
                    </v-flex>
                    <v-flex v-if="colonias.length == 0" xs12 md3>
                      <v-text-field
                        v-model="empresa.colonia"
                        label="Colonia"
                        filled
                        :rules="generalRegla"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        v-model="empresa.direccion"
                        label="Dirección"
                        filled
                        required
                        :rules="generalRegla"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-switch
                        v-model="mostrar"
                        label="Datos Fiscales"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                  <v-expand-transition>
                    <v-card
                      outlined
                      class="mb-4 transition-fast-in-fast-out"
                      v-if="mostrar"
                    >
                      <v-card-subtitle>
                        Datos Fiscales (opcional)
                      </v-card-subtitle>

                      <v-card-text>
                        <v-layout row wrap>
                          <v-flex xs12 md3>
                            <v-text-field
                              v-model="empresa.razon_social"
                              label="Razón social"
                              filled
                              prepend-inner-icon="mdi-briefcase-account"
                              required
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md3>
                            <v-text-field
                              label="Tipo de identificacion"
                              v-model="empresa.tipo_identificacion"
                              filled
                              prepend-inner-icon="mdi-clipboard-account"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md3>
                            <v-text-field
                              label="RFC-RUC-NIT"
                              v-model="empresa.RFC_RUC_NIT"
                              filled
                              prepend-inner-icon="mdi-account-card-details"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md3>
                            <v-text-field
                              label="Retenciones"
                              filled
                              v-model="empresa.retenciones"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-expand-transition>

                  <mapa ref="mapa"></mapa>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="!valid"
                  class="rounded-pill pa-3"
                  color="primary"
                  @click="validate"
                  >Continuar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import Empresa from "./Empresa";
import mapa from "@/js/components/mapa/mapaComponent.vue";
import Persona from "./Persona";

export default {
  components: {
    mapa,
  },
  data() {
    return {
      empresa: new Empresa(),
      persona: new Persona(),
      valid: true,
      mostrar: false,
      nombreReglas: [(v) => !!v || "Se necesita el nombre"],
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      telefonoReglas: [(v) => !!v || "Se necesita el campo"],
      emailRules: [
        (v) => /.+@.+/.test(v) || v.length == 0 || "Introduce un correo valido",
      ],
      rulesFile: [
        (value) =>
          !value ||
          value.length == 0 ||
          value[0].size < 2000000 ||
          "Debe ser menor a 2 MB!",
      ],
      dialog_mapa: false,
      colonias: [],
      modal2: false,
      modal: false,
      cp: "",
      logo: null,
      imagen: null,
    };
  },
  mounted() {
    this.esperarComponente();
  },
  watch: {
    cp(val) {
      //this.getDatosApi(val);
      this.empresa.codigo_postal = val;
    },
    logo(val) {
      this.empresa.logo = val[0];
    },
    imagen(val) {
      this.persona.imagen = val[0];
    },
  },
  methods: {
    async esperarComponente() {
      await this.$nextTick();
      this.$refs.mapa.mostrarMarker(
        null,
        null,
        "markerEmpresa.png",
        "Mi wisp",
        true
      );
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.empresa.latitud = this.$refs.mapa.getLatitud();
        this.empresa.longitud = this.$refs.mapa.getLongitud();
        this.persona.cp = this.empresa.codigo_postal;
        this.telefono_persona = this.empresa.telefono;
        this.persona.direccion = this.empresa.direccion;
        this.persona.municipio = this.empresa.municipio;
        this.persona.estado = this.empresa.estado;
        this.persona.colonia = this.empresa.colonia;
        this.$store.dispatch("datosPaso1", {
          step: 2,
          empresa: this.empresa,
          persona: this.persona,
        });
      }
    },
    getDatosApi(cp) {
      if (cp.length == 5) {
        let dispatch = "UsersModule/OnGet";
        let endpoint = "datos/codigo/postal/" + cp;
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: {},
          })
          .then((result) => {
            if ([200, 201].includes(result.status)) {
              let datos = result.data.data;
              this.empresa.estado = datos.estado;
              this.empresa.municipio = datos.municipio;
              this.colonias = datos.colonias;
            }
          });
      }
    },
    guardarCoordenadas() {
      this.dialog_mapa = false;
      this.empresa.latitud = this.$store.getters.getCoordenadas.lat;
      this.empresa.longitud = this.$store.getters.getCoordenadas.lng;
    },
  },
};
</script>