<template>
  <div>
    <v-container grid-list-md>
      <v-layout v-if="antena" row wrap>
        <v-flex xs12 md6>
          <v-layout row wrap>
            <v-flex xs12 md6>
              <v-card
                dark
                :color="antena.status_antena_id ? 'green' : 'orange'"
                class="cool-xs"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <v-layout row wrap>
                        <h4
                          v-if="antena.status_antena_id"
                          class="headline-2 ma-1"
                        >
                          Estado: Conectado
                        </h4>
                        <h4 v-else class="headline-2 ma-1">
                          Estado: Desconectado
                        </h4>

                        <v-spacer></v-spacer>
                        <v-icon @click="editar()">mdi-pencil</v-icon>
                      </v-layout>
                    </v-list-item-subtitle>

                    <v-list-item-title
                      ><h3>
                        <a
                          v-if="antena.ip"
                          @click="abrirIp(antena.ip)"
                          target="_blank"
                          class="headline-2"
                          >IP: {{ antena.ip }}</a
                        >
                        <a v-else class="headline-2">IP: Pendiente</a>
                      </h3></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-flex>
            <v-flex xs12 md6>
              <v-card @click="caratulaRouter()" class="cool-xs">
                <v-card-text>
                  <v-layout row wrap>
                    <strong>WIFI SSID: </strong>
                    {{ antena.ssid_wifi }}
                  </v-layout>
                  <v-layout row wrap>
                    <strong>WIFI PASS: </strong>
                    {{ antena.contrasena_wifi }}
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12>
              <v-card
                @click="abrirZona(antena.zona_id)"
                color="deep-purple darken-1"
                dark
                class="cool-xs"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle
                      ><h4 class="headline-1">Zona:</h4>
                    </v-list-item-subtitle>
                    <v-list-item-title
                      ><h3>
                        <strong>{{ antena.zona.nombre }}</strong>
                      </h3></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-flex>
          </v-layout>
          <v-flex xs12>
            <v-card class="cool-xs">
              <v-list-item three-line>
                <v-list-item-avatar color="indigo" tile size="100" class="cool">
                  <img v-if="antena.modem.imagen" :src="antena.modem.imagen" />
                  <img v-else src="/img/noimagen.jpg" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="headline-2"
                    >Modem:</v-list-item-title
                  >
                  <v-list-item-title class="headline-2">{{
                    antena.modem.producto
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    ><p>
                      Marca: {{ antena.modem.marca }}
                    </p></v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="headline-2"
                    >Propietario:
                    {{ antena.propietario.propietario }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-flex>
          <v-flex xs12>
            <v-card
              @click="verMikrotik(antena.cliente.router_id)"
              class="cool-xs"
            >
              <v-list-item three-line>
                <v-list-item-avatar color="indigo" tile size="100" class="cool">
                  <img
                    v-if="antena.cliente.router.producto.imagen"
                    :src="antena.cliente.router.producto.imagen"
                  />
                  <img v-else src="/img/noimagen.jpg" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="headline-2"
                    >Mikrotik:</v-list-item-title
                  >
                  <v-list-item-title class="headline-2">{{
                    antena.cliente.router.nombre
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    ><p>
                      Ip: {{ antena.cliente.router.ip }}
                    </p></v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    v-if="antena.cliente.router.tipo_conexion == 1"
                    class="headline-2"
                    >Conexion: <strong>Ip publica</strong>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="antena.cliente.router.tipo_conexion == 2"
                    class="headline-2"
                    >Conexion: <strong>Script</strong>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="antena.cliente.router.tipo_conexion == 3"
                    class="headline-2"
                    >Conexion: <strong>Ninguna</strong>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-flex>
        </v-flex>
        <v-flex xs12 md6>
          <v-layout row wrap>
            <v-flex xs12>
              <v-card @click="verAntena(antena.id)" class="cool-xs">
                <v-img
                  v-if="antena.cpe.imagen"
                  :src="antena.cpe.imagen"
                  max-height="150"
                >
                  <v-chip dark class="ma-4">Antena</v-chip>
                </v-img>
                <v-img v-else src="/img/noimagen.jpg" max-height="150">
                  <v-chip dark class="ma-4"> Antena </v-chip>
                </v-img>
                <v-card-title primary-title>
                  {{ antena.cpe.producto }}
                </v-card-title>
                <v-card-subtitle>
                  {{ antena.cpe.marca }}
                </v-card-subtitle>
                <v-card-text>
                  <v-layout row wrap>
                    <strong>Administracion:</strong>
                    <p v-if="antena.administracion == 1">Simple queue</p>
                    <p v-if="antena.administracion == 2">PPPoe</p>
                    <p v-if="antena.administracion == 3">Ninguna</p>
                  </v-layout>
                  <v-layout v-if="antena.administracion == 2" row wrap>
                    <v-flex xs12 md12>
                      <v-card class="cool" outlined>
                        <v-card-title primary-title> Secret </v-card-title>
                        <v-card-text>
                          <v-list two-line>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-account-network</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  antena.secret
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Usuario</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          <v-list two-line>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="grey"
                                  >mdi-textbox-password</v-icon
                                >
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  antena.password_secret
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Password</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                    <v-flex xs12 md12>
                      <v-card class="cool" outlined>
                        <v-card-title primary-title> Perfil </v-card-title>
                        <v-card-subtitle>
                          {{ antena.perfil.name }}
                        </v-card-subtitle>
                        <v-card-text>
                          <v-list two-line>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon>mdi-network</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  antena.perfil.local_address
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Local address</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="grey">mdi-pool</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  antena.perfil.remote_address
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Remote address</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="grey">mdi-server</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  antena.perfil.servicio.nombre
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Servicio</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="grey">mdi-speedometer</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  "Subida:" +
                                  antena.perfil.servicio.subida +
                                  "Mb/Bajada: " +
                                  antena.perfil.servicio.bajada +
                                  "Mb"
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                  >Velocidad</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <editarAntena ref="refEditarAntena"></editarAntena>
  </div>
</template>
<script>
import editarAntena from "@/js/components/red/antenas/editarAntena.vue";
import jsPDF from "jspdf";
export default {
  components: {
    editarAntena,
  },
  data() {
    return {
      antena: null,
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    var id = arreUrl.pop();
    this.getDatos(id);
  },
  computed: {},
  methods: {
    getDatos(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `antena/detalles/cliente/` + id,
          data: {},
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.antena = result.data.antena;
          }
        });
    },
    editar() {
      let antena = Object.assign({}, this.antena);
      antena.perfil = null;
      antena.zona = null;
      antena.cpe = null;
      antena.ap = null;
      antena.modem = null;
      antena.propietario = null;
      antena.mikrotik = null;

      this.$refs.refEditarAntena.cargarDatos(antena).then((confirm) => {
        if (confirm) {
          this.antena = confirm;
        }
      });
    },

    colorStatus(id) {
      if (id == 1) {
        return "orange";
      }
      if (id == 2) {
        return "green";
      }
      if (id == 3) {
        return "red";
      }
    },
    caratulaRouter() {
      let pdfName = "caratula_router";
      var doc = new jsPDF();

      var ssid = this.antena.ssid_wifi;
      var pass = this.antena.contrasena_wifi;
      var telefono = "951-587-6229";

      var img1 = new Image();
      img1.src = "img/recursos/caratula.png";
      doc.addImage(img1, "png", 10, 10, 135, 85);

      doc.setFont("times", "bold");
      doc.setFontSize(7);

      doc.text(90, 42, ssid);
      doc.text(90, 55, pass);
      doc.text(90, 69, telefono);

      doc.save(pdfName + ".pdf");
    },
    abrirIp(ip) {
      window.open("https://" + ip, "_blank");
    },
    abrirZona(id) {
      document.location.href = "/detalles/zona/" + id;
    },
    verAntena(id) {
      document.location.href = "/detalles/antena/vista/" + id;
    },
    verMikrotik(id) {
      document.location.href = "/routers/router/" + id;
    },
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>