<template>
  <v-dialog v-model="dialog" max-width="400px">
    <v-card>
      <v-container grid-list-md>
        <v-card-title primary-title>
          Activar o desactivar cliente
        </v-card-title>
        <v-card-text>
          <v-form dense ref="form" v-model="valid" lazy-validation>
            <v-select
              :items="estados"
              v-model="status_id"
              label="Status"
              item-value="id"
              item-text="status"
              :rules="generalRegla"
              prepend-icon="mdi-power-settings"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelar()">Cancelar</v-btn>
          <v-btn
            :disabled="!valid"
            @click="actualizar()"
            class="rounded-pill pa-3"
            color="primary"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import Common from "@/js/Common";
export default {
  data() {
    return {
      valid: true,
      estados: [],
      status_id: "0",
      dilog: false,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      reject: null,
      resolve: null,
      cliente: null,
      dialog: false,
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `estados/todos`,
          data: {},
        })
        .then((result) => {
          this.estados = result.data.estados;
        });
    },
    cargarDatos(cliente) {
      this.cliente = cliente;
      this.status_id = cliente.status_cliente_id;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    cancelar() {
      this.dialog = false;
      this.resolve(false);
    },
    actualizar() {
      if (this.$refs.form.validate()) {
        let dispatch = "UsersModule/OnUpdate";
        let endpoint = `cambiar/estado/cliente/` + this.cliente.id;

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: { status_id: this.status_id, cliente: this.cliente },
          })
          .then((result) => {
            this.dialog = false;
            this.resolve(result.data.cliente);
          });
      }
    },
  },
};
</script>