<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12 md12>
            <v-card class="cool">
              <v-card-title primary-title
                ><strong>Detalles antena</strong>
              </v-card-title>
              <v-tabs v-model="tab" color="red" grow show-arrows>
                <v-tab>
                  <v-icon left>mdi-access-point-network</v-icon>
                  Antena
                </v-tab>
                <v-tab>
                  <v-icon left>mdi-access-point-network</v-icon>
                  Punto de acceso
                </v-tab>
                <v-tab>
                  <v-icon left>mdi-access-point-network</v-icon>
                  Graficas</v-tab
                >
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <datos ref="datos"></datos>
                </v-tab-item>
                <v-tab-item>
                  <punto_de_acceso ref="ap"></punto_de_acceso>
                </v-tab-item>
                <v-tab-item>
                  <graficas></graficas>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import datos from "@/js/components/red/antenas/detalles/datos/antena.vue";
import punto_de_acceso from "@/js/components/red/antenas/detalles/access_point/access_point.vue";
import graficas from "@/js/components/red/antenas/detalles/graficas/graficas.vue";
import LocalStorage from "@/js/LocalStorage.js";
export default {
  components: {
    graficas,
    datos,
    punto_de_acceso,
  },
  data() {
    return {
      tab: 0,
    };
  },
  watch: {
    tab(val) {
      LocalStorage.set("TAB_ACTUAL_ANTENA", val);
    },
  },
  mounted() {
    var actualTab = LocalStorage.get("TAB_ACTUAL_ANTENA");
    if (actualTab) {
      this.tab = actualTab;
    }
  },
  computed: {},
  methods: {},
};
</script>