<template>
    <div class="component">
        <base-component>
            <div slot="component">
                <div class="card">
                    <div class="card-header">
                        <i class="nav-icon icon-people"></i>
                        Usuarios
                    </div>
                    <div class="card-body">
                        <div class="row mb-4">
                            <div class="col-md-8 text-right">
                                <div class="input-group mb-3">
                                    <input v-model="search" @keyup.enter="onSearchParams" type="text" class="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1">
                                    <div class="input-group-append">
                                        <button @click="onSearchParams('button')" class="btn btn-secondary" type="button">
                                            <i v-if="search.length <= 0" class="fa fa-search"></i>
                                            <i v-if="search.length > 0" class="fa fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 text-right">
                                <button @click="onCreateItem" type="button" class="btn btn-primary">
                                    <i class="nav-icon icon-user"></i>
                                    Nuevo usuario
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <vuetable ref="vuetable"
                            :api-url="api"
                            :fields="fields"
                            pagination-path="meta"
                            :css="css.table"
                            :sort-order="sortOrder"
                            :multi-sort="true"
                            :append-params="moreParams"
                            @vuetable:pagination-data="onPaginationData">
                                <template slot="actions" slot-scope="props">
                                    <div class="component">
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <button @click="onAction('remove-item', props.rowData, props.rowIndex)" v-if="props.rowData.estatus == 'activo'" type="button" class="btn btn-warning">Desactivar</button>
                                            <button @click="onAction('remove-item', props.rowData, props.rowIndex)" v-if="props.rowData.estatus == 'inactivo'" type="button" class="btn btn-success">Activar</button>
                                            <button @click="onAction('edit-item', props.rowData, props.rowIndex)" type="button" class="btn btn-info" data-target="#usuarioModal">Editar</button>
                                        </div>
                                    </div>
                                </template>
                        </vuetable>
                    </div>

                    <div class="vuetable-pagination">
                        <vuetable-pagination-info ref="paginationInfo"
                            info-class="pagination-info"
                        ></vuetable-pagination-info>
                        <vuetable-pagination ref="pagination"
                            :css="css.pagination"
                            @vuetable-pagination:change-page="onChangePage"
                        ></vuetable-pagination>
                    </div>

                </div>
                <div class="modal fade" id="usuarioModal" tabindex="-1" role="dialog" aria-labelledby="usuarioModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="usuarioModalLabel"></h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <usuario-component ref="usuario"
                                    @onSavedOrUpdated="onSavedOrUpdated"
                                    :modulo="modulo"
                                    :id="id">
                                </usuario-component>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </base-component>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
    import Vuetable from 'vuetable-2'
    import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
    import Autocomplete from 'vuejs-auto-complete'
    import { log } from 'util'

    export default {
        props: {
            id: {
                type: String,
                default: ""
            },
            modulo: {
                type: String,
                default: 'usuarios'
            }
        },
        mounted() {
            
        },
        components: {
            Vuetable,
            VuetablePagination,
            VuetablePaginationInfo,
            Autocomplete
        },
        data: function(){
            return {
                source: `${this.modulo}?q=`,
                title: 'Nuevo Usuario',
                search: '',
                params: '',
                fields: [
                    {
                        name: 'display_name',
                        sortField: 'nombre',
                        title: 'Nombre'
                    },
                    {
                        name: 'role',
                        title: 'Rol'
                    },
                    {
                        name: 'email',
                        title: 'E-mail'
                    },
                    {
                        name: 'celular',
                        title: 'Teléfono'
                    },
                    {
                        name: 'estatus',
                        sortField: 'estatus',
                        title: 'Estatus'
                    },
                    {
                        name: '__slot:actions',   // <----
                        title: 'Acciones',
                        titleClass: 'text-center aligned',
                        dataClass: 'text-center aligned'
                    }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-hover table-bordered',
                        ascendingIcon: 'glyphicon glyphicon-chevron-up',
                        descendingIcon: 'glyphicon glyphicon-chevron-down'
                    },
                    pagination: {
                        wrapperClass: 'pagination',
                        activeClass: 'active',
                        disabledClass: 'disabled',
                        pageClass: 'page',
                        linkClass: 'link',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    },
                    icons: {
                        first: 'glyphicon glyphicon-step-backward',
                        prev: 'glyphicon glyphicon-chevron-left',
                        next: 'glyphicon glyphicon-chevron-right',
                        last: 'glyphicon glyphicon-step-forward',
                    },
                },
                sortOrder: [
                    { field: 'id', sortField: 'id', direction: 'desc'}
                ],
                moreParams: {}
            }
        },
        computed: {
           api(){
               
               return `${this.modulo}/table/data?search=${this.params}`
           }
        },
        methods: {
            formattedDisplay (result) {
                return  `${result.nombres} ${result.apellido_paterno} ${result.apellido_materno} [${result.email}]`
            },
            onSearchParams(sender){
                if(sender == 'button'){
                    this.search = ''
                }
                this.params = this.search
            },
            onCreateItem(){
                this.title = 'Nuevo Usuario'
                this.$refs.usuario.setCurrentItem(null)
                $('#usuarioModal').modal('show')
            },
            onPaginationData(paginationData){
                this.$refs.pagination.setPaginationData(paginationData)
                this.$refs.paginationInfo.setPaginationData(paginationData)
            },
            onChangePage (page) {
                this.$refs.vuetable.changePage(page)
            },
            onSavedOrUpdated(event){
                this.$refs.vuetable.refresh()
                $('#usuarioModal').modal('hide')

                let msg = `El usuario ha sido guardado correctamente`;
                switch (event) {
                    case 'update':
                        msg = `El usuario ha sido actualizado correctamente`;
                        break;
                    case 'delete':
                        msg = `El usuario ha sido actualizado correctamente`;
                        break;
                    default:
                        msg = ``;
                        break;
                }

                swal("Operación exitosa", msg, "success");
            },
            onAction (action, data, index) {
                
                
                
                if(action == 'edit-item'){
                    this.title = 'Editar Usuario',
                    this.$refs.usuario.setCurrentItem(data)
                    $('#usuarioModal').modal('show')
                }

                if(action == 'remove-item'){
                    if(data.estatus == 'activo'){
                        if(!confirm(`¿Está seguro de quiere desactivar el usuario ${data.display_name}`)){
                            return false;
                        }
                    }

                    let dispatch = 'UsersModule/OnDelete'
                    let endpoint = `${this.modulo}/${data.id}`
                    let event = 'delete'

                    this.$store.dispatch(dispatch, {
                        url: endpoint,
                        data: data
                    }).then(result => {
                        this.onSavedOrUpdated(event)
                    })
                }
            },
            onIncludeItemSearch(){
                $('#searchParticipanteModal').modal('show');
            },
            onSelectedItem(item){
                
            },
            onClearSelection(item){
                this.$refs.autocomplete.clear()
            },
            onCancelItemSearch(){
                $('#searchParticipanteModal').modal('hide');
                this.onClearSelection()
            }
        }
    }
</script>
