<template>
  <div>
    <v-dialog v-model="dialog" max-width="700" persistent>
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title class="display-1">
            <strong>Editar</strong>
          </v-card-title>
          <v-card-subtitle>
            <strong>Cliente:</strong>
            {{ pago.nombreCliente }}
          </v-card-subtitle>
          <v-form ref="form" v-model="valid" lazy-validation dense>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <v-select
                    :items="servicios"
                    v-model="pago.servicio_id"
                    item-text="nombre"
                    item-value="id"
                    label="Servicio contratados del cliente"
                    :rules="servicioRegla"
                    prepend-icon="mdi-server"
                    no-data-text="Sin elementos"
                  >
                    <template max-width="60" v-slot:item="{ item }">
                      <v-list-item-avatar
                        color="indigo"
                        class="headline font-weight-light white--text"
                      >
                        {{ item.nombre.charAt(0) }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.tipo.tipo
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle
                          >{{ item.bajada }}MB/{{
                            item.subida
                          }}MB</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex xs12 md6>
                  <v-select
                    :items="status"
                    v-model="pago.status_pago_id"
                    item-text="status"
                    item-value="id"
                    :rules="generalRegla"
                    label="status de pago"
                    prepend-icon="mdi-square-inc-cash"
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs12 md4>
                  <v-select
                    :items="meses"
                    v-model="pago.mes_id"
                    item-text="mes"
                    item-value="id"
                    :rules="generalRegla"
                    label="Mes"
                    filled
                    hint="mes al que corresponde el pago"
                    prepend-icon="mdi-calendar-blank"
                  ></v-select>
                </v-flex>
                <v-flex xs12 md4>
                  <v-select
                    :items="anios"
                    v-model="pago.anio"
                    :rules="generalRegla"
                    label="Año"
                    hint="año al que corresponde el pago"
                    filled
                  ></v-select>
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    label="Extra"
                    v-model="pago.extra"
                    type="number"
                    :rules="extraRegla"
                    prefix="$"
                    prepend-icon="mdi-cash"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    label="Descuento"
                    v-model="pago.descuento"
                    type="number"
                    :rules="descuentoRegla"
                    prefix="$"
                    prepend-icon="mdi-cash"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout v-if="pago.status_pago_id == 1" row wrap>
                <v-flex xs12 md4>
                  <v-select
                    :items="tipos"
                    v-model="pago.tipo_pago_id"
                    item-text="tipo"
                    item-value="id"
                    :rules="generalRegla"
                    label="Tipo de pago"
                    prepend-icon="mdi-cash-multiple"
                  >
                    <template v-slot:append-item>
                      <v-divider></v-divider>
                      <v-list-item @click="nuevoTipoPago()">
                        <v-list-item-avatar color="grey lighten-3">
                          <v-icon>mdi-plus</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Nuevo</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex xs12 md4>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="computedDateFormatted"
                        label="Pagado el dia"
                        prepend-icon="mdi-calendar"
                        readonly
                        :rules="generalRegla"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="es-mx"
                      v-model="pago.fecha"
                      no-title
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 md4>
                  <v-menu
                    ref="menu"
                    v-model="menu_reloj"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="pago.hora"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="pago.hora"
                        label="Hora del pago"
                        prepend-icon="mdi-av-timer"
                        readonly
                        :rules="generalRegla"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_reloj"
                      v-model="pago.hora"
                      full-width
                      @click:minute="$refs.menu.save(pago.hora)"
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 md12>
                  <v-textarea
                    v-model="pago.detalles"
                    rows="5"
                    label="Detalles"
                    filled
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelar()">Cancelar</v-btn>
            <v-btn
              :disabled="!valid"
              color="primary"
              class="rounded-pill pa-3"
              @click="actualizar()"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <nuevoTipoPago ref="tipo_pago"></nuevoTipoPago>
  </div>
</template>
<script>
import Common from "@/js/Common.js";
import nuevoTipoPago from "@/js/components/pagos/recursos/tablas/tipos_pagos/nuevoTipoPago.vue";

export default {
  data() {
    return {
      pago: {
        monto: null,
        descuento: "0",
        extra: "0",
        detalles: "",
        fecha: "",
        hora: "",
        cliente_id: "",
        status_pago_id: "",
        mes_id: "",
        servicio_id: "",
        tipo_pago_id: "",
      },
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      servicioRegla: [(v) => !!v || "Se necesita el campo"],
      extraRegla: [(v) => !!v || "Si no hay un extra poner 0"],
      descuentoRegla: [(v) => !!v || "Si no hay un descuento poner 0"],
      clientes: [],
      search: null,
      entradas: [],
      meses: [],
      servicios: [],
      valid: true,
      dialog: false,
      resolve: null,
      reject: null,
      tipos: [],
      status: [],
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: new Date().toISOString().substr(0, 10),
      menu2: false,
      menu_reloj: false,
      anios: Common.anios(),
    };
  },
  mounted() {},
  watch: {
    "pago.status_pago_id"(val) {
      if (val != 1) {
        this.pago.tipo_pago_id = "";
        this.pago.hora = "";
        this.pago.fecha = "";
      }
    },
    date(val) {
      this.dateFormatted = this.formatDate(val);
    },
  },
  computed: {
    computedDateFormatted() {
      if (this.pago.fecha) {
        return this.formatDate(this.pago.fecha);
      } else {
        return "";
      }
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    nuevoTipoPago() {
      this.$refs.tipo_pago.mostrar().then((resultado) => {
        if (resultado) {
          this.tipos.push(resultado);
          this.pago.tipo_pago_id = resultado.id;
        }
      });
    },
    actualizar() {
      if (this.$refs.form.validate()) {
        this.pago.cliente = null;
        this.pago.empresa = null;
        this.pago.estado = null;
        this.pago.mes = null;
        this.pago.servicio = null;

        let dispatch = "UsersModule/OnUpdate";
        let endpoint = "pago/" + this.pago.id;

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.pago,
          })
          .then((result) => {
            this.dialog = false;
            this.limpiar();
            this.resolve(result.data.pago);
          });
      }
    },
    limpiar() {
      this.$refs.form.reset();
      this.search = null;
    },
    esperarDatos(elemento) {
      this.getDatos(elemento);

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    cancelar() {
      this.resolve(false);
      this.dialog = false;
    },
    getDatos(pago) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `datos/form/pago`,
          data: {},
        })
        .then((result) => {
          this.meses = result.data.meses;
          this.tipos = result.data.tipos;
          this.status = result.data.status;
          this.servicios = result.data.servicios;
          this.pago = pago;

          this.dialog = true;
        });
    },
  },
};
</script>