<template>
  <div>
    <v-card flat>
      <v-card-title primary-title> Pagos y egresos del empleado </v-card-title>
      <v-container grid-list-md>
        <v-layout row wrap>
          <v-flex xs12 md6>
            <v-btn
              dense
              @click="nuevo()"
              class="rounded-pill pa-3"
              color="red"
              dark
            >
              <v-icon left>mdi-plus</v-icon>
              Agregar
            </v-btn>
            <v-btn
              dense
              @click="exportar()"
              class="rounded-pill pa-3"
              color="red"
              dark
            >
              <v-icon left>mdi-file-export</v-icon>
              Exportar
            </v-btn>
          </v-flex>

          <v-spacer></v-spacer>
          <v-flex xs12 md6>
            <v-text-field
              solo
              class="rounded-pill"
              dense
              v-model="search"
              @keyup.enter="getDatos"
              append-icon="mdi-magnify"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-container>
      <v-data-table
        dense
        :search="search"
        :headers="headers"
        :items="elementos"
        :options.sync="options"
        :server-items-length="totalElementos"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-format-horizontal-align-left',
          lastIcon: 'mdi-format-horizontal-align-right',
          prevIcon: 'mdi-chevron-left',
          nextIcon: 'mdi-chevron-right',
          itemsPerPageAllText: 'Todos',
          itemsPerPageText: 'Registros por página:',
          itemsPerPageOptions: [3, 5, 10, 15, -1],
        }"
      >
        <template v-slot:no-results>
          <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
            >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
          >
        </template>
        <template v-slot:item.id="{ item }">
          {{
            elementos
              .map(function (x) {
                return x.id;
              })
              .indexOf(item.id) + 1
          }}
        </template>
        <template v-slot:item.monto="{ item }">
          ${{ item.egreso.monto }}
        </template>
        <template v-slot:item.socio="{ item }">
          {{
            item.persona.nombre +
            " " +
            item.persona.apellido_paterno +
            " " +
            item.persona.apellido_materno
          }}
        </template>
        <template v-slot:item.opciones="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn @click="editarEgreso(item)" icon v-on="on">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar Ingreso</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn @click="eliminarEgreso(item)" icon v-on="on">
                <v-icon small> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>Desactivar Ingreso</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn @click="verArchivo(item)" icon v-on="on">
                <v-icon small> mdi-file </v-icon>
              </v-btn>
            </template>
            <span>Ver comprobante</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="red" dark icon="mdi-alert-circle">
            <v-row align="center">
              <v-col> Ningún dato que mostrar :( </v-col>
              <v-col class="shrink">
                <v-btn @click="getDatos()"
                  ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                >
              </v-col>
            </v-row>
          </v-alert>
        </template>
      </v-data-table>
    </v-card>

    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>

    <formEditarEgreso ref="refEditarEgreso"></formEditarEgreso>
    <nuevoPago ref="nuevo"></nuevoPago>
    <v-row justify="center">
      <exportar ref="refExportar"></exportar>
    </v-row>
  </div>
</template>
<script>
import formEditarEgreso from "@/js/components/finanzas/egresos/tablas/empleado/formEditarEgreso.vue";
import nuevoPago from "@/js/components/personal/detalles/pagos/nuevoPago.vue";
import exportar from "@/js/components/exportar_tabla/exportarComponent.vue";
export default {
  components: {
    formEditarEgreso,
    nuevoPago,
    exportar,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      dialogNuevo: false,
      options: {},
      id: null,
      headers: [
        { text: "#", align: "start", value: "id", sortable: true },
        { text: "Monto", value: "monto", sortable: true },
        { text: "Persona", value: "socio", sortable: false },
        { text: "Tipo", value: "tipo.tipo", sortable: false },
        { text: "Fecha", value: "egreso.fecha", sortable: false },
        { text: "Hora", value: "egreso.hora", sortable: false },
        { text: "Observaciones", value: "observaciones", sortable: true },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.id = arreUrl.pop();
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos(search) {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `empleados/egreso/tabla/` + this.id,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.egresos.data;
          this.totalElementos = result.data.egresos.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    eliminarEgreso(elemento) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `pago_empleado/` + elemento.id;
            let event = "delete";

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: elemento,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(elemento), 1);
              });
          } else {
          }
        });
    },
    nuevo() {
      this.$refs.nuevo.mostrar().then((confirm) => {
        if (confirm) {
          this.elementos.push(confirm);
          this.totalElementos = this.elementos.length;
        }
      });
    },
    editarEgreso(elemento) {
      this.ingresoActualizar = elemento;
      const egreso = Object.assign({}, elemento);
      this.$refs.refEditarEgreso.esperarDatos(egreso).then((confirm) => {
        if (confirm) {
          this.actualizarEgreso(confirm);
        }
      });
    },
    actualizarEgreso(respuesta) {
      let formDataP = new FormData();
      formDataP.append("id", respuesta.id);
      formDataP.append("monto", respuesta.monto);
      formDataP.append("detalles", respuesta.detalles);
      formDataP.append("archivo", respuesta.archivo);
      formDataP.append("empresa_id", respuesta.empresa_id);
      formDataP.append("persona_id", respuesta.persona_id);
      formDataP.append("tipo_id", respuesta.tipo_id);
      formDataP.append("observaciones", respuesta.observaciones);

      let dispatch = "UsersModule/OnSave";
      let endpoint = "egresos/actualizar/empleado";

      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: formDataP,
        })
        .then((result) => {
          this.elementos.splice(
            this.elementos.indexOf(this.ingresoActualizar),
            1,
            result.data.egreso
          );
        });
    },
    verArchivo(item) {
      if (item.egreso.archivo != null) {
        window.open(item.egreso.archivo, "_blank");
      } else {
        this.$store.dispatch("notificaciones/add", {
          tipo: "warning",
          mensaje: "Sin archivo  :/",
        });
      }
    },
    exportar() {
      this.$refs.refExportar.cargarDatos("egresos-empleado/" + this.id);
    },
  },
};
</script>
