<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <v-row justify="center">
          <nuevoPorcentaje ref="refNuevo"></nuevoPorcentaje>
        </v-row>
        <v-row justify="center">
          <editarPorcentaje ref="refEditar"></editarPorcentaje>
        </v-row>
        <v-card flat>
          <v-container grid-list-md>
            <v-card-title> Poncetajes de ingresos </v-card-title>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-btn
                  dense
                  @click="nuevo()"
                  class="rounded-pill"
                  color="red"
                  dark
                >
                  <v-icon left>mdi-plus</v-icon>
                  Nuevo
                </v-btn>

                <v-spacer></v-spacer>
                <v-text-field
                  solo
                  class="rounded-pill"
                  dense
                  v-model="search"
                  @keyup.enter="getDatos"
                  append-icon="mdi-magnify"
                  label="Buscador"
                  single-line
                  hide-details
                ></v-text-field>
              </v-layout>
            </v-container>
            <v-divider></v-divider>
            <v-data-table
              dense
              :search="search"
              :headers="headers"
              :items="elementos"
              :options.sync="options"
              :server-items-length="totalElementos"
              :loading="loading"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-format-horizontal-align-left',
                lastIcon: 'mdi-format-horizontal-align-right',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                itemsPerPageAllText: 'Todos',
                itemsPerPageText: 'Registros por página:',
                itemsPerPageOptions: [3, 5, 10, 15, -1],
              }"
            >
              <template v-slot:no-results>
                <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                  >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                >
              </template>
              <template v-slot:item.id="{ item }">
                {{
                  elementos
                    .map(function (x) {
                      return x.id;
                    })
                    .indexOf(item.id) + 1
                }}
              </template>
              <template v-slot:item.opciones="{ item }">
                <v-tooltip v-if="item.eliminado == false" top>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="editar(item)" icon v-on="on">
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip v-if="item.eliminado == false" top>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="eliminar(item)" icon v-on="on">
                      <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                  <span>Desactivar</span>
                </v-tooltip>

                <v-tooltip v-if="item.eliminado" top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      dense
                      @click="restaurar(item)"
                      :color="item.eliminado == true ? 'red' : 'green'"
                      v-on="on"
                      fab
                      x-small
                      dark
                    >
                      <v-icon>mdi-backup-restore</v-icon>
                    </v-btn>
                  </template>
                  <span>Restaurar</span>
                </v-tooltip>
              </template>

              <template v-slot:no-data>
                <v-alert :value="true" color="red" dark icon="mdi-alert-circle">
                  <v-row align="center">
                    <v-col> Ningún dato que mostrar :( </v-col>
                    <v-col class="shrink">
                      <v-btn @click="getDatos()"
                        ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-alert>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </div>
    </base-component>
  </div>
</template>

<script>
import nuevoPorcentaje from "@/js/components/negocios/recursos/tablas/porcentajes/nuevoPorcentaje.vue";
import editarPorcentaje from "@/js/components/negocios/recursos/tablas/porcentajes/editarPorcentaje.vue";
import exportar from "@/js/components/exportar_tabla/exportarComponent.vue";

export default {
  components: {
    editarPorcentaje,
    nuevoPorcentaje,
    exportar,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      headers: [
        { text: "#", align: "start", value: "id" },
        { text: "Nombre", value: "tipo", sortable: true },
        {
          text: "Porcentaje Empresa",
          value: "porcentaje_empresa",
          sortable: true,
        },
        {
          text: "Porcentaje Negocio",
          value: "porcentaje_negocio",
          sortable: true,
        },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      porcentajeActualizar: {},
    };
  },
  mounted() {},
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `tipo_ingreso_negocios/tabla`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.tipos.data;
          this.totalElementos = result.data.tipos.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    eliminar(elemento) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `tipo_ingreso_negocio/` + elemento.id;
            let event = "delete";

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: elemento,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(elemento), 1);
              });
          } else {
          }
        });
    },
    nuevo() {
      this.$refs.refNuevo.mostrar().then((tipo) => {
        if (tipo) {
          this.elementos.push(tipo);
        }
      });
    },
    editar(elemento) {
      this.porcentajeActualizar = elemento;
      const porcentaje = Object.assign({}, elemento);
      this.$refs.refEditar.cargarDatos(porcentaje).then((resultado) => {
        if (resultado) {
          this.elementos.splice(
            this.elementos.indexOf(this.porcentajeActualizar),
            1,
            resultado
          );
        }
      });
    },

    restaurar(item) {},
  },
};
</script>