<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <v-dialog v-model="dialog" max-width="700px">
          <v-card>
            <v-card-title primary-title> Editar perfil </v-card-title>
            <v-container grid-list-md>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                dense
                @submit.prevent="actualizar"
              >
                <v-layout row wrap>
                  <v-flex xs12 md6>
                    <v-text-field
                      label="Nombre"
                      :rules="generalRegla"
                      v-model="perfil.name"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-autocomplete
                      v-model="perfil.servicio_id"
                      :items="servicios"
                      :rules="generalRegla"
                      :search-input.sync="search_servicios"
                      color="blue-grey lighten-2"
                      label="Servicio"
                      item-text="nombre"
                      item-value="id"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-avatar
                          color="indigo"
                          class="headline font-weight-light white--text"
                        >
                          {{ item.nombre.charAt(0) }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.nombre }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >${{ item.costo }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle
                            >Tipo:{{ item.tipo.tipo }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle
                            v-if="item.tipo.tipo == 'Internet'"
                            >{{
                              item.bajada + "MB/" + item.subida + "MB"
                            }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 md6>
                    <v-text-field
                      label="Local address"
                      :rules="generalRegla"
                      v-model="perfil.local_address"
                      prepend-icon="mdi-router-wireless"
                      hint="Suele ser la ip gateway de la interfaz donde esta el servidor pppoe"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-select
                      :items="pools"
                      label="Remote address"
                      :rules="generalRegla"
                      v-model="perfil.remote_address"
                      prepend-icon="mdi-access-point-network"
                      hint="Elige un pool de ips"
                      item-value="name"
                      item-text="name"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                          <v-list-item-subtitle
                            >Ip: {{ item.ranges }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="cancelar()">Cancelar</v-btn>
                  <v-btn type="submit" :disabled="!valid" color="primary"
                    >Actualizar</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-container>
          </v-card>
        </v-dialog>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
export default {
  data() {
    return {
      valid: true,
      perfil: {
        name: "",
        router_id: "",
        servicio_id: "",
        local_address: "",
        remote_address: "",
      },
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      id: 0,
      resolve: null,
      reject: null,
      dialog: false,
      servicios: [],
      pools: [],
      search_servicios: "",
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.id = arreUrl.pop();
  },
  computed: {},
  methods: {
    mostrar(perfil) {
      this.getDatos(perfil);

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    getDatos(perfil) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `router/form/perfil/` + this.id,
          data: {},
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.servicios = result.data.servicios;
            this.pools = result.data.pools;
            this.perfil = perfil;
            this.dialog = true;
          }
        });
    },
    actualizar() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("UsersModule/OnUpdate", {
            url: "perfil/" + this.perfil.id,
            data: this.perfil,
          })
          .then((result) => {
            this.dialog = false;
            this.limpiar();
            this.resolve(result.data.perfil);
          });
      }
    },
    limpiar() {
      this.$refs.form.reset();
    },
    cancelar() {
      this.limpiar();
      this.dialog = false;
      this.resolve(false);
    },
  },
};
</script>