<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <v-container grid-list-md>
          <v-card-title class="headline" primary-title>
            <strong> Salario</strong>
          </v-card-title>
          <v-form dense ref="form" v-model="valid" lazy-validation>
            <v-layout row wrap>
              <v-flex xs12 md6>
                <v-text-field
                  label="Sueldo"
                  v-model="contable.sueldo"
                  :rules="sueldoRegla"
                  prepend-icon="mdi-cash-usd"
                  prefix="$"
                  type="number"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-select
                  :items="periodos"
                  v-model="contable.periodo_pago_id"
                  label="Periodo de pago"
                  item-text="periodo"
                  item-value="id"
                  :rules="generalRegla"
                  prepend-icon="mdi-calendar-multiple"
                ></v-select>
              </v-flex>
              <!--
                                      <v-flex xs12 md4>
                                        <v-select
                                            v-model="contable.tipo_persona_id"
                                            :items="tipos"
                                            label="Tipo de personal"
                                            item-value="id"
                                            item-text="tipo"
                                            :rules="generalRegla"
                                            no-data-text= "No hay elementos registrados"
                                            prepend-icon="mdi-account-multiple"
                                            >
                                            <template v-slot:append-item>
                                                <v-divider class="mb-2"></v-divider>
                                                <v-list-item @click="dialogTipo = true">
                                                <v-list-item-avatar color="grey lighten-3">
                                                    <v-icon>mdi-account-star</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>Agregar</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            </template>
                                        </v-select>
                                      </v-flex>
                                      -->
            </v-layout>
            <v-card>
              <v-card-title primary-title> Cuentas </v-card-title>
              <v-form
                ref="formCuenta"
                v-model="validCuenta"
                lazy-validation
                dense
              >
                <v-container grid-list-md>
                  <v-layout row wrap>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="Banco"
                        v-model="cuenta.banco"
                        :rules="generalRegla"
                        prepend-icon="mdi-bank"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="Titular"
                        v-model="cuenta.titular"
                        :rules="generalRegla"
                        prepend-icon="mdi-account"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="Número de cuenta"
                        v-model="cuenta.numero_cuenta"
                        :rules="generalRegla"
                        prepend-icon="mdi-account-card-details"
                        type="number"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="Transferencia bancaria"
                        v-model="cuenta.transferencia"
                        prepend-icon="mdi-transfer"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="agregarCuenta" color="success"
                  ><v-icon>mdi-lock</v-icon> Agregar</v-btn
                >
              </v-card-actions>
            </v-card>
            <v-divider></v-divider>
            <v-layout pa-4 row wrap>
              <v-flex xs12>
                <v-data-table
                  :headers="headers"
                  :items="cuentas"
                  hide-default-footer
                >
                  <template v-slot:item.opciones="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn @click="eliminarCuenta(item)" icon v-on="on">
                          <v-icon small> mdi-delete </v-icon>
                        </v-btn>
                      </template>
                      <span>Quitar</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:no-data>
                    <v-alert
                      :value="true"
                      color="red"
                      dark
                      icon="mdi-alert-circle"
                    >
                      <v-row align="center">
                        <v-col> Ningúna targeta registrada :( </v-col>
                      </v-row>
                    </v-alert>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-spacer></v-spacer>
              <v-btn text @click="regresar()">Regresar</v-btn>
              <v-btn
                @click="validate()"
                :disabled="!valid"
                class="rounded-pill pa-3"
                color="primary"
                >Finalizar</v-btn
              >
            </v-layout>
          </v-form>
        </v-container>
      </div>
    </base-component>
  </div>
</template>
<script>
export default {
  data() {
    return {
      valid: true,
      validTipo: true,
      contable: {},
      cuentas: [],
      cuenta: {
        banco: "",
        numero_cuenta: "",
        transferencia: "",
        titular: "",
      },
      dialogTipo: false,
      tipos: [],
      validCuenta: true,
      generalRegla: [(v) => !!v || "Se necesita el sueldo"],
      sueldoRegla: [
        (v) => !!v || "Se necesita el sueldo",
        (v) => v >= 0 || "No puede ser numeros negativos",
      ],
      periodos: [],
      tipo: {},
      headers: [
        {
          text: "Banco",
          align: "start",
          value: "banco",
        },
        { text: "Titular", value: "titular" },
        { text: "Numero de cuenta", value: "numero_cuenta" },
        { text: "Transferencia", value: "transferencia" },
        { text: "Opciones", value: "opciones" },
      ],
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        let storeDatos = this.$store.getters.getPersonalRegistro;
        let storePermisos = this.$store.getters.getPersonalPermisos;

        const clonDatos = Object.assign({}, storeDatos);
        const clonDatosPermisos = Object.assign({}, storePermisos);

        let persona = Object.assign({}, clonDatos.persona);
        let usuario = Object.assign({}, clonDatos.usuario);
        let horario = Object.assign({}, clonDatos.horario);
        let permisos = clonDatosPermisos.seleccionados;

        persona.lunes = horario.lunes;
        persona.martes = horario.martes;
        persona.miercoles = horario.miercoles;
        persona.jueves = horario.jueves;
        persona.viernes = horario.viernes;
        persona.sabado = horario.sabado;
        persona.domingo = horario.domingo;
        persona.lunes_entrada = horario.lunes_entrada;
        persona.lunes_salida = horario.lunes_salida;
        persona.martes_entrada = horario.martes_entrada;
        persona.martes_salida = horario.martes_salida;
        persona.miercoles_entrada = horario.miercoles_entrada;
        persona.miercoles_salida = horario.miercoles_salida;
        persona.jueves_entrada = horario.jueves_entrada;
        persona.jueves_salida = horario.jueves_salida;
        persona.viernes_entrada = horario.viernes_entrada;
        persona.viernes_salida = horario.viernes_salida;
        persona.sabado_entrada = horario.sabado_entrada;
        persona.sabado_salida = horario.sabado_salida;
        persona.domingo_entrada = horario.domingo_entrada;
        persona.domingo_salida = horario.domingo_salida;
        persona.sueldo = this.contable.sueldo;
        persona.tipo_persona_id = clonDatosPermisos.rol_id;
        persona.periodo_pago_id = this.contable.periodo_pago_id;

        let datos = {
          persona: persona,
          usuario: usuario,
          cuentas: this.cuentas,
          permisos: permisos,
        };

        let imagen = persona.imagen;

        this.enviarDatos(datos, imagen);
      }
    },
    regresar() {
      this.$store.dispatch("pasoPersonal", 3);
    },
    getDatos() {
      let dispatch = "UsersModule/OnGet";
      let enpoint = "formulario_personal";

      this.$store
        .dispatch(dispatch, {
          url: enpoint,
          data: {},
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.periodos = result.data.periodos;
            this.tipos = result.data.tipos;
          }
        });
    },
    agregarCuenta() {
      if (this.$refs.formCuenta.validate()) {
        const cuenta = Object.assign({}, this.cuenta);
        this.cuentas.push(cuenta);
        this.$refs.formCuenta.reset();
      }
    },
    eliminarCuenta(item) {
      this.cuentas.splice(this.cuentas.indexOf(item), 1);
    },
    enviarDatos(datos, imagen) {
      let dispatch = "UsersModule/OnSave";
      let enpoint = "personal";

      this.$store
        .dispatch(dispatch, {
          url: enpoint,
          data: datos,
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            if (imagen) {
              this.enviarImagen(result.data.persona, imagen[0]);
            } else {
              document.location.href = "vista/tabla/personal";
            }
          }
        });
    },
    enviarImagen(persona, imagen) {
      let datos = new FormData();
      datos.append("imagen", imagen);
      datos.append("persona_id", persona.id);
      datos.append("empresa_id", persona.empresa_id);

      let dispatch = "UsersModule/OnSave";
      let enpoint = "imagen/personal";

      this.$store
        .dispatch(dispatch, {
          url: enpoint,
          data: datos,
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            document.location.href = "vista/tabla/personal";
          }
        });
    },
  },
};
</script>