//Usuario.js
class Usuario {
    constructor(modulo, id){
        
        this.id = 0
        this.display_name = ""
        this.email = ""
        this.estatus = ""
        this.nombre = ""
        this.apellido_paterno = ""
        this.apellido_materno = ""
        this.direccion = ""
        this.municipio = ""
        this.cp = ""
        this.celular = "",
        this.password = ""
        this.estatus = "activo"
        this.role_id = modulo == "clientes" ? id : ""
        this.role = ""
        this.estado_id = ""
    }
}

export default Usuario
