class Proveedor {
    constructor() {
        this.nombre = ""
        this.direccion = ""
        this.estado = ""
        this.municipio = ""
        this.colonia = ""
        this.longitud = ""
        this.latitud = ""
        this.telefono = ""
        this.correo = ""
        this.celular = ""
        this.codigo_postal = ""
        this.razon_social = ""
        this.tipo_persona = ""
        this.tipo_identificacion = ""
        this.RFC_RUC_NIT = ""
        this.retenciones = ""
    }
}

export default Proveedor