var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component mt-4"},[_c('base-component',[_c('div',{attrs:{"slot":"component"},slot:"component"},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"cool pa-4"},[_c('v-card-title',{staticClass:"display-1"},[_c('strong',[_vm._v("Productos")])]),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"rounded-pill",attrs:{"dense":"","color":"red","dark":""},on:{"click":function($event){return _vm.nuevo()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("\n                    Agregar\n                  ")],1),_vm._v(" "),_c('v-btn',{staticClass:"rounded-pill",attrs:{"dense":"","color":"red","dark":""},on:{"click":function($event){return _vm.exportar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-export")]),_vm._v("\n                    Exportar\n                  ")],1)],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"rounded-pill",attrs:{"dense":"","append-icon":"mdi-magnify","label":"Buscador","single-line":"","solo":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDatos($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_vm._v(" "),_c('v-data-table',{attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.elementos,"options":_vm.options,"server-items-length":_vm.totalElementos,"loading":_vm.loading,"footer-props":{
                showFirstLastPage: true,
                firstIcon: 'mdi-format-horizontal-align-left',
                lastIcon: 'mdi-format-horizontal-align-right',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                itemsPerPageAllText: 'Todos',
                itemsPerPageText: 'Registros por página:',
                itemsPerPageOptions: [5, 50, 100, -1],
              }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"lime lighten-1","icon":"mdi-alert"}},[_vm._v("Tu búsqueda \""+_vm._s(_vm.search)+"\" no se encuentra.")])]},proxy:true},{key:"item.id",fn:function(ref){
              var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.elementos
                    .map(function (x) {
                      return x.id;
                    })
                    .indexOf(item.id) + 1)+"\n              ")]}},{key:"item.imagen",fn:function(ref){
                    var item = ref.item;
return [(item.imagen)?_c('v-img',{staticClass:"cool-xs ma-1",attrs:{"src":item.imagen,"height":"80","width":"80","ma-2":""}}):_c('v-img',{staticClass:"cool-xs ma-1",attrs:{"src":"/img/noimagen.jpg","height":"80","width":"80","ma-2":""}})]}},{key:"item.producto",fn:function(ref){
                    var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.producto)+"\n                "+_vm._s(item.marca)+"\n              ")]}},{key:"item.venta",fn:function(ref){
                    var item = ref.item;
return [_c('p',[_c('strong',{staticClass:"orange--text"},[_vm._v(_vm._s(_vm.dinero(item.precio_venta)))])])]}},{key:"item.cantidad",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"color":item.cantidad <= item.stock_minimo ? 'red' : 'green',"dark":""}},on),[_vm._v(_vm._s(item.cantidad))])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(item.cantidad <= item.stock_minimo
                      ? "Producto escaso"
                      : "Producto ok"))])])]}},{key:"item.proveedor",fn:function(ref){
                      var item = ref.item;
return [_c('v-chip',{attrs:{"color":"teal","dark":""},on:{"click":function($event){return _vm.verProveedor(item.proveedor_id)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-account-card-details")]),_vm._v("\n                  "+_vm._s(item.nombreProveedor))],1)]}},{key:"item.opciones",fn:function(ref){
                      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.detalles(item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Detalles")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.editarProducto(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Editar Producto")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.eliminarProducto(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Eliminar")])])]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"red","dark":"","icon":"mdi-alert-circle"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_vm._v(" Ningún dato que mostrar :( ")]),_vm._v(" "),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{on:{"click":function($event){return _vm.getDatos()}}},[_c('v-icon',{attrs:{"pl-4":""}},[_vm._v("mdi-cached")]),_vm._v(" Refrescar")],1)],1)],1)],1)]},proxy:true}])})],1)],1)],1),_vm._v(" "),_c('BaseConfirmacion',{ref:"confirmar"}),_vm._v(" "),_c('formEditarProducto',{ref:"refEditarProducto"}),_vm._v(" "),_c('exportar',{ref:"refExportar"}),_vm._v(" "),_c('formProducto',{ref:"refProducto"})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }