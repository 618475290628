<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-container grid-list-md>
          <v-card-title class="display-1" primary-title>
            <strong>Editar</strong>
          </v-card-title>
          <v-card-text>
            <v-form
              dense
              ref="form"
              v-model="valid"
              enctype="multipart/form-data"
              lazy-validation
            >
              <v-layout row wrap>
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <v-text-field
                      label="Producto"
                      v-model="producto.producto"
                      :rules="generalRegla"
                      filled
                      append-icon="mdi-antenna"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      label="Marca"
                      v-model="producto.marca"
                      :rules="generalRegla"
                      append-icon="mdi-tag"
                      filled
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      label="Cantidad"
                      v-model="producto.cantidad"
                      :rules="cantidadRegla"
                      append-icon="mdi-numeric"
                      hint="cuantos elementos tienes existentes"
                      type="number"
                      filled
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field
                      label="Precio compra"
                      v-model="producto.precio_compra"
                      :rules="generalRegla"
                      append-icon="mdi-cash"
                      hint="¿Cuanto te costo?"
                      type="number"
                      prefix="$"
                      filled
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field
                      label="Precio venta"
                      v-model="producto.precio_venta"
                      :rules="precioVentaRegla"
                      append-icon="mdi-cash"
                      hint="¿A cuanto venderias este producto?"
                      type="number"
                      prefix="$"
                      filled
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field
                      label="Stock minimo"
                      v-model="producto.stock_minimo"
                      :rules="stockMinimoRegla"
                      append-icon="mdi-file-table-box"
                      hint="cantidad minima de producto a tener en inventario"
                      type="number"
                      filled
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field
                      label="Stock maximo"
                      v-model="producto.stock_maximo"
                      :rules="stockMaximoRegla"
                      append-icon="mdi-file-table-box"
                      hint="cantidad maxima de producto a tener en inventario"
                      type="number"
                      filled
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 md4>
                    <v-file-input
                      :rules="rulesFile"
                      accept="image/png, image/jpeg, image/bmp"
                      hint="Formato imagen, Maximo 2MB"
                      show-size
                      filled
                      prepend-icon=""
                      v-model="imagen"
                      append-icon="mdi-camera"
                      small-chips
                      multiple
                      label="Imagen del producto"
                    ></v-file-input>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-select
                      v-model="producto.proveedor_id"
                      :items="proveedores"
                      :rules="generalRegla"
                      filled
                      item-text="nombre"
                      item-value="id"
                      label="Proveedor"
                    >
                      <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item @click="nuevoProveedor()">
                          <v-list-item-avatar color="grey lighten-3">
                            <v-icon>mdi-plus</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>Agregar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-select
                      v-model="producto.categoria_id"
                      :items="categorias"
                      item-text="categoria"
                      item-value="id"
                      label="Categoria"
                      :rules="generalRegla"
                      filled
                      no-data-text="No hay elementos registrados"
                    >
                      <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item @click="nuevaCategoria()">
                          <v-list-item-avatar color="grey lighten-3">
                            <v-icon>mdi-plus</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>Agregar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-textarea
                      v-model="producto.detalles"
                      filled
                      label="Detalles"
                      rows="3"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelar()">Cancelar</v-btn>
            <v-btn
              color="primary"
              class="rounded-pill pa-3"
              @click="actualizar()"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <nuevoProveedor ref="nuevoProveedor"></nuevoProveedor>
    <nuevaCategoria ref="nuevaCategoria"></nuevaCategoria>
  </div>
</template>

<script>
import nuevaCategoria from "@/js/components/almacen/categorias/nuevo.vue";
import nuevoProveedor from "@/js/components/almacen/proveedores/nuevoProveedor.vue";

export default {
  components: {
    nuevaCategoria,
    nuevoProveedor,
  },
  data() {
    return {
      producto: {
        producto: "",
        marca: "",
        cantidad: 1,
        precio_compra: "",
        precio_venta: "",
        stock_maximo: 100,
        stock_minimo: 3,
        proveedor_id: "",
        detalles: "",
        imagen: null,
        provedor_id: "",
      },
      categoria: {
        categoria: "",
      },
      valid: true,
      rulesFile: [
        (value) =>
          !value ||
          value.length == 0 ||
          value[0].size < 2000000 ||
          "Debe ser menor a 2 MB!",
      ],
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      cantidadRegla: [
        (v) => !!v || "Se necesita el campo",
        (v) => parseInt(v) >= 0 || "necesita ser mayor o igual a 0",
      ],
      precioVentaRegla: [
        (v) => !!v || "Se necesita el campo",
        (v) =>
          parseFloat(v) >= parseFloat(this.producto.precio_compra) ||
          "necesita ser mayor o igual al precio de compra",
      ],
      stockMaximoRegla: [
        (v) => !!v || "Se necesita el campo",
        (v) => v >= 0 || "necesita ser mayor o igual a 0",
        (v) =>
          parseInt(v) > parseInt(this.producto.stock_minimo) ||
          "necesita ser mayor al minino de stock",
      ],
      stockMinimoRegla: [
        (v) => !!v || "Se necesita el campo",
        (v) => v >= 0 || "necesita ser mayor o igual a 0",
      ],
      proveedores: [],
      categorias: [],
      imagen: null,
      validCategoria: true,
      dialog: false,
    };
  },
  mounted() {},
  watch: {
    imagen(val) {
      if (val != null) {
        this.producto.imagen = val[0];
      }
    },
  },
  computed: {},
  methods: {
    sacar(file) {
      return true;
    },
    esperarDatos(productoEditar) {
      this.getDatosInicio();

      this.producto = productoEditar;
      this.producto.proveedor_id = parseInt(productoEditar.proveedor_id);
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    limpiar() {
      this.$refs.form.reset();
      this.imagen = null;
    },
    getDatosInicio() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "datos/registro/producto",
          data: {},
        })
        .then((result) => {
          this.proveedores = result.data.proveedores;
          this.categorias = result.data.categorias;
        });
    },
    actualizar() {
      if (this.$refs.form.validate()) {
        var respuesta = this.producto;
        let formDataP = new FormData();

        formDataP.append("id", respuesta.id);
        formDataP.append("producto", respuesta.producto);
        formDataP.append("marca", respuesta.marca);
        formDataP.append("cantidad", respuesta.cantidad);
        formDataP.append("precio_compra", respuesta.precio_compra);
        formDataP.append("precio_venta", respuesta.precio_venta);
        formDataP.append("stock_maximo", respuesta.stock_maximo);
        formDataP.append("stock_minimo", respuesta.stock_minimo);
        formDataP.append("detalles", respuesta.detalles);
        formDataP.append("imagen", respuesta.imagen);
        formDataP.append("categoria_id", respuesta.categoria_id);
        formDataP.append("proveedor_id", respuesta.proveedor_id);
        formDataP.append("empresa_id", respuesta.empresa_id);

        let dispatch = "UsersModule/OnSave";
        let endpoint = "productos/actualizar";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: formDataP,
          })
          .then((result) => {
            this.dialog = false;
            this.limpiar();
            this.resolve(result.data.producto);
          });
      }
    },
    cancelar() {
      this.resolve(false);
      this.dialog = false;
    },

    nuevaCategoria() {
      this.$refs.nuevaCategoria.mostrar().then((resultado) => {
        if (resultado) {
          this.categorias.push(resultado);
          this.producto.categoria_id = resultado.id;
        }
      });
    },
    nuevoProveedor() {
      this.$refs.nuevoProveedor.mostrar().then((resultado) => {
        if (resultado) {
          this.proveedores.push(resultado);
          this.producto.proveedor_id = resultado.id;
        }
      });
    },
  },
};
</script>