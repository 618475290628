<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card>
      <v-container grid-list-md>
        <v-card-title class="display-1" primary-title>
          <strong>Nuevo</strong>
        </v-card-title>
        <v-form
          dense
          ref="form"
          v-model="valid"
          enctype="multipart/form-data"
          lazy-validation
          @submit.prevent="nuevo()"
        >
          <v-responsive
            id="scroll-target"
            class="overflow-y-auto"
            :max-height="500"
          >
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <p class="text-subtitle-2">Datos generales</p>
                  <v-flex xs12 md12>
                    <v-text-field
                      v-model="proveedor.nombre"
                      :rules="nombreReglas"
                      label="Nombre de la  proveedor"
                      required
                      prepend-icon="mdi-home-automation"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-text-field
                      label="Celular"
                      v-model="proveedor.celular"
                      type="number"
                      counter="10"
                      prepend-icon="mdi-cellphone-android"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-text-field
                      label="Telefono"
                      v-model="proveedor.telefono"
                      type="number"
                      counter="10"
                      prepend-icon="mdi-phone"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-text-field
                      label="Correo proveedor"
                      v-model="proveedor.correo"
                      prepend-icon="mdi-gmail"
                      :rules="emailRules"
                    ></v-text-field>
                  </v-flex>
                </v-flex>
                <v-flex xs12 md6>
                  <p class="text-subtitle-2">Datos Fiscales (opcional)</p>
                  <v-flex xs12 md12>
                    <v-text-field
                      v-model="proveedor.razon_social"
                      label="Razón social"
                      prepend-icon="mdi-account-edit"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-text-field
                      label="Tipo de identificacion"
                      v-model="proveedor.tipo_identificacion"
                      prepend-icon="mdi-sim"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-text-field
                      label="Tipo persona"
                      v-model="proveedor.tipo_persona"
                      prepend-icon="mdi-account"
                    ></v-text-field>
                  </v-flex>
                  <v-layout row wrap>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="RFC-RUC-NIT"
                        v-model="proveedor.RFC_RUC_NIT"
                        prepend-icon="mdi-account-card-details"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="Retenciones"
                        v-model="proveedor.retenciones"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <p class="text-subtitle-2">Datos ubicación</p>
              <v-layout row wrap>
                <v-flex xs12 md4>
                  <v-text-field
                    v-model="cp"
                    label="Código postal"
                    :rules="generalRegla"
                    type="number"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    v-model="proveedor.estado"
                    label="Estado"
                    :rules="generalRegla"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    v-model="proveedor.municipio"
                    label="Municipio"
                    :rules="generalRegla"
                    required
                  ></v-text-field>
                </v-flex>
                <v-layout row wrap>
                  <v-flex v-if="colonias.length != 0" xs12 md6>
                    <v-select
                      v-model="proveedor.colonia"
                      :items="colonias"
                      item-text="nombre"
                      item-value="nombre"
                      label="Colonias"
                    ></v-select>
                  </v-flex>
                  <v-flex v-if="colonias.length == 0" xs12 md6>
                    <v-text-field
                      v-model="proveedor.colonia"
                      label="Colonia"
                      :rules="generalRegla"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field
                      v-model="proveedor.direccion"
                      label="Dirección"
                      :rules="generalRegla"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12>
                    <mapa ref="mapa"></mapa>
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-card-text>
          </v-responsive>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelar()">Cancelar</v-btn>
            <v-btn
              :disabled="!valid"
              class="rounded-pill"
              color="primary"
              type="submit"
            >
              Registrar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import Proveedor from "@/js/clases/Proveedor.js";
import mapa from "@/js/components/mapa/mapaComponent.vue";

export default {
  components: {
    mapa,
  },
  data() {
    return {
      proveedor: new Proveedor(),
      valid: true,
      nombreReglas: [(v) => !!v || "Se necesita el nombre"],
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      telefonoReglas: [
        (v) => !!v || "Se necesita el campo",
        (v) => (v && v.length == 10) || "No puede ser menor a 10 digitos",
      ],

      emailRules: [
        (v) => /.+@.+/.test(v) || v.length == 0 || "Introduce un correo valido",
      ],
      dialog: false,
      colonias: [],
      modal2: false,
      modal: false,
      cp: "",
    };
  },
  mounted() {},
  watch: {
    cp(val) {
      //this.getDatosApi(val);
      this.proveedor.codigo_postal = val;
    },
  },
  methods: {
    async esperarComponente() {
      await this.$nextTick();
      this.$refs.mapa.mostrarMarker(
        null,
        null,
        "markerProveedor.png",
        "Proveedor",
        true
      );
    },
    mostrar() {
      this.dialog = true;
      this.esperarComponente();

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    nuevo() {
      if (this.$refs.form.validate()) {
        this.proveedor.latitud = this.$refs.mapa.getLatitud();
        this.proveedor.longitud = this.$refs.mapa.getLongitud();
        let dispatch = "UsersModule/OnSave";
        let endpoint = "proveedor";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.proveedor,
          })
          .then((result) => {
            this.dialog = false;
            this.limpiar();
            this.resolve(result.data.proveedor);
          });
      }
    },
    limpiar() {
      this.cp = "";
      this.proveedor.nombre = "";
      this.proveedor.direccion = "";
      this.proveedor.estado = "";
      this.proveedor.municipio = "";
      this.proveedor.colonia = "";
      this.proveedor.longitud = "";
      this.proveedor.latitud = "";
      this.proveedor.telefono = "";
      this.proveedor.correo = "";
      this.proveedor.celular = "";
      this.proveedor.codigo_postal = "";
      this.proveedor.razon_social = "";
      this.proveedor.tipo_persona = "";
      this.proveedor.tipo_identificacion = "";
      this.proveedor.RFC_RUC_NIT = "";
      this.proveedor.retenciones = "";
    },
    getDatosApi(cp) {
      if (cp.length == 5) {
        let dispatch = "UsersModule/OnGet";
        let endpoint = "datos/codigo/postal/" + cp;
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: {},
          })
          .then((result) => {
            if ([200, 201].includes(result.status)) {
              let datos = result.data.data;
              this.proveedor.estado = datos.estado;
              this.proveedor.municipio = datos.municipio;
              this.colonias = datos.colonias;
            }
          });
      }
    },
    cancelar() {
      this.dialog = false;
      this.resolve(false);
    },
  },
};
</script>