<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title primary-title> Notificar por WhatsApp </v-card-title>
        <v-container grid-list-md>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            dense
            @submit.prevent="enviar"
          >
            <v-layout row wrap>
              <v-flex xs3>
                <v-text-field
                  label="Lada"
                  v-model="lada"
                  :rules="generalRegla"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs9>
                <v-text-field
                  label="Numero celular"
                  v-model="celular"
                  :rules="generalRegla"
                  outlined
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-textarea
              label="Mensaje"
              v-model="mensaje"
              :rules="generalRegla"
              rows="4"
              auto-grow
              outlined
            ></v-textarea>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialog = false">Cancelar</v-btn>
              <v-btn
                type="submit"
                class="rounded-pill pa-3"
                dark
                :disabled="!valid"
                color="green"
                ><v-icon>mdi-send</v-icon> Enviar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Common from "@/js/Common";
import LocalStorage from "@/js/LocalStorage.js";

export default {
  data() {
    return {
      valid: true,
      dialog: false,
      mensaje: "",
      celular: null,
      lada: "+52",
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      cliente: null,
      pagos: [],
      pagosLiquidados: [],
    };
  },
  mounted() {
    var lada = LocalStorage.get("LADA");
    if (lada) {
      this.lada = lada;
    }
  },
  computed: {},
  methods: {
    mostrar(cliente, pagos, liquidados) {
      this.cliente = cliente;
      if (liquidados) {
        this.pagosLiquidados = pagos;
        this.whatsLiquidado();
      } else {
        this.pagos = pagos;
        this.whatsPendiente();
      }
    },
    whatsLiquidado() {
      var pagosLiquidados = this.pagosLiquidados;
      this.celular = this.cliente.celular;
      var texto = "";

      if (pagosLiquidados.length == 1) {
        texto += "Su pago: ";
        texto += "%0A";
        for (var i = 0; i < pagosLiquidados.length; i++) {
          texto +=
            "correspondiente al mes *" +
            pagosLiquidados[i].mes.mes +
            "* del *" +
            pagosLiquidados[i].anio +
            "* 🗓️ por la cantidad de *" +
            Common.formatMoney(pagosLiquidados[i].monto) +
            "* 💵";
          texto += "%0A";
        }
        texto += "%0A";
        texto += "ha sido *registrado exitosamente*";
        texto += "%0A";
        texto += "_Muchas Gracias_ 😊";
      } else {
        texto += "Se han *registrado exitosamente* los siguientes *pagos*:";
        texto += "%0A";
        texto += "%0A";
        for (var i = 0; i < pagosLiquidados.length; i++) {
          texto +=
            " *" +
            pagosLiquidados[i].mes.mes +
            " " +
            pagosLiquidados[i].anio +
            "* --> *" +
            Common.formatMoney(pagosLiquidados[i].monto) +
            "* ";
          texto += "%0A";
          texto += "%0A";
        }
        texto += "%0A";
        texto +=
          "Su saldo pendiente ahora es de :*" +
          Common.formatMoney(this.cliente.saldo) +
          "*";
        texto += "%0A";
        texto += "%0A";
        texto += "_Muchas Gracias por ir al corriente con sus pagos 😊_";
      }
      this.mensaje = texto;
      this.dialog = true;
    },

    whatsPendiente() {
      this.celular = this.cliente.celular;
      var texto = "";
      if (this.pagos.length > 0) {
        texto =
          "Buen dia 😊, Su *saldo pendiente* es de: *" +
          Common.formatMoney(this.cliente.saldo) +
          "* de los meses: ";
        for (var i = 0; i < this.pagos.length; i++) {
          texto +=
            "*" +
            this.pagos[i].mes.mes +
            " " +
            this.pagos[i].anio +
            " => " +
            Common.formatMoney(this.pagos[i].monto) +
            "*, ";
        }
      } else {
        texto =
          "Buen dia 😊, Su *saldo pendiente* es de :*" +
          Common.formatMoney(this.cliente.saldo) +
          "*. _Muchas Gracias por ir al corriente con sus pagos_";
      }

      var arreUrl = window.location.href.split("/");
      texto +=
        " Puede revisar mas detalles de sus pagos en _" + arreUrl[2] + "_";
      this.mensaje = texto;
      this.dialog = true;
    },
    enviar() {
      let url =
        "https://api.whatsapp.com/send?phone=" +
        this.lada +
        this.celular +
        "&text=" +
        this.mensaje;
      window.open(url, "_blank");
      LocalStorage.set("LADA", this.lada);
      this.dialog = false;
    },
  },
};
</script>