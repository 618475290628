<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
      <v-layout row wrap>
        <v-flex xs12>
          <v-stepper v-model="e1" class="cool">
            <v-stepper-header class="elevation-0">
              <v-stepper-step :complete="e1 > 1" step="1">Datos del router</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 2" step="2">Script de conexión</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 3" step="3">Configurar cola total</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1" class="pt-0">
                <datosRouter ref="refDatosRouter"></datosRouter>
              </v-stepper-content>
              <v-stepper-content step="2" class="pt-0">
                <scriptConexion></scriptConexion>
              </v-stepper-content>
              <v-stepper-content step="3" class="pt-0">
                <colaTotal></colaTotal>
              </v-stepper-content>
             
            </v-stepper-items>
          </v-stepper>
        </v-flex>
      </v-layout>
    </div>
    </base-component>
    </div>
  </template>
  <script>
  import datosRouter from "@/js/components/red/routers/nuevo_router/datosRouter.vue";
  import colaTotal from "@/js/components/red/routers/nuevo_router/colaTotal.vue";
  import scriptConexion from "@/js/components/red/routers/nuevo_router/scriptConexion.vue";
  
  export default {
    components: {
      datosRouter,
      scriptConexion,
      colaTotal,
    },
    data() {
      return {
        
      };
    },
    mounted() {
      
    },
    watch:{
      
    },
    computed: {
      e1() {
        return this.$store.getters.getPasoRegistroRouter;
      },
      
      
    },
    methods: {
    },
  };
  </script>