<template>
  <div slot="component">
    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card>
        <v-container grid-list-md>
          <v-card-title class="display-1" primary-title>
            <strong>Editar</strong>
          </v-card-title>
          <v-responsive
            id="scroll-target"
            class="overflow-y-auto"
            :max-height="500"
          >
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation dense>
                <v-layout row wrap>
                  <v-flex xs12 md12>
                    <v-text-field
                      label="Nombre de la zona"
                      v-model="zona.nombre"
                      :rules="generalRegla"
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-autocomplete
                      v-model="zona.servicios"
                      :disabled="isUpdating"
                      :items="servicios"
                      chips
                      outlined
                      label="Servicios disponibles en la zona"
                      item-text="nombre"
                      item-value="id"
                      multiple
                    >
                      <v-spacer></v-spacer>
                      <template v-slot:selection="data">
                        <v-chip
                          dark
                          color="teal"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="remove(data.item)"
                        >
                          {{ data.item.nombre }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content>{{
                            data.item
                          }}</v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-avatar
                            color="teal"
                            class="headline font-weight-light white--text"
                          >
                            {{ data.item.nombre.charAt(0) }}
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ data.item.nombre }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <div>
                                <v-icon
                                  >mdi-arrow-up-bold-circle-outline</v-icon
                                >
                                <strong class="ml-2"
                                  >{{ data.item.subida }}
                                </strong>
                                Mb.
                                <v-icon class="ml-2"
                                  >mdi-arrow-down-bold-circle-outline</v-icon
                                >
                                <strong class="ml-2"
                                  >{{ data.item.bajada }}
                                </strong>
                                Mb.

                                <v-icon class="ml-2">mdi-cash-usd</v-icon>
                                <strong class="ml-2"
                                  >{{ data.item.costo }}
                                </strong>
                              </div>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12>
                    <ubicacion ref="mapa"></ubicacion>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
          </v-responsive>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelar()">Cancelar</v-btn>
            <v-btn
              :disabled="!valid"
              class="rounded-pill pa-3"
              color="primary"
              @click="actualizar()"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ubicacion from "@/js/components/mapa/mapaComponent.vue";

export default {
  components: {
    ubicacion,
  },
  data() {
    return {
      zona: {
        nombre: "",
        rango_ip: "",
        latitud: "",
        longitud: "",
        servicios: [],
      },
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      dialog: false,
      resolve: null,
      reject: null,
      dialog_mapa: false,
      isUpdating: false,
      servicios: [],
    };
  },
  mounted() {
    this.getDatos();
  },
  watch: {},
  computed: {},
  methods: {
    async esperarComponente() {
      await this.$nextTick();
      this.$refs.mapa.mostrarMarker(
        this.zona.latitud,
        this.zona.longitud,
        "markerTorre.png",
        "Zona",
        true
      );
    },
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `todos/servicios/empresa`,
          data: {},
        })
        .then((result) => {
          this.servicios = result.data.servicios;
        });
    },
    actualizar() {
      if (this.$refs.form.validate()) {
        this.zona.latitud = this.$refs.mapa.getLatitud();
        this.zona.longitud = this.$refs.mapa.getLongitud();
        let dispatch = "UsersModule/OnUpdate";
        let endpoint = `zona/` + this.zona.id;

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.zona,
          })
          .then((result) => {
            this.dialog = false;
            this.resolve(result.data.zona);
          });
      }
    },
    limpiar() {
      this.$refs.form.reset();
    },
    esperarDatos(elemento) {
      this.zona = elemento;
      this.dialog = true;
      this.esperarComponente();
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    cancelar() {
      this.resolve(false);
      this.dialog = false;
    },
    guardarCoordenadas() {
      this.dialog_mapa = false;
      this.zona.latitud = this.$store.getters.getCoordenadas.lat;
      this.zona.longitud = this.$store.getters.getCoordenadas.lng;
    },
    remove(item) {
      const index = this.zona.servicios.indexOf(item.id);
      if (index >= 0) this.zona.servicios.splice(index, 1);
    },
  },
};
</script>