<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <v-layout row wrap>
          <v-flex xs12 md12>
            <v-card class="cool pa-4">
              <v-tabs
              class="cool"
                v-model="tab"
                background-color="red"
                dark
                show-arrows
              >
                <v-tab> Procedencias de ingreso </v-tab>
                <v-tab> Tipos de compra </v-tab>
                <v-tab> Tipos de egreso de sucursal o negocio </v-tab>
                <v-tab> Tipos de egreso personal </v-tab>
                <v-tab> Tipos de egreso socio </v-tab>
                <v-tab> Otros tipos de egreso </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <tiposProcedencias></tiposProcedencias>
                </v-tab-item>
                <v-tab-item>
                  <tiposCompras></tiposCompras>
                </v-tab-item>
                <v-tab-item>
                  <tiposEgresosNegocios></tiposEgresosNegocios>
                </v-tab-item>
                <v-tab-item>
                  <tiposEgresosPersona></tiposEgresosPersona>
                </v-tab-item>
                <v-tab-item>
                  <tiposEgresosSocio></tiposEgresosSocio>
                </v-tab-item>
                <v-tab-item>
                  <tiposEgresoOtro></tiposEgresoOtro>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
import tiposProcedencias from "@/js/components/finanzas/recursos/tablas/tipos_procedencias/tipos.vue";
import tiposCompras from "@/js/components/finanzas/recursos/tablas/tipos_compras/tipos.vue";
import tiposEgresosNegocios from "@/js/components/finanzas/recursos/tablas/egresos_negocios/tipos.vue";
import tiposEgresosPersona from "@/js/components/finanzas/recursos/tablas/egresos_personal/tipos.vue";
import tiposEgresosSocio from "@/js/components/finanzas/recursos/tablas/egresos_socios/tipos.vue";
import tiposEgresoOtro from "@/js/components/finanzas/recursos/tablas/egreso_otros/tipos.vue";
import LocalStorage from "@/js/LocalStorage.js";

export default {
  components: {
    tiposProcedencias,
    tiposCompras,
    tiposEgresosNegocios,
    tiposEgresosPersona,
    tiposEgresoOtro,
    tiposEgresosSocio,
  },
  data() {
    return {
      tab: 0,
    };
  },
  watch: {
    tab(val) {
      LocalStorage.set("TAB_ACTUAL_RECURSOS_FINANZAS", val);
    },
  },
  mounted() {
    var actualTab = LocalStorage.get("TAB_ACTUAL_RECURSOS_FINANZAS");
    if (actualTab) {
      this.tab = actualTab;
    }
  },
  computed: {},
  methods: {},
};
</script>