var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component"},[_c('base-component',[_c('div',{attrs:{"slot":"component"},slot:"component"},[_c('BaseConfirmacion',{ref:"confirmar"}),_vm._v(" "),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md12":""}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-form',{ref:"form",attrs:{"dense":"","enctype":"multipart/form-data","lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md8":""}},[_c('v-text-field',{attrs:{"label":"Asunto","rules":_vm.generalRegla,"prepend-icon":"mdi-pen"},model:{value:(_vm.reporte.asunto),callback:function ($$v) {_vm.$set(_vm.reporte, "asunto", $$v)},expression:"reporte.asunto"}})],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('v-text-field',{attrs:{"label":"Costo","rules":_vm.generalRegla,"type":"number","prepend-icon":"mdi-cash-usd","prefix":"$"},model:{value:(_vm.reporte.costo),callback:function ($$v) {_vm.$set(_vm.reporte, "costo", $$v)},expression:"reporte.costo"}})],1)],1),_vm._v(" "),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Dia de revisión","prepend-icon":"mdi-calendar","readonly":"","rules":_vm.generalRegla},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"locale":"es-mx","no-title":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.reporte.fecha),callback:function ($$v) {_vm.$set(_vm.reporte, "fecha", $$v)},expression:"reporte.fecha"}})],1)],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.reporte.hora,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.reporte, "hora", $event)},"update:return-value":function($event){return _vm.$set(_vm.reporte, "hora", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hora","rules":_vm.generalRegla,"prepend-icon":"mdi-av-timer","readonly":""},model:{value:(_vm.reporte.hora),callback:function ($$v) {_vm.$set(_vm.reporte, "hora", $$v)},expression:"reporte.hora"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_reloj),callback:function ($$v) {_vm.menu_reloj=$$v},expression:"menu_reloj"}},[_vm._v(" "),(_vm.menu_reloj)?_c('v-time-picker',{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.reporte.hora)}},model:{value:(_vm.reporte.hora),callback:function ($$v) {_vm.$set(_vm.reporte, "hora", $$v)},expression:"reporte.hora"}}):_vm._e()],1)],1)],1),_vm._v(" "),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md12":""}},[_c('v-autocomplete',{ref:"autoPersonal",attrs:{"items":_vm.itemsPersonal,"item-text":"todo","item-value":"id","rules":_vm.generalRegla,"max-width":"30","search-input":_vm.searchPersonal,"hide-no-data":"","label":"Personal","placeholder":"Empieza a escribir para búscar","prepend-icon":"mdi-worker"},on:{"update:searchInput":function($event){_vm.searchPersonal=$event},"update:search-input":function($event){_vm.searchPersonal=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"headline font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v("\n                                                        "+_vm._s(item.nombre.charAt(0))+"\n                                                        ")]),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.nombre)+" "+_vm._s(item.apellido_paterno)+" "+_vm._s(item.apellido_materno))]),_vm._v(" "),_c('v-list-item-subtitle',[_vm._v("Celular: "+_vm._s(item.celular))])],1)]}}]),model:{value:(_vm.reporte.personal),callback:function ($$v) {_vm.$set(_vm.reporte, "personal", $$v)},expression:"reporte.personal"}})],1)],1)],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }