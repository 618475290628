e
<template>
  <div>
    <v-dialog v-model="dialog" max-width="400px">
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title> Eliminar clientes </v-card-title>

          <v-card-text>
            Esta seguro de eliminar a todos los clientes seleccionados?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelar()">Cancelar</v-btn>
            <v-btn @click="enviar()" class="rounded-pill pa-3" color="primary"
              >Eliminar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNoActualizados" scrollable max-width="400px">
      <v-card>
        <v-container grid-list-md>
          <v-card-title>Clientes no eliminados</v-card-title>
          <v-card-subtitle>
            Los siguientes clientes no se pudieron eliminar. Revise su conexión
            con sus RB.
          </v-card-subtitle>
          <v-responsive
            id="scroll-target"
            class="overflow-y-auto"
            :max-height="450"
          >
            <v-card-text>
              <div v-for="cliente in noActualizados" :key="cliente.id">
                <p>Cliente: {{ cliente.FullName }}</p>
                <p>Problema: {{ cliente.error }}</p>
                <v-divider></v-divider>
              </div>
            </v-card-text>
          </v-responsive>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="enviar()">Intentar de nuevo</v-btn>
            <v-btn class="rounded-pill pa-3" color="primary" @click="ok()"
              >Ok</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Common from "@/js/Common";
export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      seleccionados: [],
      noActualizados: [],
      dialogNoActualizados: false,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    cargarDatos(seleccionados) {
      this.dialog = true;
      this.seleccionados = seleccionados;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    enviar() {
      let dispatch = "UsersModule/OnSave";
      let endpoint = `cliente/eliminacion/masiva`;
      if (this.noActualizados.length > 0) {
        this.seleccionados = this.noActualizados;
      }
      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: {
            seleccionados: this.seleccionados,
          },
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            var noActualizados = result.data.clientes_no_actualizados;
            if (noActualizados.length > 0) {
              this.noActualizados = noActualizados;
              this.dialog = false;
              this.dialogNoActualizados = true;
            } else {
              this.dialog = false;
              this.noActualizados = [];
              this.resolve(true);
            }
          }
        });
    },
    cancelar() {
      this.dialogNoActualizados = false;
      this.dialog = false;
      this.resolve(false);
      this.noActualizados = [];
    },
    ok() {
      this.dialogNoActualizados = false;
      this.dialog = false;
      this.resolve(true);
      this.noActualizados = [];
    },
  },
};
</script>