<template>
  <div>
    <v-layout class="ml-2" wrap>
      <v-btn dense @click="exportar()" class="rounded-pill" color="red" dark>
        <v-icon left>mdi-file-export</v-icon>
        Exportar
      </v-btn>
      <v-spacer></v-spacer>
    </v-layout>
    <v-data-table
      dense
      :search="search"
      :headers="headers"
      :items="elementos"
      :options.sync="options"
      :server-items-length="totalElementos"
      :loading="loading"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-format-horizontal-align-left',
        lastIcon: 'mdi-format-horizontal-align-right',
        prevIcon: 'mdi-chevron-left',
        nextIcon: 'mdi-chevron-right',
        itemsPerPageAllText: 'Todos',
        itemsPerPageText: 'Registros por página:',
        itemsPerPageOptions: [3, 5, 10, 15, -1],
      }"
    >
      <template v-slot:no-results>
        <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
          >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
        >
      </template>
      <template v-slot:item.id="{ item }">
        {{
          elementos
            .map(function (x) {
              return x.id;
            })
            .indexOf(item.id) + 1
        }}
      </template>
      <template v-slot:item.monto="{ item }">
        ${{ item.egreso.monto }}
      </template>
      <template v-slot:item.socio="{ item }">
        {{
          item.persona.nombre +
          " " +
          item.persona.apellido_paterno +
          " " +
          item.persona.apellido_materno
        }}
      </template>
      <template v-slot:item.opciones="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn @click="editarEgreso(item)" icon v-on="on">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar Ingreso</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn @click="eliminarEgreso(item)" icon v-on="on">
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Desactivar Ingreso</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn @click="verArchivo(item)" icon v-on="on">
              <v-icon small> mdi-file </v-icon>
            </v-btn>
          </template>
          <span>Ver comprobante</span>
        </v-tooltip>
      </template>

      <template v-slot:no-data>
        <v-alert :value="true" color="red" dark icon="mdi-alert-circle">
          <v-row align="center">
            <v-col> Ningún dato que mostrar :( </v-col>
            <v-col class="shrink">
              <v-btn @click="getDatos()"
                ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
              >
            </v-col>
          </v-row>
        </v-alert>
      </template>
    </v-data-table>
    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <formEditarEgreso ref="refEditarEgreso"></formEditarEgreso>
    <exportar ref="refExportar"></exportar>
  </div>
</template>
<script>
import formEditarEgreso from "@/js/components/finanzas/egresos/tablas/socios/formEditarEgreso.vue";
import exportar from "@/js/components/exportar_tabla/exportarComponent.vue";
export default {
  components: {
    formEditarEgreso,
    exportar,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      headers: [
        { text: "#", align: "start", value: "id", sortable: true },
        { text: "Monto", value: "monto", sortable: true },
        { text: "Socio", value: "socio", sortable: false },
        { text: "Tipo", value: "tipo.tipo", sortable: false },
        { text: "Fecha", value: "egreso.fecha", sortable: false },
        { text: "Hora", value: "egreso.hora", sortable: false },
        { text: "Observaciones", value: "observaciones", sortable: true },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
    };
  },
  mounted() {},
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos(search) {
      if (search != null) {
        this.search = search;
      }

      this.loading = true;

      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `socios/egreso/tabla`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.egresos.data;
          this.totalElementos = result.data.egresos.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    eliminarEgreso(elemento) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `socio/` + elemento.id;
            let event = "delete";

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: elemento,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(elemento), 1);
              });
          } else {
          }
        });
    },
    editarEgreso(elemento) {
      this.ingresoActualizar = elemento;
      const egreso = Object.assign({}, elemento);
      this.$refs.refEditarEgreso.esperarDatos(egreso).then((confirm) => {
        if (confirm) {
          this.actualizarEgreso(confirm);
        }
      });
    },
    actualizarEgreso(respuesta) {
      let formDataP = new FormData();
      formDataP.append("id", respuesta.id);
      formDataP.append("monto", respuesta.monto);
      formDataP.append("hora", respuesta.hora);
      formDataP.append("fecha", respuesta.fecha);
      formDataP.append("archivo", respuesta.archivo);
      formDataP.append("empresa_id", respuesta.empresa_id);
      formDataP.append("persona_id", respuesta.persona_id);
      formDataP.append("tipo_id", respuesta.tipo_id);
      formDataP.append(
        "detalles",
        respuesta.detalles != null ? respuesta.detalles : ""
      );
      formDataP.append(
        "observaciones",
        respuesta.observaciones != null ? respuesta.observaciones : ""
      );

      let dispatch = "UsersModule/OnSave";
      let endpoint = "egresos/actualizar/socio";

      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: formDataP,
        })
        .then((result) => {
          this.elementos.splice(
            this.elementos.indexOf(this.ingresoActualizar),
            1,
            result.data.egreso
          );
        });
    },
    verArchivo(item) {
      if (item.egreso.archivo != null) {
        window.open(item.egreso.archivo, "_blank");
      } else {
        this.$store.dispatch("notificaciones/add", {
          tipo: "warning",
          mensaje: "Sin archivo  :/",
        });
      }
    },
    exportar() {
      this.$refs.refExportar.cargarDatos("socios");
    },
  },
};
</script>
