<template>
    <div class="component">
        <base-component>
            <div slot="component">
                <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
                <v-layout row wrap>
                        <v-flex xs12 md12>
                             <v-container grid-list-md>
                             <v-card-title primary-title>
                                 Datos de usuario admnistrador
                             </v-card-title>   
                        <v-form dense ref="form" v-model="valid" lazy-validation>
                            
                            <v-btn text @click="regresar()">Regresar</v-btn>
                            <v-btn :disabled="!valid" color="primary" @click="validate">Continuar</v-btn>
                            </v-form>
                            </v-container>
                        </v-flex>
                    </v-layout>
            </div>
        </base-component>
    </div>
</template>
<script>
import Common from '../../Common';
import Persona from './Persona';

export default {

    data() {
        return {
            persona: new Persona(),
            valid: true,
            nombreReglas: [v => !!v || "Se necesita el nombre"],
            generalRegla: [v => !!v || "Se necesita el campo"],
            rulesFile: [
                value => !value || value.length == 0 || value[0].size < 2000000 || 'Debe ser menor a 2 MB!',
            ],

            
        };
    },
    mounted() {

    },
    watch: {
        
    },
    methods: {
        validate() {
            if (this.$refs.form.validate()) {
               var empresa = this.$store.getters.getEmpresa;
               this.persona.cp = empresa.cp;
               this.telefono_persona = empresa.telefono;
               this.persona.direccion = empresa.direccion;
               this.persona.municipio = empresa.municipio;
               this.persona.estado = empresa.estado;
               this.persona.colonia = empresa.colonia;
               this.$store.dispatch('datosPaso2',{step:3,persona:this.persona})
            }
        },
        regresar(){
            this.$store.dispatch('asignaE1',1);
        }
    }

};
</script>