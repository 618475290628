<template>
     <div class="component">
        <base-component>
            <div slot="component">
                 <v-container mt-0 grid-list-md>
                  <v-layout row wrap>
                        <v-flex xs12 md3>
                            <widget
                                icon="mdi-cash-multiple"
                                subTitle="Todos los ingresos"
                                :title="formatoDinero(ingreso)"
                                supTitle="Año actual"
                                color="#689F38"
                            />
                        </v-flex>
                        <v-flex  xs12 md3>
                            <widget
                                icon="mdi-cash-multiple"
                                subTitle="Todos los egresos"
                                :title="formatoDinero(egreso)"
                                supTitle="Año actual"
                                color="#F4511E"
                            />
                        </v-flex>
                        <v-flex  xs12 md3>
                            <widget
                                icon="mdi-cash"
                                subTitle="Dinero actual"
                                :title="formatoDinero(actual)"
                                supTitle="Año actual"
                                color="#0097A7"
                            />
                        </v-flex>
                        <v-flex  xs12 md3>
                            <widget
                                icon="mdi-factory"
                                subTitle="Valor del inventario"
                                :title="formatoDinero(inventario)"
                                supTitle="Todo el tiempo"
                                color="#546E7A"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                    <v-flex xs12>
                         <apexchart type="bar" height="350" :options="chartOptionsEgresos" :series="seriesEgresos"></apexchart>
                    </v-flex>
                </v-layout> 
                 <v-layout row wrap>
                    <v-flex xs12>
                         <apexchart type="bar" height="350" :options="chartOptionsIngresos" :series="seriesIngresos"></apexchart>
                    </v-flex>
                </v-layout> 
                <v-layout row wrap>
                    <v-flex xs12>
                         <graficaIngresosEgresos></graficaIngresosEgresos>
                    </v-flex>
                </v-layout> 
                <v-layout row wrap>
                    <v-flex xs12>
                         <graficaBalances></graficaBalances>
                    </v-flex>
                </v-layout> 
                 </v-container>
            </div>
        </base-component>
    </div>
</template>
<script>
import Common from '@/js/Common'
import widget from "@/js/components/utileria/widget.vue";
import apexchart from 'vue-apexcharts'
import graficaIngresosEgresos from '@/js/components/finanzas/finanzas/graficaIngresosEgresos.vue'
import GraficaBalances from '@/js/components/finanzas/finanzas/graficaBalances.vue'
  export default {
    components: {
        apexchart,
        graficaIngresosEgresos,
        GraficaBalances,
    }, data() {
        return {
             showChart: false,
             ingreso:"",
             egreso:"",
             actual:"",
             inventario:"",
        //egresos mes por tipo
        seriesEgresos: [],
      chartOptionsEgresos: {
        chart: {
          type: 'bar',
          height: 350,
        },
        title: {
            text: 'Egresos por mes',
            style: {
                fontSize: '14px'
            }
            },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            
          }
        },
        dataLabels: {
          enabled: false,
          
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']

        },
        xaxis: {
          categories: ['Ene','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov','Dic']
        },
        yaxis: {
          title: {
            text: '$ Cantidad'
          },
          labels: {
                formatter: function (y) {
                  return Common.formatMoney(y)
                }
              }

        },
        fill: {
          opacity: 1,

        },
        tooltip: {
          y: {
            formatter: function (val) {
              return Common.formatMoney(val)
            }
          }
        }
      },
      
      //ingresos
       seriesIngresos: [],
      chartOptionsIngresos: {
        chart: {
          type: 'bar',
          height: 350,
        },
        title: {
            text: 'Ingresos por mes',
            style: {
                fontSize: '14px'
            }
            },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            
          }
        },
        dataLabels: {
          enabled: false,
          
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']

        },
        xaxis: {
          categories: ['Ene','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov','Dic']
        },
        yaxis: {
          title: {
            text: '$ Cantidad'
          },
          labels: {
                formatter: function (y) {
                  return Common.formatMoney(y)
                }
              }
        },
        fill: {
          opacity: 1,

        },
        tooltip: {
          y: {
            formatter: function (val) {
              return Common.formatMoney(val)
            }
          }
          
        }
      },
        };
    },
    mounted() {
        this.getDatos();
    },
    computed: {
        
    },
    methods: {
      getDatos() {
            this.$store.dispatch('UsersModule/OnGet', {
                url: `contabilidad/finanzas/datos`,
                data: {}

            }).then(result => {
              
              this.ingreso=parseFloat(result.data.ingresos);
             this.egreso=parseFloat(result.data.egresos);
             this.actual=parseFloat(result.data.dineroActual);
             this.inventario=parseFloat(result.data.valorInventario);


             this.mesesEgreso(result.data.mesesEgresos);
             this.mesesIngreso(result.data.mesesIngresos)
              this.showChart = true
              
            
            })
        },
        mesesEgreso(datos){
          let compras=[];
          let empleados=[];
          let negocios =[];
          let otros =[];
          let socios =[];

          for (let index = 0; index < datos.length; index++) {
               compras.push(datos[index].compras);
               empleados.push(datos[index].empleados);
               negocios.push(datos[index].negocios);
               otros.push(datos[index].otros);
               socios.push(datos[index].socios);
            
          }

          this.seriesEgresos= [{
            name: 'Compras',
            data: compras
          }, {
            name: 'Empleados',
            data: empleados
          }, {
            name: 'Negocios',
            data: negocios
            
          }, {
            name: 'Socios',
            data: socios
            
          }, {
            name: 'Otros',
            data: otros
            
          }
          ];

          

        },
        mesesIngreso(datos){
          let mensualidades=[];
          let tickets=[];
          let actividades =[];
          let instalaciones =[];
          let otros =[];
          let negocios =[];

          for (let index = 0; index < datos.length; index++) {
               mensualidades.push(datos[index].Mensualidades);
               tickets.push(datos[index].Tickets);
               actividades.push(datos[index].Actividades);
               instalaciones.push(datos[index].Instalaciones);
               negocios.push(datos[index].Negocios);
               otros.push(datos[index].Otros);
            
          }

          this.seriesIngresos= [{
            name: 'Mensualidades',
            data: mensualidades
          }, {
            name: 'Tickets',
            data: tickets
          }, {
            name: 'Actividades',
            data: actividades
            
          }, {
            name: 'Instalaciones',
            data: instalaciones
            
          },{
            name: 'Negocios',
            data: negocios
            
          },
          {
            name: 'Otros',
            data: otros
            
          }
          ];

        },
        formatoDinero(cantidad){
           return Common.formatMoney(cantidad,2)
        }
    }

};
</script>