<template>
    <div class="component mt-4">
        <base-component>
            <div slot="component">
            <v-layout row wrap>
                <v-flex xs12>
                    
                
                <v-card class="cool pa-4">
                    <v-row>
                        <v-col cols="12" md="6">
                    <v-card-title class="display-1">
                       
                        <strong>Egresos</strong>
                       
                    </v-card-title>
                     </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="12" md="6">
                            <v-tabs
                                center-active
                                dense
                                right
                                v-model="tablaActiva"
                                show-arrows
                                >
                                <v-tab @click="mostrarTabla('Todos')" value="0">
                                    Todos
                                </v-tab>
                                <v-tab @click="mostrarTabla('Otro')" value="1">
                                    Otros
                                </v-tab>
                                <v-tab @click="mostrarTabla('Socio')" value="2">
                                    Socios
                                </v-tab>
                                <v-tab @click="mostrarTabla('Empleado')" value="3">
                                    Empleado
                                </v-tab>
                                <v-tab @click="mostrarTabla('Compras')" value="4">
                                    Compras
                                </v-tab>
                                <v-tab @click="mostrarTabla('Sucursal')" value="5">
                                    Sucursal
                                </v-tab>
                        </v-tabs>
                        </v-col>
                       </v-row>
                       
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6">
                                 <v-btn dark @click="nuevoEgreso()" class="rounded-pill" color="red">
                            <v-icon left>mdi-plus</v-icon>
                            Agregar
                        </v-btn>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="12" md="6">
                            <v-text-field class="rounded-pill" solo v-model="search" @keyup.enter="buscar()" append-icon="mdi-magnify" label="Buscador" single-line hide-details></v-text-field>

                            </v-col>
                        </v-row>
                    </v-card-text>
                    
                    <todos ref="refTodos" v-if="tablas.Todos"></todos>
                    <otros ref="refOtro" v-if="tablas.Otro"></otros>
                    <socios ref="refSocio" v-if="tablas.Socio"></socios>
                    <empleado ref="refEmpleado" v-if="tablas.Empleado"></empleado>
                    <compras ref="refCompras" v-if="tablas.Compras"></compras>
                    <sucursal ref="refSucursal" v-if="tablas.Sucursal"></sucursal>
                </v-card>
                </v-flex>
            </v-layout>
                <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
                <formEgreso ref="nuevoEgreso"></formEgreso>
                <exportar ref="refExportar"></exportar>
                
            </div>
        </base-component>
    </div>
</template>

<script>
import todos from '@/js/components/finanzas/egresos/tablas/todos.vue';
import socios from '@/js/components/finanzas/egresos/tablas/socios/tablaSocios.vue';
import empleado from '@/js/components/finanzas/egresos/tablas/empleado/tablaEmpleado.vue';
import compras from '@/js/components/finanzas/egresos/tablas/compras/tablaCompras.vue';
import sucursal from '@/js/components/finanzas/egresos/tablas/negocios/tablaSucursales.vue';
import formEgreso from '@/js/components/finanzas/egresos/nuevo/nuevoEgreso.vue';
import exportar from '@/js/components/exportar_tabla/exportarComponent.vue';
import otros from '@/js/components/finanzas/egresos/tablas/otros/tablaOtros.vue';
import LocalStorage from '@/js/LocalStorage.js';

export default {
    components:{
        formEgreso,
        todos,
        otros,
        socios,
        empleado,
        compras,
        sucursal,
        exportar
    },
    data() {
        return {
            tablas:{
                Todos: false,
                Otro: false,
                Socio: false,
                Empleado:false,
                Compras: false,
                Sucursal:false,
                
            },
            dialogIngreso: false,
            ingresoActualizar: {},
            search: "",
            tablaActiva:1,
            value: ""
        };
    },
    mounted() {
       
          var tablaActual = LocalStorage.get('TABLA_ACTUAL');
          if(tablaActual){
            var obj = this.tablas;
            var index = 0;
            for (const prop in obj) {
                if(prop === tablaActual.tabla){
                    obj[prop] = true;
                    this.tablaActiva = index;
                }else{
                    obj[prop] = false;
                }
                index++;
            } 
          }else{
              this.tablas.Todos = true;
          }
    },
    watch: {
        tablas(val){
            

        }
    },
    computed: {},
    methods: {
        
        mostrarTabla(tabla){
            
            LocalStorage.set('TABLA_ACTUAL',{tabla:tabla})
            var obj = this.tablas;
            for (const prop in obj) {
                
                if(prop === tabla){
                    obj[prop] = true;
                }else{
                    obj[prop] = false;
                }
               
            }
        },
        nuevoEgreso(){
            this.$refs.nuevoEgreso.mostrar().then((confirm) => {
                
                if(confirm){
                    var obj = this.tablas;
                    for (const prop in obj) {
                        
                        if(obj[prop]){
                               switch (prop) {
                                case "Otro":
                                    this.$refs.refOtro.getDatos();
                                break;
                                case "Empleado":
                                    this.$refs.refEmpleado.getDatos();
                                break;
                                case "Compras":
                                    this.$refs.refCompras.getDatos();
                                break;
                                case "Sucursal":
                                    this.$refs.refSucursal.getDatos();
                                break;
                                case "Socio":
                                    this.$refs.refSocio.getDatos();
                                break;
                                case "Todos":
                                    this.$refs.refTodos.getDatos();
                                break;
                                default:
                                    this.$refs.refTodos.getDatos();
                                break;
                            }
                        }
                    
                    }
                    
                }
            });
        },
        buscar(){
                var obj = this.tablas;
                    for (const prop in obj) {
                        
                        if(obj[prop]){
                               switch (prop) {
                                case "Otro":
                                    this.$refs.refOtro.getDatos(this.search);
                                break;
                                case "Empleado":
                                    this.$refs.refEmpleado.getDatos(this.search);
                                break;
                                case "Compras":
                                    this.$refs.refCompras.getDatos(this.search);
                                break;
                                case "Sucursal":
                                    this.$refs.refSucursal.getDatos(this.search);
                                break;
                                case "Socio":
                                    this.$refs.refSocio.getDatos(this.search);
                                break;
                                case "Todos":
                                    this.$refs.refTodos.getDatos(this.search);
                                break;
                                default:
                                    this.$refs.refTodos.getDatos(this.search);
                                break;
                            }
                        }
                    
                    }
        },
        exportar(){
            this.$refs.refExportar.cargarDatos("egresos");
        },

    
    },
    
};
</script>