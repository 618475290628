/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import 'leaflet/dist/leaflet.css';
require('./bootstrap');


import Echo from "laravel-echo"

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    wsHost: window.location.hostname,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    wsPort: 6001,
    forceTLS: false,
    disableStats: true,
});




window.Vue = require('vue');
import store from './vuex/Store.js';

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader


Vue.use(Vuetify)
import 'leaflet/dist/leaflet.css';


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//detectaa si esta ejecutandose en un celular
Vue.prototype.$isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);


Vue.config.productionTip = false
const opts = {
  theme:{  
  dark: false, // para el modo obscuro 
    themes: {
      light: {
        primary: '#0085D1',
        secondary: '#005285',
        accent: '#C03724',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#212121',
      },
      dark: {
        primary: '#0085D1',
        secondary: '#005285',
        accent: '#C03724',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#212121',
      }
    }
    },
    icons: {
      iconfont: 'mdiSvg',
    },
  }

 

const app = new Vue({
    vuetify: new Vuetify(opts),
    el: '#app',
    store,
    
});


