<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="cool pa-4">
              <v-card-title class="display-1">
                <strong> Personal </strong>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-btn
                      dense
                      @click="nuevo()"
                      color="red"
                      class="rounded-pill"
                      dark
                    >
                      <v-icon left>mdi-plus</v-icon>
                      Agregar
                    </v-btn>
                    <v-btn
                      dense
                      @click="exportar()"
                      color="red"
                      class="rounded-pill"
                      dark
                    >
                      <v-icon left>mdi-file-export</v-icon>
                      Exportar
                    </v-btn>
                  </v-col>
                  <v-spacer></v-spacer>

                  <v-col cols="12" md="6">
                    <v-text-field
                      class="rounded-pill"
                      v-model="search"
                      @keyup.enter="getDatos"
                      append-icon="mdi-magnify"
                      label="Buscador"
                      single-line
                      solo
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-data-table
                dense
                :search="search"
                :headers="headers"
                :items="elementos"
                :options.sync="options"
                :server-items-length="totalElementos"
                :loading="loading"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-format-horizontal-align-left',
                  lastIcon: 'mdi-format-horizontal-align-right',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  itemsPerPageAllText: 'Todos',
                  itemsPerPageText: 'Registros por página:',
                  itemsPerPageOptions: [15, 50, 100, 500, -1],
                }"
              >
                <template v-slot:item.imagen="{ item }">
                  <v-img
                    class="cool-xs ma-1"
                    v-if="item.imagen"
                    :src="item.imagen"
                    height="60"
                    width="60"
                  ></v-img>
                  <v-img
                    v-else
                    class="cool-xs ma-1"
                    src="/img/noimagen.jpg"
                    height="60"
                    width="60"
                  ></v-img>
                </template>
                <template v-slot:item.contacto="{ item }">
                  <v-chip
                    v-if="item.celular"
                    class="ma-1"
                    outlined
                    small
                    dark
                    color="green darken-4"
                    @click="enviarWhats(item)"
                    ><v-icon left> mdi-whatsapp </v-icon
                    >{{ item.celular }}</v-chip
                  >
                  <v-chip
                    outlined
                    small
                    dark
                    class="ma-1"
                    color="deep-orange darken-4"
                    ><v-icon left> mdi-gmail </v-icon
                    >{{ item.user.email }}</v-chip
                  >
                </template>
                <template v-slot:item.tipo="{ item }">
                  <v-chip small color="indigo" outlined>
                    <v-avatar left>
                      <v-icon>mdi-account-circle</v-icon>
                    </v-avatar>
                    {{ item.tipo.name }}
                  </v-chip>
                </template>
                <template v-slot:item.actividades="{ item }">
                  <strong>{{ item.ActividadesTotal }}</strong>
                </template>

                <template v-slot:item.completados="{ item }">
                  <strong>{{ item.TicketsCompletadosTotal }}</strong>
                </template>
                <template v-slot:item.instalaciones="{ item }">
                  <strong>{{ item.InstalacionesTotal }}</strong>
                </template>
                <template v-slot:item.opciones="{ item }">
                  <v-tooltip v-if="item.eliminado == false" top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="detalles(item)" icon v-on="on">
                        <v-icon small> mdi-dots-horizontal </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver detalles</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="editar(item)" icon v-on="on">
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar Datos</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="eliminar(item)" icon v-on="on">
                        <v-icon small> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar Personal</span>
                  </v-tooltip>
                </template>
                <template v-slot:no-results>
                  <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                    >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                  >
                </template>

                <template v-slot:no-data>
                  <v-alert
                    :value="true"
                    color="red"
                    dark
                    icon="mdi-alert-circle"
                  >
                    <v-row align="center">
                      <v-col> Ningún dato que mostrar :( </v-col>
                      <v-col class="shrink">
                        <v-btn @click="getDatos()"
                          ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-alert>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <exportar ref="refExportar"></exportar>
        <editarPersona ref="refEditar"></editarPersona>
        <mensajeWhats ref="refWhats"></mensajeWhats>
      </div>
    </base-component>
  </div>
</template>

<script>
import editarPersona from "@/js/components/personal/todos/editarPersona.vue";
import exportar from "@/js/components/exportar_tabla/exportarComponent.vue";
import mensajeWhats from "@/js/components/clientes/listar_clientes/mensajeWhatsapp.vue";

export default {
  components: {
    editarPersona,
    exportar,
    mensajeWhats,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: false,
      options: {},
      status_id: "",
      estados: [],
      headers: [
        { text: "Imagen", value: "imagen", sortable: false },
        { text: "Nombre", value: "FullName", sortable: false },
        { text: "Dirección", value: "direccion" },
        { text: "Rol", value: "tipo" },
        { text: "Contacto", value: "contacto", sortable: false },
        {
          text: "Instalaciones",
          value: "instalaciones",
          sortable: false,
        },
        { text: "Trabajos", value: "actividades", sortable: false },
        { text: "Ticketes", value: "completados", sortable: false },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
    };
  },
  mounted() {},
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos() {
      if (this.loading) return;

      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `tabla/personal`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.personas.data;
          this.totalElementos = result.data.personas.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    editar(item) {
      this.personaActualizar = item;
      const persona = Object.assign({}, item);
      this.$refs.refEditar.cargarDatos(persona).then((confirm) => {
        if (confirm) {
          this.elementos.splice(
            this.elementos.indexOf(this.personaActualizar),
            1,
            confirm
          );
        }
      });
    },

    eliminar(item) {
      this.$refs.confirmar
        .open("Confirmación", "¿Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `personal/` + item.id;
            let event = "delete";

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: item,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(item), 1);
              });
          } else {
          }
        });
    },
    nuevo() {
      document.location.href = "/vista/registro/personal";
    },
    detalles(item) {
      document.location.href = "vista/persona/detalles/" + item.id;
    },

    exportar() {
      this.$refs.refExportar.cargarDatos("personas");
    },
    enviarWhats(item) {
      this.$refs.refWhats.cargarDatos(item.celular);
    },
  },
};
</script>
