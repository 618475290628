<template>
  <v-dialog v-model="dialog" max-width="400px">
    <v-card>
      <v-container grid-list-md>
        <v-card-title primary-title>
          Activar o desactivar cliente en el Mikrotik
        </v-card-title>
        <v-card-text>
          <v-form dense ref="form" v-model="valid" lazy-validation>
            <v-select
              :items="estados"
              v-model="status_id"
              label="Status"
              item-value="id"
              item-text="estado"
              :rules="generalRegla"
              prepend-icon="mdi-power-settings"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelar()">Cancelar</v-btn>
          <v-btn
            :disabled="!valid"
            @click="actualizar()"
            class="rounded-pill pa-3"
            color="primary"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import Common from "@/js/Common";
export default {
  data() {
    return {
      valid: true,
      estados: [
        {
          id: 1,
          estado: "Activar",
        },
        {
          id: 2,
          estado: "Suspender",
        },
      ],
      status_id: "0",
      dilog: false,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      reject: null,
      resolve: null,
      cliente: null,
      dialog: false,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    cargarDatos(cliente) {
      this.cliente = cliente;
      if (cliente.addres_list) {
        this.status_id = 2;
      } else {
        this.status_id = 1;
      }
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    cancelar() {
      this.dialog = false;
      this.resolve(false);
    },
    actualizar() {
      if (this.$refs.form.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint =
          `router/cambiar/estado/cliente/` + this.cliente.router_id;

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: {
              status_id: this.status_id,
              cliente: this.cliente,
            },
          })
          .then((result) => {
            this.dialog = false;
            this.resolve(result.data.resultado);
          });
      }
    },
  },
};
</script>