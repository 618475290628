var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"cool",attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-spacer'),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"rounded-pill",attrs:{"solo":"","append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDatos($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_vm._v(" "),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.elementos,"options":_vm.options,"server-items-length":_vm.totalElementos,"loading":_vm.loading,"footer-props":{
              showFirstLastPage: true,
              firstIcon: 'mdi-format-horizontal-align-left',
              lastIcon: 'mdi-format-horizontal-align-right',
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right',
              itemsPerPageAllText: 'Todos',
              itemsPerPageText: 'Registros por página:',
              itemsPerPageOptions: [10, 100, 500, 1000, -1],
            }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.antena",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.abrirAntena(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-volume-high")]),_vm._v("\n                "+_vm._s(item.antena.cpe.producto))],1)]}},{key:"item.cliente",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":"cyan","outlined":""},on:{"click":function($event){return _vm.abrirCliente(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_vm._v("\n                "+_vm._s(item.FullName))],1)]}},{key:"item.ip",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"blue"},on:{"click":function($event){return _vm.abrirIp(item.antena.ip)}}},[_vm._v(_vm._s(item.antena.ip))])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('div',[_c('v-chip',{staticClass:"ma-1",attrs:{"small":"","dense":"","color":_vm.getColorStatus(item.status_cliente_id),"dark":""},on:{"click":function($event){return _vm.editarEstado(item)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-laptop-mac")]),_vm._v("\n\n                  "+_vm._s(item.status.status))],1)],1)]}},{key:"item.velocidad",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"ma-1"},[_c('v-icon',{staticClass:"ml-2",attrs:{"color":"blue"}},[_vm._v("mdi-arrow-up-bold-circle-outline")]),_vm._v(" "),_c('strong',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(item.servicios[0].subida)+" ")]),_vm._v("\n                Mb.\n              ")],1),_vm._v(" "),_c('div',{staticClass:"ma-1"},[_c('v-icon',{staticClass:"ml-2",attrs:{"color":"pink"}},[_vm._v("mdi-arrow-down-bold-circle-outline")]),_vm._v(" "),_c('strong',{staticClass:"ml-2"},[_vm._v(_vm._s(item.servicios[0].bajada)+" ")]),_vm._v("\n                Mb.\n              ")],1)]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"lime lighten-1","icon":"mdi-alert"}},[_vm._v("Tu búsqueda \""+_vm._s(_vm.search)+"\" no se encuentra.")])]},proxy:true},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"red","dark":"","icon":"mdi-alert-circle"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_vm._v(" Ningún dato que mostrar :( ")]),_vm._v(" "),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{on:{"click":function($event){return _vm.getDatos()}}},[_c('v-icon',{attrs:{"pl-4":""}},[_vm._v("mdi-cached")]),_vm._v(" Refrescar\n                    ")],1)],1)],1)],1)]},proxy:true}])})],1)],1)],1)],1),_vm._v(" "),_c('BaseConfirmacion',{ref:"confirmar"}),_vm._v(" "),_c('editarEstadoCliente',{ref:"refEstadoCliente"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }