var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component mt-4"},[_c('base-component',[_c('div',{attrs:{"slot":"component"},slot:"component"},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"cool pa-4"},[_c('v-card-title',{staticClass:"display-1"},[_c('strong',[_vm._v("Servicios y paquetes contratados")])]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.elementos,"footer-props":{
                            firstIcon: 'mdi-format-horizontal-align-left',
                            lastIcon: 'mdi-format-horizontal-align-right',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            itemsPerPageAllText: 'Todos',
                            itemsPerPageText:'Registros por página:',
                            itemsPerPageOptions: [3,5,10,15,-1]
                            }},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"lime lighten-1","icon":"mdi-alert"}},[_vm._v("Tu búsqueda \""+_vm._s(_vm.search)+"\" no se encuentra.")])]},proxy:true},{key:"item.id",fn:function(ref){
                            var item = ref.item;
return [_vm._v("\n                       "+_vm._s(_vm.elementos.map(function(x) {return x.id; }).indexOf(item.id)+1)+"\n                    ")]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"red","dark":"","icon":"mdi-alert-circle"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_vm._v("\n                                Ningún dato que mostrar :(\n                            ")]),_vm._v(" "),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{on:{"click":function($event){return _vm.getDatos()}}},[_c('v-icon',{attrs:{"pl-4":""}},[_vm._v("mdi-cached")]),_vm._v(" Refrescar")],1)],1)],1)],1)]},proxy:true}])})],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }