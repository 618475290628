<template>
  <div>
    <v-card class="cool pa-2">
      <div id="chart">
        <apexchart
          type="line"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </v-card>
  </div>
</template>
<script>
import apexchart from "vue-apexcharts";
import Common from "@/js/Common";
export default {
  components: {
    apexchart,
  },
  data() {
    return {
      series: [
        {
          name: "Dinero esperado",
          type: "column",
          data: [],
        },
        {
          name: "Total Clientes",
          type: "line",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
        },
        colors: ["#FFC107", "#2196F3"],
        stroke: {
          width: [0, 2],
        },
        title: {
          text: "Relacion Clientes/Dinero",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
        },
        labels: [
          "Ene",
          "Feb",
          "Mar",
          "Abr",
          "May",
          "Jun",
          "Jul",
          "Ago",
          "Sep",
          "Oct",
          "Nov",
          "Dic",
        ],
        yaxis: [
          {
            title: {
              text: "Dinero esperado",
            },
            labels: {
              formatter: function (y) {
                return Common.formatMoney(y);
              },
            },
          },

          {
            opposite: true,
            title: {
              text: "Clientes totales",
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `home/empresa/clientes/dinero`,
          data: {},
        })
        .then((result) => {
          this.series = [
            {
              name: "Total Pagos",
              type: "column",
              data: result.data.pagos,
            },
            {
              name: "Total Clientes",
              type: "line",
              data: result.data.clientes_totales,
            },
          ];
        });
    },
  },
};
</script>