<template>
    <div class="component mt-4">
        <base-component>
            <div slot="component">
                <v-layout row wrap>
                    <v-flex xs12>
                        
                <v-card class="cool pa-4">
                    <v-card-title class="display-1">
                      <strong>Servicios y paquetes contratados</strong>  
                    </v-card-title>
                   
                    <v-divider></v-divider>
                    <v-data-table :search="search" :headers="headers" :items="elementos" :footer-props="{
                                firstIcon: 'mdi-format-horizontal-align-left',
                                lastIcon: 'mdi-format-horizontal-align-right',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                itemsPerPageAllText: 'Todos',
                                itemsPerPageText:'Registros por página:',
                                itemsPerPageOptions: [3,5,10,15,-1]
                                }">
                        <template v-slot:no-results>
                                    <v-alert
                                    :value="true"
                                    color="lime lighten-1"
                                    icon="mdi-alert"
                                    >Tu búsqueda "{{ search }}" no se encuentra.</v-alert>
                        </template>
                        <template v-slot:item.id="{ item }">
                           {{ elementos.map(function(x) {return x.id; }).indexOf(item.id)+1}}
                        </template>
                      

                        <template v-slot:no-data>
                            <v-alert :value="true" color="red"
                                    dark icon="mdi-alert-circle">
                                <v-row align="center">
                                <v-col>
                                    Ningún dato que mostrar :(
                                </v-col>
                                <v-col class="shrink">
                                    <v-btn @click="getDatos()"><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn>
                                </v-col>
                                </v-row>
                            </v-alert>
                        </template>
                    </v-data-table>
                    
                </v-card>
                    </v-flex>

            </v-layout>
   

            </div>
        </base-component>
    </div>
</template>

<script>
export default {
    components:{
    },
    data() {
        return {
            search: '',
            elementos: [],
            options: {},
            generalRegla: [v => !!v || "Se necesita el campo",],

            headers: [{ text: 'id', align: 'start', value: 'id' },
                { text: 'Nombre', value: 'nombre', sortable: true, },
                { text: 'Costo', value: 'costo' },
                { text: 'Servicio', value: 'tipo.tipo', sortable: false, },
                { text: 'Subida', value: 'subida', sortable: true, },
                { text: 'Bajada', value: 'bajada', sortable: true, },
                { text: 'Detalles', value: 'detalles', sortable: false, },
            ],
           
        };
    },
    mounted() {
        this.getDatos();
    },
    watch: {
        

    },
    computed: {
       
  
    },
    methods: {
        
        getDatos(){
            this.$store.dispatch('UsersModule/OnGet', {
                url: 'contratados/servicios/cliente',
                data: {}
            }).then(result => {
                
                this.elementos = result.data.servicios;
                
            })
        },
        
       

    }
};
</script>