<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <v-layout row wrap>
          <v-flex xs12 md12>
            <div style="height: 710px; width: 100%">
              <l-map
                v-if="showMap"
                :zoom="zoom"
                :center="center"
                :options="mapOptions"
                style="height: 100%"
                @update:center="centerUpdate"
                @update:zoom="zoomUpdate"
              >
                <l-tile-layer :url="url" :attribution="attribution" />
                <l-marker
                  v-for="marker in markers"
                  :key="marker.id"
                  :visible="marker.visible"
                  :lat-lng.sync="marker.position"
                  :icon="marker.icon"
                >
                  <l-tooltip :content="marker.tooltip" />

                  <l-icon
                    icon-url="img/markerCasa4.png"
                    :icon-anchor="staticAnchor"
                    :icon-size="[70, 70]"
                  />
                </l-marker>
              
                <l-marker
                  v-for="marker in markersZonas"
                  :key="marker.id"
                  :visible="marker.visible"
                  :lat-lng.sync="marker.position"
                  :icon="marker.icon"
                >
                  <l-tooltip :content="marker.tooltip" />

                  <l-icon
                    icon-url="img/markerTorre.png"
                    :icon-anchor="[120, 215]"
                    :icon-size="[230, 230]"
                  />
                </l-marker>
                <l-marker
                  v-for="marker in markersNegocios"
                  :key="marker.id"
                  :visible="marker.visible"
                  :lat-lng.sync="marker.position"
                  :icon="marker.icon"
                >
                  <l-tooltip :content="marker.tooltip" />

                  <l-icon
                    icon-url="img/markerNegocio.png"
                    :icon-anchor="staticAnchor"
                    :icon-size="[90, 90]"
                  />
                </l-marker>
                <l-marker
                  v-for="marker in markersProveedores"
                  :key="marker.id"
                  :visible="marker.visible"
                  :lat-lng.sync="marker.position"
                  :icon="marker.icon"
                >
                  <l-tooltip :content="marker.tooltip" />

                  <l-icon
                    icon-url="img/markerProveedor.png"
                    :icon-anchor="staticAnchor"
                    :icon-size="[100, 100]"
                  />
                </l-marker>
                <l-marker
                  :key="empresa.id"
                  :visible="empresa.visible"
                  :lat-lng.sync="empresa.position"
                  :icon="empresa.icon"
                >
                  <l-tooltip :content="empresa.tooltip" />

                  <l-icon
                    icon-url="img/markerEmpresa.png"
                    :icon-anchor="staticAnchor"
                    :icon-size="[90, 90]"
                  />
                </l-marker>
              </l-map>
            </div>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>

<script>
import { latLng, icon } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LTooltip,
  LIcon,
} from "vue2-leaflet";

export default {
  name: "Example",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon,
  },
  data() {
    return {
      zoom: 5,
      center: latLng(23.0, -102.0),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      currentCenter: latLng(23.0, -102.0),
      showParagraph: true,
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: false,
      loadingButton: false,
      staticAnchor: [26, 47],
      markers: [],
      markersZonas: [],
      markersNegocios: [],
      markersProveedores: [],
      empresa:{
        id:"1e",
        visible:false,
        position:null,
        tooltip:"",
      }
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  watch: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `ubicaciones/clientes`,
          data: {},
        })
        .then((result) => {
          
          if ([200, 201].includes(result.status)) {
            this.crearMarcadoresClientes(result.data.clientes);
            this.crearMarcadoresZonas(result.data.zonas);
            this.crearMarcadoresNegocios(result.data.negocios);
            this.crearMarcadorEmpresa(result.data.empresa);
            this.crearMarcadoresProveedores(result.data.proveedores);
            this.showMap = true;
          }
        });
    },
    crearMarcadoresClientes(clientes) {
      for (let index = 0; index < clientes.length; index++) {
        const cliente = clientes[index];
        if(cliente.latitud && cliente.longitud){
        let marcador = {
          id: cliente.id,
          position: { lat: cliente.latitud, lng: cliente.longitud },
          tooltip:
            cliente.nombre +
            " " +
            cliente.apellido_paterno +
            " " +
            cliente.apellido_materno,
          draggable: false,
          visible: true,
        };

        this.markers.push(marcador);
        }
        
      }
    },
    crearMarcadoresZonas(zonas) {
      for (let index = 0; index < zonas.length; index++) {
        const zona = zonas[index];
        if(zona.latitud && zona.longitud){
        
        let marcador = {
          id: + zona.id+'z',
          position: { lat: zona.latitud, lng: zona.longitud },
          tooltip: zona.nombre,
          draggable: false,
          visible: true,
        };

        this.markersZonas.push(marcador);
        }
      }
    },
    crearMarcadoresProveedores(proveedores) {
      for (let index = 0; index < proveedores.length; index++) {
        const proveedor = proveedores[index];
        if(proveedor.latitud && proveedor.longitud){
        
        let marcador = {
          id: + proveedor.id+'p',
          position: { lat: proveedor.latitud, lng: proveedor.longitud },
          tooltip: proveedor.nombre,
          draggable: false,
          visible: true,
        };

        this.markersProveedores.push(marcador);
        }
      }
    },
    
    crearMarcadoresNegocios(negocios) {
      for (let index = 0; index < negocios.length; index++) {
        const negocio = negocios[index];
        if(negocio.latitud && negocio.longitud){
        
        let marcador = {
          id: + negocio.id+'n',
          position: { lat: negocio.latitud, lng: negocio.longitud },
          tooltip: negocio.nombre,
          draggable: false,
          visible: true,
        };

        this.markersNegocios.push(marcador);
        }
      }
    },
    crearMarcadorEmpresa(empresa) {
        if(empresa.latitud && empresa.longitud){
        
          this.empresa.id= + empresa.id+'e';
          this.empresa.position=  { lat: empresa.latitud, lng: empresa.longitud };
          this.empresa.tooltip= empresa.nombre;
          this.empresa.draggable= false;
          this.empresa.visible= true;
        };
  
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
  },
};
</script>


