

import Axios from "axios"

export default {
    state: {
        pasoRegistroCliente: 1,
        cliente: {},
        instalacion: {},
        showCliente: null
    },
    mutations: {
        mutacionPasoRegistroCliente(state, datos) {
            state.pasoRegistroCliente = datos;
        },
        mutacionDatosCliente(state, datos) {
            state.cliente = datos;
        },
        mutacionDatosInstalacion(state, datos) {

            state.instalacion = datos;
        },
        mutacionShowCliente(state, datos) {
            state.showCliente = datos;
        },
        mutacionAgregarServicio(state, datos) {
            state.showCliente.servicios.push(datos);
        },
        mutacionActualizarServicio(state, datos) {
            var actual = [...state.showCliente.servicios];
            var res = actual.filter(item => item.id !== datos.anterior.id)
            res.push(datos.actualizado);

            state.showCliente.servicios = res;
        },
        mutacionRemoverServicio(state, datos) {
            var arr = [...state.showCliente.servicios];
            state.showCliente.servicios = arr.filter(item => item.id !== datos)

        }


    },
    actions: {
        regresarRegistroCliente({ commit }, payload) {
            commit('mutacionPasoRegistroCliente', payload);
        },
        PasoRegistroCliente({ commit }, payload) {
            commit('mutacionPasoRegistroCliente', payload.paso);
            commit('mutacionDatosCliente', payload.cliente);
        },
        PasoRegistroInstalacion({ commit }, payload) {
            commit('mutacionPasoRegistroCliente', payload.paso);
            commit('mutacionDatosInstalacion', payload.cliente);

        },
        showCliente({ commit }, payload) {
            commit('mutacionShowCliente', payload);
        },
        agregarServicio({ commit }, payload) {
            commit('mutacionAgregarServicio', payload);
        },
        actualizarServicio({ commit }, payload) {
            commit('mutacionActualizarServicio', payload);
        },
        removerServicio({ commit }, payload) {
            commit('mutacionRemoverServicio', payload);
        }
    },
    getters: {
        getPasoRegistroCliente(state) {
            return state.pasoRegistroCliente;
        },
        getCliente(state) {
            return state.cliente;
        },
        getInstalacion(state) {

            return state.instalacion;
        },
        getShowCliente(state) {
            return state.showCliente;
        }
    },
    modules: {

    }
};
