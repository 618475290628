<template>
  <v-card flat>
    <v-container grid-list-md>
      <v-form ref="form" v-model="valid" lazy-validation dense>
        <v-row>
          <v-col cols="12" class="pa-4">
            <h6>
              La cola total sirve para optimizar la distribucion de velocidad de
              los clientes registrados en su mikrotik.
            </h6>
          </v-col>
          <v-col cols="12" md="6">
            <v-card elevation="0" outlined>
              <v-card-text>
                Asigna la velocidad total de Mbps que recibes en el rb que estas
                agregando
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Velocidad de bajada"
                      v-model="datos.bajada"
                      :rules="velocidadRegla"
                      append-outer-icon="mdi-arrow-down-circle"
                      type="number"
                      suffix="MB"
                      hint="Velocidad total de descarga que administrara este mikrotik"
                      persistent-hint
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Velocidad de subida"
                      v-model="datos.subida"
                      :rules="velocidadRegla"
                      append-outer-icon="mdi-arrow-up-circle"
                      type="number"
                      hint="Velocidad total de subida que administrara este mikrotik"
                      persistent-hint
                      suffix="MB"
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card outlined elevation="0">
              <v-card-text>
                Redes que administrara este mikrotik es decir los rangos de Ips
                que tendran los clientes.
                <v-row>
                  <v-col cols="12">
                    <v-chip label><v-icon>mdi-label</v-icon>Ejemplo:</v-chip>
                    <v-chip label>192.168.1.0/24</v-chip>
                    <v-chip label>192.168.2.0/24</v-chip>
                  </v-col>
                  <v-col cols="12">
                    <div v-for="(red, index) in datos.redes" :key="index">
                      <v-row>
                        <v-col cols="10">
                          <v-text-field
                            label="Red"
                            v-model="datos.redes[index]"
                            :rules="validacionRed"
                            filled
                            append-icon="mdi-ip"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-btn
                            v-if="index >= 1"
                            @click="quitarRed(index)"
                            color="red"
                            icon
                            fab
                            ><v-icon>mdi-delete</v-icon></v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            v-if="index == datos.redes.length - 1"
                            @click="agregarRed()"
                            color="success"
                            ><v-icon>mdi-plus</v-icon>Agregar Red</v-btn
                          >
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="regresar()" text>Regresar</v-btn>
        <v-btn :disabled="!valid" @click="registrar()" color="primary"
          >Siguiente <v-icon>mdi-arrow-right</v-icon></v-btn
        >
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import Common from "@/js/Common";
export default {
  data() {
    return {
      datos: {
        subida: 0,
        bajada: 0,
        redes: [""],
      },

      velocidadRegla: [
        (v) => !!v || "Se necesita el campo",
        (v) => v > 0 || "necesita ser mayor a 0",
      ],
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      validacionRed: [
        (v) =>
          /^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\/\d{1,2})$/.test(v) ||
          "Introduce una red valida",
      ],
      valid: true,
    };
  },
  mounted() {},
  computed: {
    router() {
      return this.$store.getters.getRouterRegistrado;
    },
  },
  methods: {
    registrar() {
      if (this.$refs.form.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "router/crear/cola/total/" + this.router.id;
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.datos,
          })
          .then((result) => {
            document.location.href = "/routers/vista";
          });
      }
    },
    regresar() {
      if (this.router.tipo_conexion == 1) {
        this.$store.dispatch("regresarRegistroRouter", 1);
      }
      if (this.router.tipo_conexion == 2) {
        this.$store.dispatch("regresarRegistroRouter", 2);
      }
    },
    agregarRed() {
      this.datos.redes.push("");
    },
    quitarRed(index) {
      this.datos.redes.splice(index, 1);
    },
  },
};
</script>
