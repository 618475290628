var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component"},[_c('base-component',[_c('div',{attrs:{"slot":"component"},slot:"component"},[_c('BaseConfirmacion',{ref:"confirmar"}),_vm._v(" "),_c('v-card',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-card-title',[_vm._v("\n            Notificaciones movil\n            "),_c('v-spacer')],1)],1),_vm._v(" "),_c('v-container',{attrs:{"pa-5":"","grid-list-md":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-btn-toggle',{attrs:{"shaped":"","dense":""}},[_c('v-btn',{attrs:{"dense":"","color":"primary"},on:{"click":function($event){return _vm.nuevo()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("\n                  Nueva notificación\n                ")],1),_vm._v(" "),_c('v-btn',{attrs:{"dense":"","color":"primary"},on:{"click":function($event){return _vm.configurarNotificaciones()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-settings")]),_vm._v("\n                  Notificaciones automaticas\n                ")],1)],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-text-field',{attrs:{"dense":"","append-icon":"mdi-magnify","label":"Buscador","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDatos($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_vm._v(" "),_c('v-data-table',{attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.elementos,"options":_vm.options,"server-items-length":_vm.totalElementos,"loading":_vm.loading,"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-format-horizontal-align-left',
            lastIcon: 'mdi-format-horizontal-align-right',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            itemsPerPageAllText: 'Todos',
            itemsPerPageText: 'Registros por página:',
            itemsPerPageOptions: [15, 50, 100, 500, -1],
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
          var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.elementos
                .map(function (x) {
                  return x.id;
                })
                .indexOf(item.id) + 1)+"\n          ")]}},{key:"item.entregado",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":item.entregado ? 'green' : 'red',"small":""}},[_vm._v(_vm._s(item.entregado ? "Si" : "No"))])]}},{key:"item.leido",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":item.leido ? 'green' : 'red',"small":""}},[_vm._v(_vm._s(item.leido ? "Si" : "No"))])]}},{key:"item.opciones",fn:function(ref){
                var item = ref.item;
return [(item.eliminado == false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.editar(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-send")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Reenviar")])]):_vm._e(),_vm._v(" "),(item.eliminado == false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.eliminar(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Eliminar")])]):_vm._e()]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"lime lighten-1","icon":"mdi-alert"}},[_vm._v("Tu búsqueda \""+_vm._s(_vm.search)+"\" no se encuentra.")])]},proxy:true},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"red","dark":"","icon":"mdi-alert-circle"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_vm._v(" Ningún dato que mostrar :( ")]),_vm._v(" "),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{on:{"click":function($event){return _vm.getDatos()}}},[_c('v-icon',{attrs:{"pl-4":""}},[_vm._v("mdi-cached")]),_vm._v(" Refrescar")],1)],1)],1)],1)]},proxy:true}])})],1),_vm._v(" "),_c('nuevo',{ref:"nuevo"}),_vm._v(" "),_c('editar',{ref:"editar"}),_vm._v(" "),_c('configurarNotificaciones',{ref:"configuracionCliente"})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }