<template>
    <div class="component">
        <base-component>
            <div slot="component">
                <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
                <v-dialog v-model="dialogEditarReporte" max-width="800" persistent>
                        <v-card>
                            <v-card-title primary-title>
                                Editar
                            </v-card-title>
                            <v-container grid-list-md>
                                <p class="text-subtitle-2">Actuliza los datos del paquete</p>
                            <v-layout row wrap>
                                <v-flex xs12 md12>
                                    <v-container grid-list-md>
                                    <v-form dense ref="form" v-model="valid" enctype="multipart/form-data" lazy-validation>
                                        
                                                <v-layout row wrap>
                                                <v-flex xs12 md8>
                                                    <v-text-field
                                                        label="Asunto"
                                                        v-model="reporte.asunto"
                                                        :rules="generalRegla"
                                                        prepend-icon="mdi-pen"
                                                    ></v-text-field>
                                                </v-flex>
                                                <v-flex xs12 md4>
                                                    <v-text-field
                                                        label="Costo"
                                                        v-model="reporte.costo"
                                                        :rules="generalRegla"
                                                        type="number"
                                                        prepend-icon="mdi-cash-usd"
                                                        prefix="$"
                                                    ></v-text-field>
                                                </v-flex>
                                                </v-layout>
                                                <v-layout row wrap>
                                                    <v-flex xs12 md12>
                                                        <v-radio-group dense v-model="reporte.estado_reporte_id" row>
                                                            <v-radio dense color="orange" label="Pendiente" value="1"></v-radio>
                                                            <v-radio dense color="green" label="Solucionado" value="2"></v-radio>
                                                        </v-radio-group>
                                                    </v-flex>
                                                </v-layout> 
                                            <v-layout row wrap>
                                                <v-flex xs12 md6>
                                                <v-menu
                                                v-model="menu2"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                    v-model="computedDateFormatted"
                                                    label="Dia de revisión"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    :rules="generalRegla"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker locale="es-mx" v-model="reporte.fecha" no-title @input="menu2 = false"></v-date-picker>
                                                </v-menu>
                                            </v-flex>
                                            <v-flex xs12 md6>
                                                    <v-menu
                                                        ref="menu"
                                                        v-model="menu_reloj"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        :return-value.sync="reporte.hora"
                                                        transition="scale-transition"
                                                        offset-y
                                                        max-width="290px"
                                                        min-width="290px"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="reporte.hora"
                                                            label="Hora"
                                                            prepend-icon="mdi-av-timer"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                        </template>
                                                        <v-time-picker
                                                        v-if="menu_reloj"
                                                        v-model="reporte.hora"
                                                        full-width
                                                        @click:minute="$refs.menu.save(reporte.hora)"
                                                        ></v-time-picker>
                                                    </v-menu>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row wrap>
                                                <v-flex xs12 md12>
                                                <v-autocomplete 
                                                        v-model="reporte.personal"
                                                        :items="itemsPersonal"
                                                        item-text="todo"
                                                        item-value="id"
                                                        max-width="30"
                                                        :rules="generalRegla"

                                                        ref="autoPersonal"
                                                        :search-input.sync="searchPersonal"
                                                        hide-no-data label="Personal" placeholder="Empieza a escribir para búscar" prepend-icon="mdi-worker"
                                                        >
                                                            <template max-width="60" v-slot:item="{ item }">
                                                                        <v-list-item-avatar
                                                                        color="indigo"
                                                                        class="headline font-weight-light white--text"
                                                                        >
                                                                        {{ item.nombre.charAt(0) }}
                                                                        </v-list-item-avatar>
                                                                        <v-list-item-content>
                                                                        <v-list-item-title>{{item.nombre}} {{item.apellido_paterno}} {{item.apellido_materno}}</v-list-item-title>
                                                                        <v-list-item-subtitle >Celular: {{item.celular}}</v-list-item-subtitle>
                                                                        </v-list-item-content>
                                                            </template>
                                                        </v-autocomplete> 
                                                </v-flex>
                                            </v-layout>
                                        </v-form>
                                    </v-container>
                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="cancelar()">Cancelar</v-btn>
                                    <v-btn
                                        color="primary"
                                        @click="actualizarReporte()"
                                    >
                                        Actualizar
                                    </v-btn>
                                    </v-card-actions>
                                </v-flex>
                            </v-layout>
                            </v-container>
                        </v-card>
                </v-dialog>
            </div>
        </base-component>
    </div>
</template>
<script>
export default {

    data() {
        return {
            reporte:{
                costo: "",
                asunto: "",
                fecha: null,
                hora: null,
                personal: "",
                cliente_id:"",
                estado_reporte_id:"",
            },
            clientes:[],
            personal:[],
            valid: true,
            generalRegla: [v => !!v || "Se necesita el campo"],
            date: new Date().toISOString().substr(0, 10),
            dateFormatted: new Date().toISOString().substr(0, 10),
            menu2: false,
            menu_reloj:false,
            searchPersonal: "",
            searchCliente: "",
            dialogEditarReporte: false,
            resolve:null,
            reject:null,
            
        };
    },
    mounted() {
        this.getDatos();
        var arreUrl = window.location.href.split('/');
        this.reporte.cliente_id = arreUrl.pop();
    },
    watch:{
        date (val) {
            this.dateFormatted = this.formatDate(val)
        },
    },
    computed: {
        computedDateFormatted () {
            if(this.reporte.fecha){
                return this.formatDate(this.reporte.fecha)
            }else{
                return ""
            }
        },
        
      itemsPersonal () {
        return this.personal.map(entry => {
          const todo = entry.nombre+" "
                    +entry.apellido_paterno+" "
                    +entry.apellido_materno+" "
          return Object.assign({}, entry, { todo })

        })
      },
    },
    methods: {
        esperarDatos(reporteEditar) {
            
            this.reporte = reporteEditar;
            this.reporte.estado_reporte_id = reporteEditar.estado_reporte_id+"";
            this.dialogEditarReporte = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        getDatos(){
            this.$store.dispatch('UsersModule/OnGet', {
                url: 'datos/form/reporte',
                data: {}
            }).then(result => {
                this.personal = result.data.personal;
                
                
            })
        },
        formatDate (date) {
            if (!date) return null
                const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        limpiar(){
                this.reporte.costo ="";
                this.reporte.asunto= "";
                this.reporte.fecha= null;
                this.reporte.hora= null;
                this.reporte.personal= "";
                this.reporte.cliente_id="";
                this.searchCliente = "";
                this.searchPersonal = "";

                
                
        },
        actualizarReporte() {
            if (this.$refs.form.validate()) {
                
                this.reporte.personal = this.reporte.personal.id;
                this.resolve(this.reporte);
                this.dialogEditarReporte = false;
                return this.reporte;
            } else {
                return null;
            }
        },
        cancelar(){
            this.resolve(false);
            this.dialogEditarReporte = false;
        }
    }

};
</script>