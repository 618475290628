<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-dialog v-model="dialog" max-width="700px" persistent>
          <v-card width="700px" class="cool pa-4 ma-auto">
            <v-container grid-list-md>
              <v-card-title primary-title class="display-1">
                <strong>Gracias</strong>
              </v-card-title>
              <v-card-text>
                <v-layout row wrap>
                  <p>
                    <strong>
                      El pago mensual dependiendo a la cantidad de clientes que
                      tiene su ISP.
                    </strong>
                  </p>
                  <p>Importante: El dinero no se podra ser devuelto</p>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <v-text-field
                      label="Cantidad de clientes"
                      readonly
                      v-model="clientes"
                      outlined
                      prepend-icon="mdi-account-multiple"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      label="Costo por cliente"
                      readonly
                      outlined
                      prepend-icon="mdi-close-outline"
                      v-model="costoCliente"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      label="Donación sugerida"
                      outlined
                      prepend-icon="mdi-equal"
                      v-model="donacion"
                      readonly
                      hint="Dolares"
                      type="number"
                      persistent-hint
                      :rules="reglasDonacion"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <div
                  v-show="donacion != null && donacion > 0"
                  ref="paypal"
                ></div>
              </v-card-text>
            </v-container>
          </v-card>
        </v-dialog>
      </div>
    </base-component>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: true,
      clientes: 0,
      costoCliente: 0,
      donacion: 0,
      reglasDonacion: [
        (v) => v != null || "Se necesita el campo",
        (v) => v > 0 || "Debe ser mayo a 0",
      ],
      user: null,
      peersona: null,
    };
  },
  mounted() {
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=Aba_XrglcjT4vz6ZZ_dBJxkux-bbxBht1VetW1QUTnV6ZW8m9-KzQ2DLNbTCAhCh-sXr-K81ERudTeDf";

    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "donacion/empresa/datos",
          data: {},
        })
        .then((result) => {
          this.clientes = result.data.clientes;
          this.costoCliente = result.data.costoCliente;
          this.donacion = parseFloat(result.data.donacion).toFixed(2);
          this.clientes = result.data.clientes;
          this.persona = result.data.persona;
          this.user = result.data.user;
        });
    },
    setLoaded(resp) {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              application_context: {
                shipping_preference: "NO_SHIPPING",
              },
              payer: {
                email_address: this.user.email,
                name: {
                  given_name: this.persona.nombre,
                  surname:
                    this.persona.apellido_paterno +
                    " " +
                    this.persona.apellido_materno,
                },
              },
              purchase_units: [
                {
                  amount: {
                    currency_code: "USD",
                    value: this.donacion,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions, resp) => {
            this.$store
              .dispatch("UsersModule/OnGet", {
                url: "/paypal/process/" + data.orderID + "/" + this.persona.id,
              })
              .then((result) => {
                this.dialog = false;
              });
          },
          onError: (err) => {},
        })
        .render(this.$refs.paypal);
    },
  },
};
</script>