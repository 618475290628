<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="cool pa-4">
              <v-card-title class="display-1">
                <strong>Tickets</strong>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-spacer></v-spacer>

                  <v-col cols="12" md="6">
                    <v-text-field
                      solo
                      class="rounded-pill"
                      v-model="search"
                      @keyup.enter="getDatos"
                      append-icon="mdi-magnify"
                      label="Buscador"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-data-table
                dense
                :search="search"
                :headers="headers"
                :items="elementos"
                :options.sync="options"
                :server-items-length="totalElementos"
                :loading="loading"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-format-horizontal-align-left',
                  lastIcon: 'mdi-format-horizontal-align-right',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  itemsPerPageAllText: 'Todos',
                  itemsPerPageText: 'Registros por página:',
                  itemsPerPageOptions: [3, 5, 10, 15, -1],
                }"
              >
                <template v-slot:no-results>
                  <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                    >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                  >
                </template>
                <template v-slot:item.id="{ item }">
                  {{
                    elementos
                      .map(function (x) {
                        return x.id;
                      })
                      .indexOf(item.id) + 1
                  }}
                </template>
                <template v-slot:item.estado="{ item }">
                  <v-chip
                    @click="cambiarEstado(item)"
                    dense
                    text-color="white"
                    :color="calcularColor(item.estado_reporte_id)"
                    >{{ item.estado.estado }}</v-chip
                  >
                </template>
                <template v-slot:item.opciones="{ item }">
                  <v-tooltip v-if="item.eliminado == false" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        @click="detalles(item)"
                        icon
                        v-on="on"
                        v-bind="attrs"
                      >
                        <v-icon small> mdi-dots-horizontal </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver detalles</span>
                  </v-tooltip>
                </template>
                <template v-slot:no-data>
                  <v-alert
                    :value="true"
                    color="red"
                    dark
                    icon="mdi-alert-circle"
                  >
                    <v-row align="center">
                      <v-col> Ningún dato que mostrar :( </v-col>
                      <v-col class="shrink">
                        <v-btn @click="getDatos()"
                          ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-alert>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
        <v-dialog v-model="dialogEstados" max-width="300px">
          <v-card>
            <v-card-title primary-title> Cambiar Estado </v-card-title>
            <v-container grid-list-md>
              <v-form v-model="validEstados" ref="formEstados" lazy-validation>
                <v-flex xs12>
                  <v-select
                    label="Status"
                    v-model="estado_id"
                    :rules="generalRegla"
                    item-text="estado"
                    item-value="id"
                    :items="estados"
                  ></v-select>
                </v-flex>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialogEstados = false">Cancelar</v-btn>
              <v-btn @click="actualizarEstado()" color="primary">Cambiar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <exportar ref="refExportar"></exportar>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
import exportar from "@/js/components/exportar_tabla/exportarComponent.vue";

export default {
  components: {
    exportar,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      headers: [
        { text: "#", align: "start", value: "id" },
        { text: "Personal", value: "personal.FullName", sortable: true },
        { text: "Asunto", value: "asunto", sortable: true },
        { text: "Costo", value: "costo", sortable: true },
        { text: "Cliente", value: "cliente.FullName", sortable: false },
        { text: "Estado", value: "estado", sortable: false },
        { text: "Fecha", value: "fecha", sortable: true },
        { text: "Hora", value: "hora", sortable: true },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      dialogEstados: false,
      validEstados: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      estado_id: "",
      estados: [],
    };
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  mounted() {
    this.getEstados();
  },
  computed: {},
  methods: {
    getEstados() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "estados/reporte",
          data: {},
        })
        .then((result) => {
          this.estados = result.data.estados;
        });
    },
    cambiarEstado(item) {
      this.item = item;
      this.estado_id = item.id;
      this.dialogEstados = true;
    },
    actualizarEstado(item) {
      if (this.$refs.formEstados.validate()) {
        let dispatch = "UsersModule/OnUpdate";
        let endpoint = `reporte/cambiar/estado/` + this.item.id;
        let copiaItem = Object.assign({}, this.item);
        copiaItem.estado_reporte_id = this.estado_id;
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: copiaItem,
          })
          .then((result) => {
            this.elementos.splice(
              this.elementos.indexOf(this.item),
              1,
              result.data.reporte
            );
            this.dialogEstados = false;
          });
      }
    },
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `reportes/instalador/tabla`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
            status: parseInt(this.estadoPago),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.tickets.data;
          this.totalElementos = result.data.tickets.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    exportar() {
      if (this.elementos.length > 0) {
        this.$refs.refExportar.cargarDatos(
          "tickets/personal/" + this.elementos[0].personal.id
        );
      }
    },
    calcularColor(id) {
      if (id == 1) {
        return "red";
      }
      if (id == 2) {
        return "yellow";
      }
      if (id == 3) {
        return "green";
      }
    },
    detalles(item) {
      document.location.href = "vista/detalles/ticket/" + item.id;
    },
  },
};
</script>