<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <v-dialog v-model="dialog" persistent max-width="500px">
          <v-card>
            <v-card-title primary-title> Configuración correos </v-card-title>
            <v-card-text>
              <v-checkbox
                v-model="configuracion.correo_dia_pago"
                label="enviar correo de aviso del dia pago"
                color="success"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="configuracion.correo_suspencion"
                label="enviar correo de suspención de servicio por falta de pago"
                color="success"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="configuracion.correo_registro_pago"
                label="enviar correo de pago liquidado"
                color="success"
              ></v-checkbox>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="cancelar()" text>Cancelar</v-btn>
              <v-btn @click="enviar()" color="primary">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
export default {
  data() {
    return {
      configuracion: {
        cliente_id: 0,
        correo_dia_pago: false,
        correo_suspencion: false,
        correo_registro_pago: false,
      },
      dialog: false,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    cargarDatos(id) {
      let dispatch = "UsersModule/OnGet";
      let endpoint = "cliente/" + id;
      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: {},
        })
        .then((result) => {
          var cliente = result.data.cliente;
          this.configuracion.cliente_id = cliente.id;
          this.configuracion.correo_dia_pago = cliente.correo_dia_pago;
          this.configuracion.correo_suspencion = cliente.correo_suspencion;
          this.configuracion.correo_registro_pago =
            cliente.correo_registro_pago;
          this.dialog = true;
        });
    },
    enviar() {
      let dispatch = "UsersModule/OnSave";
      let endpoint = "configurar/correos/cliente";
      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: this.configuracion,
        })
        .then((result) => {
          this.dialog = false;
        });
    },

    cancelar() {
      this.dialog = false;
    },
  },
};
</script>