
<template>
    <div id="chart">
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
      </div>
</template>
<script>
import Common from '@/js/Common'
import apexchart from 'vue-apexcharts'
export default {
    components:{
        apexchart
    },
    data() {
        return {
            series: [{
            name: 'Balances',
            data: []
          }],
          chartOptions: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                colors: {
                  ranges: [{
                    from: 1,
                    to: 1000000,
                    color: '#4CAF50'
                  }, {
                    from: -1000000,
                    to: 0,
                    color: '#F44336'
                  }]
                },
                columnWidth: '80%',
              }
            },
            title: {
              text: 'Balances de los ingresos menos los egresos mensuales',
              align: 'left'
            },
            dataLabels: {
              enabled: false,
            },
            yaxis: {
              title: {
                text: 'Cantidad',
              },
              labels: {
                formatter: function (y) {
                  return Common.formatMoney(y)
                }
              }
            },
            xaxis: {
              categories: ['Ene','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov','Dic'],
              title: {
                text: 'Mes'
              }
            },
            tooltip: {
            y: {
                formatter: function (val) {
                return Common.formatMoney(val)
                }
            }
            }
          },
        };
    },
    mounted() {
      this.getDatos();
    },
    computed: {
        
    },
    methods: {
      getDatos() {
            this.$store.dispatch('UsersModule/OnGet', {
                url: `contabilidad/finanzas/grafica/ingresosegresos`,
                data: {}

            }).then(result => {
                
                var balances =[];
                for (let index = 0; index < 12; index++) {
                  var total = result.data.ingresos[index]-result.data.egresos[index];
                  balances.push(total);
                }
                
                
                this.series= [{
                    name: 'Balance',
                    data: balances
                }];
                this.showChart = true
              
            
            })
        },
    }

};
</script>