<template>
    <div class="component mt-4">
        <base-component>
            <div slot="component">
                <v-row>
                <v-card class="cool pa-4">
                    <v-card-title primary-title class="display-1">
                        <strong>Aviso</strong>
                    </v-card-title>
                    <v-card-text>
                   <v-flex xs12>
                        <div id="app">
                            <vue-editor v-model="content"></vue-editor>
                        </div>        
                    </v-flex>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="guardar()" class="rounded-pill pa-3" color="primary"><v-icon>mdi-content-save</v-icon> Guardar</v-btn>
                    </v-card-actions>
                        
                  <!--
                    <v-btn @click="vista()" color="primary"><v-icon>mdi-eye</v-icon> Vista Previa</v-btn>
                   --> 
                    </v-card>
                </v-row>
            </div>
        </base-component>
    </div>
</template>
<script>
import { VueEditor } from "vue2-editor";


export default {
    components:{
       VueEditor
     },
    data() {
        return {
            content: "<h1>edita esta plantilla</h1>"
        }
    },
    mounted() {
        this.getDatos();
    },
    computed: {
        
    },
    methods: {
        getDatos(){
            this.$store.dispatch('UsersModule/OnGet', {
                url: `aviso/empresa`,
                data: {}

            }).then(result => {
                   if([200, 201].includes(result.status)){
                        this.content = result.data.aviso;
                         
                     }
            
            })
        },
        guardar(){
           this.$store.dispatch('UsersModule/OnUpdate', {
                url: `actualizar/aviso`,
                data: {
                    content: this.content,
                }

            }).then(result => {
                   if([200, 201].includes(result.status)){
                        this.content = result.data.aviso;
                         
                     }
            
            })
        },
        vista(){
           document.location.href= "servicio/suspendido/1"; 
        }
    }

};
</script>
