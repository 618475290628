<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="cool pa-4">
              <v-card-title class="display-1">
                <strong>Ingresos</strong>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-btn
                      @click="dialogIngreso = true"
                      class="rounded-pill"
                      color="red"
                      dark
                    >
                      <v-icon left>mdi-plus</v-icon>
                      Agregar
                    </v-btn>
                    <v-btn
                      @click="exportar()"
                      class="rounded-pill"
                      color="red"
                      dark
                    >
                      <v-icon left>mdi-file-export</v-icon>
                      Exportar
                    </v-btn>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="search"
                      @keyup.enter="getDatos"
                      append-icon="mdi-magnify"
                      label="Buscador"
                      single-line
                      solo
                      class="rounded-pill"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-data-table
                dense
                :search="search"
                :headers="headers"
                :items="elementos"
                :options.sync="options"
                :server-items-length="totalElementos"
                :loading="loading"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-format-horizontal-align-left',
                  lastIcon: 'mdi-format-horizontal-align-right',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  itemsPerPageAllText: 'Todos',
                  itemsPerPageText: 'Registros por página:',
                  itemsPerPageOptions: [3, 5, 10, 15, -1],
                }"
              >
                <template v-slot:no-results>
                  <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                    >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                  >
                </template>
                <template v-slot:item.id="{ item }">
                  {{
                    elementos
                      .map(function (x) {
                        return x.id;
                      })
                      .indexOf(item.id) + 1
                  }}
                </template>
                <template v-slot:item.opciones="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="editarIngreso(item)" icon v-on="on">
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar Ingreso</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="eliminarIngreso(item)" icon v-on="on">
                        <v-icon small> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span>Desactivar Ingreso</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="verArchivo(item)" icon v-on="on">
                        <v-icon small> mdi-file </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver comprobante</span>
                  </v-tooltip>
                </template>

                <template v-slot:no-data>
                  <v-alert
                    :value="true"
                    color="red"
                    dark
                    icon="mdi-alert-circle"
                  >
                    <v-row align="center">
                      <v-col> Ningún dato que mostrar :( </v-col>
                      <v-col class="shrink">
                        <v-btn @click="getDatos()"
                          ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-alert>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <formEditarIngreso ref="refEditarIngreso"></formEditarIngreso>
        <exportar ref="refExportar"></exportar>
        <v-dialog v-model="dialogIngreso" max-width="400" persistent>
          <v-card>
            <v-container grid-list-md>
              <v-card-title class="display-1" primary-title>
                <strong>Nuevo</strong>
              </v-card-title>
              <v-container grid-list-md>
                <formIngreso ref="refIngreso"></formIngreso>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialogIngreso = false">Cancelar</v-btn>
                <v-btn
                  color="primary"
                  class="rounded-pill pa-3"
                  @click="nuevoIngreso()"
                >
                  Registrar
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>
      </div>
    </base-component>
  </div>
</template>

<script>
import formIngreso from "@/js/components/finanzas/ingresos/nuevoIngreso.vue";
import formEditarIngreso from "@/js/components/finanzas/ingresos/editarIngreso.vue";
import exportar from "@/js/components/exportar_tabla/exportarComponent.vue";

export default {
  components: {
    formIngreso,
    formEditarIngreso,
    exportar,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      headers: [
        { text: "id", align: "start", value: "id" },
        {
          text: "Procedencia",
          value: "procedencia.procedencia",
          sortable: false,
        },
        { text: "Monto", value: "monto", sorteable: true },
        { text: "fecha", value: "fecha", sortable: true },
        { text: "hora", value: "hora", sortable: true },
        { text: "Detalles", value: "detalles", sortable: false },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      dialogIngreso: false,
      ingresoActualizar: {},
    };
  },
  mounted() {},
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos() {
      this.loading = true;

      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `ingresos/tabla`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.ingresos.data;
          this.totalElementos = result.data.ingresos.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    eliminarIngreso(elemento) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `ingreso/` + elemento.id;
            let event = "delete";

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: elemento,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(elemento), 1);
              });
          } else {
          }
        });
    },
    nuevoIngreso() {
      let respuestaDialog = this.$refs.refIngreso.nuevo();

      if (respuestaDialog == null) {
        return;
      }
      let dispatch = "UsersModule/OnSave";
      let endpoint = "ingreso";

      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: respuestaDialog,
        })
        .then((result) => {
          this.totalElementos = this.elementos.unshift(result.data.ingreso);
          this.dialogIngreso = false;
          this.$refs.refIngreso.limpiar();
        });
    },
    editarIngreso(elemento) {
      this.ingresoActualizar = elemento;
      const ingreso = Object.assign({}, elemento);
      this.$refs.refEditarIngreso.esperarDatos(ingreso).then((confirm) => {
        if (confirm) {
          this.actualizarIngreso(confirm);
        }
      });
    },
    actualizarIngreso(respuesta) {
      let formDataP = new FormData();
      formDataP.append("id", respuesta.id);
      formDataP.append("monto", respuesta.monto);
      formDataP.append(
        "detalles",
        respuesta.detalles != null ? respuesta.detalles : ""
      );
      formDataP.append("archivo", respuesta.archivo);
      formDataP.append("empresa_id", respuesta.empresa_id);
      formDataP.append("fecha", respuesta.fecha);
      formDataP.append("hora", respuesta.hora);
      formDataP.append("empresa_id", this.ingresoActualizar.empresa_id);
      formDataP.append("procedencia_id", respuesta.procedencia_id);

      let dispatch = "UsersModule/OnSave";
      let endpoint = "ingresos/actualizar/" + respuesta.id;

      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: formDataP,
        })
        .then((result) => {
          this.elementos.splice(
            this.elementos.indexOf(this.ingresoActualizar),
            1,
            result.data.ingreso
          );
        });
    },
    verArchivo(item) {
      if (item.archivo != null) {
        window.open(item.archivo, "_blank");
      } else {
        this.$store.dispatch("notificaciones/add", {
          tipo: "warning",
          mensaje: "Sin archivo  :/",
        });
      }
    },
    exportar() {
      this.$refs.refExportar.cargarDatos("ingresos");
    },
  },
};
</script>
