<template>
  <div slot="component">
    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title class="display-1">
            <strong>Nuevo</strong>
          </v-card-title>
          <v-responsive
            id="scroll-target"
            class="overflow-y-auto"
            :max-height="500"
          >
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-col cols="12">
                  <v-text-field
                    label="Nombre de la zona"
                    v-model="zona.nombre"
                    :rules="generalRegla"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="zona.servicios"
                    :disabled="isUpdating"
                    :items="servicios"
                    chips
                    outlined
                    label="Servicios disponibles en la zona"
                    item-text="nombre"
                    item-value="id"
                    multiple
                  >
                    <v-spacer></v-spacer>
                    <template v-slot:selection="data">
                      <v-chip
                        dark
                        color="teal"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove(data.item)"
                      >
                        {{ data.item.nombre }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content>{{
                          data.item
                        }}</v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-avatar
                          color="teal"
                          class="headline font-weight-light white--text"
                        >
                          {{ data.item.nombre.charAt(0) }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ data.item.nombre }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <div>
                              <v-icon>mdi-arrow-up-bold-circle-outline</v-icon>
                              <strong class="ml-2"
                                >{{ data.item.subida }}
                              </strong>
                              Mb.
                              <v-icon class="ml-2"
                                >mdi-arrow-down-bold-circle-outline</v-icon
                              >
                              <strong class="ml-2"
                                >{{ data.item.bajada }}
                              </strong>
                              Mb.

                              <v-icon class="ml-2">mdi-cash-usd</v-icon>
                              <strong class="ml-2"
                                >{{ data.item.costo }}
                              </strong>
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <ubicacion ref="mapa"></ubicacion>
                </v-col>
              </v-form>
            </v-card-text>
          </v-responsive>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelar()">Cancelar</v-btn>
            <v-btn color="primary" @click="nuevo()" class="rounded-pill pa-3">
              Registrar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ubicacion from "@/js/components/mapa/mapaComponent.vue";

export default {
  components: {
    ubicacion,
  },
  data() {
    return {
      dialog: false,
      zona: {
        nombre: "",
        rango_ip: "",
        latitud: "",
        longitud: "",
        servicios: [],
      },
      dialog_mapa: false,
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      reject: null,
      resolve: null,
      isUpdating: false,
      servicios: [],
    };
  },
  mounted() {
    this.getDatos();
  },
  watch: {},
  computed: {},
  methods: {
    async esperarComponente() {
      await this.$nextTick();
      this.$refs.mapa.mostrarMarker(
        null,
        null,
        "markerTorre.png",
        "Zona",
        true
      );
    },
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `todos/servicios/empresa`,
          data: {},
        })
        .then((result) => {
          this.servicios = result.data.servicios;
        });
    },
    mostrar() {
      this.dialog = true;
      this.esperarComponente();

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    nuevo() {
      if (this.$refs.form.validate()) {
        this.zona.latitud = this.$refs.mapa.getLatitud();
        this.zona.longitud = this.$refs.mapa.getLongitud();
        let dispatch = "UsersModule/OnSave";
        let endpoint = "zona";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.zona,
          })
          .then((result) => {
            this.dialog = false;
            this.limpiar();
            this.resolve(result.data.zona);
          });
      }
    },
    limpiar() {
      this.$refs.form.reset();
    },
    cancelar() {
      this.dialog = false;
      this.resolve(false);
    },
    remove(item) {
      const index = this.zona.servicios.indexOf(item.id);
      if (index >= 0) this.zona.servicios.splice(index, 1);
    },
  },
};
</script>