<template>

  <div class="cuerpo">
    <div @mousemove="onMouseMove" class="principal" id="principal">
      <canvas id="canvas" :width="anchoCanva" :height="altoCanva"></canvas>
      <p @click="start_over()" id="announce"></p>
     
    </div>
      <v-flex v-if="mostrarBotones" xs12 class="d-flex justify-center">
        <v-btn class="mx-6 elevation-0" @click="izquierda()" fab dark x-large color="#a5afc7">
          <v-icon dark> mdi-arrow-left-bold-circle </v-icon>
        </v-btn>
        <v-btn class="mx-6 elevation-0" @click="derecha()" fab dark x-large color="#a5afc7">
          <v-icon dark> mdi-arrow-right-bold-circle </v-icon>
        </v-btn>
      </v-flex>
    
  </div>
 
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      blinkCount: 0,
      blinking: "",
      gameOver: "",
      context: null,
      WIDTH: 990,
      HEIGHT: 600,
      anchoCanva: 990,
      altoCanva: 600,
      balls: null,
      threeBallsAtWork: "",
      fastDuration: "",
      fast: "",
      ballsCount: "",
      score: "",
      radius: 12,
      paddlex: 650,
      paddleh: 20,
      paddlew: 200,
      paddleColor: "",
      paddleTouch: "",
      paddleTouchDuration: "",
      paddleWidenDuration: "",
      paddleShrinkDuration: "",
      paddleLastToken: "",
      paddlePreviousToken: "",
      bricks: "",
      n_brick_cols: 13,
      n_brick_rows: 6,
      brick_padding: 5,
      brick_width: "",
      brick_height: 30,

      paddleRight: false,
      paddleLeft: false,

      rightDown: false,
      leftDown: false,

      //pause/gameover
      pause: "",
      prepause: "",
      lostCount: "",

      //mouse
      canvasMinX: "",
      canvasMaxX: "",
      canvasStart: "",

      //Tokens
      tokenLetters: new Object(),

      mostrarBotones:false,
      restaMovil:0,
      tokenSpeed:10
    };
  },
  mounted() {

    

      // Realiza acciones específicas para dispositivos móviles
     
  
    this.onResize();
    var canvas = document.getElementById("canvas");
    this.context = canvas.getContext("2d");

    this.brick_width =
      (this.WIDTH - this.brick_padding * (this.n_brick_cols + 1)) /
      this.n_brick_cols;
    this.bricks = new Array(this.n_brick_rows);
    for (var i = 0; i < this.n_brick_rows; i++) {
      this.bricks[i] = new Array(this.n_brick_cols);
      for (var j = 0; j < this.n_brick_cols; j++) {
        this.bricks[i][j] = 1;
      }
    }
    this.startGame();

    frames = setInterval(() => {
      this.draw();
      this.prepause = false;
    }, 20);
  },
  watch: {
    getIsLoadingState(val) {
      if (val == false) {
        this.$destroy();
      }
    },
  },
  beforeDestroy() {
    clearInterval(frames);
  },
  computed: {
    ...mapGetters(["getIsLoadingState"]),
  },

  methods: {
    derecha(){
      this.leftDown = false;
      this.rightDown = true;
    },
    izquierda(){
      this.rightDown = false; 
      this.leftDown = true;
    }	,
    onResize() {
    if (this.$isMobile) {
      this.tokenSpeed = 5;
        this.n_brick_cols= 6;
        this.n_brick_rows= 5;
        this.restaMovil = 270;
        this.radius= 10;
        this.mostrarBotones = true
        this.HEIGHT =900;
        this.paddleh =15;
        //this.WIDTH = 2620;
        //this.altoCanva = 900;
        this.altoCanva = 900;
        this.anchoCanva = 2620;
        let anuncio = document.getElementById("announce");
        let principal = document.getElementById("principal");
        anuncio.style["font-size"] = "51px";
        anuncio.style.left = "0px";
        anuncio.style.top = "200px";
        anuncio.style.width = "51px";
        
        principal.style.height = "500px";
    }
      
     
    },
    startGame() {
      $("#canvas, .sidebar").show();

      this.blinking ? clearInterval(this.blinkLetters) : this.blinking;
      this.initial();
    },
    start_over() {
      //clearInterval(frames);
      for (var ball in this.balls) {
        delete this.balls[ball];
      }
      for (var token in this.tokenLetters) {
        delete this.tokenLetters[token];
      }
      for (var i = 0; i < this.n_brick_rows; i++) {
        this.bricks[i] = new Array(this.n_brick_cols);
        for (var j = 0; j < this.n_brick_cols; j++) {
          this.bricks[i][j] = 1;
        }
      }

      //this.balls = { 0: { x: 100, y: 300, dx: 12, dy: 6 } };
      this.balls = { 0: { x: 200, y: 300, dx: 12, dy: 6 } };
      this.ballsCount = 0;

      $("#canvas").css("background-image", "none");
      this.fastDuration = 0;
      this.paddleTouch = false;
      this.paddleTouchDuration = 0;
      this.paddleShrinkDuration = 0;
      this.paddleWidenDuration = 0;
      this.paddleColor = "#a5afc7";

      this.fast = false;
      this.threeBallsAtWork = false;

      this.gameOver = false;
      this.score = 0;
      this.pause = false;
      this.prepause = true;
      this.lostCount = 0;
      this.startAnimate();
      $("#announce").hide();
      this.context.clearRect(0, 0, this.WIDTH, this.HEIGHT);

      /*
      frames = setInterval(() => {
		this.draw()
    	this.prepause = false;
		
  	}, 20);
*/
    },
    initial() {
      this.balls = { 0: { x: 100, y: 300, dx: 12, dy: 6 } };

      this.ballsCount = 0;
      this.fastDuration = 0;
      this.paddleTouch = false;
      this.paddleTouchDuration = 0;
      this.paddleShrinkDuration = 0;
      this.paddleWidenDuration = 0;
      this.paddleColor = "#a5afc7";

      this.fast = false;
      this.threeBallsAtWork = false;

      this.gameOver = false;
      this.score = 0;
      this.pause = false;
      this.lostCount = 0;
      this.prepause = true;
      this.context = canvas.getContext("2d");

      this.startAnimate();
      this.canvasStart = $("#canvas").offset().left;
    },
    draw() {
      //
      this.clear();
      this.drawBalls();
      this.paddle();
      this.drawBricks();
      this.bounce();
      this.Tokens();
    },
    rect(c, x, y, w, h) {
      this.context.fillStyle = c;
      this.context.beginPath();
      this.context.rect(x, y, w, h);
      this.context.closePath();
      this.context.fill();
    },
    drawBalls() {
      if (this.fastDuration) {
        this.fastDuration--;
      } else if (this.fast) {
        for (var ball in this.balls) {
          this.balls[ball].dx *= 2 / 3;
          this.balls[ball].dy *= 2 / 3;
        }
        this.fast = false;
      }

      for (var ball in this.balls) {
        var dx = this.balls[ball].dx;

        var dy = this.balls[ball].dy;

        this.balls[ball].x += dx;
        this.balls[ball].y += dy;

        var y = this.balls[ball].y;
        var x = this.balls[ball].x;

        this.context.fillStyle = "#a5afc7";
        this.context.beginPath();
        this.context.arc(x, y, this.radius, 0, Math.PI * 2, true);

        this.context.closePath();
        this.context.fill();
      }

      if (Object.keys(this.balls).length == 1) this.threeBallsAtWork = false;
    },
    clear() {
      this.context.clearRect(0, 0, this.WIDTH, this.HEIGHT);
    },
    paddle() {
      if (this.rightDown && this.paddlex + this.paddlew < this.WIDTH) {
        this.paddlex += 15;
      }
      if (this.leftDown && this.paddlex > 0) {
        this.paddlex -= 15;
      }

      if (this.paddleWidenDuration || this.paddleShrinkDuration) {
        if (this.paddlew == 200) {
          if (this.paddleLastToken === "W") this.paddlew = 300;
          else this.paddlew = 100;
        }
        if (this.paddlew == 100) {
          if (!this.paddleShrinkDuration || this.paddleLastToken === "W")
            this.paddlew = 200;
        }
        if (this.paddlew == 300) {
          if (this.paddleLastToken === "S") this.paddlew = 200;
        }
      } else this.paddlew = 200;

      this.paddleWidenDuration && this.paddleWidenDuration--;
      this.paddleShrinkDuration && this.paddleShrinkDuration--;

      this.rect(
        this.paddleColor,
        this.paddlex,
        //this.HEIGHT - this.paddleh,
        (this.HEIGHT-this.restaMovil) - this.paddleh,
        this.paddlew,
        this.paddleh
      );

      if (this.paddleTouch && this.paddleTouchDuration == 20) {
        this.paddleColor = "#a5afc7";
        this.paddleTouch = false;
        this.paddleTouchDuration = 0;
      }
      if (this.paddleTouch && this.paddleTouchDuration < 20) {
        this.paddleTouchDuration++;
      }
    },
    drawBricks() {
      var isWon = true;
      for (var i = 0; i < this.n_brick_rows; i++) {
        for (var j = 0; j < this.n_brick_cols; j++) {
          if (this.bricks[i][j] == 1) {
            var color = "#a5afc7";
            isWon = false;
            this.rect(
              color,
              this.brick_padding + j * (this.brick_width + this.brick_padding),
              this.brick_padding + i * (this.brick_height + this.brick_padding),
              this.brick_width,
              this.brick_height
            );
          }
        }
      }

      if (isWon == true) {
        clearInterval(frames);
        $("#announce").html("Ganaste!");
        $("#announce").show();
        for (var ball in this.balls) {
          delete this.balls[ball];
          this.gameOver = true;
        }
      }
    },
    Tokens() {
      for (var token in this.tokenLetters) {
        var changedTokenColor;
        //this.tokenSpeed = 10;
        //hit bottom
        if (this.tokenLetters[token].y > this.HEIGHT) {
          delete this.tokenLetters[token];
          break;
        }
        //collect
        if (
          this.tokenLetters[token].y + this.tokenSpeed >
            (this.HEIGHT-this.restaMovil) - this.paddleh &&
          this.tokenLetters[token].x - 5 > this.paddlex &&
          this.tokenLetters[token].x + 5 < this.paddlex + this.paddlew
        ) {
          switch (this.tokenLetters[token].type) {
            case "B":
              this.score += 100;
              //$('#score').html(score);
              delete this.tokenLetters[token];
              break;
            case "W":
              this.score += 20;
              //$('#score').html(score);
              if (!this.paddleWidenDuration) this.paddlex -= 50;
              this.paddleWidenDuration += 150;
              this.paddlePrevousToken = this.paddleLastToken;
              this.paddleLastToken = "W";
              delete this.tokenLetters[token];
              break;
            case "3":
              this.score += 30;
              //$('#score').html(score);
              this.threeBalls();
              delete this.tokenLetters[token];
              break;
            case "F":
              this.score -= 100;
              $("#score").html(this.score);
              if (!this.fastDuration) {
                for (var ball in this.balls) {
                  this.balls[ball].dx *= 1.5;
                  this.balls[ball].dy *= 1.5;
                }
                this.fast = true;
              }
              this.fastDuration += 20;
              delete this.tokenLetters[token];
              break;
            case "S":
              this.score -= 100;
              //$('#score').html(score);
              if (!this.paddleShrinkDuration) this.paddlex += 50;
              this.paddleShrinkDuration += 150;
              this.paddlePrevousToken = this.paddleLastToken;
              this.paddleLastToken = "S";
              delete this.tokenLetters[token];
              break;
          }
        }

        if (this.tokenLetters[token]) {
          var tokenColor;
          var changedTokenColor;

          var tokenX = this.tokenLetters[token].x;
          var tokenY = (this.tokenLetters[token].y += this.tokenSpeed);
          var type = this.tokenLetters[token].type;

          ++this.tokenLetters[token].color;

          switch (this.tokenLetters[token].type) {
            case "B":
              this.tokenColor = "#a5afc7";
              this.changedTokenColor = "#a5afc7";
              break;
            case "W":
              this.tokenColor = "#a5afc7";
              this.changedTokenColor = "#a5afc7";
              break;
            case "3":
              this.tokenColor = "#a5afc7";
              this.changedTokenColor = "#a5afc7";
              break;
            case "F":
              this.tokenColor = "#a5afc7";
              this.changedTokenColor = "#a5afc7";
              break;
            case "S":
              this.tokenColor = "#a5afc7";
              this.changedTokenColor = "#a5afc7";
              break;
          }

          this.tokenLetters[token].color % 7 !== 0
            ? tokenColor
            : (tokenColor = changedTokenColor);

          this.context.fillStyle = this.tokenColor;
          this.context.font = "45px Arial Black, Gadget, sans-serif";
          this.context.fillText(type, tokenX, tokenY);
        }
      }
    },
    threeBalls() {
      if (!this.threeBallsAtWork) {
        for (var ball in this.balls) {
          var y = this.balls[ball].y;
          var x = this.balls[ball].x;
          var dx = this.balls[ball].dx;
          var dy = this.balls[ball].dy;

          this.balls[++this.ballsCount] = { x: x, y: y, dx: -dx, dy: dy };
          this.balls[++this.ballsCount] = { x: x, y: y, dx: dx, dy: -dy };
          this.threeBallsAtWork = true;
        }
      }
    },
    brickHit(i, j, y, x) {
      this.bricks[i][j] = 0;
      this.score += 12;
      //$('#score').html(score);
      var random = Math.random();
      if (random < 0.7) {
        this.tokenLetters["pos-i" + i + "j" + j] = new Object();
        var newToken = this.tokenLetters["pos-i" + i + "j" + j];
        newToken["y"] = y;
        newToken["x"] = x;
        newToken["color"] = 0;

        random = Math.random();
        if (this.threeBallsAtWork) {
          if (random <= 0.25) newToken["type"] = "B";
          else if (random <= 0.5) newToken["type"] = "W";
          else if (random <= 0.75) newToken["type"] = "F";
          else if (random <= 1) newToken["type"] = "S";
        } else {
          if (random <= 0.2) newToken["type"] = "B";
          else if (random <= 0.4) newToken["type"] = "W";
          else if (random <= 0.6) newToken["type"] = "3";
          else if (random <= 0.8) newToken["type"] = "F";
          else if (random <= 1) newToken["type"] = "S";
        }
      }
    },
    bounce() {
      for (var i = 0; i < this.n_brick_rows; i++) {
        for (var j = 0; j < this.n_brick_cols; j++) {
          var brick_x_l =
            this.brick_padding + j * (this.brick_width + this.brick_padding);

          var brick_x_r =
            this.brick_width +
            this.brick_padding +
            j * (this.brick_width + this.brick_padding);

          var brick_y_b =
            this.brick_padding +
            this.brick_height +
            i * (this.brick_height + this.brick_padding);

          var brick_y_t =
            this.brick_padding + i * (this.brick_height + this.brick_padding);

          var brick_edge = this.brick_height * 0.4;
          var tokenY = brick_y_b;
          var tokenX =
            0.5 * this.brick_width +
            this.brick_padding +
            j * (this.brick_width + this.brick_padding);

          if (this.bricks[i][j] == 1) {
            for (var ball in this.balls) {
              var y = this.balls[ball].y;

              var x = this.balls[ball].x;

              var dx = this.balls[ball].dx;

              var dy = this.balls[ball].dy;

              //directions
              var ballDirection = "";

              if (dx < 0 && dy < 0) ballDirection = "up left";
              if (dx > 0 && dy < 0) ballDirection = "up right";
              if (dx < 0 && dy > 0) ballDirection = "down left";
              if (dx > 0 && dy > 0) ballDirection = "down right";

              if (
                ballDirection == "up left" &&
                y + dy - this.radius <= brick_y_b &&
                y + dy + this.radius >= brick_y_t &&
                x + dx - this.radius >= brick_x_l &&
                x + dx - this.radius <= brick_x_r
              ) {
                if (y - this.radius < brick_y_b) {
                  //dy = -(dy);
                  this.balls[ball].dx = -dx;
                } else {
                  this.balls[ball].dy = -dy;
                }
                this.brickHit(i, j, tokenY, tokenX);
              } else if (
                ballDirection == "down left" &&
                y + dy + this.radius >= brick_y_t &&
                y + dy - this.radius <= brick_y_b &&
                x + dx - this.radius >= brick_x_l &&
                x + dx - this.radius <= brick_x_r
              ) {
                if (y + this.radius > brick_y_t) {
                  //dy = -(dy);
                  this.balls[ball].dx = -dx;
                } else {
                  this.balls[ball].dy = -dy;
                }
                this.brickHit(i, j, tokenY, tokenX);
              } else if (
                ballDirection == "up right" &&
                y + dy - this.radius <= brick_y_b &&
                y + dy + this.radius >= brick_y_t &&
                x + dx + this.radius >= brick_x_l &&
                x + dx + this.radius <= brick_x_r
              ) {
                if (y - this.radius < brick_y_b) {
                  //dy = -(dy);
                  this.balls[ball].dx = -dx;
                } else {
                  this.balls[ball].dy = -dy;
                }
                this.brickHit(i, j, tokenY, tokenX);
              } else if (
                ballDirection == "down right" &&
                y + dy + this.radius >= brick_y_t &&
                y + dy - this.radius <= brick_y_b &&
                x + dx + this.radius >= brick_x_l &&
                x + dx + this.radius < brick_x_r
              ) {
                if (y + this.radius > brick_y_t) {
                  //dy = -(dy);
                  this.balls[ball].dx = -dx;
                } else {
                  this.balls[ball].dy = -dy;
                }
                this.brickHit(i, j, tokenY, tokenX);
              } else {
              }
            }
          }
        }

        //walls

        for (var ball in this.balls) {
          var y = this.balls[ball].y;
          var x = this.balls[ball].x;
          var dx = this.balls[ball].dx;
          var dy = this.balls[ball].dy;

          if (x + dx + this.radius > this.WIDTH || x + dx - this.radius < 0) {
            this.balls[ball].dx = -dx;
          }

          if (y + dy - this.radius < 0) {
            this.balls[ball].dy = -dy;
          }

          //paddle
          if (
            y + dy > (this.HEIGHT-this.restaMovil) - this.radius - this.paddleh &&
            this.lostCount == 0
          ) {
            if (
              x + dx > this.paddlex - this.radius * 2 &&
              x + dx < this.paddlex + this.paddlew + this.radius * 2
            ) {
              //below surface or on edge
              if (
                (this.HEIGHT-this.restaMovil) - y - dy < this.paddleh ||
                x + dx < this.paddlex + this.paddlew * 0.1 + this.radius * 2 ||
                x + dx > this.paddlex + this.paddlew * 0.9 - this.radius * 2
              ) {
                //considering current movement
                if ((this.rightDown && dx > 0) || (this.leftDown && dx < 0)) {
                  this.balls[ball].dy = -dy;
                  this.paddleColor = "#a5afc7";
                  this.paddleTouch = true;
                } else {
                  this.balls[ball].dy = -dy;
                  this.balls[ball].dx = -dx;
                  this.paddleColor = "#a5afc7";
                  this.paddleTouch = true;
                }
              } else {
                this.balls[ball].dy = -dy;
                this.paddleColor = "#a5afc7";
                this.paddleTouch = true;
              }
            }
          }
          //lost
          if (y > (this.HEIGHT-this.restaMovil) + this.radius) {
            delete this.balls[ball];
            if (this.isEmpty()) {
              //clearInterval(frames);
              $("#announce").html("Reintentar");
              $("#announce").show();
              this.gameOver = true;
              //this.start_over();
            }
          }
        }
      }
    },
    init_mouse() {
      this.canvasStart = $("#canvas").offset().left;
      this.canvasMinX = this.canvasStart - this.paddlew * 0.2;
      this.canvasMaxX = this.canvasStart + this.WIDTH - this.paddlew * 0.2;
    },
    onMouseMove(evt) {
      if (window.innerWidth > 600) {
       this.init_mouse();
        if (evt.pageX > this.canvasMinX && evt.pageX < this.canvasMaxX) {
          this.paddlex = evt.pageX - this.canvasStart;
        }
      }
      
    },
    startAnimate() {
      $("#canvas").css({ height: 550, width: 850, opacity: 0 });
      $("#canvas").animate(
        { width: "990px", height: "600px", opacity: 1 },
        250
      );
    },
    isEmpty() {
      for (var ball in this.balls) {
        return false;
      }
      return true;
    },
  },
};
</script>
<style>
.cuerpo {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.principal {
  width: 1000px;
  height: 620px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

#canvas {
  opacity: 1;
  position: relative;
  cursor: move;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 1-px;
  display: none;
}

#announce {
  display: none;
  color: #a5afc7;
  position: absolute;
  z-index: 20;
  font-size: 100px;
  left: 0px;
  top: 200px;
  width: 1000px;
  text-align: center;
  font-family: "Arial Black", Gadget, sans-serif;
  text-transform: uppercase;
  opacity: 0.5;
  cursor: default;
}


</style>