<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <v-row justify="center">
          <v-dialog v-model="dialog" max-width="300" persistent>
            <v-card>
              <v-container grid-list-md>
                <v-card-title primary-title> Nuevo </v-card-title>
                <v-form
                  dense
                  ref="formTipo"
                  v-model="validTipo"
                  lazy-validation
                >
                  <v-container grid-list-md>
                    <v-text-field
                      label="Tipo de egreso"
                      v-model="tipo.tipo"
                      prepend-icon="mdi-format-list-bulleted-type"
                      :rules="generalRegla"
                    ></v-text-field>
                  </v-container>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">Cancelar</v-btn>
                    <v-btn
                      :disabled="!validTipo"
                      color="primary"
                      class="rounded-pill pa-3"
                      @click="nuevoTipo()"
                    >
                      Registrar
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-container>
            </v-card>
          </v-dialog>
        </v-row>
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 md12>
              <v-form
                dense
                ref="form"
                v-model="valid"
                enctype="multipart/form-data"
                lazy-validation
              >
                <v-layout row wrap>
                  <v-flex xs12 md12>
                    <v-autocomplete
                      v-model="egreso.persona_id"
                      :items="itemsPersonal"
                      item-text="todo"
                      item-value="id"
                      :rules="generalRegla"
                      max-width="30"
                      ref="autoPersonal"
                      :search-input.sync="searchPersonal"
                      hide-no-data
                      label="Personal"
                      placeholder="Empieza a escribir para búscar"
                      prepend-icon="mdi-worker"
                    >
                      <template max-width="60" v-slot:item="{ item }">
                        <v-list-item-avatar
                          color="indigo"
                          class="headline font-weight-light white--text"
                        >
                          {{ item.nombre.charAt(0) }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            >{{ item.nombre }} {{ item.apellido_paterno }}
                            {{ item.apellido_materno }}</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >Celular: {{ item.celular }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-select
                      :items="tipos"
                      v-model="egreso.tipo_id"
                      label="Tipo de egreso"
                      :rules="generalRegla"
                      item-text="tipo"
                      item-value="id"
                      no-data-text="No hay elementos registrados"
                    >
                      <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item @click="dialog = true">
                          <v-list-item-avatar color="grey lighten-3">
                            <v-icon>mdi-plus</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>Agregar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-flex>
                  <v-textarea
                    v-model="egreso.observaciones"
                    rows="3"
                    label="Observaciones"
                    filled
                  ></v-textarea>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </base-component>
  </div>
</template>
<script>
export default {
  data() {
    return {
      valid: true,
      validTipo: true,
      tipos: [],
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      egreso: {
        observaciones: "",
        tipo_id: "",
        persona_id: "",
      },
      tipo: {
        tipo: "",
      },
      dialog: false,
      personal: [],
      searchPersonal: "",
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {
    itemsPersonal() {
      return this.personal.map((entry) => {
        const todo =
          entry.nombre +
          " " +
          entry.apellido_paterno +
          " " +
          entry.apellido_materno +
          " ";
        return Object.assign({}, entry, { todo });
      });
    },
  },
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "form/egreso/persona",
          data: {},
        })
        .then((result) => {
          this.tipos = result.data.tipos;
          this.personal = result.data.socios;
        });
    },
    nuevoTipo() {
      if (this.$refs.formTipo.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "tipo_egreso_persona";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.tipo,
          })
          .then((result) => {
            this.tipos.unshift(result.data.tipo);
            this.egreso.tipo_id = result.data.tipo.id;
            this.dialog = false;
            this.$refs.formTipo.reset();
          });
      }
    },
    datos() {
      if (this.$refs.form.validate()) {
        return this.egreso;
      } else {
        return null;
      }
    },
    limpiar() {
      this.$refs.form.reset();
    },
  },
};
</script>