<template>
    
            <div>
                <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
                   
                <v-layout row wrap>
                    <v-flex xs12 md12>
                        <v-card class="cool-xs pa-4" outlined>
                            <div v-if="showChart">
                                <apexchart type="area" height="350" :options="chartOptions" :series="series"></apexchart>
                            </div>
                        </v-card>
                    </v-flex>
                </v-layout>

            
    </div>
</template>
<script>
import apexchart from 'vue-apexcharts'

export default {
    components:{
        apexchart
    },
    data() {
        return {
         showChart: false,
        series: [{
            name: 'Subida (MB)',
            data: []
        }, {
            name: 'Bajada (MB)',
            data: []
        }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'area'
        },
        title: {
          text: 'Consumo',
          align: 'left'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: []
        },
        tooltip: {
          x: {
            format: 'dd/MM/yyyy hh:mm'
          }
        }
      }
    };
    },
    mounted() {
        
    },
    computed: {
        
    },
    methods: {
      
      graficar(datos){
        
        let subidas = [];
        let bajadas = [];
          for (let index = 0; index < datos.length; index++) {
              const dato = datos[index];
              
              let subida = dato.subida;
              let bajada = dato.bajada;
              var date = new Date(dato.fecha+" "+dato.hora_completa);
              var fecha = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
              subidas.push(subida);
              bajadas.push(bajada);
              this.chartOptions.xaxis.categories.push(fecha);

              
          }

          this.series=[{
                name: 'Subida (MB)',
                data: subidas,
            }, {
                name: 'Bajada (MB)',
                data: bajadas
            }];
            this.showChart = true;
      },
    }

};
</script>