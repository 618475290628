<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <div v-if="showChart">
          <v-layout row wrap>
            <v-flex xs12 md12>
              <div>
                <div>
                  <div class="row">
                    <div class="col-md-4">
                      <div id="chart-spark1">
                        <apexchart
                          type="area"
                          height="100"
                          :options="chartOptions"
                          :series="series"
                        ></apexchart>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div id="chart-spark2">
                        <apexchart
                          type="area"
                          height="100"
                          :options="chartOptionsSpark2"
                          :series="seriesSpark2"
                        ></apexchart>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div id="chart-spark3">
                        <apexchart
                          type="area"
                          height="100"
                          :options="chartOptionsSpark3"
                          :series="seriesSpark3"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex v-if="false" xs12 md6>
              <v-layout row wrap>
                <v-flex xs12>
                  <p class="text-center">Ingresos por servicio mes actual</p>
                </v-flex>
                <v-flex xs12>
                  <div>
                    <apexchart
                      type="radialBar"
                      height="270"
                      :options="chartOptionsServicios"
                      :series="seriesServicios"
                    ></apexchart>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs12 md12>
              <v-layout row wrap>
                <v-flex xs12>
                  <apexchart
                    type="donut"
                    height="250"
                    :options="chartOptionsPagos"
                    :series="seriesPagos"
                  ></apexchart>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12>
              <graficaPagosZonas></graficaPagosZonas>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12>
              <apexchart
                type="bar"
                height="350"
                :options="chartOptionsMeses"
                :series="seriesMeses"
              ></apexchart>
            </v-flex>
          </v-layout>
        </div>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
import apexchart from "vue-apexcharts";
import graficaPagosZonas from "@/js/components/pagos/estadisticas/PagosPorZona.vue";
export default {
  components: {
    apexchart,
    graficaPagosZonas,
  },
  data() {
    return {
      showChart: false,
      series: [
        {
          name: "Liquidado",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "area",
          height: 160,
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 0.3,
        },
        yaxis: {
          min: 0,
        },
        colors: ["#82ed8a"],
        title: {
          text: "$0.00",
          offsetX: 0,
          style: {
            fontSize: "24px",
          },
        },
        subtitle: {
          text: "Liquidados de este mes",
          offsetX: 0,
          style: {
            fontSize: "14px",
          },
        },
      },

      seriesSpark2: [
        {
          name: "Pendientes",
          data: [],
        },
      ],
      chartOptionsSpark2: {
        chart: {
          type: "area",
          height: 160,
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 0.3,
        },
        yaxis: {
          min: 0,
        },
        colors: ["#f2ac3a"],
        title: {
          text: "$0,00",
          offsetX: 0,
          style: {
            fontSize: "24px",
          },
        },
        subtitle: {
          text: "Pendientes de este mes",
          offsetX: 0,
          style: {
            fontSize: "14px",
          },
        },
      },

      seriesSpark3: [
        {
          name: "Promesa",
          data: [],
        },
      ],
      chartOptionsSpark3: {
        chart: {
          type: "area",
          height: 160,
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 0.3,
        },
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        yaxis: {
          min: 0,
        },
        colors: ["#ca3af2"],
        title: {
          text: "$0.00",
          offsetX: 0,
          style: {
            fontSize: "24px",
          },
        },
        subtitle: {
          text: "Promesas de este mes",
          offsetX: 0,
          style: {
            fontSize: "14px",
          },
        },
      },
      /// servicios
      seriesServicios: [12, 12, 2],
      chartOptionsServicios: {
        chart: {
          height: 350,
          type: "radialBar",
        },

        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: "22px",
              },
              value: {
                fontSize: "16px",
              },
              total: {
                show: true,
                label: "Total",
              },
            },
          },
        },
        labels: ["", "", ""],
        title: {
          text: "",
          offsetX: 0,
          style: {
            fontSize: "24px",
          },
        },
      },
      //tipos pagos
      seriesPagos: [],
      chartOptionsPagos: {
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        labels: [],
        title: {
          text: "Tipos de liquidaciones del mes actual",
          offsetX: 0,
          style: {
            fontSize: "15px",
          },
        },
      },
      //meses
      seriesMeses: [
        {
          name: "Promesas",
          data: [],
        },
        {
          name: "Liquidados",
          data: [],
        },
        {
          name: "Pendientes",
          data: [],
        },
      ],
      chartOptionsMeses: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dic",
          ],
        },
        yaxis: {
          title: {
            text: "$ Cantidad",
          },
          labels: {
            formatter: function (y) {
              return Common.formatMoney(y);
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return Common.formatMoney(val);
            },
          },
        },
      },
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `pagos/estadisticas/datos`,
          data: {},
        })
        .then((result) => {
          this.promesas(result.data.promesas);
          this.liquidados(result.data.liquidados);
          this.pendientes(result.data.pendientes);
          //this.graficarServicios(result.data.servicios);
          this.graficarTipos(result.data.tipos);
          this.graficarMeses(result.data.meses);
          this.showChart = true;
        });
    },
    liquidados(datos) {
      let montos = [];
      let nombres = [];
      let total = parseFloat(0);
      for (let index = 0; index < datos.length; index++) {
        total += parseFloat(datos[index].monto);
        montos.push(datos[index].monto);
        nombres.push(datos[index].cliente.FullName);
      }
      this.series = [
        {
          name: "Liquidado",
          data: montos,
        },
      ];
      this.chartOptions.title.text = Common.formatMoney(total, 2);
      this.chartOptions.xaxis.categories = nombres;
    },

    pendientes(datos) {
      let montos = [];
      let nombres = [];
      let total = parseFloat(0);
      for (let index = 0; index < datos.length; index++) {
        montos.push(datos[index].monto);
        nombres.push(datos[index].cliente.FullName);
        total += parseFloat(datos[index].monto);
      }
      this.seriesSpark2 = [
        {
          name: "Pendiente",
          data: montos,
        },
      ];
      this.chartOptionsSpark2.title.text = Common.formatMoney(total, 2);
      this.chartOptionsSpark2.xaxis.categories = nombres;
    },
    promesas(datos) {
      let montos = [];
      let nombres = [];

      let total = parseFloat(0);
      for (let index = 0; index < datos.length; index++) {
        nombres.push(datos[index].cliente.FullName);
        montos.push(datos[index].monto);
        total += parseFloat(datos[index].monto);
      }
      this.seriesSpark3 = [
        {
          name: "Promesa",
          data: montos,
        },
      ];
      this.chartOptionsSpark3.title.text = Common.formatMoney(total, 2);
      this.chartOptionsSpark3.xaxis.categories = nombres;
    },
    graficarServicios(datos) {
      let servicios = datos.servicios;
      let serie = [];
      let etiquetas = [];
      for (let index = 0; index < 10; index++) {
        //let servicio = servicios[index].nombre;
        serie.push(servicios[index].porcentaje);
        //serie.push(index+10);
        etiquetas.push(servicios[index].nombre);
      }
      this.totalServicios = datos.total;

      this.seriesServicios = serie;

      this.chartOptionsServicios.labels = etiquetas;
    },
    graficarTipos(datos) {
      let tipos = datos.tipos;
      let serie = [];
      let etiquetas = [];
      for (let index = 0; index < tipos.length; index++) {
        serie.push(tipos[index].porcentaje);
        etiquetas.push(
          Common.formatMoney(tipos[index].recaudado) + " - " + tipos[index].tipo
        );
      }
      this.seriesPagos = serie;
      this.chartOptionsPagos.labels = etiquetas;
    },
    graficarMeses(datos) {
      let liquidados = [];
      let pendientes = [];
      let promesas = [];

      for (let index = 0; index < datos.length; index++) {
        liquidados.push(datos[index].liquidado);
        pendientes.push(datos[index].pendiente);
        promesas.push(datos[index].promesa);
      }

      this.seriesMeses = [
        {
          name: "Promesas",
          data: promesas,
        },
        {
          name: "Liquidados",
          data: liquidados,
        },
        {
          name: "Pendientes",
          data: pendientes,
        },
      ];
    },
  },
};
</script>
