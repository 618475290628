<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <v-row justify="center">
          <v-dialog v-model="dialogProductos" scrollable max-width="400px">
            <v-card>
              <v-card-title>Productos asociados</v-card-title>
              <v-divider></v-divider>
              <v-card-text style="height: 300px">
                <div v-if="productos.length == 0">
                  <P>Ningún producto esta asociado a esta categoria</P>
                </div>
                <div v-else v-for="producto in productos" :key="producto.id">
                  <p>Producto: {{ producto.producto }}</p>
                  <p>Marca: {{ producto.marca }}</p>
                  <v-divider></v-divider>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="rounded-pill pa-3"
                  @click="dialogProductos = false"
                  >Ok</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="cool pa-4">
              <v-card-title class="display-1" primary-title>
                <strong> Categorias</strong>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-btn
                      @click="nuevo()"
                      class="rounded-pill"
                      color="red"
                      dark
                    >
                      <v-icon left>mdi-plus</v-icon>
                      Agregar
                    </v-btn>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="search"
                      @keyup.enter="getDatos"
                      append-icon="mdi-magnify"
                      label="Buscador"
                      class="rounded-pill"
                      single-line
                      solo
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-data-table
                dense
                :search="search"
                :headers="headers"
                :items="elementos"
                :options.sync="options"
                :server-items-length="totalElementos"
                :loading="loading"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-format-horizontal-align-left',
                  lastIcon: 'mdi-format-horizontal-align-right',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  itemsPerPageAllText: 'Todos',
                  itemsPerPageText: 'Registros por página:',
                  itemsPerPageOptions: [3, 5, 10, 15, -1],
                }"
              >
                <template v-slot:no-results>
                  <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                    >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                  >
                </template>
                <template v-slot:item.id="{ item }">
                  {{
                    elementos
                      .map(function (x) {
                        return x.id;
                      })
                      .indexOf(item.id) + 1
                  }}
                </template>
                <template v-slot:item.productos="{ item }">
                  <v-chip dense color="primary" dark>{{
                    item.productosCantidad
                  }}</v-chip>
                </template>
                <template v-slot:item.opciones="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="actualizar(item)" icon v-on="on">
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar categoria</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="eliminar(item)" icon v-on="on">
                        <v-icon small> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span>Desactivar Categoria</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="verProductos(item)" icon v-on="on">
                        <v-icon small> mdi-eye </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver productos</span>
                  </v-tooltip>
                </template>
                <template v-slot:no-data>
                  <v-alert
                    :value="true"
                    color="red"
                    dark
                    icon="mdi-alert-circle"
                  >
                    <v-row align="center">
                      <v-col> Ningún dato que mostrar :( </v-col>
                      <v-col class="shrink">
                        <v-btn @click="getDatos()"
                          ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-alert>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
        <nuevo ref="nuevo"></nuevo>
        <editar ref="editar"></editar>
      </div>
    </base-component>
  </div>
</template>

<script>
import nuevo from "@/js/components/almacen/categorias/nuevo.vue";
import editar from "@/js/components/almacen/categorias/editar.vue";

export default {
  components: {
    nuevo,
    editar,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      dialog: false,
      dialogEditar: false,
      dialogProductos: false,
      elementos: [],
      loading: true,
      options: {},
      validEditar: true,
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      headers: [
        { text: "#", align: "start", value: "id" },
        { text: "Categoria", value: "categoria", sortable: true },
        { text: "Productos asociados", value: "productos", sortable: true },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      productos: [],
    };
  },
  mounted() {},
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `categorias/tabla`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.categorias.data;
          this.totalElementos = result.data.categorias.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    eliminar(elemento) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `categoria/` + elemento.id;
            let event = "delete";

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: elemento,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(elemento), 1);
              });
          } else {
          }
        });
    },
    nuevo() {
      this.$refs.nuevo.mostrar().then((resultado) => {
        if (resultado) {
          this.totalElementos = this.elementos.unshift(resultado);
        }
      });
    },
    actualizar(elemento) {
      this.actualizarTipo = elemento;
      const tipo = Object.assign({}, elemento);
      this.$refs.editar.mostrar(tipo).then((confirm) => {
        if (confirm) {
          this.elementos.splice(
            this.elementos.indexOf(this.actualizarTipo),
            1,
            confirm
          );
        }
      });
    },

    verProductos(elemento) {
      this.productos = elemento.productos;

      this.dialogProductos = true;
    },
  },
};
</script>
