<template>
  <v-flex xs12>
    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <v-card class="cool pa-4" elevation="4">
      <v-card-title primary-title> Perfiles </v-card-title>
      <v-card-text>
        <v-layout row wrap>
          <v-btn @click="nuevo()" color="red" class="rounded-pill" dark>
            <v-icon left>mdi-plus</v-icon>
            Agregar
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
            solo
            small
            class="rounded-pill"
          ></v-text-field>
        </v-layout>

        <v-data-table
          dense
          :search="search"
          :headers="headers"
          :items="elementos"
          :options.sync="options"
          :server-items-length="totalElementos"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-format-horizontal-align-left',
            lastIcon: 'mdi-format-horizontal-align-right',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            itemsPerPageAllText: 'Todos',
            itemsPerPageText: 'Registros por página:',
            itemsPerPageOptions: [5, 50, 100, -1],
          }"
        >
          <template v-slot:item.velocidad="{ item }">
            <div>
              <v-icon class="ml-1" color="blue"
                >mdi-arrow-up-bold-circle-outline</v-icon
              >
              <strong class="ml-1">{{ item.servicio.subida }} </strong> Mb.

              <v-icon class="ml-1" color="pink"
                >mdi-arrow-down-bold-circle-outline</v-icon
              >
              <strong class="ml-1">{{ item.servicio.bajada }} </strong> Mb.
            </div>
          </template>
          <template v-slot:item.local_address="{ item }">
            <v-btn text color="blue" @click="abrirIp(item.local_address)">{{
              item.local_address
            }}</v-btn>
          </template>
          <template v-slot:item.opciones="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn small @click="editar(item)" icon v-on="on">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn small @click="eliminar(item)" icon v-on="on">
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <nuevoPerfil ref="refNuevoPerfil"></nuevoPerfil>
    <editarPerfil ref="refEditarPerfil"></editarPerfil>
  </v-flex>
</template>
<script>
import nuevoPerfil from "@/js/components/red/routers/detalles/apartado_pppoe/perfiles/nuevoPerfil.vue";
import editarPerfil from "@/js/components/red/routers/detalles/apartado_pppoe/perfiles/editarPerfil.vue";

export default {
  components: {
    nuevoPerfil,
    editarPerfil,
  },
  data() {
    return {
      idRouter: 0,
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      headers: [
        { text: "name", value: "name", sortable: true },
        { text: "servicio", value: "servicio.nombre", sortable: false },
        { text: "Velocidad", value: "velocidad", sortable: false },
        { text: "Local address", value: "local_address", sortable: true },
        { text: "Remote address", value: "remote_address", sortable: true },
        {
          text: "Secrets asociados",
          value: "secretsCantidad",
          sortable: false,
        },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.idRouter = arreUrl.pop();
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `perfiles/datos/tabla`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
            router_id: this.idRouter,
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.perfiles.data;
          this.totalElementos = result.data.perfiles.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    nuevo() {
      this.$refs.refNuevoPerfil.mostrar(this.idRouter).then((resultado) => {
        if (resultado) {
          this.elementos.push(resultado);
        }
      });
    },
    editar(item) {
      this.perfilActualizar = item;
      const perfil = Object.assign({}, item);
      this.$refs.refEditarPerfil.mostrar(perfil).then((resultado) => {
        if (resultado) {
          this.elementos.splice(
            this.elementos.indexOf(this.perfilActualizar),
            1,
            resultado
          );
        }
      });
    },
    eliminar(item) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de Eliminar este perfil?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `perfil/` + item.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: this.perfil,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(item), 1);
              });
          }
        });
    },
    abrirIp(ip) {
      window.open("https://" + ip, "_blank");
    },
  },
};
</script>