<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <div v-if="correo">
        <notificacionesCorreo></notificacionesCorreo>
        </div>
        <v-card-title v-if="correo == false" primary-title>
          El cliente no tiene un correo electronico registrado
        </v-card-title>
        <div v-if="token">
        <v-divider></v-divider>
        <notificacionesMovil></notificacionesMovil>
        </div>
        <div v-if="token == false">
          <v-card-title primary-title>
            Notificaciones de la aplicación movil
          </v-card-title>
          <v-card-text>
            Detalles: {{ detallesToken }}
            <br />
            Instrucciones:
            <br />
            1.- Pedir al cliente que descargue la aplicación móvil para android
            desde la playstore.
            <br />
            2.- Una ves abierta la aplicación introducir los datos de inicio de
            sesión en la aplicación.(usuario y contraseña)
            <br />
            el usuario y contraseña son el correo con el que fue registrado el
            cliente, si aun no tiene uno pedirlo al cliente y posteriormente
            editar los datos de este cliente y colocar el correo proporcionado
            por el mismo.
            <br />
            3.- Listo.
          </v-card-text>
        </div>
      </div>
    </base-component>
  </div>
</template>
<script>
import notificacionesCorreo from "@/js/components/clientes/detalles/notificaciones/notificacionesCorreo.vue";
import notificacionesMovil from "@/js/components/clientes/detalles/notificaciones/notificacionesMovil.vue";
export default {
  components: {
    notificacionesCorreo,
    notificacionesMovil
  },
  data() {
    return {
      token: false,
      clienteId: null,
      correo: false,
      detallesToken: "",
      
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.clienteId = arreUrl.pop();
    this.yaTieneMovil();
  },
  watch: {
    
  },
  computed: {},
  methods: {
    yaTieneMovil() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "user/token/movil/registrado/" + this.clienteId,
          data: {},
        })
        .then((result) => {
          this.correo = result.data.correo;
          this.token = result.data.token;
          this.detallesToken = result.data.message;
        });
    },
  },
};
</script>
