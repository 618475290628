

import Axios from "axios"

export default {
    state: {
        variable_ejemplo:'hola HOLA!"··%$%·$&%$&/())=?¿^*Ñ¨Ç:;:_',
    },
    mutations: {
        mutacion_ejemplo(state,dato){
            state.variable_ejemplo = dato;
        }
    },
    actions: {
    llamada_ejemplo({commit},payload){
        commit('mutacion_ejemplo',payload);
    }
    },
    getters: {
      get_variable_ejemplo(state){
          return state.variable_ejemplo;
      }
    },
modules:{
  
}
};
/*
export default {
    state: {

    },
    mutations: {

    },
    actions: {

    },
    getters: {

    }
},
modules:{
  
}
};
*/