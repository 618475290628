<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <v-layout row wrap>
          <v-flex xs12 md12>
            <v-card class="cool pa-4">
              <v-tabs
                class="cool"
                v-model="tab"
                background-color="red"
                dark
                show-arrows
              >
                <v-tab> Tipos de cliente </v-tab>
                <v-tab> Captaciones </v-tab>
                <v-tab> Servicios </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <tipos></tipos>
                </v-tab-item>
                <v-tab-item>
                  <captaciones></captaciones>
                </v-tab-item>
                <v-tab-item>
                  <servicios></servicios>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
import captaciones from "@/js/components/clientes/recursos/tablas/captaciones/tipos.vue";
import tipos from "@/js/components/clientes/recursos/tablas/tipos_cliente/tipos.vue";
import servicios from "@/js/components/clientes/recursos/tablas/servicios/tipos.vue";
import LocalStorage from "@/js/LocalStorage.js";

export default {
  components: {
    captaciones,
    tipos,
    servicios,
  },
  data() {
    return {
      tab: 0,
    };
  },
  watch: {
    tab(val) {
      LocalStorage.set("TAB_ACTUAL_RECURSOS_CLIENTES", val);
    },
  },
  mounted() {
    var actualTab = LocalStorage.get("TAB_ACTUAL_RECURSOS_CLIENTES");
    if (actualTab) {
      this.tab = actualTab;
    }
  },
  computed: {},
  methods: {},
};
</script>