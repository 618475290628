<template>
  <v-dialog v-model="dialog" max-width="800px" persistent>
    <v-card>
      <v-container grid-list-md>
        <v-card-title primary-title class="display-1">
          <strong>Editar</strong>
        </v-card-title>

        <v-responsive
          id="scroll-target"
          class="overflow-y-auto"
          :max-height="500"
        >
          <v-card-text>
            <v-form
              dense
              ref="form"
              v-model="valid"
              enctype="multipart/form-data"
              lazy-validation
            >
              <v-layout row wrap>
                <v-flex xs12 md8>
                  <v-text-field
                    label="Tabajo"
                    v-model="actividad.trabajo"
                    :rules="generalRegla"
                    prepend-icon="mdi-pen"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    label="Costo"
                    v-model="actividad.costo"
                    :rules="generalRegla"
                    type="number"
                    prepend-icon="mdi-cash-usd"
                    prefix="$"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 md4>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedDateFormatted"
                        label="Dia de trabajo"
                        prepend-icon="mdi-calendar"
                        readonly
                        :rules="generalRegla"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="es-mx"
                      v-model="actividad.fecha"
                      no-title
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>

                <v-flex xs12 md4>
                  <v-menu
                    ref="menu"
                    v-model="menu_reloj"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="actividad.hora_inicio"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="actividad.hora_inicio"
                        label="Hora inicio"
                        prepend-icon="mdi-av-timer"
                        readonly
                        :rules="generalRegla"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_reloj"
                      v-model="actividad.hora_inicio"
                      full-width
                      @click:minute="$refs.menu.save(actividad.hora_inicio)"
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 md4>
                  <v-menu
                    ref="menu_fin"
                    v-model="menu_reloj_fin"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="actividad.hora_fin"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="actividad.hora_fin"
                        label="Hora fin"
                        prepend-icon="mdi-av-timer"
                        readonly
                        :rules="generalRegla"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_reloj_fin"
                      v-model="actividad.hora_fin"
                      full-width
                      :min="actividad.hora_inicio"
                      @click:minute="$refs.menu_fin.save(actividad.hora_fin)"
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <v-textarea
                    v-model="actividad.materiales"
                    label="Materiales"
                    required
                    rows="4"
                    filled
                    shaped
                    hint="Cable, tubo, conectores, etc"
                  ></v-textarea>
                </v-flex>

                <v-flex xs12 md6>
                  <v-textarea
                    v-model="actividad.detalles"
                    label="Observaciones"
                    required
                    rows="4"
                    filled
                    shaped
                    hint="Describe el trabajo a realizar"
                  ></v-textarea>
                </v-flex>
              </v-layout>

              <p class="text-subtitle-2">Datos ubicación</p>
              <v-layout row wrap>
                <v-flex xs12 md4>
                  <v-text-field
                    v-model="cp"
                    label="Código postal"
                    :rules="generalRegla"
                    type="number"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    v-model="actividad.estado"
                    label="Estado"
                    :rules="generalRegla"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                  <v-text-field
                    v-model="actividad.municipio"
                    label="Municipio"
                    :rules="generalRegla"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex v-if="colonias.length != 0" xs12 md6>
                  <v-select
                    v-model="actividad.colonia"
                    :items="colonias"
                    item-text="nombre"
                    item-value="nombre"
                    label="Colonias"
                  ></v-select>
                </v-flex>
                <v-flex v-if="colonias.length == 0" xs12 md6>
                  <v-text-field
                    v-model="actividad.colonia"
                    label="Colonia"
                    :rules="generalRegla"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    v-model="actividad.direccion"
                    label="Dirección"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12>
                  <mapa ref="mapa"></mapa>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
        </v-responsive>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Cancelar</v-btn>
          <v-btn @click="enviar()" color="primary" class="rounded-pill pa-3"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import mapa from "@/js/components/mapa/mapaComponent.vue";

export default {
  components: {
    mapa,
  },
  data() {
    return {
      actividad: {
        personal_asignado_id: "",
        hora_inicio: "",
        hora_fin: "",
        fecha: "",
        trabajo: "",
        materiales: "",
        detalles: "",
        observaciones: "",
        latitud: "",
        longitud: "",
        direccion: "",
        estado: "",
        municipio: "",
        colonia: "",
        costo: 0,
        codigo_postal: "",
      },
      personal: [],
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: new Date().toISOString().substr(0, 10),
      menu2: false,
      menu_fin: false,
      menu_reloj: false,
      menu_reloj_fin: false,
      searchPersonal: "",
      dialog_mapa: false,
      colonias: [],
      modal2: false,
      modal: false,
      cp: "",
      resolve: null,
      reject: null,
      dialog: false,
    };
  },
  mounted() {
    this.getDatos();
    var arreUrl = window.location.href.split("/");
    this.actividad.personal_asignado_id = arreUrl.pop();
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(val);
    },
    cp(val) {
      //this.getDatosApi(val);
      this.actividad.codigo_postal = val;
    },
    menu_reloj(val) {
      this.actividad.hora_fin = "";
    },
  },
  computed: {
    computedDateFormatted() {
      if (this.actividad.fecha) {
        return this.formatDate(this.actividad.fecha);
      } else {
        return "";
      }
    },
    itemsPersonal() {
      return this.personal.map((entry) => {
        const todo =
          entry.nombre +
          " " +
          entry.apellido_paterno +
          " " +
          entry.apellido_materno +
          " ";
        return Object.assign({}, entry, { todo });
      });
    },
  },
  methods: {
    async esperarComponente() {
      await this.$nextTick();
      this.$refs.mapa.mostrarMarker(
        this.actividad.latitud,
        this.actividad.longitud,
        "markerTrabajo.png",
        "Trabajo",
        true
      );
    },
    esperarDatos(item) {
      this.actividad = item;
      this.cp = item.codigo_postal;
      this.dialog = true;
      this.esperarComponente();
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "datos/form/actividad",
          data: {},
        })
        .then((result) => {
          this.personal = result.data.personal;
        });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    enviar() {
      if (this.$refs.form.validate()) {
        this.actividad.latitud = this.$refs.mapa.getLatitud();
        this.actividad.longitud = this.$refs.mapa.getLongitud();
        let dispatch = "UsersModule/OnUpdate";
        let endpoint = `actividad/` + this.actividad.id;

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.actividad,
          })
          .then((result) => {
            this.limpiar();
            this.dialog = false;
            this.resolve(result.data.actividad);
          });
      }
    },
    limpiar() {
      this.actividad.costo = "";
      this.actividad.fecha = null;
      this.actividad.searchPersonal = "";
      this.actividad.personal_asignado_id = "";
      this.actividad.hora_inicio = "";
      this.actividad.hora_fin = "";
      this.actividad.fecha = "";
      this.actividad.trabajo = "";
      this.actividad.materiales = "";
      this.actividad.detalles = "";
      this.actividad.observaciones = "";
      this.actividad.latitud = "";
      this.actividad.longitud = "";
      this.actividad.direccion = "";
      this.actividad.estado = "";
      this.municipio = "";
      this.actividad.colonia = "";
      this.actividad.costo = 0;
      this.actividad.codigo_postal = "";
    },
    getDatosApi(cp) {
      if (cp.length == 5) {
        let dispatch = "UsersModule/OnGet";
        let endpoint = "datos/codigo/postal/" + cp;
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: {},
          })
          .then((result) => {
            if ([200, 201].includes(result.status)) {
              let datos = result.data.data;
              this.actividad.estado = datos.estado;
              this.actividad.municipio = datos.municipio;
              this.colonias = datos.colonias;
            }
          });
      }
    },
    guardarCoordenadas() {
      this.dialog_mapa = false;
      this.actividad.latitud = this.$store.getters.getCoordenadas.lat;
      this.actividad.longitud = this.$store.getters.getCoordenadas.lng;
    },
  },
};
</script>