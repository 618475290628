<template>
  <div>
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-btn
                    @click="nuevo()"
                    class="rounded-pill ma-1"
                    color="red"
                    dark
                  >
                    <v-icon left>mdi-plus</v-icon>
                    Agregar
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    v-model="search"
                    @keyup.enter="getDatos"
                    append-icon="mdi-magnify"
                    label="Buscador"
                    solo
                    class="rounded-pill"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-data-table
              dense
              :search="search"
              :headers="headers"
              :items="elementos"
              :options.sync="options"
              :server-items-length="totalElementos"
              :loading="loading"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-format-horizontal-align-left',
                lastIcon: 'mdi-format-horizontal-align-right',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                itemsPerPageAllText: 'Todos',
                itemsPerPageText: 'Registros por página:',
                itemsPerPageOptions: [10, 500, 1000, 5000, -1],
              }"
            >
              <template v-slot:no-results>
                <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                  >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                >
              </template>
              <template v-slot:item.id="{ item }">
                {{
                  elementos
                    .map(function (x) {
                      return x.id;
                    })
                    .indexOf(item.id) + 1
                }}
              </template>
              <template v-slot:item.cliente="{ item }">
                <v-chip
                  outlined
                  v-if="item.cliente"
                  dark
                  color="cyan"
                  @click="abrirCliente(item.cliente)"
                  >{{ item.cliente.FullName }}</v-chip
                >
              </template>
              <template v-slot:item.monto="{ item }">
                ${{ item.monto }}
              </template>
              <template v-slot:item.descuento="{ item }">
                <p class="success--text">
                  <v-icon small color="success"
                    >mdi-arrow-up-bold-circle-outline</v-icon
                  >${{ item.extra }}
                </p>
                <p class="red--text">
                  <v-icon small color="red"
                    >mdi-arrow-down-bold-circle-outline</v-icon
                  >${{ item.descuento }}
                </p>
              </template>
              <template v-slot:item.correspondencia="{ item }">
                {{ item.mes.mes }}<br />{{ item.anio }}
              </template>
              <template v-slot:item.estado="{ item }">
                <v-chip
                  dense
                  small
                  text-color="white"
                  :color="calcularColor(item.status_pago_id)"
                  >{{ item.estado.status }}</v-chip
                >
              </template>
              <template v-slot:item.retraso="{ item }">
                <v-chip
                  small
                  dark
                  :color="parseInt(item.retraso) == 0 ? 'green' : 'red'"
                  >{{ item.retraso }}</v-chip
                >
              </template>
              <template v-slot:item.fecha="{ item }">
                <v-chip
                  v-if="item.fecha"
                  small
                  outlined
                  class="mb-1"
                  color="primary"
                  >{{ item.fecha }}</v-chip
                >
                <v-chip v-if="item.hora" small outlined color="secondary">{{
                  item.hora
                }}</v-chip>
              </template>
              <template v-slot:item.cajero="{ item }">
                <v-chip outlined v-if="item.persona" dark color="pink">{{
                  item.persona.nombre
                }}</v-chip>
              </template>
              <template v-slot:item.opciones="{ item }">
                <v-tooltip v-if="item.eliminado == false" top>
                  <template v-slot:activator="{ on }">
                    <v-btn small @click="detalles(item)" icon v-on="on">
                      <v-icon small> mdi-dots-horizontal </v-icon>
                    </v-btn>
                  </template>
                  <span>Ver detalles</span>
                </v-tooltip>
                <v-tooltip v-if="item.status_pago_id == 1" top>
                  <template v-slot:activator="{ on }">
                    <v-btn small @click="ticketCalor(item)" icon v-on="on">
                      <v-icon small>mdi-printer</v-icon>
                    </v-btn>
                  </template>
                  <span>Ticket</span>
                </v-tooltip>
                <v-tooltip v-if="item.eliminado == false" top>
                  <template v-slot:activator="{ on }">
                    <v-btn small @click="recibo(item)" icon v-on="on">
                      <v-icon small>mdi-file-document</v-icon>
                    </v-btn>
                  </template>
                  <span>Recibo</span>
                </v-tooltip>
                <v-tooltip v-if="item.eliminado == false" top>
                  <template v-slot:activator="{ on }">
                    <v-btn small @click="editar(item)" icon v-on="on">
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip v-if="item.eliminado == false" top>
                  <template v-slot:activator="{ on }">
                    <v-btn small @click="eliminar(item)" icon v-on="on">
                      <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>

                <v-tooltip v-if="item.eliminado" top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      dense
                      small
                      @click="restaurar(item)"
                      :color="item.eliminado == true ? 'red' : 'green'"
                      v-on="on"
                      fab
                      x-small
                      dark
                    >
                      <v-icon>mdi-backup-restore</v-icon>
                    </v-btn>
                  </template>
                  <span>Restaurar</span>
                </v-tooltip>
              </template>

              <template v-slot:no-data>
                <v-alert :value="true" color="red" dark icon="mdi-alert-circle">
                  <v-row>
                    <v-col> Ningún dato que mostrar :( </v-col>
                    <v-col class="shrink">
                      <v-btn @click="getDatos()"
                        ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-alert>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <formPago ref="refPago"></formPago>
    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <formEditarPago ref="refEditarPago"></formEditarPago>
    <exportar ref="refExportar"></exportar>
  </div>
</template>

<script>
import formPago from "@/js/components/pagos/todos/nuevoPago.vue";
import exportar from "@/js/components/exportar_tabla/exportarComponent.vue";
import formEditarPago from "@/js/components/pagos/todos/editarPago.vue";
import jsPDF from "jspdf";
import Comun from "@/js/Common.js";
import LocalStorage from "@/js/LocalStorage.js";

import "jspdf-autotable";

export default {
  components: {
    formPago,
    formEditarPago,
    exportar,
  },
  data() {
    return {
      estadoPago: 1,
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      hoy: new Date().toISOString().substr(0, 10),
      headers: [
        { text: "#", align: "start", value: "id" },
        { text: "Monto", value: "monto", sortable: true },
        { text: "Extra y descuento", value: "descuento", sortable: false },
        { text: "Mes y año", value: "correspondencia", sortable: false },
        { text: "Servicio", value: "servicio.nombre", sortable: false },
        { text: "Estado", value: "estado", sortable: false },
        { text: "Dias de Retraso", value: "retraso", sortable: false },
        { text: "Cliente", value: "cliente", sortable: false },
        { text: "Tipo de pago", value: "tipo.tipo", sortable: false },
        { text: "Fecha de liquidación", value: "fecha", sortable: true },
        { text: "Cajero", value: "cajero", sortable: false },
        { text: "Detalles", value: "detalles", sortable: true },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      dialog: false,
      PagoActualizar: {},
      resolve: null,
      reject: null,
      id: 0,
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.id = arreUrl.pop();
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    estadoPago(val) {
      this.getDatos();
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `facturas/cobradas/personal/` + this.id,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
            status: parseInt(this.estadoPago),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.pagos.data;
          this.totalElementos = result.data.pagos.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    eliminar(elemento) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `pago/` + elemento.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: elemento,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(elemento), 1);
              });
          } else {
          }
        });
    },
    nuevo() {
      this.$refs.refPago.mostrar().then((resultado) => {
        if (resultado) {
          this.totalElementos = this.elementos.unshift(resultado);
        }
      });
    },
    editar(elemento) {
      this.PagoActualizar = elemento;
      const pago = Object.assign({}, elemento);
      this.$refs.refEditarPago.esperarDatos(pago).then((confirm) => {
        if (confirm) {
          this.elementos.splice(
            this.elementos.indexOf(this.PagoActualizar),
            1,
            confirm
          );
        }
      });
    },
    restaurar(item) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de restaurar este elemento?", {
          color: "orange",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnUpdate";
            let endpoint = `pago/restaurar/` + item.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: item,
              })
              .then((result) => {
                item.eliminado = false;
              });
          }
        });
    },

    calcularColor(id) {
      if (id == 1) {
        return "green";
      }
      if (id == 2) {
        return "orange";
      }
      if (id == 3) {
        return "purple";
      }
    },

    detalles(item) {
      document.location.href = "detalles/pago/" + item.id;
    },

    recibo(item) {
      let pdfName = "Recibo-" + Comun.formatoFecha(this.hoy);
      var doc = new jsPDF();

      var nombre =
        item.cliente.nombre +
        " " +
        item.cliente.apellido_paterno +
        " " +
        item.cliente.apellido_materno;
      var direccion = item.cliente.direccion;
      var colonia = item.cliente.colonia;
      var municipio = item.cliente.municipio;
      var telefono = item.cliente.telefono;
      var celular = item.cliente.celular ? item.cliente.celular : "";
      var correo = item.cliente.correo;
      var estado = item.estado.status;
      var status = item.status_pago_id;
      var servicio = item.servicio.nombre;
      var tipoServicio = item.servicio.tipo.tipo;
      var detalles = item.servicio.detalles;
      var bajada = item.servicio.bajada;
      var subida = item.servicio.subida;
      var descuento = item.descuento;
      var extra = item.extra;
      var tipo = item.tipo ? item.tipo.tipo : "";
      var fecha = item.fecha ? item.fecha : "";
      var hora = item.hora ? item.fecha : "";
      var detallePago = item.detalles;
      var mes = item.mes.mes;
      var monto = item.monto;
      var creado = item.created_at;
      var logo = item.empresa.logo ? item.empresa.logo : "";

      doc.setFont("arial");
      doc.setFontSize(12);
      var lMargin = 15; //left margin in mm
      var rMargin = 15; //right margin in mm
      var pdfInMM = 210; // width of A4 in mm
      var pageCenter = pdfInMM / 2;

      var img1 = new Image();
      img1.src = "img/recursos/tune.png";
      doc.addImage(img1, "png", 0, -2, 290, 20);
      if (logo) {
        var img2 = new Image();
        img2.src = logo;
        doc.addImage(img2, "png", 15, 25, 20, 20);
      }

      doc.setFont("helvetica");
      doc.setFontSize(15);

      var cabecera = "RECIBO DE PAGO";
      var linea = doc.splitTextToSize(cabecera, pdfInMM - lMargin - rMargin);
      doc.text(linea, pageCenter, 40, "center");

      doc.setFontSize(14);
      doc.setFont("arial");
      doc.text(15, 58, "Cliente: ");

      doc.setFontSize(12);

      doc.text(15, 65, nombre);
      doc.text(15, 70, direccion + ", " + colonia);
      doc.text(15, 75, municipio);
      if (telefono) {
        doc.text(15, 80, "Telefono: " + telefono);
      }
      doc.text(15, 85, "Celular: " + celular);
      if (correo) {
        doc.text(15, 90, "Correo: " + correo);
      }

      doc.text(135, 65, "Estado: " + estado);

      doc.setLineWidth(1);
      doc.setDrawColor(0);
      if (status == 1) {
        doc.setFillColor(0, 255, 0);
      }
      if (status == 2) {
        doc.setFillColor(255, 0, 0);
      }
      if (status == 3) {
        doc.setFillColor(139, 0, 255);
      }
      doc.circle(150, 80, 10, "FD");

      doc.setLineWidth(1);
      doc.line(195, 100, 15, 100);
      doc.setLineWidth(0.5);
      doc.line(195, 102, 15, 102);

      doc.setFontSize(14);
      doc.text(15, 110, "Detalles del servicio: ");

      doc.text(15, 115, "Nombre: " + servicio);
      doc.text(15, 120, "Tipo: " + tipoServicio);
      doc.text(15, 125, "Detalles: " + detalles);
      if (tipo == "Internet") {
        doc.text(
          15,
          130,
          "Velocidad: " + "Bajada: " + bajada + "MB / Subida: " + subida + "MB"
        );
      }

      var head = [
        ["Descuento", "Extra", "Pago", "Fecha", "Mes", "detalles", "Monto"],
      ];
      var body = [
        [descuento, extra, tipo, fecha + "-" + hora, mes, detallePago, monto],
      ];
      doc.autoTable({
        head: head,
        body: body,
        startY: 145,
        bodyStyles: {
          valign: "top",
        },
        styles: {
          rowPageBreak: "auto",
          overflow: "linebreak",
        },
        columnStyles: {
          text: {
            cellWidth: "wrap",
          },
        },
        margin: { left: 15, right: 15 },
      });
      doc.setFontSize(14);
      let finalY = doc.autoTable.previous.finalY;
      doc.text(160, finalY + 6, "Total: $" + monto);
      doc.setFontSize(8);
      doc.text(15, 240, "Generado el: " + creado);

      //var img = new Image();
      //img.src = "img/recursos/ola1.jpg";
      //doc.addImage(img, "jpg", 0, 247, 290, 55);

      doc.save(pdfName + ".pdf");
    },
    ticketCalor(item) {
      let url = "ticket/calor/pago/" + item.id;
      window.open(url, "_blank");
    },
    abrirCliente(cliente) {
      LocalStorage.set("TAB_ACTUAL_CLIENTE", 0);

      document.location.href = "detalles/cliente/" + cliente.id;
    },
  },
};
</script>
