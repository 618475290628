<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-row>
          <v-col cols="12">
            <v-card class="cool pa-2">
              <v-card-title primary-title>
                <strong>{{ proveedor.nombre }}</strong>
              </v-card-title>
              <v-card-subtitle primary-title> </v-card-subtitle>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="5">
                    <v-list two-line>
                      <v-list-item
                        v-show="proveedor.telefono"
                        @click="
                          abrirEnMaps(proveedor.latitud, proveedor.longitud)
                        "
                      >
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-map-marker </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title
                            >{{ proveedor.direccion }}, {{ proveedor.colonia }},
                            {{ proveedor.municipio }}
                            {{ proveedor.codigo_postal }},{{
                              proveedor.estado
                            }}</v-list-item-title
                          >
                          <v-list-item-subtitle>Dirección</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item v-show="proveedor.telefono">
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-phone </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            proveedor.telefono
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Telefono</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-show="proveedor.celular">
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-cellphone </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            proveedor.celular
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Celular</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-show="proveedor.correo">
                        <v-list-item-icon>
                          <v-icon color="indigo"> mdi-email </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{
                            proveedor.correo
                          }}</v-list-item-title>
                          <v-list-item-subtitle>Correo</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="12" md="7">
                    <mapa v-if="mostrarMapa" ref="mapa"></mapa>
                  </v-col>
                  <v-col cols="12">
                    <v-card elevation="0" flat>
                      <v-card-title primary-title> Productos </v-card-title>
                      <v-card-subtitle>
                        Productos que se han adquirido con este proveedor
                      </v-card-subtitle>
                      <v-card-subtitle>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Buscar"
                          rounded
                          solo
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-card-subtitle>
                      <v-card-text>
                        <v-data-table
                          :headers="headers"
                          :items="productos"
                          :search="search"
                        >
                          <template v-slot:item.imagen="{ item }">
                            <img
                              class="ma-1"
                              v-if="item.imagen"
                              :src="item.imagen"
                              style="width: 50px; height: 50px"
                            />
                            <img
                              v-else
                              class="ma-1"
                              src="/img/noimagen.jpg"
                              style="width: 50px; height: 50px"
                            />
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </base-component>
  </div>
</template>
<script>
import mapa from "@/js/components/mapa/mapaComponent.vue";
import Proveedor from "@/js/clases/Proveedor.js";
import Common from "@/js/Common";
export default {
  components: {
    mapa,
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Imagen", value: "imagen", sortable: false },
        { text: "Marca", value: "marca" },
        { text: "Categoria", value: "categoria.categoria" },
        { text: "Precio Compra", value: "precio_compra" },
        { text: "Stock", value: "cantidad" },
      ],
      mostrarMapa: true,
      proveedor: new Proveedor(),
      productos: [],
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.show(arreUrl.pop());
  },
  computed: {},
  watch: {},
  methods: {
    async esperarComponente2() {
      await this.$nextTick();
      this.$refs.mapa.mostrarMarker(
        this.proveedor.latitud,
        this.proveedor.longitud,
        "markerProveedor.png",
        this.proveedor.trabajo
      );
    },
    show(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "proveedor/" + id,
        })
        .then((result) => {
          this.proveedor = result.data.proveedor;
          this.productos = result.data.productos;
          this.esperarComponente2();
        });
    },
    formatoDinero(cantidad) {
      return Common.formatMoney(cantidad, 2);
    },
    abrirEnMaps(latitud, longitud) {
      if (latitud && longitud) {
        const url = `https://www.google.com/maps?q=${latitud},${longitud}`;
        window.open(url);
      }
    },
  },
};
</script>
