var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component mt-4"},[_c('base-component',[_c('div',{attrs:{"slot":"component"},slot:"component"},[_c('v-row',[_c('v-card',{staticClass:"cool pa-4"},[_c('v-card-title',{staticClass:"display-1"},[_c('strong',[_vm._v("Routers")])]),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"rounded-pill",attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.nuevoRouter()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("\n                  Agregar\n                ")],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"rounded-pill",attrs:{"solo":"","append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDatos($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.elementos,"options":_vm.options,"server-items-length":_vm.totalElementos,"loading":_vm.loading,"footer-props":{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-format-horizontal-align-left',
                    lastIcon: 'mdi-format-horizontal-align-right',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    itemsPerPageAllText: 'Todos',
                    itemsPerPageText: 'Registros por página:',
                    itemsPerPageOptions: [10, 50, 500, 1000, -1],
                  }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"lime lighten-1","icon":"mdi-alert"}},[_vm._v("Tu búsqueda \""+_vm._s(_vm.search)+"\" no se encuentra.")])]},proxy:true},{key:"item.imagen",fn:function(ref){
                  var item = ref.item;
return [(item.producto.imagen)?_c('v-img',{staticClass:"cool-xs ma-1",attrs:{"src":item.producto.imagen,"height":"80","width":"80","ma-2":""}}):_c('v-img',{staticClass:"cool-xs ma-1",attrs:{"src":"/img/noimagen.jpg","height":"80","width":"80"}})]}},{key:"item.ip",fn:function(ref){
                  var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.abrirIp(item.ip)}}},[_vm._v(_vm._s(item.ip))])]}},{key:"item.estado",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [(
                            item.tipo_conexion == 1 || item.tipo_conexion == 2
                          )?_c('v-chip',_vm._g(_vm._b({staticClass:"mb-1",attrs:{"color":_vm.calcularEstado(item),"dark":""},on:{"click":function($event){return _vm.testDeConexion(item)}}},'v-chip',attrs,false),on),[_vm._v("\n                          "+_vm._s(item.estado)+"\n                        ")]):_c('v-chip',_vm._g(_vm._b({attrs:{"color":"secondary","dark":""}},'v-chip',attrs,false),on),[_vm._v("\n                          Ninguno\n                        ")])]}}],null,true)},[_vm._v(" "),_c('span',[(item.tipo_conexion == 1)?_c('div',[_vm._v("IP Publica")]):_vm._e(),_vm._v(" "),(item.tipo_conexion == 2)?_c('div',[_vm._v("Script")]):_vm._e(),_vm._v(" "),(item.tipo_conexion == 3)?_c('div',[_vm._v("No asociado")]):_vm._e()])])]}},{key:"item.zona",fn:function(ref){
                          var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","color":"purple"},on:{"click":function($event){return _vm.abrirZona(item.zona.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-radio-tower")]),_vm._v("\n                      "+_vm._s(item.zona.nombre))],1)]}},{key:"item.clientes",fn:function(ref){
                          var item = ref.item;
return [_c('p',[_c('strong',{staticClass:"cyan--text"},[_vm._v(_vm._s(item.clientesCantidad))])])]}},{key:"item.opciones",fn:function(ref){
                          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.detalles(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Ver detalles")])]),_vm._v(" "),(item.eliminado == false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.editar(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Editar")])]):_vm._e(),_vm._v(" "),(item.eliminado == false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.eliminar(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Eliminar")])]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"red","dark":"","icon":"mdi-alert-circle"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_vm._v(" Ningún dato que mostrar :( ")]),_vm._v(" "),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{on:{"click":function($event){return _vm.getDatos()}}},[_c('v-icon',{attrs:{"pl-4":""}},[_vm._v("mdi-cached")]),_vm._v(" Refrescar")],1)],1)],1)],1)]},proxy:true}])})],1)],1)],1)],1)],1),_vm._v(" "),_c('BaseConfirmacion',{ref:"confirmar"}),_vm._v(" "),_c('formEditarRouter',{ref:"refEditarRouter"})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }