<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 md12>
        <v-dialog v-model="dialog" max-width="800" persistent>
          <v-card>
            <v-container grid-list-md>
              <v-card-title class="display-1" primary-title>
                <strong>Editar</strong>
              </v-card-title>
              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation dense>
                  <v-container grid-list-md>
                    <v-layout row wrap>
                      <v-flex xs12 md4>
                        <v-text-field
                          v-model="persona.nombre"
                          :rules="nombreReglas"
                          label="Nombre"
                          required
                          prepend-icon="mdi-account"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md4>
                        <v-text-field
                          v-model="persona.apellido_paterno"
                          :rules="nombreReglas"
                          label="Apellido paterno"
                          required
                          prepend-icon="mdi-account"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md4>
                        <v-text-field
                          v-model="persona.apellido_materno"
                          label="Apellido materno"
                          required
                          prepend-icon="mdi-account"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex xs12 md4>
                        <v-text-field
                          label="Celular"
                          v-model="persona.celular"
                          :rules="generalRegla"
                          type="number"
                          counter="10"
                          prepend-icon="mdi-cellphone-android"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 md4>
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                          attach
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              label="Fecha de nacimiento"
                              prepend-icon="mdi-cake-variant"
                              readonly
                              v-bind="attrs"
                              :rules="generalRegla"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            ref="picker"
                            v-model="date"
                            @change="save"
                            locale="es-mx"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>

                      <v-flex xs12 md4>
                        <v-file-input
                          :rules="rulesFile"
                          accept="image/png, image/jpeg, image/bmp"
                          placeholder="Maximo 2MB"
                          show-size
                          v-model="imagen"
                          prepend-icon="mdi-camera"
                          small-chips
                          multiple
                          label="Imagen de usuario"
                        ></v-file-input>
                      </v-flex>
                      <v-layout row wrap>
                        <v-flex xs12 md4>
                          <v-text-field
                            label="Sueldo"
                            v-model="persona.sueldo"
                            :rules="sueldoRegla"
                            prepend-icon="mdi-cash-usd"
                            prefix="$"
                            type="number"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 md4>
                          <v-select
                            :items="periodos"
                            v-model="persona.periodo_pago_id"
                            label="Periodo de pago"
                            item-text="periodo"
                            item-value="id"
                            :rules="generalRegla"
                            prepend-icon="mdi-calendar-multiple"
                          ></v-select>
                        </v-flex>
                        <v-flex xs12 md4>
                          <v-select
                            v-model="persona.tipo_persona_id"
                            :items="roles"
                            label="Rol del personal"
                            item-value="id"
                            item-text="name"
                            :rules="generalRegla"
                            no-data-text="No hay elementos registrados"
                            prepend-icon="mdi-account-multiple"
                            :disabled="disableRol"
                          >
                          </v-select>
                        </v-flex>
                      </v-layout>
                    </v-layout>
                    <p class="text-subtitle-2">Dirección</p>
                    <v-layout row wrap>
                      <v-flex xs12 md4>
                        <v-text-field
                          v-model="cp"
                          label="Código postal"
                          :rules="generalRegla"
                          type="number"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md4>
                        <v-text-field
                          v-model="persona.estado"
                          label="Estado"
                          :rules="generalRegla"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md4>
                        <v-text-field
                          v-model="persona.municipio"
                          label="Municipio"
                          :rules="generalRegla"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex v-if="colonias.length != 0" xs12 md6>
                        <v-select
                          v-model="persona.colonia"
                          :items="colonias"
                          item-text="nombre"
                          item-value="nombre"
                          label="Colonias"
                        ></v-select>
                      </v-flex>
                      <v-flex v-if="colonias.length == 0" xs12 md6>
                        <v-text-field
                          v-model="persona.colonia"
                          label="Colonia"
                          :rules="generalRegla"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-text-field
                          v-model="persona.direccion"
                          label="Dirección"
                          required
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="cancelar()">Cancelar</v-btn>
                <v-btn
                  :disabled="!valid"
                  class="rounded-pill pa-3"
                  color="primary"
                  @click="actualizar()"
                >
                  Actualizar
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Persona from "@/js/components/personal/Persona";

export default {
  data() {
    return {
      persona: new Persona(),
      valid: true,
      nombreReglas: [(v) => !!v || "Se necesita el nombre"],
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      cp: "",
      colonias: [],
      rulesFile: [
        (value) =>
          !value ||
          value.length == 0 ||
          value[0].size < 2000000 ||
          "Debe ser menor a 2 MB!",
      ],
      emailRules: [
        (v) => !!v || "Se necesita el correo.",
        (v) => /.+@.+/.test(v) || "Introduce un correo valido",
      ],
      sueldoRegla: [
        (v) => !!v || "Se necesita el sueldo",
        (v) => v >= 0 || "No puede ser numeros negativos",
      ],

      date: null,
      menu: false,
      dialog: false,
      imagen: null,
      dialogTipo: false,
      periodos: [],
      roles: [],
      validTipo: true,
      tipo: {
        tipo: "",
      },
      disableRol: false,
    };
  },
  mounted() {
    this.getDatos();
  },
  watch: {
    cp(val) {
      //this.getDatosApi(val);
      this.persona.cp = val;
    },

    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    date(val) {
      this.persona.fecha_nacimiento = val;
    },
    imagen(val) {
      if (val != null) {
        this.persona.imagen = val[0];
      }
    },
  },
  computed: {},
  methods: {
    getDatos() {
      let dispatch = "UsersModule/OnGet";
      let enpoint = "formulario_personal";

      this.$store
        .dispatch(dispatch, {
          url: enpoint,
          data: {},
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.periodos = result.data.periodos;
            this.roles = result.data.roles;
          }
        });
    },
    actualizar() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("id", parseInt(this.persona.id));
        formData.append("nombre", this.persona.nombre);
        formData.append("apellido_paterno", this.persona.apellido_paterno);
        formData.append("apellido_materno", this.persona.apellido_materno);
        formData.append("direccion", this.persona.direccion);
        formData.append("municipio", this.persona.municipio);
        formData.append("colonia", this.persona.colonia);
        formData.append("estado", this.persona.estado);
        formData.append("cp", this.persona.cp);
        formData.append("periodo_pago_id", this.persona.periodo_pago_id);
        formData.append("sueldo", this.persona.sueldo);
        formData.append("celular", this.persona.celular);
        formData.append("imagen", this.persona.imagen);
        formData.append(
          "tipo_persona_id",
          parseInt(this.persona.tipo_persona_id)
        );
        formData.append("fecha_nacimiento", this.persona.fecha_nacimiento);

        let dispatch = "UsersModule/OnSave";
        let endpoint = "persona/actualizar";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: formData,
          })
          .then((result) => {
            this.dialog = false;
            this.resolve(result.data.persona);
          });
      }
    },
    limpiar() {
      this.$refs.form.reset();
    },
    cargarDatos(elemento) {
      this.persona = elemento;
      this.cp = elemento.cp;
      this.date = elemento.fecha_nacimiento;
      this.dialog = true;
      if (elemento.protegido == true) {
        this.disableRol = true;
      } else {
        this.disableRol = false;
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    cancelar() {
      this.resolve(false);
      this.dialog = false;
    },
    getDatosApi(cp) {
      if (cp.length == 5) {
        let dispatch = "UsersModule/OnGet";
        let endpoint = "datos/codigo/postal/" + cp;
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: {},
          })
          .then((result) => {
            if ([200, 201].includes(result.status)) {
              let datos = result.data.data;
              this.persona.estado = datos.estado;
              this.persona.municipio = datos.municipio;
              this.colonias = datos.colonias;
            }
          });
      }
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>