<template>
    <v-container grid-list-md>
        <v-layout row wrap>
            <v-flex lg4 md4 xs12 v-for="item in items" :key="item.id">
                <v-card style="height: 225px; overflow: hidden;">
                    <v-card-title style="height: 68px;">
                        <h4 class="text-truncate">{{item.titulo}}</h4>
                    </v-card-title>
                    <v-card-text style="height: 110px;">
                        <p class="text-justify">{{item.descripcion}}</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-layout align-center justify-end row fill-height>
                            <v-btn color="warning" @click="$router.push({name:item.vista})">De clic aquí</v-btn>
                        </v-layout>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    props: {
        items: {
            type:Array,
            required:true
        }
    }
};
</script>

<style scoped></style>
