<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px" persistent>
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title> Nuevo correo electronico </v-card-title>
          <v-container grid-list-md>
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-text-field
                label="Asunto del correo"
                v-model="correo.asunto"
                :rules="generalRegla"
                hint="Ejemplo: Recordatorio, Importante, Pago pendiente, etc."
              ></v-text-field>

              <v-textarea
                label="Mensaje"
                :rules="generalRegla"
                v-model="correo.mensaje"
                rows="2"
                filled
              ></v-textarea>
            </v-form>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="cancelar()" text>Cancelar</v-btn>
            <v-btn @click="enviar()" class="rounded-pill pa-3" color="primary"
              >Enviar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Common from "@/js/Common";
export default {
  data() {
    return {
      dialog: false,
      clienteId: null,
      correo: {
        asunto: "",
        mensaje: "",
        cliente_id: "",
      },
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.clienteId = arreUrl.pop();
    this.correo.cliente_id = this.clienteId;
  },
  computed: {},
  methods: {
    mostrar() {
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    cargarDatos(item) {
      this.clienteId = item.id;
      this.correo.cliente_id = this.clienteId;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    enviar() {
      if (this.$refs.form.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "correo";
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.correo,
          })
          .then((result) => {
            this.dialog = false;
            this.limpiar();
            this.resolve(result.data.correo);
          });
      }
    },
    limpiar() {
      this.$refs.form.reset();
    },
    cancelar() {
      this.dialog = false;
      this.resolve(false);
    },
  },
};
</script>