<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 md12>
              <v-form
                dense
                ref="form"
                v-model="valid"
                enctype="multipart/form-data"
                lazy-validation
              >
                <p class="headline">
                  <strong>Persona</strong>
                </p>
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <v-text-field
                      v-model="persona.nombre"
                      :rules="nombreReglas"
                      label="Nombre"
                      required
                      prepend-icon="mdi-account"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      v-model="persona.apellido_paterno"
                      :rules="nombreReglas"
                      label="Apellido paterno"
                      required
                      prepend-icon="mdi-account"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      v-model="persona.apellido_materno"
                      label="Apellido materno"
                      required
                      prepend-icon="mdi-account"
                      :rules="generalRegla"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      label="Celular"
                      v-model="persona.celular"
                      :rules="generalRegla"
                      type="number"
                      prepend-icon="mdi-cellphone-android"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      label="Correo electronico"
                      v-model="usuario.email"
                      prepend-icon="mdi-gmail"
                      :rules="emailRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      attach
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Fecha de nacimiento"
                          prepend-icon="mdi-cake-variant"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="generalRegla"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="date"
                        :max="new Date().toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="save"
                        locale="es-mx"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      label="Contraseña"
                      v-model="contrasenia"
                      :rules="contraseniaRegla"
                      :type="show ? 'text' : 'password'"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      prepend-icon="mdi-textbox-password"
                      @click:append="show = !show"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      label="Confirmacion Contraseña"
                      :rules="confirmacionRegla"
                      v-model="confirmacionContra"
                      :type="show ? 'text' : 'password'"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      prepend-icon="mdi-textbox-password"
                      @click:append="show = !show"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-file-input
                      :rules="rulesFile"
                      accept="image/png, image/jpeg, image/bmp"
                      placeholder="Formato imagen, Maximo 2MB"
                      show-size
                      v-model="persona.imagen"
                      prepend-icon="mdi-camera"
                      small-chips
                      multiple
                      label="Imagen de usuario"
                    ></v-file-input>
                  </v-flex>
                </v-layout>
                <p class="text-subtitle-2">Dirección</p>
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <v-text-field
                      v-model="cp"
                      label="Código postal"
                      :rules="generalRegla"
                      type="number"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      v-model="persona.estado"
                      label="Estado"
                      :rules="generalRegla"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-text-field
                      v-model="persona.municipio"
                      label="Municipio"
                      :rules="generalRegla"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex v-if="colonias.length != 0" xs12 md6>
                    <v-select
                      v-model="persona.colonia"
                      :items="colonias"
                      item-text="nombre"
                      item-value="nombre"
                      label="Colonias"
                    ></v-select>
                  </v-flex>
                  <v-flex v-if="colonias.length == 0" xs12 md6>
                    <v-text-field
                      v-model="persona.colonia"
                      label="Colonia"
                      :rules="generalRegla"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field
                      v-model="persona.direccion"
                      label="Dirección"
                      :rules="generalRegla"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="!valid"
                    class="rounded-pill pa-3"
                    color="primary"
                    @click="validate"
                    >Continuar</v-btn
                  >
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </base-component>
  </div>
</template>
<script>
import Persona from "@/js/components/personal/Persona";
import Usuario from "@/js/components/personal/Usuario";

export default {
  data() {
    return {
      persona: new Persona(),
      usuario: new Usuario(),
      valid: true,
      rulesFile: [
        (value) =>
          !value ||
          value.length == 0 ||
          value[0].size < 2000000 ||
          "Debe ser menor a 2 MB!",
      ],
      nombreReglas: [(v) => !!v || "Se necesita el nombre"],
      contraseniaRegla: [
        (v) => !!v || "Se necesita el nombre",
        (v) => v.length > 8 || "Debe ser mayor de 8 caracteres",
        (v) =>
          /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{8,})$/.test(v) ||
          "Debe contener almenos 1 digito, 1 un caracter en minscula y una mayuscula",
      ],
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      cp: "",
      colonias: [],
      show: false,
      emailRules: [
        (v) => !!v || "Se necesita el correo.",
        (v) => /.+@.+/.test(v) || "Introduce un correo valido",
      ],
      confirmacionRegla: [
        (v) => !!v || "Se necesita la confirmación de la contraseña.",
        (v) =>
          this.usuario.password == v || "Los dos contraseñas deben coincidir",
      ],
      contrasenia: "",
      confirmacionContra: "",
      //
      date: null,
      menu: false,
    };
  },
  mounted() {},
  watch: {
    cp(val) {
      //this.getDatosApi(val);
      this.persona.cp = val;
    },
    contrasenia(val) {
      this.confirmacionContra = "";
      this.usuario.password = val;
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    date(val) {
      this.persona.fecha_nacimiento = val;
    },
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.usuario.name = (
          this.persona.nombre +
          " " +
          this.persona.apellido_paterno +
          " " +
          this.persona.apellido_materno
        ).trim();

        this.$store.dispatch("registroPersonalPaso1", {
          paso: 2,
          persona: this.persona,
          usuario: this.usuario,
        });
      }
    },
    getDatosApi(cp) {
      if (cp.length == 5) {
        let dispatch = "UsersModule/OnGet";
        let endpoint = "datos/codigo/postal/" + cp;
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: {},
          })
          .then((result) => {
            if ([200, 201].includes(result.status)) {
              let datos = result.data.data;
              this.persona.estado = datos.estado;
              this.persona.municipio = datos.municipio;
              this.colonias = datos.colonias;
            }
          });
      }
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>