<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <v-row justify="center">
          <v-dialog v-model="dialogTipo" max-width="300" persistent>
            <v-card>
              <v-container grid-list-md>
                <v-card-title primary-title> Nuevo </v-card-title>
                <v-form
                  dense
                  ref="formTipo"
                  v-model="validTipo"
                  lazy-validation
                >
                  <v-container grid-list-md>
                    <v-text-field
                      label="Tipo de egreso"
                      v-model="tipo.tipo"
                      prepend-icon="mdi-format-list-bulleted-type"
                      :rules="generalRegla"
                    ></v-text-field>
                  </v-container>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialogTipo = false">Cancelar</v-btn>
                    <v-btn
                      :disabled="!validTipo"
                      color="primary"
                      class="rounded-pill pa-3"
                      @click="nuevoTipo()"
                    >
                      Registrar
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-container>
            </v-card>
          </v-dialog>
        </v-row>
        <v-dialog v-model="dialog" max-width="700">
          <v-card>
            <v-container grid-list-md>
              <v-card-title primary-title> Nuevo </v-card-title>
              <v-form
                dense
                ref="form"
                v-model="valid"
                enctype="multipart/form-data"
                lazy-validation
              >
                <v-container grid-list-md>
                  <v-layout row wrap>
                    <v-flex xs12 md4>
                      <v-text-field
                        v-model="egreso.monto"
                        label="Monto"
                        prefix="$"
                        type="number"
                        :rules="generalRegla"
                        prepend-icon="mdi-cash"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md8>
                      <v-file-input
                        :rules="rulesFile"
                        placeholder="2MB"
                        show-size
                        v-model="archivo"
                        hint="Puede ser documento o imagen"
                        prepend-icon="mdi-file-document"
                        small-chips
                        multiple
                        label="Archivo Comprobante"
                      ></v-file-input>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs12 md12>
                      <v-textarea
                        v-model="egreso.detalles"
                        rows="3"
                        label="Detalles"
                        filled
                      >
                      </v-textarea>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-select
                        :items="tipos"
                        v-model="egreso.tipo_id"
                        label="Tipo de egreso"
                        :rules="generalRegla"
                        item-text="tipo"
                        item-value="id"
                        no-data-text="No hay elementos registrados"
                      >
                        <template v-slot:append-item>
                          <v-divider class="mb-2"></v-divider>
                          <v-list-item @click="dialogTipo = true">
                            <v-list-item-avatar color="grey lighten-3">
                              <v-icon>mdi-plus</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>Agregar</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-flex>
                    <v-textarea
                      v-model="egreso.observaciones"
                      rows="3"
                      label="Observaciones"
                      filled
                    ></v-textarea>
                  </v-layout>
                </v-container>
              </v-form>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="cancelar()" text>Cancelar</v-btn>
                <v-btn
                  color="primary"
                  class="rounded-pill pa-3"
                  :disabled="!valid"
                  @click="enviar()"
                >
                  registrar
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>
      </div>
    </base-component>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dialog: false,
      valid: true,
      rulesFile: [
        (value) =>
          !value ||
          value.length == 0 ||
          value[0].size < 2000000 ||
          "Debe ser menor a 2 MB!",
      ],
      generalRegla: [(v) => !!v || "Se necesita el campo"],

      egreso: {
        monto: "",
        detalles: "",
        archivo: null,
        tipo_id: "",
        persona_id: "",
      },
      tipo: {
        tipo: "",
      },
      tipos: [],
      dialogTipo: false,
      validTipo: true,

      archivo: null,
    };
  },
  watch: {
    archivo(val) {
      if (val != null) {
        this.egreso.archivo = val[0];
      }
    },
  },
  mounted() {
    this.getDatos();
  },
  computed: {
    persona() {
      return this.$store.getters.getShowPersona;
    },
  },
  methods: {
    mostrar() {
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "form/egreso/persona",
          data: {},
        })
        .then((result) => {
          this.tipos = result.data.tipos;
        });
    },
    enviar() {
      if (this.$refs.form.validate()) {
        var today = new Date();
        var fecha =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        var hora =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();

        let formData = new FormData();
        formData.append("monto", this.egreso.monto);
        formData.append("fecha", fecha);
        formData.append("hora", hora);
        formData.append("detalles", this.egreso.detalles);
        formData.append("archivo", this.egreso.archivo);
        formData.append("tipo_id", this.egreso.tipo_id);

        formData.append("persona_id", this.persona.id);
        formData.append("observaciones", this.egreso.observaciones);

        let dispatch = "UsersModule/OnSave";
        let endpoint = "pago_empleado";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: formData,
          })
          .then((result) => {
            this.dialog = false;
            this.$refs.form.reset();
            this.resolve(result.data.egreso);
          });
      }
    },
    nuevoTipo() {
      if (this.$refs.formTipo.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "tipo_egreso_persona";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.tipo,
          })
          .then((result) => {
            this.tipos.push(result.data.tipo);
            this.egreso.tipo_id = result.data.tipo.id;
            this.dialogTipo = false;
            this.$refs.formTipo.reset();
          });
      }
    },

    cancelar() {
      this.dialog = false;
      this.resolve(false);
    },
  },
};
</script>