<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="cool pa-4">
              <v-card-title class="display-1">
                <strong>Servicios y paquetes</strong>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-btn
                      dense
                      @click="nuevo()"
                      class="rounded-pill"
                      color="red"
                      dark
                    >
                      <v-icon left>mdi-plus</v-icon>
                      Nuevo
                    </v-btn>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      v-model="search"
                      @keyup.enter="getDatos"
                      append-icon="mdi-magnify"
                      label="Buscador"
                      single-line
                      solo
                      class="rounded-pill"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-data-table
                dense
                :search="search"
                :headers="headers"
                :items="elementos"
                :options.sync="options"
                :server-items-length="totalElementos"
                :loading="loading"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-format-horizontal-align-left',
                  lastIcon: 'mdi-format-horizontal-align-right',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  itemsPerPageAllText: 'Todos',
                  itemsPerPageText: 'Registros por página:',
                  itemsPerPageOptions: [10, 100, 500, -1],
                }"
              >
                <template v-slot:no-results>
                  <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                    >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                  >
                </template>
                <template v-slot:item.id="{ item }">
                  {{
                    elementos
                      .map(function (x) {
                        return x.id;
                      })
                      .indexOf(item.id) + 1
                  }}
                </template>
                <template v-slot:item.costo="{ item }">
                  <strong class="orange--text">{{
                    formatoDinero(item.costo)
                  }}</strong>
                </template>
                <template v-slot:item.subida="{ item }">
                  <div v-if="item.subida">
                    <v-icon class="ml-2" color="blue"
                      >mdi-arrow-up-bold-circle-outline</v-icon
                    >
                    <strong class="ml-2">{{ item.subida }} </strong> Mb.
                  </div>
                </template>
                <template v-slot:item.bajada="{ item }">
                  <div v-if="item.bajada">
                    <v-icon class="ml-2" color="pink"
                      >mdi-arrow-down-bold-circle-outline</v-icon
                    >
                    <strong class="ml-2">{{ item.bajada }} </strong> Mb.
                  </div>
                </template>
                <template v-slot:item.clientes="{ item }">
                  <v-chip outlined dense color="primary" dark>{{
                    item.clientesCantidad
                  }}</v-chip>
                </template>
                <template v-slot:item.zonas="{ item }">
                  <v-chip outlined dense color="purple" dark>{{
                    item.zonasCantidad
                  }}</v-chip>
                </template>
                <template v-slot:item.opciones="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="detalles(item)" icon v-on="on">
                        <v-icon small>mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <span>Detalles</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="editarServicio(item)" icon v-on="on">
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar Servicio</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="eliminarServicio(item)" icon v-on="on">
                        <v-icon small> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span>Desactivar Servicio</span>
                  </v-tooltip>
                </template>

                <template v-slot:no-data>
                  <v-alert
                    :value="true"
                    color="red"
                    dark
                    icon="mdi-alert-circle"
                  >
                    <v-row align="center">
                      <v-col> Ningún dato que mostrar :( </v-col>
                      <v-col class="shrink">
                        <v-btn @click="getDatos()"
                          ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-alert>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <formServicio ref="refServicio"></formServicio>
        <editarServicio ref="refEditarServicio"></editarServicio>
      </div>
    </base-component>
  </div>
</template>

<script>
import formServicio from "@/js/components/servicios/dialogServicioComponent.vue";
import editarServicio from "@/js/components/servicios/dialogEditarServicioComponent.vue";
import Common from "@/js/Common.js";

export default {
  components: {
    formServicio,
    editarServicio,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      headers: [
        { text: "id", align: "start", value: "id" },
        { text: "Nombre", value: "nombre", sortable: true },
        { text: "Costo", value: "costo" },
        { text: "Servicio", value: "tipo.tipo", sortable: false },
        { text: "Subida", value: "subida", sortable: true },
        { text: "Bajada", value: "bajada", sortable: true },
        { text: "Clientes asociados", value: "clientes", sortable: false },
        { text: "Zonas asociados", value: "zonas", sortable: false },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      ServicioActualizar: {},
      categorias: [],
    };
  },
  mounted() {},
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `servicios/tabla`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.servicios.data;
          this.totalElementos = result.data.servicios.total;
        });
    },
    getCategorias() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "datos/actividad/personal",
          data: {},
        })
        .then((result) => {
          this.categorias = result.data.personal;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    eliminarServicio(elemento) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `servicio/` + elemento.id;
            let event = "delete";

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: elemento,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(elemento), 1);
              });
          } else {
          }
        });
    },
    nuevo() {
      this.$refs.refServicio.mostrar().then((confirm) => {
        if (confirm) {
          this.totalElementos = this.elementos.unshift(confirm);
        }
      });
    },
    editarServicio(elemento) {
      this.ServicioActualizar = elemento;
      let servicio = Object.assign({}, elemento);
      this.$refs.refEditarServicio.esperarDatos(servicio).then((confirm) => {
        if (confirm) {
          this.elementos.splice(
            this.elementos.indexOf(this.ServicioActualizar),
            1,
            confirm
          );
        }
      });
    },
    formatoDinero(cantidad) {
      return Common.formatMoney(cantidad);
    },
    detalles(item) {
      window.location.href = "vista/detalles/servicio/" + item.id;
    },
  },
};
</script>
