<template>
    <div class="component">
        <base-component>
            <div slot="component">
            <v-container grid-list-md>
                <v-layout row wrap>
                        <v-flex  xs12 md4>
                            <widget
                                icon="mdi-cash-multiple"
                                subTitle="Pagos recibidos"
                                :title="pagosHoy"
                                color="#CDDC39"
                            />
                        </v-flex>
                        <v-flex  xs12 md4>
                            <widget
                                icon="mdi-ticket-account"
                                subTitle="Tickets"
                                :title="ticketsHoy"
                                supTitle="Hoy"
                                color="#6D4C41"
                            />
                        </v-flex>
                        <v-flex  xs12 md4>
                            <widget
                                icon="mdi-wrench"
                                subTitle="Trabajos"
                                :title="trabajosHoy"
                                supTitle="Hoy"
                                color="#3949AB"
                            />
                        </v-flex>
                        
                </v-layout>   
                <v-layout row wrap>
                <v-flex xs12 md3>
                    <v-list-item-avatar
                        color="indigo"
                        tile 
                        size="240"
                        >
                        <img v-if="persona.imagen != null"
                             :src="persona.imagen"
                        >
                        <img v-else
                            src="/img/user_default.png"
                        >
                    </v-list-item-avatar>
                </v-flex>
                <v-flex xs12 md9>
                <v-card v-if="persona">
                    <v-list-item three-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="headline mb-4">{{persona.nombre}} {{persona.apellido_paterno}} {{persona.apellido_materno}}</v-list-item-title>
                                        <v-list-item-subtitle>{{persona.direccion}}, {{persona.colonia}}, {{persona.municipio}},{{persona.cp}}, {{persona.estado}}</v-list-item-subtitle>
                                        <v-list-item-subtitle>Celular: {{persona.celular}}</v-list-item-subtitle>
                                        <v-list-item-subtitle>Correo: {{persona.user.email}}</v-list-item-subtitle>
                                        
                                        <v-list-item-subtitle v-if="persona.periodo">Periodo de pago: <v-chip class="ma-2" small color="primary">
                                            {{persona.periodo.periodo}}
                                        </v-chip> Cargo: <v-chip class="ma-2" small color="secondary">
                                            {{persona.tipo.name}}
                                        </v-chip> </v-list-item-subtitle>
                                        
                                    </v-list-item-content>
                                    
                                </v-list-item>
                        <v-list-item three-line>
                            <v-list-item-content>
                                        <div class="overline mb-4">
                                            Fecha de nacimiento
                                            <v-chip class="ma-2" color="accent">
                                                {{persona.fecha_nacimiento}}
                                           <v-icon>mdi-cake-variant</v-icon>
                                            </v-chip>
                                            Sueldo:
                                            <v-chip class="ma-2" color="orange" text-color="white">
                                                 ${{persona.sueldo}}
                                                <v-icon right>mdi-star</v-icon>
                                            </v-chip>
                                            
                                        </div>
                                        
                                    </v-list-item-content>
                        </v-list-item>
                                
                </v-card>
                </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-row>
                        <v-col>
                        <v-sheet height="600">
                            <v-calendar
                            ref="calendar"
                            :now="today"
                            :value="today"
                            :events="events"
                            :event-color="getEventColor"
                            type="week"
                            locale="es-mx"
                            @click:event="showEvent"
                            ></v-calendar>
                        </v-sheet>
                        </v-col>
                    </v-row>
                </v-layout> 
            
            </v-container>
            <v-dialog
                  v-if="model"
                  v-model="dialog_detalles"
                  max-width="500px"
                >
                 <v-card :color="model.color">
                   <v-card-title primary-title>
                     {{detalles}}
                   </v-card-title>
                   <v-container grid-list-md>
                     <v-list v-if="model">
                        <v-list-item
                          v-for="(field, i) in fields"
                          :key="i"
                        >
                          <v-list-item-content>
                            <v-list-item-title v-text="field.key"></v-list-item-title>
                            <v-list-item-subtitle v-text="field.value"></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                   </v-container>
                   <v-card-actions>
                     <v-spacer></v-spacer>
                     <v-btn @click="dialog_detalles = false" color="primary">Ok</v-btn>
                   </v-card-actions>
                 </v-card> 
                </v-dialog>
            </div>
        </base-component>
    </div>
</template>
<script>
import Common from '@/js/Common'
export default {

    data() {
        return {
            persona:null,
            today: new Date().toISOString().substr(0, 10),
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
            dialogNuevaActividad : false,
            actividades: [],
            actividadActualizar: null,
            model:null,
            dialog_detalles:false,
            detalles:"",
            trabajosHoy:0,
            ticketsHoy:0,
            instalacionesHoy:0,
            pagosHoy:0
            
        };
    },
    mounted() {
        this.getDatos();
    },
    computed: {
     events(){

          return this.actividades.map(entry => {
          let fecha = "";
          let hora_inicio ="";
          let nombre = "";
        //  let personal = "";
          if(entry.trabajo){
            nombre = "Trabajo: "+entry.trabajo;
          }
          if(entry.asunto){
            nombre = "Ticket: "+entry.asunto;
          }
          if(entry.FullName){
            nombre = "Instalacion: "+entry.FullName;
          }
          if(entry.fecha){
            fecha = entry.fecha;
          };
          if(entry.fecha_instalacion){
            fecha = entry.fecha_instalacion;
          }
          if(entry.hora_inicio){
            hora_inicio = entry.hora_inicio;
          }
          if(entry.hora){
            hora_inicio = entry.hora;
          }

          if(entry.hora_instalacion){
            hora_inicio = entry.hora_instalacion;
          }
          if(entry.personal){
            entry.personal = entry.personal.FullName;
          }

          if(entry.instalador){
            entry.instalador = entry.instalador.FullName;
          }
        //  const personal_asignado =personal;
          const name = nombre;
          
          const start = fecha+' '+hora_inicio;
          
          const color = this.getEventColor(entry);
          
          if(entry.hora_fin){
            const end = entry.fecha+' '+entry.hora_fin;
            
          return Object.assign({}, entry, { name,start,end,color })

          }
          
          return Object.assign({}, entry, {name,start,color })
          })
          
         },
         fields () {
        if (!this.model) return []

        return Object.keys(this.model).map(key => {
          return {
            key,
            value: this.model[key] || 'n/a',
          }
        })
      },
    },
    methods: {
        getDatos(){
            this.$store.dispatch('UsersModule/OnGet', {
                url: 'datos/instalador/home',
                data: {}
            }).then(result => {
              
                this.persona= result.data.persona;
                this.actividades = result.data.actividades;
                this.trabajosHoy = result.data.trabajosHoy;
                this.instalacionesHoy = result.data.instalacionesHoy;
                this.ticketsHoy = result.data.ticketsHoy;
                this.pagosHoy = result.data.pagosHoy;
                
            })
        },
        showEvent ({ nativeEvent, event }) {
        
        this.detalles = event.name;
        this.model = event;
        this.dialog_detalles = true;
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      calcularColor(event) {
          
        return 'primary';

      },
      getEventColor (event) {
          
          if(event.trabajo){
            return "cyan darken-1"
          }
          if(event.asunto){
            return "orange"
          }
          if(event.FullName){
            return "light-green darken-1"
          }
          return "light-green darken-1"

        
      },
        
    }

};
</script>
<style scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}
</style>