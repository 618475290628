<template>
    
            <div>
                <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
                <v-layout row wrap>
                    <v-flex xs12 md12>
                         <v-form ref="form" v-model="valid" lazy-validation dense>
                                         <v-layout row wrap>
                                      <v-flex xs12 md12>
                                        <v-text-field
                                            label="Banco"
                                            v-model="cuenta.banco"
                                            :rules="generalRegla"
                                            prepend-icon="mdi-bank"
                                        ></v-text-field>                                          
                                      </v-flex>
                                      <v-flex xs12 md12>
                                        <v-text-field
                                            label="Titular"
                                            v-model="cuenta.titular"
                                            :rules="generalRegla"
                                            prepend-icon="mdi-account"
                                        ></v-text-field>                                          
                                      </v-flex>
                                      <v-flex xs12 md12>
                                        <v-text-field
                                            label="Número de cuenta"
                                            v-model="cuenta.numero_cuenta"
                                            :rules="generalRegla"
                                            prepend-icon="mdi-account-card-details"
                                            type="number"
                                        ></v-text-field>                                          
                                      </v-flex>
                                      <v-flex xs12 md12>
                                        <v-text-field
                                            label="Transferencia bancaria"
                                            v-model="cuenta.transferencia"
                                            prepend-icon="mdi-transfer"
                                        ></v-text-field>                                          
                                      </v-flex>
                                      
                                     </v-layout>
                                     
                                    </v-form>
                      
                    </v-flex>
                </v-layout>
         
    </div>
</template>
<script>

export default {
    
    data() {
        return {
            cuenta:{
              banco:"",
              numero_cuenta:"",
              transferencia:"",
              titular:"",
            },
            valid: true,
            generalRegla: [v => !!v || "Se necesita el campo",],
            
        };
    },
    mounted() {
        
    },
    watch:{
        
    },
    computed: {
       
    },
    methods: {
        nuevo(){
            if (this.$refs.form.validate()) {
                return this.cuenta;
            }else{
                return null
            }
        },
        limpiar(){
            this.$refs.form.reset();
        },
        
        
        
    
    }

};
</script>