<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-card class="cool pa-5">
          <v-card-title primary-title> Importar Clientes </v-card-title>
          <v-card-subtitle>
            <strong>Instrucciones</strong>
            <br />
            1.- Descargar la plantilla de excel y rellenar los datos de sus
            clientes.
            <br />
            2.- Subir el archivo excel rellenado con los datos de sus clientes.
            <br />
            3.- verifique sus datos, puede editar los datos subidos dando doble
            clic en la celdas de las tablas.
            <br />
            4.- dar clic en el botón procesar.
            <br />
          </v-card-subtitle>
          <v-container grid-list-md>
            <v-btn @click="descargarPlantilla()" color="secondary"
              >Descargar Plantilla</v-btn
            >
          </v-container>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                class="d-flex justify-end"
                style="margin-top: -80px"
              >
                <div>
                  <v-btn
                    color="green"
                    class="text-none"
                    fab
                    dark
                    large
                    :loading="isSelecting"
                    @click="onButtonClick"
                  >
                    <v-icon> mdi-file-excel </v-icon>
                  </v-btn>
                  <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    accept=".xlsx"
                    @change="readFile"
                  />
                </div>
              </v-col>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="clientes"
                  hide-default-footer
                  class="elevation-0"
                >
                  <template v-slot:item.nombre="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.nombre"
                      large
                      persistent
                    >
                      {{ props.item.nombre }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.nombre"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.apellido_paterno="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.apellido_paterno"
                      large
                      persistent
                    >
                      {{ props.item.apellido_paterno }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.apellido_paterno"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.apellido_materno="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.apellido_materno"
                      large
                      persistent
                    >
                      {{ props.item.apellido_materno }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.apellido_materno"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.direccion="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.direccion"
                      large
                      persistent
                    >
                      {{ props.item.direccion }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.direccion"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.codigo_postal="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.codigo_postal"
                      large
                      persistent
                    >
                      {{ props.item.codigo_postal }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.codigo_postal"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.colonia="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.colonia"
                      large
                      persistent
                    >
                      {{ props.item.colonia }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.colonia"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.municipio="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.municipio"
                      large
                      persistent
                    >
                      {{ props.item.municipio }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.municipio"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.estado="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.estado"
                      large
                      persistent
                    >
                      {{ props.item.estado }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.estado"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.celular="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.celular"
                      large
                      persistent
                    >
                      {{ props.item.celular }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.celular"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.correo="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.correo"
                      large
                      persistent
                    >
                      {{ props.item.correo }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.correo"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.dia_pago="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.dia_pago"
                      large
                      persistent
                    >
                      {{ props.item.dia_pago }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.dia_pago"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.zona="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.zona"
                      large
                      persistent
                    >
                      {{ props.item.zona }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.zona"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.ip="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.ip"
                      large
                      persistent
                    >
                      {{ props.item.ip }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.ip"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.antena="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.antena"
                      large
                      persistent
                    >
                      {{ props.item.antena }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.antena"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.paquete="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.paquete"
                      large
                      persistent
                    >
                      {{ props.item.paquete }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.paquete"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.costo_paquete="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.costo_paquete"
                      large
                      persistent
                    >
                      {{ props.item.costo_paquete }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.costo_paquete"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.velocidad_bajada="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.velocidad_bajada"
                      large
                      persistent
                    >
                      {{ props.item.velocidad_bajada }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.velocidad_bajada"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.velocidad_subida="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.velocidad_subida"
                      large
                      persistent
                    >
                      {{ props.item.velocidad_subida }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.velocidad_subida"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.administrar_RB="props">
                    <v-edit-dialog
                      :return-value.sync="props.item.administrar_RB"
                      large
                      persistent
                    >
                      {{ props.item.administrar_RB }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.administrar_RB"
                          label="Edit"
                          single-line
                          counter
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="importar()">Importar</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
import XLSX from "xlsx";
export default {
  data() {
    return {
      headers: [
        { text: "Nombre", value: "nombre", sortable: false },
        {
          text: "Apellido Paterno",
          value: "apellido_paterno",
          sortable: false,
        },
        {
          text: "Apellido Materno",
          value: "apellido_materno",
          sortable: false,
        },
        { text: "Dirección", value: "direccion", sortable: false },
        { text: "Código Postal", value: "codigo_postal", sortable: false },
        { text: "Colonia", value: "colonia", sortable: false },
        { text: "Municipio", value: "municipio", sortable: false },
        { text: "Estado", value: "estado", sortable: false },
        { text: "Celular", value: "celular", sortable: false },
        { text: "Correo", value: "correo", sortable: false },
        { text: "Dia de Pago", value: "dia_pago", sortable: false },
        { text: "Zona", value: "zona", sortable: false },
        { text: "IP", value: "ip", sortable: false },
        { text: "Antena", value: "antena", sortable: false },
        { text: "Paquete", value: "paquete" },
        { text: "Costo del Paquete", value: "costo_paquete", sortable: false },
        {
          text: "Velocidad de Subida",
          value: "velocidad_subida",
          sortable: false,
        },
        {
          text: "Velocidad de Bajada",
          value: "velocidad_bajada",
          sortable: false,
        },
        { text: "Administrar en RB", value: "administrar_RB", sortable: false },
      ],
      clientes: [],
      defaultButtonText: "",
      selectedFile: null,
      isSelecting: false,
      e6: 1,
      valid: true,
      archivo: null,
      rulesFile: [
        (v) => !!v || "Seleccione un archivo",
        (value) => !value || value.size < 20000000 || "Debe ser menor a 20 MB!",
      ],
    };
  },
  mounted() {},
  computed: {
    buttonText() {
      return this.selectedFile
        ? this.selectedFile.name
        : this.defaultButtonText;
    },
  },
  methods: {
    importar() {
      let dispatch = "UsersModule/OnSave";
      let endpoint = "importar/clientes";

      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: this.clientes,
        })
        .then((result) => {
          document.location.href = "/clientes/vista";
        });
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    readFile(evt) {
      let file = evt.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        // Cuando el archivo se terminó de cargar
        this.parseCSV(e.target.result);
      };
      // Leemos el contenido del archivo seleccionado
      reader.readAsBinaryString(file);
    },
    parseCSV(data) {
      var workbook = XLSX.read(data, {
        type: "binary",
      });
      //var self = this;

      workbook.SheetNames.forEach((sheetName) => {
        // Here is your object
        var XL_row_object = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[sheetName]
        );
        var json_object = JSON.stringify(XL_row_object);

        this.clientes = JSON.parse(json_object);
      });
    },
    descargarPlantilla() {
      axios
        .get("/plantilla/clientes/download/actualizado", {
          responseType: "blob", // had to add this one here
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));

          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            "INNOVAISP_PLANTILLA_CLIENTES.xlsx"
          );
          document.body.appendChild(fileLink);

          fileLink.click();
        });
    },
  },
};
</script>
