<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <editar ref="editar"></editar>
        <exportar ref="refExportar"></exportar>
        <nuevo ref="nuevo"></nuevo>
        <v-card flat>
          <v-card-title> Actividades </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-btn
                  dense
                  @click="nuevo()"
                  class="rounded-pill pa-3"
                  color="red"
                  dark
                >
                  <v-icon left>mdi-plus</v-icon>
                  Agregar
                </v-btn>
                <v-btn
                  dense
                  @click="exportar()"
                  class="rounded-pill pa-3"
                  color="red"
                  dark
                >
                  <v-icon left>mdi-file-export</v-icon>
                  Exportar
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" md="6">
                <v-text-field
                  solo
                  class="rounded-pill"
                  dense
                  v-model="search"
                  @keyup.enter="getDatos"
                  append-icon="mdi-magnify"
                  label="Buscador"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            dense
            :search="search"
            :headers="headers"
            :items="elementos"
            :options.sync="options"
            :server-items-length="totalElementos"
            :loading="loading"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-format-horizontal-align-left',
              lastIcon: 'mdi-format-horizontal-align-right',
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right',
              itemsPerPageAllText: 'Todos',
              itemsPerPageText: 'Registros por página:',
              itemsPerPageOptions: [3, 5, 10, 15, -1],
            }"
          >
            <template v-slot:no-results>
              <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
              >
            </template>
            <template v-slot:item.id="{ item }">
              {{
                elementos
                  .map(function (x) {
                    return x.id;
                  })
                  .indexOf(item.id) + 1
              }}
            </template>
            <template v-slot:item.opciones="{ item }">
              <v-tooltip v-if="item.eliminado == false" top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="editar(item)" icon v-on="on">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip v-if="item.eliminado == false" top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="eliminar(item)" icon v-on="on">
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Desactivar</span>
              </v-tooltip>
            </template>

            <template v-slot:no-data>
              <v-alert :value="true" color="red" dark icon="mdi-alert-circle">
                <v-row align="center">
                  <v-col> Ningún dato que mostrar :( </v-col>
                  <v-col class="shrink">
                    <v-btn @click="getDatos()"
                      ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </base-component>
  </div>
</template>

<script>
import nuevo from "@/js/components/personal/detalles/actividades/nuevaActividad.vue";
import exportar from "@/js/components/exportar_tabla/exportarComponent.vue";
import editar from "@/js/components/personal/detalles/actividades/editarActividad.vue";
import Comun from "@/js/Common.js";

export default {
  components: {
    nuevo,
    editar,
    exportar,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      hoy: new Date().toISOString().substr(0, 10),
      headers: [
        { text: "#", align: "start", value: "id" },
        { text: "Trabajo", value: "trabajo", sortable: true },
        { text: "Fecha", value: "fecha", sortable: true },
        { text: "hora_inicio", value: "hora_inicio", sortable: true },
        { text: "hora_fin", value: "hora_fin", sortable: false },
        { text: "direccion", value: "direccion", sortable: false },
        { text: "materiales", value: "materiales", sortable: true },
        { text: "detalles", value: "detalles", sortable: true },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      dialog: false,
      PagoActualizar: {},
      resolve: null,
      reject: null,
      id: null,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      estado_id: "",
      estados: [],
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.id = arreUrl.pop();
  },
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    estadoPago(val) {
      this.getDatos();
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `actividades/personal/tabla/` + this.id,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
            status: parseInt(this.estadoPago),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.actividades.data;
          this.totalElementos = result.data.actividades.total;
        });
    },

    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    eliminar(item) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `actividad/` + item.id;
            let event = "delete";

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: {},
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(item), 1);
              });
          }
        });
    },
    nuevo() {
      this.$refs.nuevo.mostrar().then((confirm) => {
        if (confirm) {
          this.elementos.push(confirm);
          this.totalElementos = this.elementos.length;
        }
      });
    },
    editar(elemento) {
      this.reporteActualizar = elemento;
      const reporte = Object.assign({}, elemento);
      this.$refs.editar.esperarDatos(reporte).then((confirm) => {
        if (confirm) {
          this.elementos.splice(
            this.elementos.indexOf(this.reporteActualizar),
            1,
            confirm
          );
        }
      });
    },

    restaurar(item) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de restaurar este elemento?", {
          color: "orange",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnUpdate";
            let endpoint = `pago/restaurar/` + item.id;

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: item,
              })
              .then((result) => {
                item.eliminado = false;
              });
          }
        });
    },

    exportar() {
      this.$refs.refExportar.cargarDatos("actividades/personal/" + this.id);
    },
    detalles(item) {
      document.location.href = "actividades/detalles/personal/" + item.id;
    },
  },
};
</script>