<template>
  <v-data-table
    dense
    :search="search"
    :headers="headers"
    :items="elementos"
    :options.sync="options"
    :server-items-length="totalElementos"
    :loading="loading"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-format-horizontal-align-left',
      lastIcon: 'mdi-format-horizontal-align-right',
      prevIcon: 'mdi-chevron-left',
      nextIcon: 'mdi-chevron-right',
      itemsPerPageAllText: 'Todos',
      itemsPerPageText: 'Registros por página:',
      itemsPerPageOptions: [3, 5, 10, 15, -1],
    }"
  >
    <template v-slot:no-results>
      <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
        >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
      >
    </template>
    <template v-slot:item.id="{ item }">
      {{
        elementos
          .map(function (x) {
            return x.id;
          })
          .indexOf(item.id) + 1
      }}
    </template>
    <template v-slot:item.monto="{ item }"> ${{ item.monto }} </template>
    <template v-slot:item.opciones="{ item }">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn @click="verArchivo(item)" icon v-on="on">
            <v-icon small>mdi-file</v-icon>
          </v-btn>
        </template>
        <span>Ver comprobante</span>
      </v-tooltip>
    </template>
    <template v-slot:no-data>
      <v-alert :value="true" color="red" dark icon="mdi-alert-circle">
        <v-row align="center">
          <v-col> Ningún dato que mostrar :( </v-col>
          <v-col class="shrink">
            <v-btn @click="getDatos()"
              ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
            >
          </v-col>
        </v-row>
      </v-alert>
    </template>
  </v-data-table>
</template>
<script>
export default {
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      headers: [
        { text: "#", align: "start", value: "id" },
        { text: "Monto", value: "monto", sortable: false },
        { text: "fecha", value: "fecha", sortable: true },
        { text: "hora", value: "hora", sortable: true },
        { text: "Detalles", value: "detalles", sortable: false },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
    };
  },
  mounted() {},
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos(search) {
      if (search != null) {
        this.search = search;
      }

      this.loading = true;

      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `egresos/tabla`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.egresos.data;
          this.totalElementos = result.data.egresos.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    verArchivo(item) {
      if (item.archivo != null) {
        window.open(item.archivo, "_blank");
      } else {
        this.$store.dispatch("notificaciones/add", {
          tipo: "warning",
          mensaje: "Sin archivo  :/",
        });
      }
    },
  },
};
</script>
