class Antena {
    constructor() {
        this.ip = "";
        this.nombre = "";
        this.contrasena = "";
        this.contrasena_snmp = "";
        this.tipo_antena_id = "";
        this.router_casa_id = "";
        this.propietario_id = 1;
        this.status_antena_id = 0;
        this.eliminado = "";
        this.contrasena_wifi = "";
        this.ssid_wifi = "";
        this.usuario = "";
        this.cola = true;
        this.ap_id = null;
        this.mikrotik_id = null;
        this.administracion = 1;
        this.perfil_id = null;
        this.secret = null;
        this.password_secret = null;
        this.local_address_secret = null;
        this.remote_address_secret = null;
    }

}

export default Antena