<template>
    <v-container grid-list-md>
      <v-row>
      <v-col cols="12">
        <h2>Instrucciones:</h2>
      </v-col>
      <v-col cols="12">
        <v-card outlined elevation="0" class="pa-3">
          <v-card-title primary-title>
            <v-chip label color="primary"><v-icon left>mdi-label</v-icon>1</v-chip>
          </v-card-title>
          <v-card-text primary-title>
            Generar el script: click en el boton para generar el codigo 
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="generarScript()" color="success">Generar script</v-btn>
            
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card outlined elevation="0" class="pa-3">
          <v-card-title primary-title>
            <v-chip label color="primary"><v-icon left>mdi-label</v-icon>2</v-chip>
          </v-card-title>
          <v-card-text primary-title>
            Copia el script generado y accede a tu Mikrotik mediante winbox, abre una nueva terminar y pega el script y espera a que se establezca la conexion con el sistema.
            <v-card outlined elevation="0" class="mt-2">
              <v-responsive
                id="scroll-target"
                class="overflow-y-auto"
                :max-height="400"
              >
                <v-card-text>
                  <div class="row">
                    <div class="col-sm-12">
                      <span class="code text-red">{{ script }}</span>
                      <input type="hidden" id="testing-code" :value="script" />
                    </div>
                  </div>
                </v-card-text>
              </v-responsive>
            </v-card> 
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.stop.prevent="copyCode" color="success">Copiar Script</v-btn>
            
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card outlined elevation="0" class="pa-3">
          <v-card-title primary-title>
            <v-chip label color="primary"><v-icon left>mdi-label</v-icon>3</v-chip>
          </v-card-title>
          <v-card-text>
            Al finalizar de ejecutar el scrip en la terminal del mikrotik te muestra un codigo porfavor introducelo en el siguente campo para confirmar la conexion.
            <v-form ref="form" v-model="valid" lazy-validation dense>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Codigo de registro"
                  v-model="codigo_registro"
                  filled
                  :rules="reglasCodigo"
                  append-icon="mdi-code-array"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
      <v-layout row wrap class="pt-5">
        <v-spacer></v-spacer>
        <v-btn @click="regresar()" text>Regresar</v-btn>
        <v-btn :disabled="!valid" @click="siguiente()" class="pa-3" color="primary">Siguiente<v-icon>mdi-arrow-right</v-icon></v-btn>

      </v-layout>
    </v-container>
</template>
<script>
import Common from "@/js/Common";
export default {
  data() {
    return {
      script: "",
      yaSeCopio: true,
      codigo_registro: 0,
      valid: true,
      reglasCodigo: [
        (v) => !!v || "Se necesita el código de confirmacion",
        (v) => this.validarCodigo(v) || "Código incorrecto",
      ],
    };
  },
  mounted() {
    
  },
  computed: {
    router(){
        return this.$store.getters.getRouterRegistrado;
    }
  },
  methods: {
    validarCodigo(codigo){
        return this.router && codigo == this.router.id;
    },
    copyCode() {
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text");
      testingCodeToCopy.select();
      try {
        var successful = document.execCommand("copy");
        if (successful) {
          this.$store.dispatch("notificaciones/add", {
            tipo: "success",
            mensaje: "Copiado en el portapapeles",
          });
        } else {
          this.$store.dispatch("notificaciones/add", {
            tipo: "warrning",
            mensaje: "No se pudo copiar",
          });
        }
      } catch (err) {
        this.$store.dispatch("notificaciones/add", {
          tipo: "warrning",
          mensaje: "No se pudo copiar: Detalles: " + err.message,
        });
      }
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
      this.yaSeCopio = false;
    },
    generarScript() {
        
      this.$store
          .dispatch("UsersModule/OnGet", {
            url: "crear/conexion/script/" + this.router.id,
          })
          .then((result) => {
            this.script = result.data.script;
          });
      
      
    },
    siguiente(){
        if (this.$refs.form.validate()) {
          this.$store
          .dispatch("UsersModule/OnGet", {
            url: "cargar/reglas/conexion/script/" + this.router.id,
          })
          .then((result) => {
            if ([200, 201].includes(result.status)) {
              this.$store.dispatch("siguienteRegistroRouter", 3);
            }
          });
        }
    },
    regresar(){
      this.$store.dispatch("regresarRegistroRouter",1);
    },
  
  obtenerUrlSinParametros() {
  const urlCompleta = window.location.href;
  const urlSinParametros = urlCompleta.split('?')[0];
  const urlObj = new URL(urlSinParametros);
  return urlObj.hostname + (urlObj.port ? ':' + urlObj.port : '');
},
  getProtocol() {
  var protocol = window.location.protocol;
  return protocol.replace(':', '');
}
  }
};
</script>