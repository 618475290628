<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="cool pa-4">
              <v-container grid-list-md>
                <v-card-title class="display-1" primary-title
                  ><strong> Datos de la empresa</strong></v-card-title
                >
                <v-form
                  dense
                  ref="form"
                  v-model="valid"
                  enctype="multipart/form-data"
                  lazy-validation
                >
                  <v-layout row wrap>
                    <v-flex xs12 md6>
                      <v-layout row wrap>
                        <v-flex xs12>
                          <p class="text-subtitle-2">Datos generales</p>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-text-field
                            v-model="empresa.nombre"
                            :rules="nombreReglas"
                            label="Nombre de la empresa"
                            required
                            prepend-icon="mdi-home-automation"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-text-field
                            :rules="telefonoReglas"
                            label="Telefono"
                            v-model="empresa.telefono"
                            type="number"
                            prepend-icon="mdi-cellphone-android"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap>
                        <v-flex xs12 md6>
                          <v-text-field
                            label="Correo empresarial"
                            v-model="empresa.email"
                            prepend-icon="mdi-gmail"
                            :rules="emailRules"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-file-input
                            :rules="rulesFile"
                            accept="image/png, image/jpeg, image/bmp"
                            placeholder="Maximo 2MB"
                            show-size
                            v-model="logo"
                            prepend-icon="mdi-camera"
                            small-chips
                            multiple
                            label="Logotipo"
                          ></v-file-input>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 md6>
                      <p class="text-subtitle-2">Datos Fiscales (opcional)</p>
                      <v-layout row wrap>
                        <v-flex xs12 md6>
                          <v-text-field
                            v-model="empresa.razon_social"
                            label="Razón social"
                            prepend-icon="mdi-account-edit"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-text-field
                            label="Tipo de identificacion"
                            v-model="empresa.tipo_identificacion"
                            prepend-icon="mdi-sim"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap>
                        <v-flex xs12 md6>
                          <v-text-field
                            label="RFC-RUC-NIT"
                            v-model="empresa.RFC_RUC_NIT"
                            prepend-icon="mdi-account-card-details"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-text-field
                            label="Retenciones"
                            v-model="empresa.retenciones"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout mt-2 row wrap>
                    <v-flex xs12>
                      <p class="text-subtitle-2">Datos ubicación</p>
                    </v-flex>
                    <v-flex xs12 md1>
                      <v-text-field
                        v-model="cp"
                        label="Código postal"
                        :rules="generalRegla"
                        type="number"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md2>
                      <v-text-field
                        v-model="empresa.estado"
                        label="Estado"
                        :rules="generalRegla"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-text-field
                        v-model="empresa.municipio"
                        label="Municipio"
                        :rules="generalRegla"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex v-if="colonias.length != 0" xs12 md3>
                      <v-select
                        v-model="empresa.colonia"
                        :items="colonias"
                        item-text="nombre"
                        item-value="nombre"
                        label="Colonias"
                      ></v-select>
                    </v-flex>
                    <v-flex v-if="colonias.length == 0" xs12 md3>
                      <v-text-field
                        v-model="empresa.colonia"
                        label="Colonia"
                        :rules="generalRegla"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-text-field
                        v-model="empresa.direccion"
                        label="Dirección"
                        required
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs12>
                      <ubicacion ref="mapa"></ubicacion>
                    </v-flex>
                  </v-layout>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!valid" color="primary" @click="validate"
                      >Actualizar</v-btn
                    >
                  </v-card-actions>
                </v-form>
              </v-container>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import Empresa from "@/js/components/configuracion_inicial/Empresa";
import ubicacion from "@/js/components/mapa/mapaComponent.vue";
export default {
  components: {
    ubicacion,
  },
  data() {
    return {
      empresa: new Empresa(),
      valid: true,
      nombreReglas: [(v) => !!v || "Se necesita el nombre"],
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      telefonoReglas: [(v) => !!v || "Se necesita el campo"],
      rulesFile: [
        (value) =>
          !value ||
          value.length == 0 ||
          value[0].size < 2000000 ||
          "Debe ser menor a 2 MB!",
      ],
      emailRules: [
        (v) => !!v || "Se necesita el correo.",
        (v) => /.+@.+/.test(v) || "Introduce un correo valido",
      ],
      dialog_mapa: false,
      colonias: [],
      modal2: false,
      modal: false,
      cp: "",
      cuentas: [],
      logo: null,
    };
  },
  mounted() {
    this.getDatos();
  },
  watch: {
    cp(val) {
      //this.getDatosApi(val);
      this.empresa.codigo_postal = val;
    },
    logo(val) {
      this.empresa.logo = val[0];
    },
  },
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "empresa/datos/editar",
          data: {},
        })
        .then((result) => {
          this.cuentas = result.data.empresa.cuentas;
          this.empresa = result.data.empresa;
          this.cp = result.data.empresa.codigo_postal;
          this.$refs.mapa.mostrarMarker(
            result.data.empresa.latitud,
            result.data.empresa.longitud,
            "markerEmpresa.png",
            "Mi wisp",
            true
          );
        });
    },
    validate() {
      if (this.$refs.form.validate()) {
        var ubicacion = this.$refs.mapa.getUbicacion();

        if (ubicacion) {
          this.empresa.latitud = ubicacion.lat;

          this.empresa.longitud = ubicacion.lng;
        }
        let formData = new FormData();
        formData.append("id", this.empresa.id);
        formData.append("nombre", this.empresa.nombre);
        formData.append("logo", this.empresa.logo);
        formData.append("razon_social", this.empresa.razon_social);
        formData.append(
          "tipo_identificacion",
          this.empresa.tipo_identificacion
        );
        formData.append("RFC_RUC_NIT", this.empresa.RFC_RUC_NIT);
        formData.append("direccion", this.empresa.direccion);
        formData.append("estado", this.empresa.estado);
        formData.append("municipio", this.empresa.municipio);
        formData.append("colonia", this.empresa.colonia);
        formData.append("codigo_postal", this.empresa.codigo_postal);
        formData.append("longitud", this.empresa.longitud);
        formData.append("latitud", this.empresa.latitud);
        formData.append("email", this.empresa.email);
        formData.append("telefono", this.empresa.telefono);
        formData.append("retenciones", this.empresa.retenciones);

        let dispatch = "UsersModule/OnSave";
        let endpoint = "actualizar/empresa/";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: formData,
          })
          .then((result) => {
            this.empresa = result.data.empresa;
          });
      }
    },
    getDatosApi(cp) {
      if (cp.length == 5) {
        let dispatch = "UsersModule/OnGet";
        let endpoint = "datos/codigo/postal/" + cp;
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: {},
          })
          .then((result) => {
            if ([200, 201].includes(result.status)) {
              let datos = result.data.data;
              this.empresa.estado = datos.estado;
              this.empresa.municipio = datos.municipio;
              this.colonias = datos.colonias;
            }
          });
      }
    },
  },
};
</script>