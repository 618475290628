var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component mt-4"},[_c('base-component',[_c('div',{attrs:{"slot":"component"},slot:"component"},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"cool pa-4"},[_c('v-card-title',{staticClass:"display-1"},[_c('strong',[_vm._v("Tickets")])]),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-spacer'),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"rounded-pill",attrs:{"solo":"","append-icon":"mdi-magnify","label":"Buscador","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDatos($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-data-table',{attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.elementos,"options":_vm.options,"server-items-length":_vm.totalElementos,"loading":_vm.loading,"footer-props":{
                showFirstLastPage: true,
                firstIcon: 'mdi-format-horizontal-align-left',
                lastIcon: 'mdi-format-horizontal-align-right',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                itemsPerPageAllText: 'Todos',
                itemsPerPageText: 'Registros por página:',
                itemsPerPageOptions: [3, 5, 10, 15, -1],
              }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"lime lighten-1","icon":"mdi-alert"}},[_vm._v("Tu búsqueda \""+_vm._s(_vm.search)+"\" no se encuentra.")])]},proxy:true},{key:"item.id",fn:function(ref){
              var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.elementos
                    .map(function (x) {
                      return x.id;
                    })
                    .indexOf(item.id) + 1)+"\n              ")]}},{key:"item.estado",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{attrs:{"dense":"","text-color":"white","color":_vm.calcularColor(item.estado_reporte_id)},on:{"click":function($event){return _vm.cambiarEstado(item)}}},[_vm._v(_vm._s(item.estado.estado))])]}},{key:"item.opciones",fn:function(ref){
                    var item = ref.item;
return [(item.eliminado == false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.detalles(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Ver detalles")])]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"red","dark":"","icon":"mdi-alert-circle"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_vm._v(" Ningún dato que mostrar :( ")]),_vm._v(" "),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{on:{"click":function($event){return _vm.getDatos()}}},[_c('v-icon',{attrs:{"pl-4":""}},[_vm._v("mdi-cached")]),_vm._v(" Refrescar")],1)],1)],1)],1)]},proxy:true}])})],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"300px"},model:{value:(_vm.dialogEstados),callback:function ($$v) {_vm.dialogEstados=$$v},expression:"dialogEstados"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Cambiar Estado ")]),_vm._v(" "),_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-form',{ref:"formEstados",attrs:{"lazy-validation":""},model:{value:(_vm.validEstados),callback:function ($$v) {_vm.validEstados=$$v},expression:"validEstados"}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-select',{attrs:{"label":"Status","rules":_vm.generalRegla,"item-text":"estado","item-value":"id","items":_vm.estados},model:{value:(_vm.estado_id),callback:function ($$v) {_vm.estado_id=$$v},expression:"estado_id"}})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialogEstados = false}}},[_vm._v("Cancelar")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.actualizarEstado()}}},[_vm._v("Cambiar")])],1)],1)],1),_vm._v(" "),_c('exportar',{ref:"refExportar"})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }