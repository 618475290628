<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12 md12>
            <transacciones></transacciones>
          </v-flex>
        </v-layout>
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
      </div>
    </base-component>
  </div>
</template>
<script>
import transacciones from "@/js/components/almacen/inventario/transaccionesTabla.vue";
export default {
  components: {
    transacciones,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>