<template>
    <div id="chart">
        <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
<script>
import Common from '@/js/Common'
import apexchart from 'vue-apexcharts'

export default {
    components: {
        apexchart
    },
    data() {
        return {
            series: [],
          chartOptions: {
            chart: {
              height: 350,
              type: 'line',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#77B6EA', '#545454'],
            dataLabels: {
              enabled: true,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: 'Ingresos y egresos totales',
              align: 'left'
            },
            grid: {
              borderColor: '#e7e7e7',
              row: {
                colors: ['transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            markers: {
              size: 1
            },
            xaxis: {
              categories: ['Ene','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov','Dic'],
              title: {
                text: 'Mes'
              }
            },
            yaxis: {
              title: {
                text: '$ Cantidad'
              },
              labels: {
                formatter: function (y) {
                  return Common.formatMoney(y)
                },
              }
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            },
            tooltip: {
            y: {
                formatter: function (val) {
                return Common.formatMoney(val)
                }
            },
            }
          },
          
        }   
        
        
    },
    mounted() {
        this.getDatos();
    },
    computed: {
        
    },
    methods: {
        getDatos() {
            this.$store.dispatch('UsersModule/OnGet', {
                url: `contabilidad/finanzas/grafica/ingresosegresos`,
                data: {}

            }).then(result => {
                this.series= [{
                    name: 'Ingresos',
                    data: result.data.ingresos
                }, {
                    name: 'Egresos',
                    data: result.data.egresos
                }
                ];
                this.showChart = true
              
            
            })
        },
        
    }

};
</script>