import moment from 'moment'
let Static = {
    foo() {
        console.log('fooo!')
    },
    //regresa la hora en un formato dia mes, año y hora ejemplo: 1 de marzo de 2020 11:50
    localDateTime(date){
        let locale = window.navigator.userLanguage || window.navigator.language
        return moment(date).locale(locale).format('LLL')
    },
    //se pone en un imput para verificar que lo que se esta introduciendo es un numero
    //ejemplo:  <input v-model="usuario.cp" required maxlength="10" @keypress="validateNumber($event, [46])" type="text" class="form-control" id="cp" name="cp" placeholder="CP">
    validateNumber(evt, code) {
        evt = (evt) ? evt : window.event
        let charCode = (evt.which) ? evt.which : evt.keyCode
        let validation = null
        //console.log(evt)
        if(code){
            validation = (charCode > 31 && (charCode < 48 || charCode > 57)) && !code.includes(charCode)
        }
        else{
            validation = (charCode > 31 && (charCode < 48 || charCode > 57))
        }

        if (validation) {
            evt.preventDefault()
        } else {
            return true
        }
    },

    normalizeYear(year){
        // Century fix
        var YEARS_AHEAD = 20
        if (year<100){
            var nowYear = new Date().getFullYear()
            year += Math.floor(nowYear/100)*100
            if (year > nowYear + YEARS_AHEAD){
                year -= 100
            } else if (year <= nowYear - 100 + YEARS_AHEAD) {
                year += 100
            }
        }
        return year
    },

    //le das una fecha y verifica si ya paso o ya caduco
    checkExpirationDate(value){
        let match=value.match(/^\s*(0?[1-9]|1[0-2])\/(\d\d|\d{4})\s*$/)
        if (!match || value.length < 5){
            let month = new Date().getMonth()+2
            if(month < 10){
                month = '0'+month
            }
            let year = (new Date().getFullYear()+2).toString().slice(-2)
            return 'Ingrese una fecha de expiración válida MM/YY\nEjemplo: '+month+'/'+year
        }
        let exp = new Date(this.normalizeYear(1*match[2]),1*match[1]-1,1).valueOf()
        let now=new Date()
        let currMonth = new Date(now.getFullYear(),now.getMonth(),1).valueOf()
        if (exp<=currMonth){
            return 'La tarjeta ha expirado'
        } else {
            return false
        }
    },
    //verifica la sintaxis de un correo
    validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    },
    //da formato de dimero a un numero
    //ejemplo 130 = 130.00
    
    formatMoney(number,decimales) {
        const formatterDolar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
          })

          return formatterDolar.format(number);
          // → $12,500.00
          // → $ 12.500
       
       /*
        decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
        decSep = typeof decSep === "undefined" ? "." : decSep;
        thouSep = typeof thouSep === "undefined" ? "," : thouSep;
        var sign = number < 0 ? "-" : "";
        var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
        var j = (j = i.length) > 3 ? j % 3 : 0;

        return sign +
            (j ? i.substr(0, j) + thouSep : "") +
            i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
            (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
            */
    },
    //le da formato a una fecha de la base de altos a dd/mm/aaaa
    formatoFecha(date) {
        if (!date) return null;
  
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      },

    //formatear bytes
    // Ejemplos

    //formatBytes(1024);       // 1 KB
    //formatBytes('1024');     // 1 KB
    //formatBytes(1234);       // 1.21 KB
    //formatBytes(1234, 3);    // 1.205 KB
        
    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },

    anios(){
        return [2020,2021,2022,2023,2024,2025,2026,2027,2028,2029,2030,2031,2032,2033,2034,2035,2036,2037,2038,2039,2040,2041,2042,2043,2044,2045,2046,2047,2048,2049,2050,2051,2052,2053,2054,2055,2056,2057,2058,2059,2060,2061,2062,2063,2064,2065,2066,2067,2068,2069,2070,2071,2072,2073,2074,2075,2076,2077,2077,2078,2079,2080,2081,2082,2083,2084,2085,2086,2087,2088,2089,2090];
    },
    meses(){
        return [
        {id: 1, protegido: 1, mes: "Enero", created_at: null},
        {id: 2, protegido: 1, mes: "Febrero", created_at: null},
        {id: 3, protegido: 1, mes: "Marzo", created_at: null},
        {id: 4, protegido: 1, mes: "Abril", created_at: null},
        {id: 5, protegido: 1, mes: "Mayo", created_at: null},
        {id: 6, protegido: 1, mes: "Junio", created_at: null},
        {id: 7, protegido: 1, mes: "Julio", created_at: null},
        {id: 8, protegido: 1, mes: "Agosto", created_at: null},
        {id: 9, protegido: 1, mes: "Septiembre", created_at: null},
        {id: 10, protegido: 1, mes: "Octubre", created_at: null},
        {id: 11, protegido: 1, mes: "Noviembre", created_at: null},
        {id: 12, protegido: 1, mes: "Diciembre", created_at: null},
        ];
    },
    estadosClientes(){
        return [
            {id: 1, status: "instalación", protegido: 1, eliminado: 0, created_at: null},
            {id: 2, status: "Activo", protegido: 1, eliminado: 0, created_at: null},
            {id: 3, status: "Suspendido", protegido: 1, eliminado: 0, created_at: null}
        ];
    }

    
}

export default Static
