<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="cool pa-3">
              <v-card-title class="display-1">
                <strong>Clientes instalados</strong>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-spacer></v-spacer>

                  <v-col cols="12" md="6">
                    <v-text-field
                      solo
                      class="rounded-pill"
                      v-model="search"
                      @keyup.enter="getDatos"
                      append-icon="mdi-magnify"
                      label="Buscador"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-data-table
                dense
                :search="search"
                :headers="headers"
                :items="elementos"
                :options.sync="options"
                :server-items-length="totalElementos"
                :loading="loading"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-format-horizontal-align-left',
                  lastIcon: 'mdi-format-horizontal-align-right',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  itemsPerPageAllText: 'Todos',
                  itemsPerPageText: 'Registros por página:',
                  itemsPerPageOptions: [15, 50, 100, 500, -1],
                }"
              >
                <template v-slot:item.id="{ item }">
                  {{
                    elementos
                      .map(function (x) {
                        return x.id;
                      })
                      .indexOf(item.id) + 1
                  }}
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip
                    small
                    @click="status(item)"
                    dense
                    :color="getColorStatus(item.status.id)"
                    dark
                    >{{ item.status.status }}</v-chip
                  >
                </template>
                <template v-slot:item.eliminado="{ item }">
                  <v-chip
                    @click="status(item)"
                    dense
                    :color="item.eliminado ? 'red' : 'green'"
                    dark
                    >{{ item.eliminado ? "Eliminado" : "Activo" }}</v-chip
                  >
                </template>
                <template v-slot:item.opciones="{ item }">
                  <v-tooltip v-if="item.eliminado == false" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        @click="detalles(item)"
                        icon
                        v-on="on"
                        v-bind="attrs"
                      >
                        <v-icon small> mdi-dots-horizontal </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver detalles</span>
                  </v-tooltip>
                </template>
                <template v-slot:no-results>
                  <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                    >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                  >
                </template>
                <template v-slot:no-data>
                  <v-alert
                    :value="true"
                    color="red"
                    dark
                    icon="mdi-alert-circle"
                  >
                    <v-row align="center">
                      <v-col> Ningún dato que mostrar :( </v-col>
                      <v-col class="shrink">
                        <v-btn @click="getDatos()"
                          ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-alert>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
export default {
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: false,
      options: {},
      headers: [
        { text: "#", align: "start", value: "id" },
        { text: "Cliente", value: "FullName", sortable: false },
        { text: "Dirección", value: "direccion" },
        { text: "Colonia", value: "colonia" },
        { text: "Zona", value: "zona.nombre", sortable: false },
        { text: "IP", value: "antena.ip", sortable: false },
        { text: "Instalador", value: "instalador.FullName", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Eliminado", value: "eliminado", sortable: false },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
    };
  },
  mounted() {},
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos() {
      if (this.loading) return;

      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `clientes/instalador/instalador/tabla`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.clientes.data;
          this.totalElementos = result.data.clientes.total;
        });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    detalles(item) {
      document.location.href = "vista/detalles/instalacion/" + item.id;
    },
    getColorStatus(item) {
      if (item == 1) return "orange";
      else if (item == 2) return "green";
      else return "red";
    },
  },
};
</script>
