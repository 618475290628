<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <div v-if="showChart">
          <v-layout row wrap>
            <v-flex xs12>
              <apexchart
                type="treemap"
                height="350"
                :options="chartOptionsCantidad"
                :series="seriesCantidad"
              ></apexchart>
            </v-flex>
          </v-layout>
        </div>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
import apexchart from "vue-apexcharts";
export default {
  components: {
    apexchart,
  },
  data() {
    return {
      showChart: false,

      //mapa de calor de cantidad por inventario
      seriesCantidad: [
        {
          data: [],
        },
      ],
      chartOptionsCantidad: {
        chart: {
          type: "treemap",
          height: 350,
        },
        title: {
          text: "Cantidad de productos en el inventario",
          style: {
            fontSize: "14px",
          },
        },
        plotOptions: {
          treemap: {
            enableShades: true,
            shadeIntensity: 0.5,
            reverseNegativeShade: true,
            colorScale: {
              ranges: [
                {
                  from: -1000,
                  to: 3,
                  color: "#CD363A",
                },
              ],
            },
          },
        },
      },
    };
  },
  mounted() {
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `almacen/estadisticas/datos`,
          data: {},
        })
        .then((result) => {
          this.seriesCantidad[0].data = result.data.almacen;
          this.showChart = true;
        });
    },
  },
};
</script>