<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <v-layout row wrap>
          <v-flex xs12 md12>
            <v-card class="cool pa-4">
              <v-tabs
              class="cool"
                v-model="tab"
                background-color="red"
                dark
                show-arrows
              >
                <v-tab> Tipos de negocio </v-tab>
                <v-tab> Porcentajes de repartición </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <tipos></tipos>
                </v-tab-item>
                <v-tab-item>
                  <porcentajes></porcentajes>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
import porcentajes from "@/js/components/negocios/recursos/tablas/porcentajes/tablaPorcentajeNegocios.vue";
import tipos from "@/js/components/negocios/recursos/tablas/tipos_negocios/tablaTiposNegocios.vue";
import LocalStorage from "@/js/LocalStorage.js";

export default {
  components: {
    porcentajes,
    tipos,
  },
  data() {
    return {
      tab: 0,
    };
  },
  watch: {
    tab(val) {
      LocalStorage.set("TAB_ACTUAL_RECURSOS_NEGOCIOS", val);
    },
  },
  mounted() {
    var actualTab = LocalStorage.get("TAB_ACTUAL_RECURSOS_NEGOCIOS");
    if (actualTab) {
      this.tab = actualTab;
    }
  },
  computed: {},
  methods: {},
};
</script>