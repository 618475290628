<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent 
      max-width="600px"
    >
    <v-card>
      <v-card-title primary-title>
          {{ chat_title }}
          <v-spacer></v-spacer>
          <v-btn icon @click="cerrar()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
      <v-card-text class="scrollable-container pa-0" ref="messageContainer" v-scroll-bottom>
        <v-scroll-x-transition>
          <v-list>
            <v-list-item v-for="message in chat" :key="message.id">
              <v-list-item-content class="px-3">
                <v-layout row wrap>
                  <v-spacer v-if="message.cliente == cliente"></v-spacer>
                  <v-flex xs8 md7>
                    <v-card
                      elevation-3
                      class="cool"
                      color="primary"
                      dark
                      v-if="message.cliente == cliente"
                    >
                      <v-card-title>
                        {{ message.mensaje }}
                      </v-card-title>
                      <v-card-subtitle>
                        {{ convierteFecha(message.fecha, message.hora) }}
                      </v-card-subtitle>
                    </v-card>
                    <v-card elevation-3 class="cool" dark color="grey" v-else>
                      <v-card-title>
                        {{ message.mensaje }}
                      </v-card-title>
                      <v-card-subtitle>
                        {{ convierteFecha(message.fecha, message.hora) }}
                      </v-card-subtitle>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-scroll-x-transition>
      </v-card-text>
      <v-card-actions class="teal lighten-5 elevation-2 pa-4">
    
        <v-text-field v-model="message" ref="newMessage" label="Nuevo mensaje" solo rounded :rules="messageRules" @keyup.enter="sendMessage"></v-text-field>

        <v-btn fab dark color="red" @click="sendMessage" class="mb-7 ml-2">
          <v-icon>mdi-send</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Common from "@/js/Common";
export default {
  data() {
    return {
      chat: [],
      resolve: null,
      reject: null,
      dialog: false,
      message: "",
      chat_title: "",
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      cargaMensaje: false,
      cliente_id: 0,
      valid: true,
      messageRules: [v => !!v || 'El mensaje es obligatorio'],
      cliente: false,
    };
  },
  directives: {
    scrollBottom: {
      inserted: function (el) {
        el.scrollTop = el.scrollHeight;
      },
      componentUpdated: function (el) {
        el.scrollTop = el.scrollHeight;
      }
    }
  },
  mounted() {
    
    window.Echo.private('chats').
        listen('MessageSent',(e) => {
          
           if(this.cliente != e.message.cliente){
              this.chat.push(e.message);
              this.$nextTick(() => {
                this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight;
              });
            }
        });
  },
  computed: {},
  methods: {
    esperarDatos(titulo,cliente_id,cliente) {
      this.chat_title = titulo;
      this.cliente= cliente;
      
      this.cliente_id = cliente_id;
      this.dialog = true;
      this.getChat();
      
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    getChat() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "chat/reporte/" + this.cliente_id,
          data: {},
        })
        .then((result) => {
          this.chat = result.data.chat;
          this.$nextTick(() => {
                this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight;
              });
          
        });
    },
    cerrar() {
      this.resolve(false);
      this.dialog = false;
    },
    sendMessage() {
      if (!this.$refs.newMessage.validate()) {
        return;
      }

        this.cargaMensaje = true;
        let datos = {
          cliente_id: this.cliente_id,
          mensaje: this.message,
          cliente: this.cliente,
        };

        let dispatch = "UsersModule/OnSaveSP";
        let endpoint = "reporte/mensaje";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: datos,
          })
          .then((result) => {
            this.cargaMensaje = false;
            if ([200, 201].includes(result.status)) {
              this.chat.push(result.data.mensaje);
              this.clearMessage();
              this.$nextTick(() => {
                this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight;
              });
            }
          });
    },
    convierteFecha(date, hora) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}` + "-" + hora;
    },
    clearMessage() {
      this.message = "";
    },
  },
};
</script>
<style>
.scrollable-container {
  min-height: 400px;
  max-height: 400px; /* Ajusta la altura máxima según tus necesidades */
  overflow-y: auto;
}
</style>