<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12 md12>
            <v-card class="cool">
              <v-card-title primary-title
                ><strong>Detalles Mikrotik</strong>
              </v-card-title>
              <v-tabs v-model="tab" color="red" grow show-arrows>
                <v-tab>
                  <v-icon left>mdi-router-wireless</v-icon>
                  Router
                </v-tab>
                <v-tab>
                  <v-icon left>mdi-account-network</v-icon>
                  PPPoe
                </v-tab>
                <v-tab>
                  <v-icon left>mdi-account-multiple</v-icon>
                  Clientes
                </v-tab>
                <v-tab v-if="router && router.tipo_conexion != 3">
                  <v-icon left>mdi-chart-bar</v-icon>
                  Graficas
                </v-tab>
                <v-tab v-if="router && router.tipo_conexion != 3">
                  <v-icon left>mdi-format-list-bulleted</v-icon>
                  Logs
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <router ref="routerDatos"></router>
                </v-tab-item>

                <v-tab-item>
                  <pppoe></pppoe>
                </v-tab-item>
                <v-tab-item>
                  <clientes_simple_queue></clientes_simple_queue>
                </v-tab-item>
                <v-tab-item v-if="router && router.tipo_conexion != 3">
                  <graficas></graficas>
                </v-tab-item>
                <v-tab-item v-if="router && router.tipo_conexion != 3">
                  <logs></logs>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
import router from "@/js/components/red/routers/detalles/router.vue";
import clientes_simple_queue from "@/js/components/red/routers/detalles/simple_queue/clientes_simple_queue.vue";
import pppoe from "@/js/components/red/routers/detalles/apartado_pppoe/pppoe.vue";
import logs from "@/js/components/red/routers/detalles/logs.vue";
import graficas from "@/js/components/red/routers/detalles/graficas_router/graficas.vue";
import LocalStorage from "@/js/LocalStorage.js";
export default {
  components: {
    router,
    pppoe,
    logs,
    graficas,
    clientes_simple_queue,
  },
  data() {
    return {
      tab: 0,
      router: null,
    };
  },
  watch: {
    tab(val) {
      LocalStorage.set("TAB_ACTUAL_ROUTER", val);
    },
  },
  mounted() {
    var actualTab = LocalStorage.get("TAB_ACTUAL_ROUTER");
    if (actualTab) {
      this.tab = actualTab;
    }
    var arreUrl = window.location.href.split("/");
    this.id = arreUrl.pop();
    this.getDatos();
  },
  computed: {},
  methods: {
    getDatos() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `router/` + this.id,
          data: {},
        })
        .then((result) => {
          this.router = result.data.router;
          this.$refs.routerDatos.cargarDatos(result);
        });
    },
  },
};
</script>