<template>
  <div class="component mt-3">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <v-layout row wrap>
          <v-flex xs12>
            
        <v-card class="cool pa-3">
          <v-card-title class="display-1" primary-title>
            <strong>Trabajos</strong>
          </v-card-title>
          <v-card-subtitle>
            En el calendario se muestran las instalaciones, actividades y tickets
          </v-card-subtitle>
        <v-layout row wrap>
          <v-flex xs12 md12>
            <v-row class="fill-height">
              <v-col>
                <v-sheet elevation-1 height="64">
                  <v-toolbar flat color="white">
                    <v-btn
                      outlined
                      class="mr-4"
                      color="grey darken-2"
                      @click="setToday"
                    >
                      Hoy
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="prev">
                      <v-icon small>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="next">
                      <v-icon small>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="$refs.calendar">
                      {{ tituloMes(focus) }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu bottom right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          color="grey darken-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span>{{ typeToLabel[type] }}</span>
                          <v-icon right>mdi-menu-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="type = 'day'">
                          <v-list-item-title>Dia</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'week'">
                          <v-list-item-title>Semana</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'month'">
                          <v-list-item-title>Mes</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = '4day'">
                          <v-list-item-title>4 Dias</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-toolbar>
                </v-sheet>
                <v-sheet height="600">
                  <v-calendar
                    ref="calendar"
                    locale="es-mx"
                    v-model="focus"
                    dense
                    color="primary"
                    :events="events"
                    :event-color="getEventColor"
                    :short-months="false"
                    :show-week="false"
                    :type="type"
                    @click:event="showEvent"
                    @click:more="viewDay"
                    @click:date="viewDay"
                  >
                  </v-calendar>
                </v-sheet>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
        
        </v-card>
          </v-flex>

        </v-layout>

        
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "../../Common";
export default {
  components: {
    
  },
  data() {
    return {
      focus: new Date().toISOString().slice(0, 10),
      type: "month",
      typeToLabel: {
        month: "Mes",
        week: "Semana",
        day: "Dia",
        "4day": "4 Dias",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      
      dialogNuevaActividad: false,
      actividades: [],
      actividadActualizar: null,
     
    };
  },
  mounted() {
    this.$refs.calendar.checkChange();
    this.getDatos();
  },
  computed: {
    events() {
      return this.actividades.map((entry) => {
        let fecha = "";
        let hora_inicio = "";
        let nombre = "";
        let tipo = "";
        let color = "deep-purple";
       

        if (entry.trabajo) {
          nombre = "Trabajo: " + entry.trabajo;
          tipo = "Trabajo";
          color = "deep-purple";
        }
        if (entry.asunto) {
          nombre = "Ticket: " + entry.asunto;
          tipo = "Ticket";
          color="orange";

        }
        if (entry.FullName) {
          nombre = "Instalacion: " + entry.FullName;
          tipo = "Instalacion";
          color = "cyan"
        }
        if (entry.fecha) {
          fecha = entry.fecha;
        }
        if (entry.fecha_instalacion) {
          fecha = entry.fecha_instalacion;
        }
        if (entry.hora_inicio) {
          hora_inicio = entry.hora_inicio;
        }
        if (entry.hora) {
          hora_inicio = entry.hora;
        }

        if (entry.hora_instalacion) {
          hora_inicio = entry.hora_instalacion;
        }

        const name = nombre;
        const id = entry.id;
        const start = fecha + " " + hora_inicio;
        if (entry.hora_fin) {
          const end = entry.fecha + " " + entry.hora_fin;
          return Object.assign({}, entry, {id, name, start, end, color, tipo });
        }
        return Object.assign({}, entry, {id, name, start, color, tipo });
      });
    },
    fields() {
      if (!this.model) return [];
      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
  },
  watch: {
    focus(val) {
      this.tituloMes(val);
      this.getDatos();
    },
    selectedOpen(val) {},
  },
  methods: {
    getDatos() {
      
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "calendario/actividades/datos",
          data: {
            focus: this.focus ,
          },
        })
        .then((result) => {
          this.actividades = result.data.actividades;
        });
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },

    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getEventColor(event) {
      return event.color;
    },
    showEvent({ nativeEvent, event }) {
      
      switch(event.tipo) {
        case "Instalacion":
          window.location.href = "vista/detalles/instalacion/"+event.id;
          break;
        case "Trabajo":
          window.location.href = "vista/detalles/actividad/"+event.id;
          break;
        case "Ticket":
          window.location.href = "vista/detalles/ticket/"+event.id;
          break;
        default:
          
      }
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    tituloMes(date) {
      if (date) {
        var mes_actual = date.substring(5, 7);
        return this.numero_a_mes(mes_actual) + " " + date.substring(0, 4);
      } else {
        var hoy = new Date().toISOString().substr(0, 10);
        var mes_actual = hoy.substring(5, 7);
        return this.numero_a_mes(mes_actual) + " " + hoy.substring(0, 4);
      }
    },
    numero_a_mes(mes) {
      switch (mes) {
        case "01":
          return "Enero";
          break;
        case "02":
          return "Febrero";
          break;
        case "03":
          return "Marzo";
          break;
        case "04":
          return "Abril";
          break;
        case "05":
          return "Mayo";
          break;
        case "06":
          return "Junio";
          break;
        case "07":
          return "Julio";
          break;
        case "08":
          return "Agosto";
          break;
        case "09":
          return "Septiembre";
          break;
        case "10":
          return "Octubre";
          break;
        case "11":
          return "Noviembre";
          break;
        case "12":
          return "Diciembre";
          break;
        default:
          return "Mes actual";
          break;
      }
    },
  },
};
</script>