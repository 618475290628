<template>
  <v-dialog v-model="dialog" dense max-width="400">
    <v-card class="pa-6">
      <v-form dense ref="form" v-model="valid">
        <v-card-title primary-title> Exportar Tabla </v-card-title>
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex class="d-flex justify-center" xs12 md12>
              <v-radio-group dense v-model="filtro" row>
                <v-radio dense color="blue" label="Rango" value="1"></v-radio>
                <v-radio
                  dense
                  color="blue"
                  label="Mes actual"
                  value="2"
                ></v-radio>
                <v-radio dense color="blue" label="Todos" value="3"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
          <v-layout disabled row wrap>
            <v-flex xs12 md6>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateFormatted"
                    label="Fecha Inicio"
                    prepend-icon="mdi-calendar"
                    readonly
                    :disabled="filtro != '1'"
                    :rules="generalRegla"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="es-mx"
                  v-model="fecha_inicio"
                  no-title
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 md6>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateFormatted2"
                    label="Fecha Fin"
                    prepend-icon="mdi-calendar"
                    readonly
                    :rules="generalRegla"
                    v-bind="attrs"
                    :disabled="filtro != '1'"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="es-mx"
                  :min="fecha_inicio"
                  v-model="fecha_fin"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex class="d-flex justify-center" xs12 md12>
              <v-radio-group dense v-model="formato" row>
                <v-radio dense color="green" label="Excel" value="1"></v-radio>
                <v-radio dense color="red" label="PDF" value="2"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Cancelar</v-btn>
          <v-btn @click="exportar()" class="rounded-pill pa-3" color="success"
            >Exportar</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import XLSX from "xlsx";
export default {
  data() {
    return {
      dialog: false,
      menu2: false,
      menu: false,
      fecha_inicio: null,
      fecha_fin: null,
      filtro: "1",
      formato: "1",
      valid: true,
      date: new Date().toISOString().substr(0, 10),
      tabla: "",
      generalRegla: [(v) => !!v || "Se necesita el campo"],
    };
  },
  mounted() {},
  watch: {
    fecha_inicio(val) {
      this.fecha_fin = null;
    },
  },
  computed: {
    computedDateFormatted() {
      if (this.fecha_inicio) {
        if (!this.fecha_inicio) return null;
        const [year, month, day] = this.fecha_inicio.split("-");
        return `${day}/${month}/${year}`;
      } else {
        return "";
      }
    },
    computedDateFormatted2() {
      if (this.fecha_fin) {
        if (!this.fecha_fin) return null;
        const [year, month, day] = this.fecha_fin.split("-");
        return `${day}/${month}/${year}`;
      } else {
        return "";
      }
    },
  },
  methods: {
    cargarDatos(table) {
      this.tabla = table;
      this.dialog = true;
    },
    exportar() {
      if (this.filtro == "1") {
        if (!this.$refs.form.validate()) {
          return;
        }
      }
      this.$store.dispatch("mostrarCarga", true);
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `exportar/` + this.tabla,
          data: {
            incio: this.fecha_inicio,
            fin: this.fecha_fin,
            filtro: this.filtro,
            formato: this.formato,
          },
        })
        .then((result) => {
          if (result.data.elementos.length == 0) {
            this.$store.dispatch("mostrarCarga", false);
            this.$store.dispatch("notificaciones/add", {
              tipo: "warning",
              mensaje: "Ningun elemento encontrado  :/",
            });
            return;
          }
          if (parseInt(this.formato) == 1) {
            this.generarExel(result.data.elementos);
          } else if (parseInt(this.formato) == 2) {
            this.generarPdf(result.data.elementos);
          }
        });
    },
    generarExel(elementos) {
      var elementos = XLSX.utils.json_to_sheet(elementos);
      var wb = XLSX.utils.book_new();
      var url = this.tabla.split("/");
      var nombreTabla = url[0];

      let range = XLSX.utils.decode_range(elementos["!ref"]);
      for (let R = range.s.r + 1; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          let ref = XLSX.utils.encode_cell({ r: R, c: C });
          if (elementos[ref].t == "n") {
            continue;
          }
          if (+elementos[ref].v) {
            elementos[ref].t = "n";
          }
        }
      }

      XLSX.utils.book_append_sheet(wb, elementos, nombreTabla);
      XLSX.writeFile(wb, nombreTabla + "-" + this.date + ".xlsx");
      this.$store.dispatch("mostrarCarga", false);
    },
    generarPdf(elementos) {
      let pdfName = this.tabla + "-" + this.date;
      var doc = new jsPDF("landscape");
      var cabecera = "Tabla de " + this.tabla;

      if (this.filtro == "1") {
        cabecera =
          cabecera +
          " - del " +
          this.computedDateFormatted +
          " al " +
          this.computedDateFormatted2;
      }
      doc.text(5, 10, cabecera);

      var ObjElemento = elementos[0];
      var head = [];
      head.push(Object.keys(ObjElemento));

      var body = [];
      for (var i = 0; i < elementos.length; i++) {
        var elemento = elementos[i];
        var row = Object.values(elemento);
        body.push(row);
      }
      doc.setFontSize(2);
      doc.autoTable({
        head: head,
        body: body,
        startY: 15,
        bodyStyles: {
          valign: "top",
        },
        styles: {
          rowPageBreak: "auto",
          overflow: "linebreak",
        },
        columnStyles: {
          text: {
            cellWidth: "wrap",
          },
        },
        margin: { left: 5, right: 5 },
      });

      doc.save(pdfName + ".pdf");
      this.$store.dispatch("mostrarCarga", false);
    },
  },
};
</script>
