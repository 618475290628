<template>
  <v-container grid-list-md>
    <consumo ref="consumo"></consumo>
    <pingGrafica ref="ping"></pingGrafica>
  </v-container>
</template>
<script>
import consumo from "@/js/components/red/antenas/detalles/graficas/consumo.vue";
import pingGrafica from "@/js/components/red/antenas/detalles/graficas/ping.vue";

export default {
  components: {
    consumo,
    pingGrafica,
  },
  data() {
    return {
      datos: [],
      pings: [],
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    var id = arreUrl.pop();
    this.getDatos(id);
  },
  computed: {},
  methods: {
    getDatos(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `graficas/antena/` + id,
          data: {},
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.datos = result.data.datos;
            this.pings = result.data.pings;

            if (this.datos.length > 0) {
              this.$refs.consumo.graficar(this.datos);
            }
            if (this.pings.length > 0) {
              this.$refs.ping.graficar(this.pings);
            }
          }
        });
    },
  },
};
</script>