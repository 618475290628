

import Axios from "axios"

export default {
    state: {
        pasoRegistroRouter: 1,
        router: null,
        dialog: false,

    },
    mutations: {
        mutacionPasoRegistroRouter(state, datos) {
            state.pasoRegistroRouter = datos;
        },
        mutacionDatosRouter(state, datos) {
            state.router = datos;
        },
        mutacionDatosInstalacion(state, datos) {

            state.instalacion = datos;
        },
        mutacionCambiarDialog(state, datos) {
            state.dialog = datos;
        }



    },
    actions: {
        regresarRegistroRouter({ commit }, payload) {
            commit('mutacionPasoRegistroRouter', payload);
        },
        siguienteRegistroRouter({ commit }, payload) {
            commit('mutacionPasoRegistroRouter', payload);
        },
        PasoRegistroRouter({ commit }, payload) {
            commit('mutacionPasoRegistroRouter', payload.paso);
            commit('mutacionDatosRouter', payload.router);
        },
        PasoRegistroInstalacion({ commit }, payload) {
            commit('mutacionPasoRegistroCliente', payload.paso);
            commit('mutacionDatosInstalacion', payload.cliente);
        },
        cambiarDialogRouter({ commit }, payload) {
            commit('mutacionCambiarDialog', payload);
        },
        asignarRouter({ commit }, payload) {
            commit('mutacionDatosRouter', payload);
        }

    },
    getters: {
        getPasoRegistroRouter(state) {
            return state.pasoRegistroRouter;
        },
        getRouterRegistrado(state) {
            return state.router;
        },
        getDialogRouter(state) {
            return state.dialog;
        }

    },
    modules: {

    }
};
