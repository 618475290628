<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <v-dialog v-model="dialog" max-width="500px" persistent>
          <v-card>
            <v-card-title primary-title> Editar </v-card-title>
            <v-container grid-list-md>
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-text-field
                  label="Titulo de la notificación"
                  v-model="notificacion.titulo"
                  :rules="generalRegla"
                  hint="Ejemplo: Recordatorio, Importante, Pago pendiente, etc."
                ></v-text-field>

                <v-textarea
                  label="Mensaje"
                  :rules="generalRegla"
                  v-model="notificacion.mensaje"
                  rows="2"
                  filled
                ></v-textarea>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="cancelar()" text>Cancelar</v-btn>
              <v-btn @click="enviar()" color="primary"
                >Actualizar y renviar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
export default {
  data() {
    return {
      dialog: false,
      clienteId: null,
      notificacion: {
        titulo: "",
        mensaje: "",
        cliente_id: "",
      },
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.clienteId = arreUrl.pop();
    this.notificacion.cliente_id = this.clienteId;
  },
  computed: {},
  methods: {
    cargarDatos(notificacion) {
      this.notificacion = notificacion;
      this.notificacion.cliente_id = this.clienteId;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    enviar() {
      if (this.$refs.form.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "notificacion/movil";
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.notificacion,
          })
          .then((result) => {
            this.dialog = false;
            this.limpiar();
            this.resolve(result.data.notificacion);
          });
      }
    },
    limpiar() {
      this.$refs.form.reset();
    },
    cancelar() {
      this.dialog = false;
      this.resolve(false);
    },
  },
};
</script>