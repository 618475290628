import Vue from 'vue'
import Vuex from 'vuex'
import UsersModule from './modules/UsersModule'
import * as Errores from '@/js/modulos/errores.js';
import * as notificaciones from '@/js/modulos/notification.js';
import dialogs from '@/js/modulos/dialogs.js';
import rama_modulo_ejemplo from '@/js/components/carpeta_ejemplo/modulo_ejemplo.js';
import rama_modulo_registro_empresa from '@/js/components/configuracion_inicial/modulo_configuracion_inicial.js'
import rama_modulo_registro_cliente from '@/js/components/clientes/modulo_registro_cliente.js'
import rama_modulo_personal from '@/js/components/personal/modulo_personal.js';
import rama_modulo_router from '@/js/components/red/routers/modulo_router.js';

Vue.use(Vuex)
export default new Vuex.Store({
  strict: true,
  state: {
    is_loading: false,
    current_item: null,
    links: [],
    randomDelay: Math.floor(Math.random() * (3000 - 1000 + 1) + 1000), // genera un número aleatorio entre 1000 y 3000
    skill_state: 0,
  },
  mutations: {
    SET_IS_LOADING_STATE: (state, value) => {

      state.is_loading = value

    },
    SET_CURRENT_ITEM_STATE: (state, value) => {
      state.current_item = value
    },
    SET_SKILL_STATE: (state, value) => {
      //console.log('value: ', value);
      state.skill_state = value
    }
  },
  modules: {
    UsersModule,
    Errores,
    notificaciones,
    dialogs,
    rama_modulo_ejemplo,
    rama_modulo_registro_empresa,
    rama_modulo_registro_cliente,
    rama_modulo_personal,
    rama_modulo_router

  },
  actions: {
    setIsLoadingState: ({ commit, dispatch, getters }, value) => {
      commit('SET_IS_LOADING_STATE', value)
      return new Promise((resolve, reject) => {
        resolve(getters.getIsLoadingState)
      })
    },
    setCurrentItemState: ({ commit, dispatch, getters }, value) => {
      commit('SET_CURRENT_ITEM_STATE', value)
      return new Promise((resolve, reject) => {
        resolve(getters.getCurrentItemState)
      })
    },
    getAuthorizationToken: ({ commit, dispatch, getters }, payload) => {
      return new Promise((resolve, reject) => {
        commit('SET_IS_LOADING_STATE', true)
        let company_id = payload ? payload.company_id : null
        axios.get('get/current/token', {
          params: {
            company_id: company_id
          }
        }).then(response => {
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`
          commit('SET_IS_LOADING_STATE', false)
          resolve(response)
        }).catch(error => {
          console.log('error: ', error);
          dispatch("Errores/evaluar", error);
        })
      })
    },
    get: ({ commit, dispatch, getters }, payload) => {
      commit('SET_SKILL_STATE', 0);
      commit('SET_IS_LOADING_STATE', true);
      var params = {
        params: payload.data,
        onDownloadProgress: progressEvent => {
          if (progressEvent.total != 0) {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            commit('SET_SKILL_STATE', progress); // Actualiza el progreso
          } else {
            let i = 0;
            const intervalId = setInterval(() => {
              i += 10;
              const skill = i * (100 / 90); // 90 es un número arbitrario para que la simulación llegue al 100% antes de que finalice la petición
              commit('SET_SKILL_STATE', skill);

              if (i >= 80) { // Detener la simulación cuando llegue al 100%
                clearInterval(intervalId);
              }
            }, 100);
          }
        }
      };
      return new Promise((resolve, reject) => {
        axios
          .get(payload.url, params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log('error: ', error);
            dispatch('Errores/evaluar', error);
            resolve(error.response);
          }).finally(() => {
            setTimeout(() => {
              commit('SET_SKILL_STATE', 100);
              commit('SET_IS_LOADING_STATE', false);
            }, 10);
          });
      })
    },
    getSP: ({ commit, dispatch, getters }, payload) => {
      var params = {
        params: payload.data
      }
      return new Promise((resolve, reject) => {
        axios.get(payload.url, params).then(response => {
          resolve(response)
        }).catch(error => {
          console.log('error: ', error);
          dispatch("Errores/evaluar", error);
          resolve(error.response)
        })
      })
    },
    post: ({ commit, dispatch }, payload) => {
      commit('SET_SKILL_STATE', 0);
      commit('SET_IS_LOADING_STATE', true);
      let config = {
        onUploadProgress: progressEvent => {
          if (progressEvent.total != 0) {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            commit('SET_SKILL_STATE', progress); // Actualiza el progreso
          } else {
            let i = 0;
            const intervalId = setInterval(() => {
              i += 10;
              const skill = i * (100 / 90); // 90 es un número arbitrario para que la simulación llegue al 100% antes de que finalice la petición
              commit('SET_SKILL_STATE', skill);
              if (i >= 80) { // Detener la simulación cuando llegue al 100%
                clearInterval(intervalId);
              }
            }, 100);
          }
        }
      };
      return new Promise((resolve, reject) => {
        axios
          .post(payload.url, payload.data, config)
          .then((response) => {
            resolve(response);

          })
          .catch((error) => {
            console.log('error: ', error);
            dispatch('Errores/evaluar', error);
            resolve(error.response);
          }).finally(() => {
            setTimeout(() => {
              commit('SET_SKILL_STATE', 100);
              commit('SET_IS_LOADING_STATE', false);
            }, 10);
          });
      })
    },

    put: ({ commit, dispatch, getters }, payload) => {
      commit('SET_SKILL_STATE', 0);
      commit('SET_IS_LOADING_STATE', true);
      var params = {
        params: payload.data,
        onUploadProgress: progressEvent => {
          if (progressEvent.total != 0) {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            commit('SET_SKILL_STATE', progress); // Actualiza el progreso
          } else {
            let i = 0;
            const intervalId = setInterval(() => {
              i += 10;
              console.log('i: ', i);
              const skill = i * (100 / 90); // 90 es un número arbitrario para que la simulación llegue al 100% antes de que finalice la petición
              commit('SET_SKILL_STATE', skill);
              if (i >= 80) { // Detener la simulación cuando llegue al 100%
                clearInterval(intervalId);
              }
            }, 100);
          }
        }
      };
      return new Promise((resolve, reject) => {
        axios
          .put(payload.url, payload.data, params)
          .then((response) => {
            resolve(response);

          })
          .catch((error) => {
            console.log('error: ', error);
            dispatch('Errores/evaluar', error);
            resolve(error.response);
          }).finally(() => {
            setTimeout(() => {
              commit('SET_SKILL_STATE', 100);
              commit('SET_IS_LOADING_STATE', false);
            }, 10);
          });
      })
    },

    destroy: ({ commit, dispatch, getters }, payload) => {
      commit('SET_SKILL_STATE', 0);
      commit('SET_IS_LOADING_STATE', true);
      var params = {
        data: payload.data,
        onDownloadProgress: progressEvent => {
          if (progressEvent.total != 0) {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            commit('SET_SKILL_STATE', progress); // Actualiza el progreso
          } else {
            let i = 0;
            const intervalId = setInterval(() => {
              i += 10;
              const skill = i * (100 / 90); // 90 es un número arbitrario para que la simulación llegue al 100% antes de que finalice la petición
              commit('SET_SKILL_STATE', skill);
              if (i >= 80) { // Detener la simulación cuando llegue al 100%
                clearInterval(intervalId);
              }
            }, 100);
          }
        }
      };
      return new Promise((resolve, reject) => {
        axios
          .delete(payload.url, params)
          .then((response) => {
            resolve(response);

          })
          .catch((error) => {
            console.log('error: ', error);
            dispatch('Errores/evaluar', error);
            resolve(error.response);
          }).finally(() => {
            setTimeout(() => {
              commit('SET_SKILL_STATE', 100);
              commit('SET_IS_LOADING_STATE', false);
            }, 10);
          });
      })
    },

    copy: ({ commit, dispatch, getters }, payload) => {
      let testingCodeToCopy = document.querySelector(payload.element)
      testingCodeToCopy.select()
      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        alert('Testing code was copied ' + msg);
      } catch (err) {
        this.$store.dispatch("notificaciones/add", {
          tipo: "error",
          mensaje: "Opps, No se puede copiar"
        });
      }
      /* unselect the range */
      window.getSelection().removeAllRanges()
    },
    mostrarCarga: ({ commit, getters }, payload) => {

      commit('SET_IS_LOADING_STATE', payload)
    }
  },
  getters: {
    getIsLoadingState: state => {
      return state.is_loading
    },
    getCurrentItemState: state => {
      return state.current_item
    },
    getSkill_State: state => {
      return Math.floor(state.skill_state);
    }
  }
})
