<template>
  <div>
    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>

    <v-dialog v-model="dialogTipo" max-width="400" persistent>
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title class="display-1">
            <strong>Editar</strong>
          </v-card-title>
          <v-card-text>
            <v-form dense ref="formTipo" v-model="validTipo" lazy-validation>
              <v-text-field
                label="Nombre del Tipo"
                v-model="tipo.tipo"
                prepend-icon="mdi-cube-send"
                :rules="generalRegla"
              ></v-text-field>
              <v-text-field
                label="Porsentaje empresa"
                v-model="porsentajeEmpresa"
                prepend-icon="mdi-factory"
                suffix="%"
                type="number"
                :rules="porsentajeRegla2"
              ></v-text-field>
              <v-text-field
                label="Porsentaje negocio"
                v-model="tipo.porsentaje_negocio"
                prepend-icon="mdi-store"
                suffix="%"
                type="number"
                :rules="porsentajeRegla"
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogTipo = false">Cancelar</v-btn>
            <v-btn
              :disabled="!validTipo"
              color="primary"
              class="rounded-pill pa-3"
              @click="nuevoTipo()"
            >
              Registrar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditarIngreso" max-width="600" persistent>
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title class="display-1">
            <strong>Editar</strong>
          </v-card-title>
          <v-card-text>
            <v-form
              dense
              ref="form"
              v-model="valid"
              enctype="multipart/form-data"
              lazy-validation
            >
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <v-select
                    v-model="ingreso_negocio.negocio_id"
                    :items="negocios"
                    item-text="nombre"
                    item-value="id"
                    label="Negocio"
                    :rules="generalRegla"
                    prepend-icon="mdi-format-list-bulleted-type"
                    no-data-text="No hay elementos registrados"
                  ></v-select>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    v-model="ingreso_negocio.total"
                    label="Monto total"
                    :rules="generalRegla"
                    type="number"
                    prefix="$"
                    prepend-icon="mdi-cash"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-select
                    v-model="ingreso_negocio.tipo_ingreso_negocio_id"
                    :items="tipos"
                    item-text="tipo"
                    item-value="id"
                    label="Tipo del negocio"
                    :rules="generalRegla"
                    prepend-icon="mdi-format-list-bulleted-type"
                    no-data-text="No hay elementos registrados"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-avatar
                        color="indigo"
                        class="headline font-weight-light white--text"
                      >
                        {{ item.tipo.charAt(0) }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.tipo"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          >Empresa:
                          {{ item.porsentaje_empresa }}%</v-list-item-subtitle
                        >
                        <v-list-item-subtitle
                          >Negocio:
                          {{ item.porsentaje_negocio }}%</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </template>
                    <template v-slot:append-item>
                      <v-divider class="mb-2"></v-divider>
                      <v-list-item @click="dialogTipo = true">
                        <v-list-item-avatar color="grey lighten-3">
                          <v-icon>mdi-plus</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Agregar</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex xs12 md6>
                  <v-file-input
                    :rules="rulesFile"
                    placeholder="2MB"
                    show-size
                    v-model="archivo"
                    hint="Puede ser documento o imagen"
                    prepend-icon="mdi-file-document"
                    small-chips
                    multiple
                    label="Archivo Comprobante"
                  ></v-file-input>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedDateFormatted"
                        label="Dia de revisión"
                        prepend-icon="mdi-calendar"
                        readonly
                        :rules="generalRegla"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="es-mx"
                      v-model="ingreso_negocio.fecha"
                      no-title
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 md6>
                  <v-menu
                    ref="menu"
                    v-model="menu_reloj"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="ingreso_negocio.hora"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="ingreso_negocio.hora"
                        :rules="generalRegla"
                        label="Hora"
                        prepend-icon="mdi-av-timer"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_reloj"
                      v-model="ingreso_negocio.hora"
                      full-width
                      @click:minute="$refs.menu.save(ingreso_negocio.hora)"
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-textarea
                  v-model="ingreso_negocio.detalles"
                  rows="3"
                  filled
                  label="Detalles"
                ></v-textarea>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelar()">Cancelar</v-btn>
            <v-btn
              :disabled="!valid"
              color="primary"
              class="rounded-pill pa-3"
              @click="actualizar()"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      ingreso_negocio: {
        total: "",
        tipo_ingreso_negocio_id: "",
        detalles: "",
        negocio_id: "",
        archivo: null,
        fecha: null,
        hora: null,
      },
      porsentajeEmpresa: "",
      tipo: {
        tipo: "",
        porsentaje_negocio: "",
        porsentaje_empresa: "",
      },
      valid: true,
      archivo: null,
      rulesFile: [
        (value) =>
          !value ||
          value.length == 0 ||
          value[0].size < 2000000 ||
          "Debe ser menor a 2 MB!",
      ],
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      porsentajeRegla: [
        (v) => !!v || "Se necesita el campo",
        (v) => (v >= 0 && v <= 100) || "No puede ser mayor a 100",
        (v) =>
          parseInt(v) + parseInt(this.tipo.porsentaje_empresa) == 100 ||
          "la suma de los porsentajes debe ser igual a 100",
      ],
      porsentajeRegla2: [
        (v) => !!v || "Se necesita el campo",
        (v) => (v >= 0 && v <= 100) || "No puede ser mayor a 100",
      ],
      tipos: [],
      validTipo: true,
      dialogTipo: false,
      negocios: [],
      dialogEditarIngreso: false,
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: new Date().toISOString().substr(0, 10),
      menu2: false,
      menu_reloj: false,
    };
  },
  mounted() {
    this.getDatosInicio();
  },
  watch: {
    porsentajeEmpresa(val) {
      this.tipo.porsentaje_empresa = val;
      this.tipo.porsentaje_negocio = "";
    },
    archivo(val) {
      if (val != null) {
        this.ingreso_negocio.archivo = val[0];
      }
    },
    date(val) {
      this.dateFormatted = this.formatDate(val);
    },
  },
  computed: {
    computedDateFormatted() {
      if (this.ingreso_negocio.fecha) {
        return this.formatDate(this.ingreso_negocio.fecha);
      } else {
        return "";
      }
    },
  },
  methods: {
    esperarDatos(ingresoEditar) {
      this.ingreso_negocio = ingresoEditar;
      this.dialogEditarIngreso = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },

    actualizar() {
      if (this.$refs.form.validate()) {
        this.resolve(this.ingreso_negocio);
        this.dialogEditarIngreso = false;
        return this.ingreso_negocio;
      } else {
        return null;
      }
    },
    limpiar() {
      this.$refs.form.reset();
    },
    cancelar() {
      this.resolve(false);
      this.dialogEditarIngreso = false;
    },
    getDatosInicio() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "datos/registro/ingreso_negocio",
          data: {},
        })
        .then((result) => {
          this.tipos = result.data.tipos;
          this.negocios = result.data.negocios;
        });
    },
    nuevoTipo() {
      if (this.$refs.formTipo.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "tipo_ingreso_negocio";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.tipo,
          })
          .then((result) => {
            this.tipos.push(result.data.tipo);
            this.ingreso_negocio.tipo_ingreso_negocio_id = result.data.tipo.id;
            this.dialogTipo = false;
            this.$refs.formTipo.reset();
          });
      }
    },
  },
};
</script>