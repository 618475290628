e
<template>
  <div>
    <v-dialog v-model="dialogStatus" max-width="350px">
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title>
            Activar o desactivar cliente
          </v-card-title>
          <v-card-text>
            <v-form dense ref="formstatus" v-model="valid" lazy-validation>
              <v-select
                :items="estados"
                v-model="status_id"
                label="Status"
                item-value="id"
                item-text="status"
                :rules="generalRegla"
                prepend-icon="mdi-power-settings"
              ></v-select>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelar()">Cancelar</v-btn>
            <v-btn
              :disabled="!valid"
              @click="actualizarStatus()"
              class="rounded-pill pa-3"
              color="primary"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Common from "@/js/Common";
export default {
  data() {
    return {
      status_id: "",
      dialogStatus: false,
      resolve: null,
      reject: null,
      estados: [],
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      cliente: null,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    cargarDatos(item) {
      this.getEstados();

      this.cliente = item;
      this.status_id = item.status_cliente_id;
      this.dialogStatus = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    getEstados() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `estados/todos`,
          data: {},
        })
        .then((result) => {
          this.estados = result.data.estados;
        });
    },

    actualizarStatus() {
      if (this.$refs.formstatus.validate()) {
        let dispatch = "UsersModule/OnUpdate";
        let endpoint = `cambiar/estado/cliente/` + this.cliente.id;

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: { status_id: this.status_id },
          })
          .then((result) => {
            this.dialogStatus = false;
            this.resolve(result.data.cliente);
          });
      }
    },
    cancelar() {
      this.dialogStatus = false;
      this.resolve(false);
    },
  },
};
</script>