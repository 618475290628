

import Axios from "axios"

export default {
    state: {
        registroPersona: null,
        registroUsuario:null,
        pasoPersonal: 1,
        horario: null,
        datosHorario: null,
        showPersona:null,
        showActividad:null,
        permisos:null,
       
    },
    mutations: {
        mutacionPasoPersonal(state,datos){
            state.pasoPersonal =datos
        },
        mutacionRegistraPersonalPaso1(state,datos){
            state.registroPersona = datos.persona;
            state.registroUsuario = datos.usuario;
        },
        mutacionRegistraPersonalPaso2(state,datos){
            state.horario = datos;
        },
        mutacionRegistraPersonalPaso3(state,datos){
            state.permisos = datos;
            
        },
        mutacionShowPersona(state,datos){
            state.showPersona = datos;
        },
        mutacionShowActividad(state,datos){
            state.showActividad = datos;
        },
        mutacionHorario(state,datos){
            state.datosHorario = datos;
        }


    },
    actions: {
        registroPersonalPaso1({commit},payload){
            commit('mutacionPasoPersonal',payload.paso);
            commit('mutacionRegistraPersonalPaso1', {persona:payload.persona,usuario:payload.usuario});
        },
        registroPersonalPaso2({commit},payload){
            commit('mutacionPasoPersonal',payload.paso);
            commit('mutacionRegistraPersonalPaso2',payload.persona);
        },
        registroPersonalPaso3({commit},payload){
            commit('mutacionPasoPersonal',payload.paso);
            commit('mutacionRegistraPersonalPaso3',payload.datos);
        },
        pasoPersonal({commit},payload){
            commit('mutacionPasoPersonal',payload);
        },
        showPersona({commit},payload){
            commit('mutacionShowPersona',payload);
        },
        showActividad({commit},payload){
            commit('mutacionShowActividad',payload);
            
        },
        datosHorario({commit},payload){
            commit('mutacionHorario',payload);
        }



    },
    getters: {
        getPasoRegistroPersonal(state){
            return state.pasoPersonal;
        },
        getPersonalRegistro(state){
            
            
            let datos = {
                usuario: state.registroUsuario,
                persona: state.registroPersona,
                horario: state.horario,
            }
            return datos;
        },
        getShowPersona(state){
            return state.showPersona;
        },
        getShowActividad(state){
            return state.showActividad;
        },
        getDatosHorario(state){
            return state.datosHorario;
        },
        getPersonalPermisos(state){
            return state.permisos;
        },
        

    },
modules:{
  
}
};
