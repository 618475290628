<template>
    <div class="component mt-4">
        <base-component>
            <div slot="component">
                <v-layout row wrap>
                    <v-flex xs12>
                        
                  
                <v-card class="cool pa-3">
                    <v-card-title class="display-1">
                        <strong>Trabajos</strong> 
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            
                            <v-spacer></v-spacer>

                            <v-col cols="12" md="6">
                        <v-text-field solo class="rounded-pill" v-model="search" @keyup.enter="getDatos" append-icon="mdi-magnify" label="Buscador" single-line hide-details></v-text-field>

                            </v-col>
                        </v-row>
                    </v-card-text>  
                    <v-divider></v-divider>
                    <v-data-table dense :search="search" :headers="headers" :items="elementos" :options.sync="options" :server-items-length="totalElementos" :loading="loading" :footer-props="{
                                showFirstLastPage: true,
                                firstIcon: 'mdi-format-horizontal-align-left',
                                lastIcon: 'mdi-format-horizontal-align-right',
                                prevIcon: 'mdi-chevron-left',
                                nextIcon: 'mdi-chevron-right',
                                itemsPerPageAllText: 'Todos',
                                itemsPerPageText:'Registros por página:',
                                itemsPerPageOptions: [3,5,10,15,-1]
                                }">
                        <template v-slot:no-results>
                                    <v-alert
                                    :value="true"
                                    color="lime lighten-1"
                                    icon="mdi-alert"
                                    >Tu búsqueda "{{ search }}" no se encuentra.</v-alert>
                        </template>
                        <template v-slot:item.id="{ item }">
                           {{ elementos.map(function(x) {return x.id; }).indexOf(item.id)+1}}
                        </template>
                        <template v-slot:item.opciones="{ item }">
									<v-tooltip v-if="item.eliminado == false" top>
										<template v-slot:activator="{ on, attrs }">
											<v-btn small @click="detalles(item)" icon v-on="on" v-bind="attrs">
												<v-icon small>
													mdi-dots-horizontal
												</v-icon>
											</v-btn>
										</template>
										<span>Ver detalles</span>
									</v-tooltip>
								</template>
                        <template v-slot:no-data>
                            <v-alert :value="true" color="red"
                    dark icon="mdi-alert-circle">
                                <v-row align="center">
                                <v-col>
                                    Ningún dato que mostrar :(
                                </v-col>
                                <v-col class="shrink">
                                    <v-btn @click="getDatos()"><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn>
                                </v-col>
                                </v-row>
                            </v-alert>
                        </template>
                    </v-data-table>
                    
                </v-card>
                  </v-flex>
                </v-layout>
                <exportar ref="refExportar"></exportar>
                
            </div>
        </base-component>
    </div>
</template>
<script>
import Common from '@/js/Common'
import exportar from '@/js/components/exportar_tabla/exportarComponent.vue';

export default {
    components:{
        exportar
    },
    data() {
        return {
            search: '',
            totalElementos: 0,
            elementos: [],
            loading: true,
            options: {},
            hoy: new Date().toISOString().substr(0, 10),
            headers: [{ text: '#', align: 'start', value: 'id' },
                { text: 'Trabajo', value: 'trabajo', sortable: true, },
                { text: 'Personal', value: 'personal.FullName', sortable: true, },
                { text: 'Fecha', value: 'fecha',sortable: true },
                { text: 'hora_inicio', value: 'hora_inicio', sortable: true, },
                { text: 'hora_fin', value: 'hora_fin', sortable: false, },
                { text: 'direccion', value: 'direccion', sortable: false, },
                { text: 'materiales', value: 'materiales', sortable: true, },
                { text: 'detalles', value: 'detalles', sortable: true, },
                { text: "Opciones", value: "opciones", sortable: false },

             ],
        };
    },
    watch: {
        options: {
            handler() {
                this.getDatos();
            },
            deep: true,
        },
        estadoPago(val){
            this.getDatos();
        },
        search: {
            handler() {
                this.options.page = 1;
            },
            deep: true,
        },

    },
    mounted() {

    },
    computed: {
        
    },
    methods: {
        getDatos() {
            this.loading = true;
            this.$store.dispatch('UsersModule/OnGet', {
                url: 'actividades/instalador/tabla',
                data: {
                    search: this.search,
                    sort: this.ordenarPor(),
                    page: this.options.page,
                    per_page: this.sonTodos(this.options.itemsPerPage),
                    status: parseInt(this.estadoPago)
                }

            }).then(result => {
                
                this.loading = false;
                this.elementos = result.data.actividades.data
                this.totalElementos = result.data.actividades.total
            
            })
        },
        sonTodos(cantidad) {
            if (cantidad === -1) {
                return this.totalElementos;
            } else {
                return cantidad;
            }
        },
        ordenarPor() {
            if (this.options.sortBy.length === 1 && this.options.sortDesc.length === 1) {
                if (this.options.sortDesc[0]) {
                    return this.options.sortBy[0] + "|desc";
                } else {
                    return this.options.sortBy[0] + "|asc";
                }

            }
            return "id|desc"
        },
        exportar(){
            if(this.elementos.length >0){
                this.$refs.refExportar.cargarDatos("actividades/personal/"+this.elementos[0].personal_asignado_id);
            }
        },
        detalles(item) {
			document.location.href = "vista/detalles/actividad/" + item.id;
		},
    }

};
</script>