class Cliente {
    constructor() {
        this.nombre = "";
        this.apellido_paterno = "";
        this.apellido_materno = "";
        this.direccion = "";
        this.estado = "";
        this.municipio = "";
        this.colonia = "";
        this.longitud = "";
        this.dias_gracia = "",
            this.latitud = "";
        this.telefono = "";
        this.correo = "";
        this.celular = "";
        this.codigo_postal = "";
        this.dia_pago = 1;
        this.dia_factura = 1,
            this.fecha_instalacion = "";
        this.hora_instalacion = null;
        this.costo_instalacion = "";
        this.detalles = "";
        this.zona_id = "";
        this.antena_id = "";
        this.empresa_id = "";
        this.datos_fiscales_id = "";
        this.recepcionista_id = "";
        this.instalador_id = "";
        this.captacion_id = "";
        this.status_cliente_id = "";
        this.tipo_cliente_id = "";
        this.costo_por_cliente = "";
        this.razon_social = "";
        this.tipo_persona = "";
        this.tipo_identificacion = "";
        this.RFC_RUC_NIT = "";
        this.direccion_facturacion = "";
        this.email_facturacion = "";
        this.retenciones = "";
        this.inmunidad = "";
        this.historial = true;
        this.monitoreo_ping = false;
        this.monitoreo_consumo = true;
        this.contrato = null;
        this.imagenes = [];
        this.ayudantes = [];
    }
}

export default Cliente