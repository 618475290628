<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex xs12>
            <v-card class="cool pa-4">
              <v-card-title class="display-1" primary-title>
                <strong>Provedores</strong>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-btn
                      @click="nuevo()"
                      class="rounded-pill"
                      color="red"
                      dark
                    >
                      <v-icon left>mdi-plus</v-icon>
                      Nuevo
                    </v-btn>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      v-model="search"
                      @keyup.enter="getDatos"
                      append-icon="mdi-magnify"
                      label="Buscador"
                      single-line
                      solo
                      class="rounded-pill"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-data-table
                dense
                :search="search"
                :headers="headers"
                :items="elementos"
                :options.sync="options"
                :server-items-length="totalElementos"
                :loading="loading"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-format-horizontal-align-left',
                  lastIcon: 'mdi-format-horizontal-align-right',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right',
                  itemsPerPageAllText: 'Todos',
                  itemsPerPageText: 'Registros por página:',
                  itemsPerPageOptions: [15, 50, 100, 500, -1],
                }"
              >
                <template v-slot:item.id="{ item }">
                  {{
                    elementos
                      .map(function (x) {
                        return x.id;
                      })
                      .indexOf(item.id) + 1
                  }}
                </template>
                <template v-slot:item.contacto="{ item }">
                  <v-chip
                    v-if="item.celular"
                    class="ma-1"
                    outlined
                    small
                    dark
                    color="green darken-4"
                    @click="enviarWhats(item.celular)"
                  >
                    <v-icon left> mdi-whatsapp </v-icon>{{ item.celular }}
                  </v-chip>
                  <v-chip
                    v-if="item.telefono"
                    class="ma-1"
                    outlined
                    small
                    dark
                    color="blue darken-4"
                    @click="llamar(item)"
                  >
                    <v-icon left> mdi-phone </v-icon>{{ item.telefono }}
                  </v-chip>
                  <v-chip
                    v-if="item.correo"
                    class="ma-1"
                    outlined
                    small
                    dark
                    color="deep-orange darken-4"
                    @click="enviarCorreo(item)"
                  >
                    <v-icon left> mdi-gmail </v-icon>{{ item.correo }}
                  </v-chip>
                </template>
                <template v-slot:item.opciones="{ item }">
                  <v-tooltip v-if="item.eliminado == false" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        @click="detalles(item)"
                        icon
                        v-on="on"
                        v-bind="attrs"
                      >
                        <v-icon small> mdi-dots-horizontal </v-icon>
                      </v-btn>
                    </template>
                    <span>Detalles</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="editarProveedor(item)" icon v-on="on">
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar Proveedor</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="eliminarProveedor(item)" icon v-on="on">
                        <v-icon small> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span>Desactivar Proveedor</span>
                  </v-tooltip>
                </template>
                <template v-slot:no-data>
                  <v-alert
                    :value="true"
                    color="red"
                    dark
                    icon="mdi-alert-circle"
                  >
                    <v-row align="center">
                      <v-col> Ningún dato que mostrar :( </v-col>
                      <v-col class="shrink">
                        <v-btn @click="getDatos()"
                          ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-alert>
                </template>
                <template v-slot:no-results>
                  <v-alert :value="true" color="lime lighten-1" icon="mdi-alert"
                    >Tu búsqueda "{{ search }}" no se encuentra.</v-alert
                  >
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
        <formEditarProveedor ref="refEditarProveedor"></formEditarProveedor>
        <formProveedor ref="refProveedor"></formProveedor>
        <mensajeWhats ref="refWhats"></mensajeWhats>
      </div>
    </base-component>
  </div>
</template>

<script>
import formProveedor from "@/js/components/almacen/proveedores/nuevoProveedor.vue";
import formEditarProveedor from "@/js/components/almacen/proveedores/editarProveedor.vue";
import mensajeWhats from "@/js/components/clientes/listar_clientes/mensajeWhatsapp.vue";

export default {
  components: {
    formProveedor,
    formEditarProveedor,
    mensajeWhats,
  },
  data() {
    return {
      search: "",
      totalElementos: 0,
      elementos: [],
      loading: true,
      options: {},
      headers: [
        { text: "#", align: "start", value: "id" },
        { text: "Nombre", value: "nombre", sortable: true },
        { text: "Direccion", value: "direccion", sortable: false },
        { text: "Contacto", value: "contacto", sortable: false },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      ServicioActualizar: {},
      dialogProveedor: false,
      categorias: [],
    };
  },
  mounted() {},
  watch: {
    options: {
      handler() {
        this.getDatos();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `proveedores/tabla`,
          data: {
            search: this.search,
            sort: this.ordenarPor(),
            page: this.options.page,
            per_page: this.sonTodos(this.options.itemsPerPage),
          },
        })
        .then((result) => {
          this.loading = false;
          this.elementos = result.data.proveedores.data;
          this.totalElementos = result.data.proveedores.total;
        });
    },
    nuevo() {
      this.$refs.refProveedor.mostrar().then((resultado) => {
        if (resultado) {
          this.elementos.push(resultado);
        }
      });
    },
    sonTodos(cantidad) {
      if (cantidad === -1) {
        return this.totalElementos;
      } else {
        return cantidad;
      }
    },
    ordenarPor() {
      if (
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        if (this.options.sortDesc[0]) {
          return this.options.sortBy[0] + "|desc";
        } else {
          return this.options.sortBy[0] + "|asc";
        }
      }
      return "id|desc";
    },
    eliminarProveedor(elemento) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `proveedor/` + elemento.id;
            let event = "delete";

            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: elemento,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(elemento), 1);
              });
          }
        });
    },

    editarProveedor(elemento) {
      this.proveedorActualizar = elemento;
      const proveedor = Object.assign({}, elemento);
      this.$refs.refEditarProveedor.esperarDatos(proveedor).then((confirm) => {
        if (confirm) {
          this.elementos.splice(
            this.elementos.indexOf(this.proveedorActualizar),
            1,
            confirm
          );
        }
      });
    },
    enviarCorreo(item) {
      if (item.correo) {
        var destinatario = item.correo;
        var asunto = "Mercancia";
        var cuerpo = "Buen dia";
        var link =
          "mailto:" +
          destinatario +
          "?subject=" +
          encodeURIComponent(asunto) +
          "&body=" +
          encodeURIComponent(cuerpo);
        window.open(link, "_blank");
      }
    },
    enviarWhats(item) {
      this.$refs.refWhats.cargarDatos(item);
    },
    llamar(item) {
      document.location.href = "tel:" + item.telefono;
    },
    detalles(item) {
      document.location.href = "detalle/proveedor/vista/" + item.id;
    },
  },
};
</script>
