<template>
  <div v-if="antena">
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12 md4>
          <v-card class="cool">
            <v-card-title primary-title> Access Point </v-card-title>
            <v-card-subtitle>
              <div>Estaciones conectadas: {{ antena.estacionesCantidad }}</div>
              IP: {{ antena.ip }}
            </v-card-subtitle>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-avatar tile size="90" class="cool">
                        <img
                          v-if="antena.cpe.imagen"
                          :src="antena.cpe.imagen"
                        />
                        <img v-else src="/img/user_default.png" />
                      </v-list-item-avatar>
                      <v-list-item-title class="headline-2"
                        >Antena: {{ antena.cpe.producto }}</v-list-item-title
                      >
                      <v-list-item-subtitle
                        ><p>
                          Marca: {{ antena.cpe.marca }}
                        </p></v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="antena.mikrotik" three-line>
                    <v-list-item-avatar
                      color="indigo"
                      tile
                      size="50"
                      class="cool"
                    >
                      <img
                        v-if="antena.mikrotik.producto.imagen"
                        :src="antena.mikrotik.producto.imagen"
                      />
                      <img v-else src="/img/noimagen.jpg" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="headline-2"
                        >Mikrotik:</v-list-item-title
                      >
                      <v-list-item-title class="headline-2">{{
                        antena.mikrotik.nombre
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        ><p>
                          Marca: {{ antena.mikrotik.producto.producto }}
                        </p></v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item-subtitle class="headline-2"
                    >Zona: {{ antena.zona.nombre }}</v-list-item-subtitle
                  >
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 md8>
          <v-container grid-list-md>
            <v-responsive
              id="scroll-target"
              class="overflow-y-auto"
              :max-height="580"
            >
              <div
                v-for="estacion in antena.estaciones"
                :key="estacion.id"
                class="cool"
              >
                <v-card class="cool ma-2">
                  <v-card-title primary-title>
                    Estación
                    <v-spacer></v-spacer>
                    <v-chip
                      dark
                      small
                      outlined
                      :color="estacion.status_antena_id ? 'green' : 'orange'"
                      >{{
                        estacion.status_antena_id ? "Conectado" : "desconectado"
                      }}</v-chip
                    >
                  </v-card-title>
                  <v-card-text>
                    <v-list-item three-line>
                      <v-list-item-avatar tile size="100" class="cool">
                        <img
                          v-if="estacion.producto.imagen"
                          :src="estacion.producto.imagen"
                        />
                        <img v-else src="/img/noimagen.png" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-if="estacion.cliente"
                          class="headline-2"
                          >Cliente:
                          {{ estacion.cliente.FullName }}</v-list-item-title
                        >
                        <v-list-item-title class="headline-2"
                          >IP: {{ estacion.ip }}</v-list-item-title
                        >
                        <v-list-item-title
                          v-if="estacion.ping"
                          class="headline-2"
                          >ping: {{ estacion.ping.ping }}</v-list-item-title
                        >
                        <v-list-item-title class="headline-2"
                          >Antena:
                          {{ estacion.producto.producto }}</v-list-item-title
                        >
                        <v-list-item-subtitle
                          ><p>
                            Marca: {{ estacion.producto.marca }}
                          </p></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>
                </v-card>
              </div>
            </v-responsive>
          </v-container>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      antena: null,
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    var id = arreUrl.pop();
    this.getDatos(id);
  },

  computed: {},
  methods: {
    getDatos(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `antena/` + id,
          data: {},
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            var antena = result.data.antena;
            if (antena.ap == null) {
              this.antena = antena;
            } else {
              this.antena = antena.ap;
            }
          }
        });
    },

    abrirIp(ip) {
      window.open("https://" + ip, "_blank");
    },
    abrirZona(id) {
      document.location.href = "/detalles/zona/" + id;
    },
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>