var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseConfirmacion',{ref:"confirmar"}),_vm._v(" "),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"cool pa-4",attrs:{"elevation":"4"}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v("\n          Secrets\n          "),_c('v-spacer'),_vm._v(" "),_c('v-text-field',{staticClass:"rounded-pill",attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","solo":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDatos($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('v-data-table',{attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.elementos,"options":_vm.options,"server-items-length":_vm.totalElementos,"loading":_vm.loading,"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-format-horizontal-align-left',
            lastIcon: 'mdi-format-horizontal-align-right',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            itemsPerPageAllText: 'Todos',
            itemsPerPageText: 'Registros por página:',
            itemsPerPageOptions: [5, 10, 100, -1],
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.ip",fn:function(ref){
          var item = ref.item;
return [(item.ip)?_c('v-btn',{attrs:{"text":"","small":"","dark":"","color":"blue"},on:{"click":function($event){return _vm.abrirIp(item.ip)}}},[_vm._v(_vm._s(item.ip))]):_c('v-btn',{attrs:{"text":"","small":"","dark":"","color":"blue"}},[_vm._v("Pendiente")])]}},{key:"item.perfil",fn:function(ref){
          var item = ref.item;
return [(item.perfil)?_c('v-chip',{attrs:{"outlined":"","small":"","dark":"","text":"","color":"teal"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-card-details")]),_vm._v("\n              "+_vm._s(item.perfil.name))],1):_c('v-chip',{attrs:{"outlined":"","text":"","small":"","dark":"","color":"black"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-card-details")]),_vm._v("\n\n              No encontrado")],1)]}},{key:"item.cliente",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","outlined":"","dark":"","text":"","color":"primary"},on:{"click":function($event){return _vm.abrirCliente(item.clienteId)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_vm._v("\n              "+_vm._s(item.nombreCliente))],1)]}},{key:"item.opciones",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.irAntena(item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-access-point-network")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Ver antena")])])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }