<template>
  <div>
    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12 md12>
          <v-card v-if="persona" class="cool">
            <v-list-item three-line>
              <v-list-item-avatar color="indigo" tile size="160">
                <img v-if="persona.imagen" :src="persona.imagen" />
                <img v-else src="/img/user_default.png" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="headline mb-4"
                  >{{ persona.nombre }} {{ persona.apellido_paterno }}
                  {{ persona.apellido_materno }}</v-list-item-title
                >
                <v-list-item-subtitle
                  >{{ persona.direccion }}, {{ persona.colonia }},
                  {{ persona.municipio }},{{ persona.cp }},
                  {{ persona.estado }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  >Celular: {{ persona.celular }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  >Correo: {{ persona.user.email }}</v-list-item-subtitle
                >

                <v-list-item-subtitle v-if="persona.periodo"
                  >Periodo de pago:
                  <v-chip class="ma-2" small color="primary">
                    {{ persona.periodo.periodo }}
                  </v-chip>
                  Cargo:
                  <v-chip class="ma-2" small color="secondary">
                    {{ persona.tipo.name }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4">
                  Fecha de nacimiento
                  <v-chip class="ma-2" color="accent">
                    {{ persona.fecha_nacimiento }}
                    <v-icon>mdi-cake-variant</v-icon>
                  </v-chip>
                  Sueldo:
                  <v-chip class="ma-2" color="orange" text-color="white">
                    ${{ persona.sueldo }}
                    <v-icon right>mdi-star</v-icon>
                  </v-chip>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <v-data-table
            :headers="headers"
            :items="elementos"
            :items-per-page="5"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Cuentas bancarias</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn
                  class="rounded-pill pa-3"
                  dark
                  color="red mb-2"
                  @click="dialog = true"
                >
                  Nueva cuenta
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.id="{ item }">
              {{
                persona.cuentas
                  .map(function (x) {
                    return x.id;
                  })
                  .indexOf(item.id) + 1
              }}
            </template>
            <template v-slot:item.opciones="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="editar(item)" icon v-on="on">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="eliminar(item)" icon v-on="on">
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="red" dark icon="mdi-alert-circle">
                <v-row align="center">
                  <v-col> Ningún dato que mostrar :( </v-col>
                  <v-col class="shrink">
                    <v-btn @click="getDatos()"
                      ><v-icon pl-4>mdi-cached</v-icon> Refrescar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="dialog" max-width="500px" transition="dialog-transition">
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title> Cuentas bancarias </v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation dense>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <v-text-field
                    label="Banco"
                    v-model="cuenta.banco"
                    :rules="generalRegla"
                    prepend-icon="mdi-bank"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    label="Titular"
                    v-model="cuenta.titular"
                    :rules="generalRegla"
                    prepend-icon="mdi-account"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    label="Número de cuenta"
                    v-model="cuenta.numero_cuenta"
                    :rules="generalRegla"
                    prepend-icon="mdi-account-card-details"
                    type="number"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    label="Transferencia bancaria"
                    v-model="cuenta.transferencia"
                    prepend-icon="mdi-transfer"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false">Cancelar</v-btn>
            <v-btn
              color="primary"
              class="rounded-pill pa-3"
              @click="enviarDatos()"
              :disabled="!valid"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      headers: [
        { text: "#", align: "start", sortable: true, value: "id" },
        { text: "Banco", align: "start", sortable: true, value: "banco" },
        { text: "Titular", align: "start", sortable: true, value: "titular" },
        {
          text: "Numero de cuenta",
          align: "start",
          sortable: true,
          value: "numero_cuenta",
        },
        {
          text: "Clave intervancaria",
          align: "start",
          sortable: true,
          value: "transferencia",
        },
        { text: "Opciones", align: "start", sortable: true, value: "opciones" },
      ],
      cuenta: {
        id: 0,
        banco: "",
        numero_cuenta: "",
        transferencia: "",
        titular: "",
      },
      generalRegla: [(v) => !!v || "Se necesita el sueldo"],
      dialog: false,
      id_persona: null,
      valid: true,
      elementos: [],
    };
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.id_persona = arreUrl.pop();
    this.getDatos();
  },
  computed: {
    persona() {
      return this.$store.getters.getShowPersona;
    },
  },
  methods: {
    getDatos() {
      this.loading = true;
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `cuentas/persona/tabla/` + this.id_persona,
          data: {},
        })
        .then((result) => {
          this.elementos = result.data.cuentas;
        });
    },
    eliminar(elemento) {
      this.$refs.confirmar
        .open("Confirmación", "Esta seguro de eliminar este elemento?", {
          color: "warninig",
        })
        .then((confirm) => {
          if (confirm) {
            let dispatch = "UsersModule/OnDelete";
            let endpoint = `cuenta/` + elemento.id;
            this.$store
              .dispatch(dispatch, {
                url: endpoint,
                data: elemento,
              })
              .then((result) => {
                this.elementos.splice(this.elementos.indexOf(elemento), 1);
              });
          } else {
          }
        });
    },
    enviarDatos() {
      if (this.$refs.form.validate()) {
        if (this.cuenta.id == 0) {
          this.nuevo();
        } else {
          this.actualizar();
        }
      }
    },
    nuevo() {
      let dispatch = "UsersModule/OnSave";
      let endpoint = "cuenta";
      this.cuenta.persona_id = this.id_persona;
      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: this.cuenta,
        })
        .then((result) => {
          this.elementos.push(result.data.cuenta);
          this.totalElementos = this.elementos.length;
          this.dialog = false;
          this.cuenta.id = 0;
          this.cuenta.banco = "";
          this.cuenta.numero_cuenta = "";
          this.cuenta.transferencia = "";
          this.cuenta.titular = "";
        });
    },
    editar(elemento) {
      this.CuentaActualizar = elemento;
      let cuenta = Object.assign({}, elemento);
      this.cuenta = cuenta;
      this.dialog = true;
    },
    actualizar() {
      let dispatch = "UsersModule/OnUpdate";
      let endpoint = `cuenta/` + this.cuenta.id;
      this.cuenta.persona_id = this.id_persona;
      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: this.cuenta,
        })
        .then((result) => {
          this.elementos.splice(
            this.elementos.indexOf(this.CuentaActualizar),
            1,
            result.data.cuenta
          );
          this.cuenta.id = 0;
          this.cuenta.banco = "";
          this.cuenta.numero_cuenta = "";
          this.cuenta.transferencia = "";
          this.cuenta.titular = "";
          this.dialog = false;
        });
    },
  },
};
</script>