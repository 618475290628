<template>
    <div class="component">
        <base-component>
            <div slot="component">
                <v-layout row wrap>
                    <v-flex xs12 md12>
                        <v-card class="cool pa-4">
                       <div v-if="showChart">
                          <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
                        </div>     
                       
                        </v-card>

                    </v-flex>
                </v-layout>
            </div>
        </base-component>
    </div>
</template>
<script>
import apexchart from 'vue-apexcharts';

export default {
    components: {
          apexchart
    },
    data() {
        return {
         showChart: false,
      series: [{
        name: 'Ping (MS)',
        data: []
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Ping',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          type: 'datetime',
          categories: []
     //     categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']

        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          }
        }
      }
    }
    },
    mounted() {
        this.showChart = true

    },
    computed: {
        
    },
    methods: {
      graficar(datos){
        let pings = [];
           for (let index = 0; index < datos.length; index++) {
                let dato = datos[index];
                
                  
                pings.push(parseInt(dato.ping));
                var date = new Date(dato.fecha+" "+dato.hora_completa);
                var fecha = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
              
                this.chartOptions.xaxis.categories.push(fecha);

          }
          pings
          
          this.series=[{
                name: 'Ping (MS)',
                data: pings,
            },];
      },
    }

};
</script>