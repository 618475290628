<template>
	<div class="component">
		<base-component>
			<div slot="component">
				<form ref="form" @submit.prevent="handleSubmit">
					<div class="form-row">
						<div class="form-group col-md-4">
							<label for="nombre">Nombre(s)</label>
							<input v-model="usuario.nombre" required maxlength="254" type="text" class="form-control" id="nombre" name="nombre" placeholder="Nombre(s)" />
						</div>
						<div class="form-group col-md-4">
							<label for="paterno">Apellido Paterno</label>
							<input v-model="usuario.apellido_paterno" maxlength="254" type="text" id="paterno" name="paterno" class="form-control" placeholder="Apellido Paterno" />
						</div>
						<div class="form-group col-md-4">
							<label for="materno">Apellido Materno</label>
							<input v-model="usuario.apellido_materno" maxlength="254" type="text" id="materno" name="materno" class="form-control" placeholder="Apellido Materno" />
						</div>
					</div>

					<div class="form-group">
						<label for="direccion">Dirección (Calle, Numero, Colonia)</label>
						<input v-model="usuario.direccion" required maxlength="254" type="text" class="form-control" id="direccion" name="direccion" placeholder="Calle, Numero y Colonia" />
					</div>

					<div class="form-row">
						<div class="form-group col-md-6">
							<label for="municipio">Municipio</label>
							<input v-model="usuario.municipio" required maxlength="254" type="text" class="form-control" id="municipio" name="municipio" placeholder="Municipio" />
						</div>
						<div class="form-group col-md-4">
							<label for="edo">Estado</label>
							<select v-model="usuario.estado_id" required id="edo" class="form-control" name="edo">
								<option value="" selected disabled>Selecciona...</option>
								<option v-for="(item, index) in estados" :key="index" :value="item.id">{{ item.nombre }}</option>
							</select>
						</div>
						<div class="form-group col-md-2">
							<label for="cp">C.P.</label>
							<input v-model="usuario.cp" required maxlength="10" @keypress="validateNumber($event, [46])" type="text" class="form-control" id="cp" name="cp" placeholder="CP" />
						</div>
					</div>
					<div class="form-row">
						<div class="form-group col-md-4">
							<label for="tel">Teléfono</label>
							<input v-model="usuario.celular" required maxlength="11" @keypress="validateNumber($event, [46])" type="text" class="form-control" id="tel" name="tel" placeholder="Teléfono" />
						</div>
						<div class="form-group col-md-4">
							<label for="correo">Email</label>
							<input v-model="usuario.email" required maxlength="254" ref="correo" type="email" class="form-control" id="correo" name="correo" placeholder="Email" />
						</div>
						<div class="form-group col-md-4">
							<label for="concorreo">Confirmar Email</label>
							<input v-model="confirmar_correo" required maxlength="254" type="email" class="form-control" id="concorreo" name="concorreo" placeholder="Confirmar Email" />
						</div>
					</div>
					<div class="form-row">
						<div class="form-group col-md-6">
							<label for="password">Contraseña</label>
							<input v-model="usuario.password" :required="usuario.id == 0" maxlength="254" type="password" class="form-control" id="password" placeholder="Contraseña" name="password" />
						</div>
						<div class="form-group col-md-6">
							<label for="rol">Tipo de Usuario</label>
							<select v-model="usuario.role_id" v-show="modulo == 'clientes'" required id="rol" class="form-control" name="rol">
								<option value="" selected disabled>Selecciona...</option>
								<option v-for="(item, index) in roles" v-show="item.name == 'CLIENTE'" :key="index" :value="item.id">{{ item.name }}</option>
							</select>
							<select v-model="usuario.role_id" v-show="modulo == 'usuarios'" required id="rol-usuarios" class="form-control" name="rol">
								<option value="" selected disabled>Selecciona...</option>
								<option v-for="(item, index) in roles" :key="index" :value="item.id">{{ item.name }}</option>
							</select>
						</div>
					</div>

					<button @click="handleSignup" type="submit" class="btn btn-primary px-4 btn-block">Guardar Datos <i class="icon-plus"></i></button>
				</form>
			</div>
		</base-component>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import Autocomplete from 'vuejs-auto-complete';
import Usuario from './Usuario';
import Common from '../../Common';

export default {
	props: {
		id: {
			type: String,
			default: '',
		},
		modulo: {
			type: String,
			default: 'usuarios',
		},
	},
	mounted() {
		this.$store.dispatch('UsersModule/OnGet', {
			url: 'usuarios/catalogos/data',
			data: {},
		}).then((result) => {
			this.roles = result.data.roles;
			this.estados = result.data.estados;
		});
	},
	components: {},
	data: function () {
		return {
			usuario: new Usuario(),
			confirmar_correo: '',
			roles: [],
			estados: [],
		};
	},
	computed: {},
	methods: {
		validateNumber: Common.validateNumber,
		handleSubmit(e) { },
		handleSignup() {
			if (this.$refs.form.checkValidity()) {
				if (this.usuario.email != this.confirmar_correo) {
					swal('Oops, algo salió mal', `El Email debe de coincidir`, 'error');
					this.$refs.porcentaje.focus();
					return false;
				}

				let dispatch = 'UsersModule/OnSave';
				let endpoint = 'usuarios';
				let event = 'insert';

				if (this.usuario.id > 0) {
					dispatch = 'UsersModule/OnUpdate';
					endpoint = `usuarios/${this.usuario.id}`;
					event = 'update';
				}

				this.$store.dispatch(dispatch, {
					url: endpoint,
					data: this.usuario,
				}).then((result) => {
					if (!result.data.data.id) {
						swal('Oops, algo salió mal', result.data.data.display_name, 'error');
					} else {
						this.onSaveOrUpdateCallback(result, event);
					}
				});
			} else {
				this.$refs.form.reportValidity();
			}
		},
		onSaveOrUpdateCallback(result, event) {
			if (![200, 201].includes(result.status)) {
				if (result.data.hasOwnProperty('errors')) {
					if (result.data.errors.hasOwnProperty('name')) {
						this.errors.name = true;
					}

					if (result.data.errors.hasOwnProperty('description')) {
						this.data.errors.name = true;
					}
					return false;
				}
				swal('Oops, algo salió mal', `Código: [${result.status}] ${result.statusText}`, 'error');
				return false;
			}
			this.$emit('onSavedOrUpdated', event);
		},
		setCurrentItem(item) {
			if (item) {
				this.usuario = item;
				this.confirmar_correo = item.email;
			} else {
				this.usuario = new Usuario(this.modulo, this.id);
				this.confirmar_correo = '';
			}
		},
	},
};
</script>
