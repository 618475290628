<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
          <v-card class="elevation-0">
                  <v-card-text>
                <v-form dense ref="formRouter" v-model="valid" lazy-validation>
                    <p class="text-subtitle-2"><strong>Router principal</strong></p>
                  <v-layout row wrap>
                    <v-flex xs12 md6>
                      <v-text-field
                        v-model="router.nombre"
                        label="Alias o nombre del router"
                        :rules="generalRegla"
                        hint="Ejemplo: nodo principal, administrador, etc."
                        filled
                        prepend-inner-icon="mdi-router-wireless"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        v-model="zona.nombre"
                        label="Nombre de la zona principal"
                        :rules="generalRegla"
                        hint="Ejemplo: principal, matriz, centro. o puede ser el nombre de su localidad"
                        required
                        filled
                        prepend-inner-icon="mdi-access-point-network"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-card outlined>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                        <v-radio-group row horizontal v-model="tipoConexion">
                                <template v-slot:label>
                                <div><strong>Tipo de conexión.</strong></div>
                                </template>
                                <v-radio color="primary" value=1>
                                    <template v-slot:label>
                                    <div><strong class="primary--text">IP publica o DDNS</strong></div>
                                    </template>
                                </v-radio>
                                <v-radio color="success" value=2>
                                    <template v-slot:label>
                                    <div><strong class="success--text">Script </strong>(Tunel VPN no se necesita ip  publica)</div>
                                    </template>  
                                </v-radio>
                                <v-radio color="secondary" value=3>
                                    <template v-slot:label>
                                    <div><strong class="secondary--text">Ninguno </strong>(Solo registro)</div>
                                    </template>
                                </v-radio>
                    </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-layout v-if="tipoConexion == 1" row wrap class="my-4">
                  <v-flex xs12 class="my-4"> <strong>Datos de router administrador</strong>  </v-flex>
                    <v-flex xs12 md3>
                      <v-text-field
                        label="IP Publica"
                        v-model="router.ip"
                        :rules="ipRegla"
                        filled
                        prepend-inner-icon="mdi-ip"
                        hint="Puede ser una url de algun servicio DDNS como no-ip, ip claud, Duckdns, etc."
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-text-field
                        label="Usuario"
                        v-model="router.usuario"
                        filled
                        prepend-inner-icon="mdi-account-circle"
                        :rules="generalRegla"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-text-field
                        label="Contraseña"
                        v-model="router.contrasena"
                        :rules="generalRegla"
                        autocomplete="new-password"
                        filled
                        :type="show ? 'text' : 'password'"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        prepend-inner-icon="mdi-textbox-password"
                        @click:append="show = !show"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-text-field
                        label="Puerto de la api"
                        v-model="router.api"
                        :rules="apiRegla"
                        type="number"
                        filled
                        prepend-inner-icon="mdi-serial-port"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="tipoConexion == 3" row wrap class="mt-4">
                    <v-flex xs12 md3>
                      <v-text-field
                        label="IP privada"
                        v-model="router.ip"
                        :rules="ipPrivadaRegla"
                        filled
                        prepend-inner-icon="mdi-ip"
                        hint="Ip privada del rango 192.168.x.x o 172.16.x.x o 10.x.x.x"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                    </v-card-text>
                  </v-card>
                </v-form>
                  </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn text @click="regresar()">Regresar</v-btn>
                    <v-btn :disabled="!valid" class="rounded-pill pa-3" color="primary" @click="siguiente()"
                      >Siguiente</v-btn>
                </v-card-actions>
                </v-card>
      </div>
    </base-component>
  </div>
</template>
<script>

export default {
  data() {
    return {
      zona: {
        nombre: "",
        rango_ip: "SIN RANGO",
        latitud: "",
        longitud: "",
      },
      router: {
        ip: "",
        nombre: "",
        contrasena: "",
        usuario: "",
        contrasena_snmp: "",
        tiempo_escaneo: "",
        comunidad_snmp_id: "",
        api: "",
        zona_id: "",
        version_snmp_id: "",
        producto_id: "",
        reglas: false,
        tipo_conexion: "1",
      },
      valid: true,
      mostrar: false,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      ipRegla: [
        (v) => !!v || "Se necesita el campo",
        (v) => this.quitaPuntos(v) || "Sin el puerto",
      ],
      ipPrivadaRegla: [v => /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(v) || "Ip no valida. Ejemplo: 192.168.1.10",],

      apiRegla: [
        (v) => !!v || "Se necesita el campo",
        (v) => parseInt(v) > 1023 || "Debe ser mayor a 1204",
        (v) => parseInt(v) < 49151 || "Debe ser menor a 49151",
        (v) =>
          parseInt(v) != 10022 || "cambia el puerto a uno diferente de 10022",
        (v) =>
          parseInt(v) != 10080 || "cambia el puerto a uno diferente de 10080",
        (v) =>
          parseInt(v) != 18291 || "cambia el puerto a uno diferente de 180291",
      ],
      show: false,
      tipoConexion: "1",
      script: "",
      dialogScrpt: false,
      routerRegistrado:null,
      dialogScript:false,
    };
  },
  mounted() {
    
  },
  watch: {
    tipoConexion(val) {
      this.router.tipo_conexion = val;
      if (parseInt(val) == 2) {
        this.router.ip = "192.168.1.1";
        this.router.usuario = "admin";
        this.router.contrasena = "password";
        this.router.api = "8728";
      } else if (parseInt(val) == 3) {
        this.router.api = "8728";
      } else {
        this.router.usuario = "";
        this.router.contrasena = "";
        this.router.api = "";
      }
    },
    

  },
  methods: {
    quitaPuntos(ip) {
      if (ip == null) {
        return true;
      }
      for (let index = 0; index < ip.length; index++) {
        if (ip[index] == ":") {
          return false;
        }
      }
      return true;
    },
    
    regresar() {
      this.$store.dispatch("asignaE1", 1);
    },
    siguiente(){
    if (this.$refs.formRouter.validate()) {

      this.$store.dispatch("datosPaso2", {
          step: 3,
          zona: this.zona,
          router: this.router,
        });
    }
    },
    
  },
};
</script>
