<template>
  <div class="component mt-4">
    <base-component>
      <div slot="component">
        <v-layout row wrap>
          <v-flex v-if="mostrar" xs12 md12>
            <v-card class="cool">
              <v-card-title primary-title>
                <strong>Detalles cliente</strong>
              </v-card-title>
              <v-tabs v-model="tab" color="red" grow show-arrows>
                <v-tab>
                  <v-icon left>mdi-account</v-icon>
                  Datos
                </v-tab>
                <v-tab>
                  <v-icon left>mdi-access-point-network</v-icon>
                  Red</v-tab
                >
                <v-tab>
                  <v-icon left>mdi-cash-multiple</v-icon>
                  Pagos</v-tab
                >
                <v-tab>
                  <v-icon left>mdi-server</v-icon>
                  Servicios</v-tab
                >
                <v-tab>
                  <v-icon left>mdi-note-text</v-icon>
                  Tickets</v-tab
                >
                <v-tab v-if="cliente.correo">
                  <v-icon left>mdi-bell</v-icon>
                  Notificaciones</v-tab
                >

                <v-tab v-if="cliente.historial">
                  <v-icon left>mdi-history</v-icon>
                  Historial</v-tab
                >
              </v-tabs>
              <v-card-text>
                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <cliente ref="cliente"></cliente>
                  </v-tab-item>
                  <v-tab-item>
                    <antena ref="antena"></antena>
                  </v-tab-item>
                  <v-tab-item>
                    <pagos></pagos>
                  </v-tab-item>
                  <v-tab-item>
                    <servicios></servicios>
                  </v-tab-item>
                  <v-tab-item>
                    <tickets></tickets>
                  </v-tab-item>
                  <v-tab-item v-if="cliente.correo">
                    <notificaciones></notificaciones>
                  </v-tab-item>

                  <v-tab-item v-if="cliente.historial">
                    <historial></historial>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </base-component>
  </div>
</template>
<script>
import Common from "@/js/Common";
import cliente from "@/js/components/clientes/detalles/cliente/clienteVista.vue";
import antena from "@/js/components/clientes/detalles/antena/antenaVista.vue";
import pagos from "@/js/components/clientes/detalles/pagos/pagosCliente.vue";
import servicios from "@/js/components/clientes/detalles/servicios/serviciosCliente.vue";
import tickets from "@/js/components/clientes/detalles/tickets/ticketsCliente.vue";
import notificaciones from "@/js/components/clientes/detalles/notificaciones/notificacionesCliente.vue";
import historial from "@/js/components/clientes/detalles/historial/historial.vue";
import LocalStorage from "@/js/LocalStorage.js";
export default {
  components: {
    cliente,
    antena,
    pagos,
    servicios,
    tickets,
    notificaciones,
    historial,
  },
  data() {
    return {
      tab: 0,
      mostrar: false,
      cliente: null,
    };
  },
  watch: {
    tab(val) {
      LocalStorage.set("TAB_ACTUAL_CLIENTE", val);
    },
  },
  mounted() {
    var arreUrl = window.location.href.split("/");
    this.show(arreUrl.pop());
    var actualTab = LocalStorage.get("TAB_ACTUAL_CLIENTE");
    if (actualTab) {
      this.tab = actualTab;
    }
  },
  computed: {},
  methods: {
    show(id) {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: `cliente/` + id,
          data: {},
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            var cliente = result.data.cliente;
            this.cliente = cliente;
            this.$store.dispatch("showCliente", cliente);
            this.mostrar = true;
          } else {
            //document.location.href = "/";
          }
        });
    },
  },
};
</script>