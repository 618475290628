//import router from '@/js/router.js';
export const namespaced = true;

export const actions = {
    evaluar({ dispatch }, error) {
        if (error.response) {
            if (error.response.status === 403) {

                dispatch('notificaciones/add', { tipo: "warning", mensaje: error.response.data.message, show: true, dialog: true, tiempo: 5 }, { root: true });
                return;
            }
            if (error.response.status === 401) {
                dispatch('notificaciones/add', { tipo: "error", mensaje: error.response.data.message, show: true, dialog: true, tiempo: 5 }, { root: true });
                return;
            }
            if (error.response.status === 409) {

                //dispatch('notificaciones/add_dialog', { tipo: "warning", mensaje: error.response.data.message, show: true, dialog: false, tiempo: 5 }, { root: true });
                dispatch('notificaciones/add', { tipo: "warning", mensaje: error.response.data.message, show: true, dialog: true, tiempo: 5 }, { root: true });
                return;
            }
            if (error.response.status === 500) {
                dispatch('notificaciones/add', { tipo: "error", mensaje: error.response.data.message, show: true, dialog: true, tiempo: 5 }, { root: true });
                return;
            }
            if (error.response.status === 202) {
                dispatch('notificaciones/add', { tipo: "success", mensaje: error.response.data.message, show: true, dialog: true, tiempo: 5 }, { root: true });
                return;
            }

            dispatch('notificaciones/add', { tipo: "error", mensaje: error.response.data.message, show: true, dialog: true, tiempo: 5 }, { root: true });
        } else {
            dispatch('notificaciones/add', { tipo: "error", mensaje: "No pudimos conectar con el servidor", show: true, dialog: true, tiempo: 5 }, { root: true });
            console.log(error.config, error);
        }
    }
}
