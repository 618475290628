<template>
    <div class="component mt-4">
        <base-component>
            <div slot="component">
                <v-row>
                      <v-stepper v-model="e1" class="cool">
                        <v-stepper-header class="elevation-0">
                        <v-stepper-step :complete="e1 > 1" step="1">Datos generales</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="e1 > 2" step="2">Disponibilidad</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="3">Permisos</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="4">Contable</v-stepper-step>
                        </v-stepper-header>
                        <v-stepper-items>
                        <v-stepper-content step="1">
                            <datosGenerales></datosGenerales>
                        </v-stepper-content>
                        <v-stepper-content step="2">        
                            <disponibilidad></disponibilidad>
                        </v-stepper-content>
                        <v-stepper-content step="3">
                            <datosPermisos></datosPermisos>
                        </v-stepper-content>
                        <v-stepper-content step="4">
                            <datosSueldo></datosSueldo>
                        </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-row>
            </div>
        </base-component>
    </div>
</template>
<script>
import datosGenerales from '@/js/components/personal/steps_registro/stepDatosGenerales.vue';
import disponibilidad from  '@/js/components/personal/steps_registro/stepDisponibilidad.vue';
import datosSueldo from '@/js/components/personal/steps_registro/stepSueldo.vue';
import datosPermisos from '@/js/components/personal/steps_registro/stepPermisos.vue';

export default {
    components:{
        datosGenerales,
        disponibilidad,
        datosSueldo,
        datosPermisos
    },
    data() {
        return {
           
        };
    },
    mounted() {
        
    },
    computed: {
        e1(){
            return this.$store.getters.getPasoRegistroPersonal;
        }
    },
    methods: {
     
    }

};
</script>