<template>
  <div class="component">
    <base-component>
      <div slot="component">
        <BaseConfirmacion ref="confirmar"></BaseConfirmacion>

        <v-dialog v-model="dialog" max-width="700">
          <v-flex xs12 md12>
            <v-stepper v-model="e1">
              <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1"
                  >General</v-stepper-step
                >
                <v-divider></v-divider>
                <v-stepper-step :complete="e1 > 2" step="2">{{
                  paso2
                }}</v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content flat step="1">
                  <v-card dense class="mb-2" flat>
                    <v-container grid-list-md>
                      <v-form
                        dense
                        ref="form"
                        v-model="valid"
                        enctype="multipart/form-data"
                        lazy-validation
                      >
                        <v-layout row wrap>
                          <v-flex xs12 md4>
                            <v-text-field
                              v-model="egreso.monto"
                              label="Monto"
                              prefix="$"
                              type="number"
                              :rules="generalRegla"
                              prepend-icon="mdi-cash"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 md8>
                            <v-file-input
                              :rules="rulesFile"
                              placeholder="Maximo 4MB"
                              show-size
                              v-model="archivo"
                              hint="Puede ser documento o imagen"
                              prepend-icon="mdi-file-document"
                              small-chips
                              multiple
                              label="Archivo Comprobante"
                            ></v-file-input>
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                          <v-flex xs6>
                            <v-menu
                              v-model="menu2"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :value="computedDateFormatted"
                                  label="Dia"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  :rules="generalRegla"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                locale="es-mx"
                                v-model="egreso.fecha"
                                no-title
                                @input="menu2 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex xs6>
                            <v-menu
                              ref="menu"
                              v-model="menu_reloj"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="egreso.hora"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="egreso.hora"
                                  :rules="generalRegla"
                                  label="Hora"
                                  prepend-icon="mdi-av-timer"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-if="menu_reloj"
                                v-model="egreso.hora"
                                full-width
                                @click:minute="$refs.menu.save(egreso.hora)"
                              ></v-time-picker>
                            </v-menu>
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                          <v-flex xs12 md12>
                            <v-textarea
                              v-model="egreso.detalles"
                              rows="3"
                              label="Detalles"
                              filled
                            >
                            </v-textarea>
                          </v-flex>
                        </v-layout>
                        <p>Tipo de egreso</p>
                        <v-layout row wrap>
                          <v-flex class="d-flex justify-center" xs12 md12>
                            <v-radio-group dense v-model="tipo" row>
                              <v-radio
                                dense
                                color="orange"
                                label="Compras"
                                value="Compras"
                              ></v-radio>
                              <v-radio
                                dense
                                color="green"
                                label="Empleados"
                                value="Empleado"
                              ></v-radio>
                              <v-radio
                                dense
                                color="purple"
                                label="Sucursal"
                                value="Sucursal"
                              ></v-radio>
                              <v-radio
                                dense
                                color="blue"
                                label="Socios"
                                value="Socio"
                              ></v-radio>
                              <v-radio
                                dense
                                color="yellow"
                                label="Otro"
                                value="Otro"
                              ></v-radio>
                            </v-radio-group>
                          </v-flex>
                        </v-layout>
                      </v-form>
                      <v-layout row wrap>
                        <v-spacer></v-spacer>
                        <v-btn @click="cancelar()" text>Cancelar</v-btn>
                        <v-btn
                          color="primary"
                          class="rounded-pill pa-3"
                          :disabled="!valid"
                          @click="continuar()"
                        >
                          Continuar
                        </v-btn>
                      </v-layout>
                    </v-container>
                  </v-card>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-card class="mb-2" dense flat>
                    <otro ref="refOtro" v-if="forms.Otro"></otro>
                    <socio ref="refSocio" v-if="forms.Socio"></socio>
                    <empleado
                      ref="refEmpleado"
                      v-if="forms.Empleado"
                    ></empleado>
                    <compras ref="refCompras" v-if="forms.Compras"></compras>
                    <negocio ref="refSucursal" v-if="forms.Sucursal"></negocio>
                    <v-container grid-list-md>
                      <v-layout row wrap>
                        <v-spacer></v-spacer>
                        <v-btn @click="e1 = 1" text>Regresar</v-btn>
                        <v-btn
                          color="primary"
                          class="rounded-pill pa-3"
                          @click="finalizar()"
                        >
                          Finalizar
                        </v-btn>
                      </v-layout>
                    </v-container>
                  </v-card>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-flex>
        </v-dialog>
      </div>
    </base-component>
  </div>
</template>
<script>
import otro from "@/js/components/finanzas/egresos/nuevo/otroEgresoForm.vue";
import socio from "@/js/components/finanzas/egresos/nuevo/socioEgresoForm.vue";
import empleado from "@/js/components/finanzas/egresos/nuevo/empleadoEgresoForm.vue";
import compras from "@/js/components/finanzas/egresos/nuevo/comprasEgresoForm.vue";
import negocio from "@/js/components/finanzas/egresos/nuevo/negocioEgresoForm.vue";

export default {
  components: {
    otro,
    socio,
    empleado,
    compras,
    negocio,
  },
  data() {
    return {
      paso2: "Compras",
      dialog: false,
      valid: true,
      e1: 1,
      rulesFile: [
        (value) =>
          !value ||
          value.length == 0 ||
          value[0].size < 4000000 ||
          "Debe ser menor a 4 MB!",
      ],
      generalRegla: [(v) => !!v || "Se necesita el campo"],

      tipo: "Compras",
      egreso: {
        monto: "",
        detalles: "",
        archivo: null,
        fecha: null,
        hora: null,
      },
      forms: {
        Otro: false,
        Socio: false,
        Sucursal: false,
        Compras: true,
        Empleado: false,
      },
      archivo: null,
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: new Date().toISOString().substr(0, 10),
      menu2: false,
      menu_reloj: false,
    };
  },
  watch: {
    tipo(val) {
      this.paso2 = val;
      var obj = this.forms;

      for (const prop in obj) {
        if (prop === val) {
          obj[prop] = true;
        } else {
          obj[prop] = false;
        }
      }
    },
    archivo(val) {
      if (val != null) {
        this.egreso.archivo = val[0];
      }
    },
    date(val) {
      this.dateFormatted = this.formatDate(val);
    },
  },
  mounted() {},
  computed: {
    computedDateFormatted() {
      if (this.egreso.fecha) {
        return this.formatDate(this.egreso.fecha);
      } else {
        return "";
      }
    },
  },
  methods: {
    mostrar() {
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    continuar() {
      if (this.$refs.form.validate()) {
        this.e1 = 2;
      }
    },
    finalizar() {
      var obj = this.forms;
      let respuesta = false;
      for (const prop in obj) {
        if (prop === this.paso2) {
          //var today = new Date();
          //var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
          //var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
          var date = this.egreso.fecha;
          var time = this.egreso.hora;

          switch (prop) {
            case "Otro":
              respuesta = this.$refs.refOtro.datos();
              if (respuesta) {
                this.enviarOtro(time, date, respuesta);
              }
              break;
            case "Empleado":
              respuesta = this.$refs.refEmpleado.datos();
              if (respuesta) {
                this.enviarEmpleado(time, date, respuesta);
              }
              break;
            case "Compras":
              respuesta = this.$refs.refCompras.datos();
              if (respuesta) {
                this.enviarCompras(time, date, respuesta);
              }
              break;
            case "Sucursal":
              respuesta = this.$refs.refSucursal.datos();
              if (respuesta) {
                this.enviarSucursal(time, date, respuesta);
              }
              break;
            case "Socio":
              respuesta = this.$refs.refSocio.datos();
              if (respuesta) {
                this.enviarSocio(time, date, respuesta);
              }
              break;
            default:
              respuesta = false;
              break;
          }
        }
      }
    },
    enviarOtro(hora, fecha, respuesta) {
      var today = new Date();
      // var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      // var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

      let formData = new FormData();
      formData.append("monto", this.egreso.monto);
      formData.append("fecha", fecha);
      formData.append("hora", hora);
      formData.append(
        "detalles",
        this.egreso.detalles != null ? this.egreso.detalles : ""
      );
      formData.append(
        "observaciones",
        respuesta.observaciones != null ? respuesta.observaciones : ""
      );
      formData.append("archivo", this.egreso.archivo);
      formData.append("tipo_otro_id", respuesta.tipo_otro_id);

      let dispatch = "UsersModule/OnSave";
      let endpoint = "otro";

      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: formData,
        })
        .then((result) => {
          this.dialog = false;
          this.$refs.form.reset();
          this.$refs.refOtro.limpiar();
          this.e1 = 1;
          this.tipo = "Compras";
          this.egreso.fecha = null;
          this.egreso.hora = null;
          this.resolve(result.data.egreso);
        });
    },
    enviarSocio(hora, fecha, respuesta) {
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

      let formData = new FormData();
      formData.append("monto", this.egreso.monto);
      formData.append("fecha", fecha);
      formData.append("hora", hora);
      formData.append("detalles", this.egreso.detalles);
      formData.append("archivo", this.egreso.archivo);
      formData.append("tipo_id", respuesta.tipo_id);
      formData.append("persona_id", respuesta.persona_id);
      formData.append("observaciones", respuesta.observaciones);

      let dispatch = "UsersModule/OnSave";
      let endpoint = "socio";

      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: formData,
        })
        .then((result) => {
          this.dialog = false;
          this.$refs.form.reset();
          this.$refs.refSocio.limpiar();
          this.e1 = 1;
          this.tipo = "Compras";
          this.egreso.fecha = null;
          this.egreso.hora = null;
          this.resolve(result.data.egreso);
        });
    },
    enviarEmpleado(hora, fecha, respuesta) {
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

      let formData = new FormData();
      formData.append("monto", this.egreso.monto);
      formData.append("fecha", fecha);
      formData.append("hora", hora);
      formData.append("detalles", this.egreso.detalles);
      formData.append("archivo", this.egreso.archivo);
      formData.append("tipo_id", respuesta.tipo_id);
      formData.append("persona_id", respuesta.persona_id);
      formData.append("observaciones", respuesta.observaciones);

      let dispatch = "UsersModule/OnSave";
      let endpoint = "pago_empleado";

      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: formData,
        })
        .then((result) => {
          this.dialog = false;
          this.$refs.form.reset();
          this.$refs.refEmpleado.limpiar();
          this.e1 = 1;
          this.tipo = "Compras";
          this.egreso.fecha = null;
          this.egreso.hora = null;
          this.resolve(result.data.egreso);
        });
    },
    enviarCompras(hora, fecha, respuesta) {
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

      let formData = new FormData();
      formData.append("monto", this.egreso.monto);
      formData.append("fecha", fecha);
      formData.append("hora", hora);
      formData.append("detalles", this.egreso.detalles);
      formData.append("archivo", this.egreso.archivo);
      formData.append("tipo_id", respuesta.tipo_id);
      formData.append("proveedor_id", respuesta.proveedor_id);
      formData.append("observaciones", respuesta.observaciones);

      let dispatch = "UsersModule/OnSave";
      let endpoint = "compra";

      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: formData,
        })
        .then((result) => {
          this.dialog = false;
          this.$refs.form.reset();
          this.$refs.refCompras.limpiar();
          this.e1 = 1;
          this.tipo = "Compras";
          this.forms.compras = true;
          this.egreso.fecha = null;
          this.egreso.hora = null;
          this.resolve(result.data.egreso);
        });
    },
    enviarSucursal(hora, fecha, respuesta) {
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

      let formData = new FormData();
      formData.append("monto", this.egreso.monto);
      formData.append("fecha", fecha);
      formData.append("hora", hora);
      formData.append("detalles", this.egreso.detalles);
      formData.append("archivo", this.egreso.archivo);
      formData.append("tipo_id", respuesta.tipo_id);
      formData.append("negocio_id", respuesta.negocio_id);
      formData.append("observaciones", respuesta.observaciones);

      let dispatch = "UsersModule/OnSave";
      let endpoint = "egreso_negocio";

      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: formData,
        })
        .then((result) => {
          this.dialog = false;
          this.$refs.form.reset();
          this.$refs.refSucursal.limpiar();
          this.e1 = 1;
          this.tipo = "Compras";
          this.forms.compras = true;
          this.egreso.fecha = null;
          this.egreso.hora = null;
          this.resolve(result.data.egreso);
        });
    },

    cancelar() {
      this.dialog = false;
      this.resolve(false);
    },
  },
};
</script>