<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title primary-title> Cola Total </v-card-title>
      <v-card-subtitle>
        La cola total sirve para agrupar tadas las colas de los clientes que
        administra el mikrotik para poder distriburi de mejor forma la cantidad
        total del velocidad que recibe su mikrotik
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation dense>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 md6>
                <v-text-field
                  label="Velocidad de bajada"
                  v-model="datos.bajada"
                  :rules="velocidadRegla"
                  prepend-icon="mdi-cloud-download"
                  type="number"
                  suffix="MB"
                  hint="Velocidad total de descarga que administrara este mikrotik"
                  persistent-hint
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  label="Velocidad de subida"
                  v-model="datos.subida"
                  :rules="velocidadRegla"
                  prepend-icon="mdi-cloud-upload"
                  type="number"
                  hint="Velocidad total de subida que administrara este mikrotik"
                  persistent-hint
                  suffix="MB"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout row wrap>
              Redes que administrara este mikrotik es decir los rangos de Ips
              que tendran los clientes
              <br />
              Ejemplo:
              <br />
              192.168.1.0/24
              <br />
              192.168.2.0/24
            </v-layout>
            <div v-for="(red, index) in datos.redes" :key="index">
              <v-layout row wrap>
                <v-flex xs10 md6>
                  <v-text-field
                    label="red"
                    v-model="datos.redes[index]"
                    :rules="generalRegla"
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 md2>
                  <v-btn
                    v-if="index >= 1"
                    @click="quitarRed(index)"
                    color="primary"
                    ><v-icon>mdi-minus</v-icon></v-btn
                  >
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 md12>
                  <v-btn
                    v-if="index == datos.redes.length - 1"
                    @click="agregarRed()"
                    color="success"
                    ><v-icon>mdi-plus</v-icon></v-btn
                  >
                </v-flex>
              </v-layout>
            </div>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="cancelar()" text>Cancelar</v-btn>
            <v-btn
              :disabled="!valid"
              @click="registrar()"
              class="rounded-pill"
              color="primary"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Common from "@/js/Common";
export default {
  data() {
    return {
      dialog: false,
      datos: {
        id: 0,
        subida: 0,
        bajada: 0,
        redes: [""],
      },

      velocidadRegla: [
        (v) => !!v || "Se necesita el campo",
        (v) => v > 0 || "necesita ser mayor a 0",
      ],
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      valid: true,
      reject: null,
      resolve: null,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    esperarDatos(router_id) {
      this.getDatos(router_id);
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    getDatos(id) {
      let dispatch = "UsersModule/OnGet";
      let endpoint = "cola/total/router/" + id;
      this.$store
        .dispatch(dispatch, {
          url: endpoint,
          data: {},
        })
        .then((result) => {
          if ([200, 201].includes(result.status)) {
            this.datos.id = id;
            if (result.data.cola) {
              var velocidad = result.data.cola["max-limit"].split("/");
              this.datos.subida = velocidad[0] / 1000000;
              this.datos.bajada = velocidad[1] / 1000000;
              this.datos.redes = result.data.cola.target.split(",");
            }
          }
        });
    },
    cancelar() {
      this.limpiar();
      this.dialog = false;
      this.resolve(false);
    },
    limpiar() {
      this.$refs.form.reset();
    },
    registrar() {
      if (this.$refs.form.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "router/crear/cola/total/" + this.datos.id;
        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.datos,
          })
          .then((result) => {
            this.limpiar();
            this.dialog = false;
            this.resolve(result.data.cola);
          });
      }
    },
    agregarRed() {
      this.datos.redes.push("");
    },
    quitarRed(index) {
      this.datos.redes.splice(index, 1);
    },
  },
};
</script>