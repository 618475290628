<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-container grid-list-md>
          <v-card-title primary-title> Generar Facturas </v-card-title>
          <v-card-subtitle>
            Se crearan los pagos de los clientes selecionados.
            <br />Nota: Los pagos se creearan en status pendiente, si el cliente
            ya cuenta con un pago creado con el mes y año seleccionado el
            sistema no lo creera.
          </v-card-subtitle>
          <v-form dense @submit.prevent="enviar()" ref="form" v-model="valid">
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <v-select
                    v-model="mes"
                    :items="meses"
                    item-text="mes"
                    item-value="id"
                    label="Mes"
                    prepend-icon="mdi-city"
                    :rules="generalRegla"
                    no-data-text="No hay elementos registrados"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 md6>
                  <v-select
                    v-model="anio"
                    :items="anios"
                    item-text="nombre"
                    item-value="id"
                    label="Año"
                    prepend-icon="mdi-city"
                    :rules="generalRegla"
                    no-data-text="No hay elementos registrados"
                  >
                  </v-select>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="cancelar()" text>Cancelar</v-btn>
              <v-btn
                type="submit"
                :disabled="!valid"
                color="success"
                class="rounded-pill pa-3"
                >Generar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Common from "@/js/Common";

export default {
  components: {},
  data() {
    return {
      dialog: false,
      mes: "",
      anio: "",
      meses: [],
      anios: [],
      valid: true,
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      seleccionados: [],
      resolve: null,
      reject: null,
      anios: Common.anios(),
      meses: Common.meses(),
    };
  },
  mounted() {},
  computed: {},
  methods: {
    cargarDatos(elementos) {
      this.seleccionados = elementos;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    enviar() {
      if (this.$refs.form.validate()) {
        let datos = {
          mes: this.mes,
          anio: this.anio,
          selecionados: this.seleccionados,
        };

        this.$store
          .dispatch("UsersModule/OnSave", {
            url: `pagos/generar/facturas/masivas`,
            data: datos,
          })
          .then((result) => {
            if ([200, 201].includes(result.status)) {
              this.dialog = false;
              this.resolve(result.data.facturas);
            }
          });
      }
    },
    cancelar() {
      this.dialog = false;
      this.resolve(false);
    },
  },
};
</script>