<template>
  <v-layout row wrap>
    <v-flex xs12>
    
  <v-card flat>
    <v-card-title v-if="arrastre" class="headline"> Ubicación </v-card-title>
    <v-card-text>
      <v-row>
        <v-col v-if="arrastre" cols="12" md="6" class="pb-0">
          <v-text-field
            label="latitud"
            v-model="marker.lat"
            filled
            prepend-inner-icon="mdi-map-marker"
          ></v-text-field>
        </v-col>
        <v-col v-if="arrastre" cols="12" md="6" class="pb-0">
          <v-text-field
            label="longitud"
            v-model="marker.lng"
            filled
            prepend-inner-icon="mdi-map-marker"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" class="pt-0">
          <div style="height: 550px; width: 100%">
            <l-map
              v-if="showMap"
              :zoom="zoom"
              :center="center"
              :options="mapOptions"
              @update:center="centerUpdate"
              style="border-radius: 20px"
              class="shadow"
              @update:zoom="zoomUpdate"
            >
              <l-tile-layer :url="url" :attribution="attribution" />
              <l-marker
                v-if="marker"
                :lat-lng.sync="marker"
                :icon="marker.icon"
                :draggable="arrastre"
                
              >
                <l-tooltip :content="label" />
                
                <l-icon
                  :icon-url="'img/' + icono"
                  :icon-anchor="[120, 140]"
                  :icon-size="[230, 160]"
                />
              </l-marker>
              <div v-for="cliente in clientes" :key="cliente.id">
              <l-marker
                v-if="cliente.latitud && cliente.longitud"
                :key="cliente.id"
                :visible="cliente.visible"
                :lat-lng.sync="cliente.position"
                :icon="marker.icon"
                @click="verCliente(cliente.id)"
              >
                <l-tooltip style="width: 300px;">
                  <v-card flat>
                    <v-img
                            v-if="cliente.antena.cpe.imagen"
                            :src="cliente.antena.cpe.imagen"
                            width="100%"
                            height="200px"
                          ></v-img>
                          <v-card-title class="headline" primary-title>
            <strong>{{ cliente.FullName }}</strong>
            <v-spacer></v-spacer>
            <v-icon @click="verAntena()">mdi-dots-horizontal</v-icon>
          </v-card-title>
          <v-card-text>
            {{
              cliente.direccion +
              " " +
              cliente.colonia +
              " " +
              cliente.municipio
            }}
            <br />
            <v-chip @click="abrirIp(cliente.antena.ip)" dark color="blue" small
              ><a>IP:{{ cliente.antena.ip }}</a></v-chip
            >
            <v-chip dark small color="purple">{{cliente.antena.cpe.producto}}</v-chip>
            <v-chip
              small
              v-if="cliente.status_cliente_id == 1"
              class="ma-2"
              color="green"
              text-color="white"
            >
              En proceso de instalación
              <v-icon right>mdi-worker</v-icon>
            </v-chip>
            <v-chip
              small
              v-if="cliente.status_cliente_id == 2"
              class="ma-2"
              color="green"
              text-color="white"
            >
              Activo
              <v-icon right>mdi-wifi</v-icon>
            </v-chip>
            <v-chip v-if="cliente.status_cliente_id == 3" class="ma-2" color="red" text-color="white">
              Suspendido
              <v-icon right>mdi-wifi-off</v-icon>
            </v-chip>
            <v-chip v-if="cliente.status_cliente_id == 4" class="ma-2" color="purple" text-color="white">
              Pendiente
              <v-icon right>mdi-clock</v-icon>
            </v-chip>
            <v-chip dark color="green" small>{{ cliente.celular }}</v-chip>
          </v-card-text>
                </v-card>
                </l-tooltip>

                <l-icon
                  icon-url="img/markerCasa4.png"
                  :icon-anchor="staticAnchor"
                  :icon-size="[70, 70]"
                />
              </l-marker>
            </div>
            <div v-for="cliente in clientes" :key="cliente.FullName">
              <l-polyline :lat-lngs="[marker,cliente.position]">
                <l-popup :content="calcularDistancia(marker.lat,marker.lng,cliente.position.lat,cliente.position.lng)" />
              </l-polyline>
              
            </div>
            
            </l-map>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
    
</v-flex>
  </v-layout>
</template>

<script>
import { latLng, icon } from "leaflet";
import LocalStorage from "@/js/LocalStorage.js";

import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LTooltip,
  LIcon,
  LPolyline
} from "vue2-leaflet";

export default {
  name: "Example",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon,
    LPolyline
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      marker: latLng(23.0, -102.0),
      zoom: 13,
      currentZoom: 11.5,
      center: latLng(23.0, -102.0),
      currentCenter: latLng(23.0, -102.0),
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: false,
      staticAnchor: [26, 47],
      staticAnchorTorre: [12, 41],
      latitudInicial: null,
      longitudInicial: null,
      icono: "marker.png",
      label: "Ubicación",
      arrastre: true,
      clientes: [],
      travel: [[16.999875434704, -96.432287501434], [17.059410363792, -96.728610992432]]
    };
  },
  mounted() {},
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    },
    mostrarMarker(clientes, latitud, longitud, icono, label, arrastre) {
      if (icono) {
        this.icono = icono;
      }
      if (label) {
        this.label = label;
      }
      this.arrastre = arrastre;
      if (
        latitud != "null" &&
        longitud != "null" &&
        latitud != null &&
        longitud != null &&
        latitud != "" &&
        longitud != ""
      ) {
        this.marker = latLng(latitud, longitud);
        
        this.center = latLng(latitud, longitud);
        this.zoom = 16;
        this.currentZoom = 16;
        this.showMap = true;
      } else {
        this.getGps();
      }
      this.crearMarcadoresClientes(clientes);
    },
    crearMarcadoresClientes(clientes) {
      
      for (let index = 0; index < clientes.length; index++) {
        var cliente = clientes[index];
        cliente.position = { lat: cliente.latitud, lng: cliente.longitud };
        cliente.draggable= false;
        cliente.visible= true;
      }
      this.clientes = clientes;
      
    },
    getGps() {
      this.$store.dispatch("mostrarCarga", true);

      const error = (err) => {
        this.marker = latLng(23.0, -102.0);
        this.center = latLng(23.0, -102.0);
        this.zoom = 4.5;
        this.showMap = true;
        this.latitudInicial = 23.0;
        this.longitudInicial = -102.0;

        this.$store.dispatch("mostrarCarga", false);
      };
      const success = (position) => {
        this.marker = latLng(
          position.coords.latitude,
          position.coords.longitude
        );
        this.center = latLng(
          position.coords.latitude,
          position.coords.longitude
        );
        this.zoom = 16.5;
        this.showMap = true;
        this.latitudInicial = position.coords.latitude;
        this.longitudInicial = position.coords.longitude;
        this.$store.dispatch("mostrarCarga", false);
      };
      navigator.geolocation.getCurrentPosition(success, error);
    },
    getUbicacion() {
      if (
        this.marker.lat + "" == this.latitudInicial + "" &&
        this.marker.lng + "" == this.longitudInicial + ""
      ) {
        return null;
      }
      return this.marker;
    },
    getLatitud() {
      if (this.marker.lat + "" == this.latitudInicial + "") {
        return null;
      }
      return this.marker.lat;
    },
    getLongitud() {
      if (this.marker.lng + "" == this.longitudInicial + "") {
        return null;
      }
      return this.marker.lng;
    },
    calcularTextoAVG(avg) {
      if (avg == null) {
        return "Sin vida :(";
      }
      if (avg == 0) {
        return "Perfección :)";
      } else {
        return avg;
      }
    },
    verCliente(id) {
      LocalStorage.set("TAB_ACTUAL_CLIENTE", 1);
      document.location.href = "detalles/cliente/" + id;
    },
    abrirIp(ip) {
      window.open("https://" + ip, "_blank");
    },
    gradosARadianes(grados) {
      return (grados * Math.PI) / 180;
    },
    calcularDistancia(lat1, lon1, lat2, lon2) {
      const radioTierraKm = 6371; // Radio de la Tierra en kilómetros
      const dLat = this.gradosARadianes(lat2 - lat1);
      const dLon = this.gradosARadianes(lon2 - lon1);

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.gradosARadianes(lat1)) *
        Math.cos(this.gradosARadianes(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distancia = radioTierraKm * c; // Distancia en kilómetros
      return distancia.toFixed(1)+"km";
    }
  },
};
</script>
