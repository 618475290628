<template>
  <div>
    <BaseConfirmacion ref="confirmar"></BaseConfirmacion>
    <v-row justify="center">
      <v-dialog v-model="dialogProcedencia" max-width="300" persistent>
        <v-card>
          <v-container grid-list-md>
            <v-card-title class="headline" primary-title>
              <strong>Nueva</strong>
            </v-card-title>
            <v-form
              dense
              ref="formProcedencia"
              v-model="validProcedencia"
              lazy-validation
            >
              <v-container grid-list-md>
                <v-text-field
                  label="Prodencia"
                  v-model="procedencia.procedencia"
                  prepend-icon="mdi-cube-send"
                  :rules="generalRegla"
                ></v-text-field>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialogProcedencia = false">Cancelar</v-btn>
                <v-btn
                  color="primary"
                  class="rounded-pill pa-3"
                  @click="nuevaProcedencia()"
                >
                  Registrar
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
    <v-layout row wrap>
      <v-flex xs12 md12>
        <v-container grid-list-md>
          <v-form
            dense
            ref="form"
            v-model="valid"
            enctype="multipart/form-data"
            lazy-validation
          >
            <v-layout row wrap>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <v-text-field
                    label="Monto"
                    v-model="ingreso.monto"
                    :rules="generalRegla"
                    prepend-icon="mdi-cash-usd"
                    type="number"
                    prefix="$"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-select
                    v-model="ingreso.procedencia_id"
                    :items="procedencias"
                    item-text="procedencia"
                    item-value="id"
                    label="Procedencia"
                    :rules="generalRegla"
                    prepend-icon="mdi-cube-send"
                    no-data-text="No hay elementos registrados"
                  >
                    <template v-slot:append-item>
                      <v-divider class="mb-2"></v-divider>
                      <v-list-item @click="dialogProcedencia = true">
                        <v-list-item-avatar color="grey lighten-3">
                          <v-icon>mdi-cube-send</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>Agregar</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs6>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="computedDateFormatted"
                        label="Dia"
                        prepend-icon="mdi-calendar"
                        readonly
                        :rules="generalRegla"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="es-mx"
                      v-model="ingreso.fecha"
                      no-title
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs6>
                  <v-menu
                    ref="menu"
                    v-model="menu_reloj"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="ingreso.hora"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="ingreso.hora"
                        label="Hora"
                        :rules="generalRegla"
                        prepend-icon="mdi-av-timer"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_reloj"
                      v-model="ingreso.hora"
                      full-width
                      @click:minute="$refs.menu.save(ingreso.hora)"
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 md12>
                  <v-file-input
                    :rules="rulesFile"
                    placeholder="2MB"
                    show-size
                    v-model="archivo"
                    hint="Puede ser documento o imagen"
                    prepend-icon="mdi-file-document"
                    small-chips
                    multiple
                    label="Archivo Comprobante"
                  ></v-file-input>
                </v-flex>
                <v-flex xs12 md12>
                  <v-textarea
                    v-model="ingreso.detalles"
                    label="Detalles"
                    rows="3"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-form>
        </v-container>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      ingreso: {
        monto: "",
        detalles: "",
        archivo: null,
        fecha: null,
        hora: null,
        procedencia_id: "",
      },
      procedencia: {
        procedencia: "",
      },
      valid: true,
      procedencias: [],
      archivo: null,
      validProcedencia: true,
      dialogProcedencia: false,
      rulesFile: [
        (value) =>
          !value ||
          value.length == 0 ||
          value[0].size < 2000000 ||
          "Debe ser menor a 2 MB!",
      ],
      generalRegla: [(v) => !!v || "Se necesita el campo"],
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: new Date().toISOString().substr(0, 10),
      menu2: false,
      menu_reloj: false,
    };
  },
  mounted() {
    this.getDatosInicio();
  },
  watch: {
    archivo(val) {
      if (val != null) {
        this.ingreso.archivo = val[0];
      }
    },
    date(val) {
      this.dateFormatted = this.formatDate(val);
    },
  },
  computed: {
    computedDateFormatted() {
      if (this.ingreso.fecha) {
        return this.formatDate(this.ingreso.fecha);
      } else {
        return "";
      }
    },
  },
  methods: {
    nuevo() {
      if (this.$refs.form.validate()) {
        //var today = new Date();
        //var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        //var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

        let formData = new FormData();

        formData.append("monto", this.ingreso.monto);
        formData.append("detalles", this.ingreso.detalles);
        formData.append("archivo", this.ingreso.archivo);
        formData.append("hora", this.ingreso.hora);
        formData.append("fecha", this.ingreso.fecha);
        formData.append("procedencia_id", this.ingreso.procedencia_id);

        return formData;
      } else {
        return null;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    limpiar() {
      this.$refs.form.reset();
      this.archivo = null;
    },
    getDatosInicio() {
      this.$store
        .dispatch("UsersModule/OnGet", {
          url: "datos/registro/ingreso",
          data: {},
        })
        .then((result) => {
          this.procedencias = result.data.procedencias;
        });
    },

    nuevaProcedencia() {
      if (this.$refs.formProcedencia.validate()) {
        let dispatch = "UsersModule/OnSave";
        let endpoint = "procedencia";

        this.$store
          .dispatch(dispatch, {
            url: endpoint,
            data: this.procedencia,
          })
          .then((result) => {
            this.procedencias.unshift(result.data.procedencia);
            this.ingreso.procedencia_id = result.data.procedencia.id;
            this.dialogProcedencia = false;
            this.$refs.formProcedencia.reset();
          });
      }
    },
  },
};
</script>